import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_STAND_TYPES_GROUP } from "graphql/query/stand-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import Stand from "components/stand";
import StandTypeRoute from "components/routes/stand/stand-route";
import Localize, { localizeByKey } from "components/service/localize";
import { TStand } from 'components/stand/helpers/stand-types-helper';


interface IStandsGroup
{
  standTypesGroup: TStand[];
}


const StandPage: React.FC = () =>
{
  useActiveMenuItem([ "stands" ], [ "stands" ]);

  const basePath = Stand.Const.basePath;
  const { group_id, lang } = useParams<{ group_id: string; lang: string }>();
  const groupID = group_id ? parseInt(group_id) : null;

  const containsCreate = (url: string) => url.includes("create");
  const conditionCreate = containsCreate(window.location.pathname);

  const { data, loading } = useQuery<IStandsGroup>(
    GET_STAND_TYPES_GROUP,
    {
      skip: !groupID,
      variables: {
        group_id: groupID,
      },
      fetchPolicy: "cache-and-network",
      // nextFetchPolicy: "cache-first",
    }
  );

  const standType = data?.standTypesGroup?.filter((item) => item?.lang === lang)[ 0 ];

  const pageNavbar = [
    {
      label: localizeByKey("NAVBAR_PAGES.Label_Overview"),
      path: "",
    },
    {
      label: localizeByKey("NAVBAR_PAGES.Label_Equipments"),
      path: "equipments",
    },
    {
      label: localizeByKey("NAVBAR_PAGES.Label_Floorings"),
      path: "floorings",
    },
    {
      label: localizeByKey("NAVBAR_PAGES.Label_Files"),
      path: "files",
    },
    {
      label: localizeByKey("NAVBAR_PAGES.Label_Notes"),
      path: "notes",
    },
  ];

  return (
    <PageWrapDefault
      className="page-stand"
      loading={loading}
      title={
        conditionCreate ? (
          <Localize>PAGES.Title_CreateStand</Localize>
        ) : !loading && standType ? (
          standType.title ? (
            standType.title
          ) : (
            <Localize>VARIABLE.Label_EMPTY</Localize>
          )
        ) : (
          "Page 404"
        )
      }
      dataExist={conditionCreate ? true : Boolean(!loading && standType)}
      pageNavbar={!loading && standType ? pageNavbar : undefined}
      staticPath={`${basePath}/edit/${groupID}/${lang}`}
    >
      <StandTypeRoute
        conditionCreate={conditionCreate}
        stand={standType}
        standTypesGroup={data?.standTypesGroup as TStand[]}
        lang={lang || ""}
      />
    </PageWrapDefault>
  );
};

export default StandPage;
