import ExhibitionInfoField from "./exhibition-info-field";
import ExhibitionActionField from "./exhibition-action-field";
import ExhibitionStatus from "./exhibition-status-field";
import ExhibitionRemoveField from "./exhibition-remove-field";

const ExhibitionFields = {
  Info: ExhibitionInfoField,
  Status: ExhibitionStatus,
  Remove: ExhibitionRemoveField,
  Action: ExhibitionActionField,
};

export default ExhibitionFields;
