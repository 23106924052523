import React, { FunctionComponent, JSX } from "react";
import Icon from "@ant-design/icons";

import { IIcon } from "./icons-interfaces";

const SettingsSvg = () => (

	<svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1">
		<style type="text/css">
			{ `.st0{ opacity:0.5;}` }
		</style>
		<g>
			<g>
				<path className="st0" d="M24.4,32.5c-4.2,0-7.6-3.4-7.6-7.6s3.4-7.6,7.6-7.6s7.6,3.4,7.6,7.6S28.6,32.5,24.4,32.5z" />
				<path d="M42.4,21.8l-3.3-0.6c-0.4-1.4-0.9-2.8-1.7-4l1.9-2.8c0.6-0.6,0.6-1.6,0-2.3l-2.2-2.2c-0.6-0.6-1.6-0.6-2.3,0l-2.8,1.9
			c-1.2-0.7-2.6-1.3-4-1.7l-0.6-3.3c0-0.9-0.7-1.6-1.6-1.6h-3.1c-0.9,0-1.6,0.7-1.6,1.6l-0.6,3.3c-1.4,0.4-2.8,0.9-4,1.7l-2.8-1.9
			c-0.6-0.6-1.6-0.6-2.3,0l-2.2,2.2c-0.6,0.6-0.6,1.6,0,2.3l1.9,2.8c-0.7,1.2-1.3,2.6-1.7,4l-3.3,0.6c-0.9,0-1.6,0.7-1.6,1.6v3.1
			c0,0.9,0.7,1.6,1.6,1.6l3.3,0.6c0.4,1.4,0.9,2.8,1.7,4l-1.9,2.8c-0.6,0.6-0.6,1.6,0,2.3l2.2,2.2c0.6,0.6,1.6,0.6,2.3,0l2.8-1.9
			c1.2,0.7,2.6,1.3,4,1.7l0.6,3.3c0,0.9,0.7,1.6,1.6,1.6h3.1c0.9,0,1.6-0.7,1.6-1.6l0.6-3.3c1.4-0.4,2.8-0.9,4-1.7l2.8,1.9
			c0.6,0.6,1.6,0.6,2.3,0l2.2-2.2c0.6-0.6,0.6-1.6,0-2.3l-1.9-2.8c0.7-1.2,1.3-2.6,1.7-4l3.3-0.6c0.9,0,1.6-0.7,1.6-1.6v-3.1
			C44,22.5,43.3,21.8,42.4,21.8z M24.4,32.5c-4.2,0-7.6-3.4-7.6-7.6c0-4.2,3.4-7.6,7.6-7.6c4.2,0,7.6,3.4,7.6,7.6
			C32,29.1,28.6,32.5,24.4,32.5z"/>
			</g>
		</g>
	</svg>
);

const SettingsMenuIcon: FunctionComponent<IIcon> = (): JSX.Element => {
	return <Icon component={ SettingsSvg } />;
};


export default SettingsMenuIcon;


