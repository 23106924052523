import { useMutation } from "@apollo/client";
import { successNotification } from "components/request-result";
import { GET_USERS } from "graphql/query/user-gql";
import { USER_SEND_PASSWORD } from "graphql/mutation/user-gql";

const useMutationUserSendPass = () => {
    const [_setMutationUserSendPass, { loading }] = useMutation(
        USER_SEND_PASSWORD,
        {
            update(cache, { data }) {
                const {
                    userSendPassword: { label, message },
                } = data;

                successNotification({
                    title: label.toUpperCase(),
                    description: message,
                });
            },
            refetchQueries: [
                {
                    query: GET_USERS,
                },
            ],
        }
    );

    return {
        _setMutationUserSendPass,
        loadingMutationSendPass: loading,
    };
};

export default useMutationUserSendPass;
