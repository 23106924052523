import React from "react";
import { Routes, Route } from "react-router-dom";
import SubPage from "components/pages/user/sub-pages";
import type { TUser } from "components/user/helpers/user-types-helper";

interface IUserRouteProps
{
    user: TUser | undefined;
}

const UserRoute: React.FC<IUserRouteProps> = ({ user }) =>
{
    return (
        <Routes>
            <Route path="/" element={<SubPage.OverView user={user} />} />
        </Routes>
    );
};

export default UserRoute;
