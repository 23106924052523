import React from "react";
import { Form, Input, Button } from "antd";
import { GET_ME } from "graphql/query/user-gql";
import { GET_SUPPLIER_CURSOR } from "graphql/query/supplier-gql";
import { useApolloClient } from "@apollo/client";

import { flooringUseMutation } from "../../hooks";

import { Localize, EntityRemoveButton } from "components/service";
import { localizeByKey } from "components/service/localize";
import { FormElements } from "components/layout";
import Variable from "components/variable";
import { TFlooring } from "../../helpers/flooring-types-helper";
import Icons from "components/icons";


interface IFlooringEditFormProps
{
  flooring: TFlooring | undefined;
}


const FlooringEditForm: React.FC<IFlooringEditFormProps> = ({ flooring }) =>
{
  const [ form ] = Form.useForm();

  const client = useApolloClient();
  const cachedMe = client.readQuery({
    query: GET_ME,
  });

  const disabledColor = cachedMe.me.roles.filter(
    ({ slug }: { slug: string }) => slug === "role_library_manager"
  ).length ? true : false;

  const { _setFlooringCreateUpdate, loadingMutationFlooringCreateUpdate } =
    flooringUseMutation.createUpdate(flooring?.id);

  const { _setMutationFlooringDelete, loadingMutationFlooringDelete } =
    flooringUseMutation.delete(flooring?.id);


  return (
    <Form
      key="edit-flooring-form"
      layout="vertical"
      form={form}
      className="model-form edit-flooring-form"
      onFinish={(values) =>
      {
        _setFlooringCreateUpdate({
          variables: {
            input: {
              id: flooring?.id ? +flooring.id : undefined,
              ...values,
            },
          },
        });
      }}
    >
      <Form.Item
        name="title"
        label={<Localize>FORMS.Input_Label_Name</Localize>}
        initialValue={flooring?.title}
        rules={[
          {
            required: true,
            message: <Localize>FORM_RULES.Required_Name</Localize>,
          },
        ]}
      >
        <Input name="title" />
      </Form.Item>

      <div className="row-grid grid-gap-10 row-gap-0 col-md-2">
        <Form.Item
          name="article"
          label={<Localize>FORMS.Input_Label_Article</Localize>}
          initialValue={flooring?.article}
          rules={[
            {
              required: true,
              message: <Localize>FORM_RULES.Required_Article</Localize>,
            },
          ]}
        >
          <Input name="article" />
        </Form.Item>

        <Variable.Forms.Fields.VariablesSelect
          form={form}
          name="type"
          groupName="flooring type"
          rules={true}
          initialValue={flooring?.type}
          label={
            localizeByKey("FORMS.Input_Label_Type")
          }
        />

        <FormElements.RadioSelect
          form={form}
          query={GET_SUPPLIER_CURSOR}
          model="suppliersCursor"
          name="supplier_id"
          label="FORMS.Input_Label_Supplier"
          modalTitle="FORMS.Title_AddSupplier"
          initialValue={
            flooring?.supplier?.id
              ? [
                {
                  value: flooring.supplier.id,
                  label: flooring.supplier.title,
                },
              ]
              : undefined
          }
        />

        <Variable.Forms.Fields.VariablesSelect
          form={form}
          name="color"
          groupName="flooring color"
          rules={true}
          disabledColor={!disabledColor}
          initialValue={flooring?.color}
          label={
            localizeByKey("FORMS.Input_Label_Color")
          }
        />
      </div>

      <div className="form-btn-holder">
        {flooring?.id && (
          <EntityRemoveButton
            modalButton={
              <Button icon={<Icons.Delete />}>
                <span className="ellipsis">
                  <Localize>GLOBAL.Button_Text_DeleteItem</Localize>
                </span>
              </Button>
            }
            nameEntity={
              localizeByKey("ENTITY.Modal_Title_Flooring")
            }
            dataNameEntity={`${flooring?.title}`}
            loading={loadingMutationFlooringDelete}
            deleteMutation={_setMutationFlooringDelete}
            variables={{
              id: flooring?.id,
            }}
          />
        )}
        <Button
          className="btn-right"
          type="primary"
          htmlType="submit"
          icon={flooring?.id ? <Icons.Edit /> : <Icons.Plus />}
          loading={loadingMutationFlooringCreateUpdate}
        >
          <span className="ellipsis">
            {flooring?.id ? (
              <Localize>GLOBAL.Button_Text_SaveChanges</Localize>
            ) : (
              <Localize>GLOBAL.Button_Text_CreateNew</Localize>
            )}
          </span>
        </Button>
      </div>
    </Form>
  );
};

export default FlooringEditForm;
