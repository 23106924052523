import React from "react";
import { Link } from "react-router-dom";
import SupplierConst from "../supplier-const";
import { TSupplier } from "components/supplier/helpers/supplier-types-helper";

type TSupplierInfoFieldProps = {
  supplier: TSupplier;
};

const SupplierInfoField: React.FC<TSupplierInfoFieldProps> = ({ supplier }) => (
  <Link
    className="table-link-underline"
    to={`${SupplierConst.basePath}/${supplier?.id}/`}
  >
    {`${supplier?.title}`}
  </Link>
);

export default SupplierInfoField;
