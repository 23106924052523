import React from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { teamUseMutation } from "../hooks";
import TeamConst from "../team-const";
import { EntityRemoveButton, Localize } from "components/service";
import { localizeByKey } from "components/service/localize";
import Icons from "components/icons";


interface ITeamActionFieldProps
{
  teamId: string;
  teamName: string;
}

const TeamActionField: React.FC<ITeamActionFieldProps> = ({ teamId, teamName }) =>
{
  const navigate = useNavigate();

  const { _setMutationTeamDelete, loadingMutationTeamDelete } =
    teamUseMutation.delete(teamId);

  const items = [
    {
      label: <Localize>MENUS_DROP.Label_Edit</Localize>,
      key: "1",
      icon: <Icons.Edit />,
      onClick: () => navigate(`${TeamConst.basePath}/${teamId}`),
    },
    {
      key: "2",
      label: (
        <EntityRemoveButton
          modalButton={
            <span style={{ display: "flex", alignItems: "center", width: 100 }}>
              <Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize>
            </span>
          }
          nameEntity={
            localizeByKey("ENTITY.Modal_Title_Team")
          }
          dataNameEntity={teamName}
          loading={loadingMutationTeamDelete}
          deleteMutation={_setMutationTeamDelete}
          variables={{
            id: teamId,
          }}
        />
      ),
    },
  ];

  return (
    <Dropdown
      // trigger={['click']}
      placement="bottomRight"
      menu={{ items }}
      overlayClassName="drop-more-menu"
      arrow
    >
      <EllipsisOutlined className="btn-more" />
    </Dropdown>
  );
};

export default TeamActionField;
