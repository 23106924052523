import { useNavigate } from "react-router-dom";
import { useMutation, MutationTuple } from "@apollo/client";
import { ROLE_UPDATE, ROLE_CREATE } from "graphql/mutation/role-gql";
import RoleConst from "../role-const";
import
    {
        successNotification,
        errorNotification,
    } from "components/request-result";

interface IRoleCreateUpdateData
{
    roleUpdate?: {
        label: string;
        message: string;
    };
    roleCreate?: {
        label: string;
        message: string;
    };
}

interface IRoleCreateUpdateVariables
{
    input: {
        id?: number;
        [ key: string ]: any;
        permission_id: number[];
    };
}

const useMutationRoleCreateUpdate = (id: string | undefined, redirect: boolean = true) =>
{
    const navigate = useNavigate();

    const ROLE_CREATE_UPDATE = id ? ROLE_UPDATE : ROLE_CREATE;
    const roleCreateUpdate = id ? "roleUpdate" : "roleCreate";

    const [ _setRoleCreateUpdate, { loading } ]: MutationTuple<IRoleCreateUpdateData, IRoleCreateUpdateVariables> = useMutation(
        ROLE_CREATE_UPDATE,
        {
            update(cache, { data })
            {
                if (data && data[ roleCreateUpdate ]) {
                    const {
                        label,
                        message,
                    } = data[ roleCreateUpdate ]!;

                    if (redirect) {
                        navigate(RoleConst.basePath);
                    }

                    successNotification({
                        title: label,
                        description: message,
                    });
                }
            },
            onError(error)
            {
                errorNotification(error);
            },
        }
    );

    return {
        _setRoleCreateUpdate,
        loadingMutationRoleCreateUpdate: loading,
    };
};

export default useMutationRoleCreateUpdate;