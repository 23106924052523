import React from "react";
import { useApolloClient } from "@apollo/client";
import { GET_EQUIPMENT_GROUP } from "graphql/query/equipment-gql";
import { GET_ME } from "graphql/query/user-gql";
import Equipment from "components/equipment";
import { useBreadCrumbs } from "components/use-hooks";
import { localizeByKey } from "components/service/localize";
import { UploadMedia } from "components/layout";
import type { TEquipment } from "components/equipment/helpers/equipment-types-helper";

const equipmentBasePath = Equipment.Const.basePath;

interface IEquipmentOverviewSubPageProps
{
  equipment: TEquipment | undefined;
  equipmentsGroup: TEquipment[] | undefined;
  conditionCreate: boolean;
  lang: string;
}

const EquipmentOverviewSubPage: React.FC<IEquipmentOverviewSubPageProps> = ({
  equipment,
  equipmentsGroup,
  conditionCreate,
  lang,
}) =>
{
  const client = useApolloClient();
  const cachedMe = client.readQuery({ query: GET_ME });
  const defaultLang = cachedMe.me.lang_api;
  const activeLang = lang || defaultLang;

  const breadcrumbs = [
    {
      label: localizeByKey("BREADCRUMBS.Label_Equipment"),
      path: `${equipmentBasePath}`,
    },
    {
      label: equipment
        ? equipment?.title
        : localizeByKey("BREADCRUMBS.Label_Create"),
      path: equipment
        ? `${equipmentBasePath}/edit/${equipment?.group_id}/${lang}`
        : `${equipmentBasePath}/create/${lang}`,
    },
  ];
  useBreadCrumbs(breadcrumbs);


  return (
    <>
      <Equipment.Fields.Langs
        equipment={equipment || ({} as TEquipment)}
        equipmentsGroup={equipmentsGroup || []}
        activeLang={activeLang}
        conditionCreate={conditionCreate}
        extraClass="form"
      />

      <div className="row-grid col-lg-2">
        <div className={`col-left ${equipment?.id && "col-border-right"} `}>
          <Equipment.Forms.Edit
            activeLang={activeLang}
            equipment={equipment}
            conditionCreate={conditionCreate}
          />
        </div>
        <div className="col-right">
          {equipment?.id && (
            <UploadMedia
              model="Equipment"
              identifier="group_id"
              modelId={equipment?.group_id}
              defaultMedia={equipment?.getImages}
              query={GET_EQUIPMENT_GROUP}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EquipmentOverviewSubPage;