import React from "react";
import { Button, FormInstance } from "antd";
import Variable from "components/variable";
import Localize, { localizeByKey } from "components/service/localize";
import { GET_SUPPLIER_CURSOR } from "graphql/query/supplier-gql";
import { FormElements } from "components/layout";
import { TEquipment, IFilter } from "components/equipment/helpers/equipment-types-helper";

interface IFilterList
{
    [ key: string ]: IFilter;
}

interface IEquipmentFiltersFormProps
{
    setCount: React.Dispatch<any>;
    setFilters: React.Dispatch<React.SetStateAction<IFilter | undefined>>;
    setFilterList: React.Dispatch<
        React.SetStateAction<IFilterList | undefined>
    >;
    equipment: TEquipment | undefined;
    filterList: IFilterList | undefined;
    equipmentFilters: IFilterList | undefined;
    action: () => void;
    gqlBuilderWhere: (filters: any) => any;
    form: FormInstance;
}

const EquipmentFiltersForm: React.FC<IEquipmentFiltersFormProps> = ({
    equipment,
    form,
    action,
    setCount,
    equipmentFilters,
    setFilters,
    setFilterList,
    filterList,
    gqlBuilderWhere,
}) =>
{
    const onChangeSetFilter = (
        e: React.ChangeEvent<HTMLInputElement> | false,
        name: string,
        value: string | string[]
    ) =>
    {
        if (e) {
            name = e.target.name;
            value = e.target.value;
        }

        if (value.toString().length > 0) {
            setFilterList({
                ...filterList,
                [ name ]: {
                    column: name,
                    operator: "IN",
                    value: Array.isArray(value) ? value : [ value ],
                },
            });
        } else {
            const newFilterList = { ...filterList };
            delete newFilterList[ name ];
            setFilterList(newFilterList);
        }
    };

    return (
        <div className="ant-form ant-form-vertical filters-form">
            <div>
                <Variable.Forms.Fields.VariablesSelect
                    form={form}
                    name="TYPE"
                    groupName="equipment type"
                    initialValue={Array.isArray(equipmentFilters?.TYPE?.value) ? equipmentFilters?.TYPE?.value[ 0 ] : equipmentFilters?.TYPE?.value}

                    label={
                        localizeByKey("FORMS.Input_Label_Type")
                    }
                    onChangeSetFilter={() => onChangeSetFilter(false, "TYPE", form.getFieldValue("TYPE"))}
                />

                <Variable.Forms.Fields.VariablesSelect
                    form={form}
                    name="COLOR"
                    groupName="equipment color"
                    initialValue={Array.isArray(equipmentFilters?.COLOR?.value) ? equipmentFilters?.COLOR?.value[ 0 ] : equipmentFilters?.COLOR?.value}

                    label={
                        localizeByKey("FORMS.Input_Label_Color")
                    }
                    onChangeSetFilter={() => onChangeSetFilter(false, "COLOR", form.getFieldValue("COLOR"))}
                />

                <FormElements.RadioSelect
                    skipQuery={!equipmentFilters}
                    location={true}
                    form={form}
                    query={GET_SUPPLIER_CURSOR}
                    model="suppliersCursor"
                    name="SUPPLIER_ID"
                    label="FORMS.Input_Label_Supplier"
                    modalTitle="FORMS.Title_SelectSupplier"
                    initialValue={
                        equipmentFilters?.SUPPLIER_ID?.value
                            ? [ { value: equipmentFilters?.SUPPLIER_ID?.value[ 0 ] } ]
                            : undefined
                    }
                    onChangeSetFilter={() => onChangeSetFilter(false, "SUPPLIER_ID", form.getFieldValue("SUPPLIER_ID"))}
                />
            </div>

            <div className="form-btn-holder">
                <Button
                    className="light-bg"
                    htmlType="submit"
                    onClick={() =>
                    {
                        setCount({});
                        setFilterList({});
                        setFilters(gqlBuilderWhere({}));
                        localStorage.removeItem(
                            `equipmentFilters_groupID-${equipment?.group_id || 0
                            }`
                        );
                        localStorage.removeItem(
                            `equipmentsSorting_groupID-${equipment?.group_id || 0
                            }`
                        );
                    }}
                >
                    <Localize>FORMS.Button_Text_ResetFilters</Localize>
                </Button>

                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    onClick={() =>
                    {
                        setFilters(
                            gqlBuilderWhere({
                                ...equipmentFilters,
                                ...filterList,
                            })
                        );
                        setCount({
                            ...equipmentFilters,
                            ...filterList,
                        });
                        action();
                    }}
                >
                    <Localize>FORMS.Button_Text_ApplyFilters</Localize>
                </Button>
            </div>
        </div>
    );
};

export default EquipmentFiltersForm;
