import Organizer from "components/organizer";
import { useBreadCrumbs } from "components/use-hooks";
import { GET_ORGANIZER } from "graphql/query/organizer-gql";
import { localizeByKey } from "components/service/localize";
import { UploadLogo } from "components/layout";
import type { TOrganizer } from "components/organizer/helpers/organizer-types-helper";

const organizerBasePath = Organizer.Const.basePath;


interface IOrganizerOverviewSubPageProps
{
  organizer: TOrganizer | undefined;
}


const OrganizerOverviewSubPage: React.FC<IOrganizerOverviewSubPageProps> = ({ organizer }) =>
{
  const breadcrumbs = [
    {
      label: localizeByKey("BREADCRUMBS.Label_Organizers"),
      path: `${organizerBasePath}`,
    },
    {
      label:
        (organizer && organizer?.title) ||
        localizeByKey("BREADCRUMBS.Label_Create"),
      path:
        (organizer && `${organizerBasePath}/${organizer?.id}`) ||
        `${organizerBasePath}/create`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  return (
    <div className="row-grid col-lg-2">
      <div className={`col-left ${organizer?.id && "col-border-right z-indx-1"} `}>
        <Organizer.Forms.Edit organizer={organizer} />
      </div>
      <div className="col-right">
        {organizer?.id && (
          <UploadLogo
            label="FORMS.Input_Label_Logo"
            uploadButtonText="GLOBAL.Text_AddLogo"
            uploadImgSize="MODAL.Button_Text_LogoSize"
            model="Organizer"
            modelId={organizer?.id}
            modelField="logo_path"
            defaultMedia={organizer?.logo_path}
            query={GET_ORGANIZER}
          />
        )}
      </div>
    </div>
  );
};

export default OrganizerOverviewSubPage;
