import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { TEAM_UPDATE, TEAM_CREATE } from "graphql/mutation/team-gql";
import TeamConst from "../team-const";
import
{
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationTeamCreateUpdate = (id: string | undefined) =>
{
  const navigate = useNavigate();

  const TEAM_CREATE_UPDATE = id ? TEAM_UPDATE : TEAM_CREATE;
  const teamCreateUpdate = id ? "teamUpdate" : "teamCreate";

  const [ _setTeamCreateUpdate, { loading } ] = useMutation(TEAM_CREATE_UPDATE, {
    update(cache, { data })
    {
      const {
        [ teamCreateUpdate ]: {
          label,
          message,
          team: { id },
        },
      } = data;

      if (teamCreateUpdate === "teamCreate") {
        navigate(TeamConst.basePath + "/" + id);
      } else {
        navigate(TeamConst.basePath);
      }

      successNotification({
        title: label,
        description: message,
      });
    },
    onError(error)
    {
      errorNotification(error);
    },
  });

  return {
    _setTeamCreateUpdate,
    loadingMutationTeamCreateUpdate: loading,
  };
};

export default useMutationTeamCreateUpdate;
