import React from "react";
import { Routes, Route } from "react-router-dom";
import SubPage from "components/pages/supplier/sub-pages";
import type { TSupplier } from "components/supplier/helpers/supplier-types-helper";

interface ISupplierRouteProps
{
    supplier: TSupplier | undefined;
}

const SupplierRoute: React.FC<ISupplierRouteProps> = ({ supplier }) =>
{
    return (
        <Routes>
            <Route path="/" element={<SubPage.OverView supplier={supplier} />} />
            <Route path="/users/page/:pageNum" element={<SubPage.Users supplier={supplier} />} />
            <Route path="/users/page" element={<SubPage.Users supplier={supplier} />} />
            <Route path="/users" element={<SubPage.Users supplier={supplier} />} />
        </Routes>
    );
};

export default SupplierRoute;