import { gql } from "@apollo/client";

export const TEAM_UPDATE = gql`
  mutation TeamUpdate($input: TeamFieldsInput!) {
    teamUpdate(input: $input) {
      label
      message
      team {
        id
        title
        head_id
        count_project_manager
        count_draftsmen
        count_construction_manager
        relHead {
          id
          supplier_id
          surname
          name
        }
        relTeamUsers {
          id
          team_id
          user_id
          team_role
          relUser {
            id
            name
            surname
          }
        }
        created_at
        updated_at
      }
    }
  }
`;

export const TEAM_CREATE = gql`
  mutation TeamCreate($input: TeamFieldsInput!) {
    teamCreate(input: $input) {
      label
      message
      team {
        id
        title
        head_id
        count_project_manager
        count_draftsmen
        count_construction_manager
        relHead {
          id
          supplier_id
          surname
          name
        }
        relTeamUsers {
          id
          team_id
          user_id
          team_role
          relUser {
            id
            name
            surname
          }
        }
        created_at
        updated_at
      }
    }
  }
`;

export const TEAM_DELETE = gql`
  mutation TeamDelete($id: ID!) {
    teamDelete(id: $id) {
      label
      message
    }
  }
`;