import React from "react";
import { Routes, Route } from "react-router-dom";
import SubPage from "components/pages/exhibition/sub-pages";
import type { TExhibitionType } from "components/exhibition/helpers/exhibition-types-helper";

interface IExhibitionRouteProps
{
  exhibition: TExhibitionType | undefined;
}

const ExhibitionRoute: React.FC<IExhibitionRouteProps> = ({ exhibition }) =>
{
  return (
    <Routes>
      <Route path="/" element={<SubPage.OverView exhibition={exhibition} />} />
      <Route path="/equipment" element={<SubPage.Equipment exhibition={exhibition} />} />
      <Route path="/flooring" element={<SubPage.Flooring exhibition={exhibition} />} />
      <Route path="/stand-types" element={<SubPage.Stand exhibition={exhibition} />} />
    </Routes>
  );
};

export default ExhibitionRoute;
