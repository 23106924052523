import React from "react";
import { Form, Select } from "antd";
import Icons from "components/icons";
import { Localize } from "components/service";
import { localizeByKey } from "components/service/localize";

type TUserLanguageSelectProps = {
    disabled?: boolean;
    rules?: boolean | { required: true; message: string }[];
    initialValue?: string;
    mode?: string;
    name?: string;
    label?: string;
};

const UserLanguageSelect: React.FC<TUserLanguageSelectProps> = ({
    disabled = false,
    rules = true,
    initialValue,
    mode = "",
    name = "language_slug",
    label = "FORMS.Input_Label_Language",
}) =>
{
    return (
        <Form.Item
            name={name}
            label={localizeByKey(label)}
            initialValue={initialValue}
            rules={
                rules && rules !== true
                    ? rules
                    : [
                        {
                            required: true,
                            message: localizeByKey(label).toString(),
                        },
                    ]
            }
        >
            <Select
                showSearch
                suffixIcon={<Icons.Arrow />}
                mode={mode as any}
                disabled={disabled}
                placeholder={localizeByKey(label).toString()}
                popupClassName="select-serch-dropdown"
                optionFilterProp="value"
            >
                <Select.Option key="en" value="en">
                    <Localize>FORMS.Select_OptionLabel_English</Localize>
                </Select.Option>
                <Select.Option key="de" value="de">
                    <Localize>FORMS.Select_OptionLabel_Deutsch</Localize>
                </Select.Option>
            </Select>
        </Form.Item>
    );
};

export default UserLanguageSelect;
