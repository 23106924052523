import Team from "components/team";
import { useBreadCrumbs } from "components/use-hooks";
import { localizeByKey } from "components/service/localize";
import type { TTeam } from "components/team/helpers/team-types-helper";

const teamBasePath = Team.Const.basePath;

interface ITeamOverviewSubPageProps
{
    team: TTeam | undefined;
}

const TeamOverviewSubPage: React.FC<ITeamOverviewSubPageProps> = ({ team }) =>
{
  const breadcrumbs = [
    {
      label: localizeByKey("BREADCRUMBS.Label_Teams"),
      path: `${teamBasePath}`,
    },
    {
      label:
        (team && `${team?.title}`) ||
        localizeByKey("BREADCRUMBS.Label_Create"),
      path: (team && `${teamBasePath}/${team?.id}`) || `${teamBasePath}/create`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  return (
    <div className="row-grid col-lg-2">
      <div className="col-left">
        <Team.Forms.Edit team={team} />
      </div>
      <div className="col-right"></div>
    </div>
  );
};

export default TeamOverviewSubPage;