import React, { useState } from "react";
import { Button } from "antd";
import { useMatch, useNavigate } from "react-router-dom";

import { GET_SUPPLIERS } from "graphql/query/supplier-gql";

import Supplier from "components/supplier";
import Icons from "components/icons";
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables, Elements } from "components/layout";
import { Localize, UploadFile } from "components/service";
import { localizeByKey } from "components/service/localize";
import { gqlBuilderWhere } from "common/utils";
import { IFilter } from 'components/supplier/helpers/supplier-types-helper';

import "./supplier.scss";

const basePath = Supplier.Const.basePath;
const tableHelper = Supplier.Helpers.TableMain;


enum ModelType
{
  Supplier = 'supplier',
}


const SuppliersPage: React.FC = () =>
{
  useActiveMenuItem([ "suppliers" ], [ "suppliers" ]);

  const breadcrumbs = [
    {
      label: localizeByKey("BREADCRUMBS.Label_Suppliers"),
      path: `${basePath}`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const navigate = useNavigate();

  const match = useMatch(`${basePath}/page/:pageNum`);

  const objOrderBy = [ { column: "ID", order: "DESC" } ];

  const [ variables, setVariables ] = useState({});
  const [ searchText, setSearchText ] = useState<string>("");
  const [ filters, setFilters ] = useState<IFilter | undefined>();
  const [ resetSorting, setResetSorting ] = useState<{ column: string; order: "ASC" | "DESC" }[]>();

  const supplierFilters = JSON.parse(localStorage.getItem("supplierFilters") || "{}");
  const suppliersSorting = JSON.parse(localStorage.getItem("suppliersSorting") || "[]");

  const objectFilters = filters || gqlBuilderWhere(supplierFilters);

  const {
    _setImportReimport,
    loadingMutationImportReimport,
    dataMutationImportReimport,
  } = Supplier.Hooks.importReimport({ variables });

  return (
    <PageWrapDefault
      className="page-suppliers"
      dataExist={true}
      title={<Localize>PAGES.Title_Suppliers</Localize>}
      staticPath=""
    >
      <Tables.Main
        model="suppliers"
        query={GET_SUPPLIERS}
        varSet={{ perPage: 50 }}
        routeUrl={`${basePath}`}
        currentPage={match ? parseInt(match.params.pageNum || "1") : 1}
        searchText={searchText}
        setVariables={setVariables}
        tableHelper={tableHelper}
        objectWhere={objectFilters}
        resetPaginationPage={typeof objectFilters === "object"}
        objOrderBy={suppliersSorting || objOrderBy}
        sortingName="suppliersSorting"
        resetSorting={resetSorting}
      >
        <div className="table-action-bar">
          <div className="col">
            <Supplier.Filters.Table
              {...{
                setSearchText,
                filters,
                setFilters,
                supplierFilters,
                setResetSorting,
                objOrderBy,
              }}
            />
          </div>

          <div className="col">
            {loadingMutationImportReimport && (
              <Elements.Importing
                loading={loadingMutationImportReimport}
                modalTitle="MODAL.Title_Text_SuppliersFileImporting"
              />
            )}

            {dataMutationImportReimport && (
              <Elements.Imported
                model={ModelType.Supplier}
                data={dataMutationImportReimport?.supplierImportOrReImport}
                isImport={true}
                modalTitle="MODAL.Title_Text_SuppliersFileImported"
              />
            )}

            <UploadFile
              setMutation={_setImportReimport}
              loading={loadingMutationImportReimport}
              uploadBtn={
                <Button type="primary" icon={<Icons.Plus />}>
                  <span className="ellipsis">
                    <Localize>SUPPLIER.Button_Text_Import</Localize>
                  </span>
                </Button>
              }
            />

            <Button
              type="primary"
              icon={<Icons.Plus />}
              onClick={() => navigate(`${basePath}/create`)}
            >
              <span className="ellipsis">
                <Localize>GLOBAL.Button_Text_CreateNew</Localize>
              </span>
            </Button>
          </div>
        </div>
      </Tables.Main>
    </PageWrapDefault>
  );
};

export default SuppliersPage;
