import StandInfoField from "./stand-info-field";
import StandActionField from "./stand-action-field";
import StandImageField from "./stand-image-field";
import StandLangsField from "./stand-langs-field";
import StandOrientationField from "./stand-orientation-field";
import StandEquipmentInfoField from "./stand-equipment-info-field";
import StandEquipmentEquipmentActionField from "./stand-equipment-action-field";
import StandEquipmentRuleField from "./stand-equipment-rule-field";
import StandFlooringActionField from "./stand-flooring-action-field";
import StandFileActionField from "./stand-file-action-field";
import StandFileAttachmentField from "./stand-file-attachment-field";
import StandNoteActionField from "./stand-note-action-field";
import StandEquipmentSizeField from "./stand-equipment-size-field";

const StandFields = {
  Info: StandInfoField,
  Image: StandImageField,
  Langs: StandLangsField,
  Action: StandActionField,
  Orientation: StandOrientationField,
  EquipmentInfo: StandEquipmentInfoField,
  EquipmentAction: StandEquipmentEquipmentActionField,
  EquipmentRule: StandEquipmentRuleField,
  EquipmentSize: StandEquipmentSizeField,
  FlooringAction: StandFlooringActionField,
  FilesAction: StandFileActionField,
  FileAttachment: StandFileAttachmentField,
  NoteAction: StandNoteActionField,
};

export default StandFields;
