import React from "react";
import { Routes, Route } from "react-router-dom";
import SubPage from "components/pages/team/sub-pages";
import type { TTeam } from "components/team/helpers/team-types-helper";

interface ITeamRouteProps
{
  team: TTeam | undefined;
}


const TeamRoute: React.FC<ITeamRouteProps> = ({ team }) =>
{
  return (
    <Routes>
      <Route path="/" element={<SubPage.OverView team={team} />} />
    </Routes>
  );
};

export default TeamRoute;
