import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { EXHIBITION_DELETE } from "graphql/mutation/exhibition-gql";
import ExhibitionConst from "../exhibition-const";
import Organizer from "components/organizer";
import type { TOrganizer } from "components/organizer/helpers/organizer-types-helper";
import
{
  successNotification,
  errorNotification,
} from "components/request-result";


const useMutationExhibitionDelete = (id: string | undefined, organizer: TOrganizer | undefined) =>
{
  const navigate = useNavigate();

  const [ _setMutationExhibitionDelete, { loading } ] = useMutation(
    EXHIBITION_DELETE,
    {
      update(cache, { data })
      {
        const {
          exhibitionTypeDelete: { label, message },
        } = data;

        !organizer
          ? navigate(ExhibitionConst.basePath)
          : navigate(
            `${Organizer.Const.basePath}/${organizer.id}${ExhibitionConst.basePath}`
          );

        cache.evict({
          id: cache.identify({ id, __typename: "ExhibitionType" }),
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message,
        });
      },
      onError(error)
      {
        errorNotification(error);
      },
    }
  );

  const handleDelete = async (options: { variables: Record<string, any> }) =>
  {
    await _setMutationExhibitionDelete(options);
  };

  return {
    _setMutationExhibitionDelete: handleDelete,
    loadingMutationExhibitionDelete: loading,
  };
};

export default useMutationExhibitionDelete;
