import { useReactiveVar } from "@apollo/client";
import { Breadcrumb } from "antd";
import { breadCrumbsVar } from "graphql/cache";
import { Link } from "react-router-dom";

import "./breadcrumbs.scss";

interface IBreadcrumbItem
{
    path: string;
    label: string;
}

const Breadcrumbs = () =>
{
    const breadcrumbs: IBreadcrumbItem[] = useReactiveVar(breadCrumbsVar);

    const items =
        breadcrumbs.length > 0 &&
        breadcrumbs.map((item, index) => ({
            title : <Link to={item.path} key={index}>{item.label}</Link>,
        }));

    return <Breadcrumb items={items || []} className="main-breadcrumb" />;
};

export default Breadcrumbs;


