import React from "react";
import { Link } from "react-router-dom";
import EquipmentConst from "../equipment-const";
import flagGermany from "assets/flag-germany.png";
import flagEngland from "assets/flag-england.png";
import { langExists } from "common/utils";
import { TEquipment } from "../helpers/equipment-types-helper";

interface IFlagLinkProps
{
  lang: string;
  equipment: TEquipment;
  equipmentsGroup?: TEquipment[];
  path: string;
  imgSrc: string;
  text: string;
  activeLang?: string;
}

const FlagLink: React.FC<IFlagLinkProps> = ({
  lang,
  equipment,
  equipmentsGroup,
  path,
  imgSrc,
  text,
  activeLang,
}) =>
{
  const equipmentToCheck = equipment?.relatives ? equipment : { relatives: equipmentsGroup };

  return (
    <li
      className={
        (langExists(equipmentToCheck, lang)[ 0 ]?.title ? "exists " : "") +
        (lang === activeLang ? "active" : "")
      }
    >
      <Link to={path}>
        <div className="box-img">
          <img src={imgSrc} alt="flag" />
        </div>
        <span className="text">{text}</span>
      </Link>
    </li>
  );
};

interface IEquipmentLangsFieldProps
{
  equipment: TEquipment;
  equipmentsGroup?: TEquipment[];
  extraClass?: string;
  activeLang?: string;
  conditionCreate?: boolean;
}

const EquipmentLangsField: React.FC<IEquipmentLangsFieldProps> = ({
  equipment,
  equipmentsGroup,
  extraClass = "",
  activeLang,
  conditionCreate,
}) =>
{
  return (
    <ul className={`lang-flags ${extraClass}`}>
      <FlagLink
        lang="de"
        equipment={equipment}
        equipmentsGroup={equipmentsGroup}
        path={`${conditionCreate
          ? EquipmentConst.basePath + "/create/de/"
          : `${EquipmentConst.basePath}/edit/${equipment?.group_id}/de/`
          }`}
        imgSrc={flagGermany}
        activeLang={activeLang}
        text="Deutsch"
      />

      <FlagLink
        lang="en"
        equipment={equipment}
        equipmentsGroup={equipmentsGroup}
        path={`${conditionCreate
          ? EquipmentConst.basePath + "/create/en/"
          : `${EquipmentConst.basePath}/edit/${equipment?.group_id}/en/`
          }`}
        imgSrc={flagEngland}
        activeLang={activeLang}
        text="English"
      />
    </ul>
  );
};

export default EquipmentLangsField;