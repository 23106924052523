import { gql } from "@apollo/client";

export const GET_STAND_TYPES_CURSOR = gql`
  query StandTypesCursor(
    $group_id: ID
    $text: String
    $first: Int!
    $after: String
    $where: QueryStandTypesCursorWhereWhereConditions
    $whereExhibitionTypeStandTypes: QueryStandTypesCursorWhereExhibitionTypeStandTypesWhereHasConditions
    $whereOrientations: QueryStandTypesCursorWhereOrientationsWhereHasConditions
    $orderBy: [QueryStandTypesCursorOrderByOrderByClause!]
  ) {
    standTypesCursor(
      group_id: $group_id
      text: $text
      first: $first
      after: $after
      where: $where
      whereExhibitionTypeStandTypes: $whereExhibitionTypeStandTypes
      whereOrientations: $whereOrientations
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        total
        count
        currentPage
        lastPage
      }
      edges {
        cursor
        node {
          id
          group_id
          title
          lang
          navision_number
          size_min
          size_max
          price
          complexity
          getImages {
            id
            sizes
          }
          meta_fields {
            id
            stand_type_id
            meta_key
            meta_value
            meta_group
          }
          orientations {
            id
            stand_type_group
            orientation_key
            img_path
          }
          relatives {
            id
            group_id
            title
            lang
            navision_number
            size_min
            size_max
            price
            complexity
          }
        }
      }
    }
  }
`;

export const GET_STAND_TYPE = gql`
  query GetStandType($id: ID) {
    standType(id: $id) {
      id
      group_id
      title
      lang
      navision_number
      size_min
      size_max
      price
      complexity
      getImages {
        id
        sizes
      }
      meta_fields {
        id
        stand_type_id
        meta_key
        meta_value
        meta_group
      }
      orientations {
        id
        stand_type_group
        orientation_key
        img_path
      }
    }
  }
`;

export const GET_STAND_TYPES_GROUP = gql`
  query GetStandTypesGroup($group_id: ID!) {
    standTypesGroup(group_id: $group_id) {
      id
      group_id
      title
      lang
      navision_number
      size_min
      size_max
      price
      complexity
      getImages {
        id
        sizes
      }
      meta_fields {
        id
        stand_type_id
        meta_key
        meta_value
        meta_group
      }
      orientations {
        id
        stand_type_group
        orientation_key
        img_path
      }
      organizerExhibitionTypes {
        organizer_id
        title
        et_default_id
        status
        exhibitionTypes {
          et_id
          title
          default
          status
        }
      }
    }
  }
`;

export const GET_STAND_TYPE_EQUIPMENTS_CURSOR = gql`
  query StandTypeEquipmentsCursor(
    $first: Int!
    $after: String
    $where: QueryStandTypeEquipmentsCursorWhereWhereConditions
    $whereEquipment: QueryStandTypeEquipmentsCursorWhereEquipmentWhereHasConditions
    $orderBy: [QueryStandTypeEquipmentsCursorOrderByOrderByClause!]
  ) {
    standTypeEquipmentsCursor(
      first: $first
      after: $after
      where: $where
      whereEquipment: $whereEquipment
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        total
        count
        currentPage
        lastPage
      }
      edges {
        cursor
        node {
          id
          stand_type_group
          equipment_group
          organizer_id
          rule_type
          size_min
          size_max
          amount
          relOrganizer {
            id
            title
          }
          standType {
            id
            group_id
            title
            lang
            navision_number
            size_min
            size_max
            price
            complexity
            created_at
            updated_at
          }
          equipment {
            id
            group_id
            title
            lang
            type
            navision_number
            octacad_number
            color
            supplier_article
            supplier_id
            description
            getImages {
              id
              sizes
              title
            }
            supplier {
              id
              title
            }
          }
        }
      }
    }
  }
`;

export const GET_STAND_TYPE_FILES_CURSOR = gql`
  query StandTypeFilesCursor(
    $text: String
    $first: Int!
    $after: String
    $where: QueryStandTypeFilesCursorWhereWhereConditions
    $orderBy: [QueryStandTypeFilesCursorOrderByOrderByClause!]
  ) {
    standTypeFilesCursor(
      text: $text
      first: $first
      after: $after
      where: $where
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        total
        count
        currentPage
        lastPage
      }
      edges {
        cursor
        node {
          id
          stand_type_group
          server_name
          original_name
          extension
          size
          title
          description
          path
        }
      }
    }
  }
`;

export const GET_STAND_TYPE_FILE = gql`
  query GetStandTypeFile($id: ID) {
    standTypeFile(id: $id) {
      id
      stand_type_group
      server_name
      original_name
      extension
      size
      title
      description
      path
    }
  }
`;

export const GET_STAND_TYPE_NOTES_CURSOR = gql`
  query StandTypeNotesCursor(
    $text: String
    $first: Int!
    $after: String
    $where: QueryStandTypeNotesCursorWhereWhereConditions
    $orderBy: [QueryStandTypeNotesCursorOrderByOrderByClause!]
  ) {
    standTypeNotesCursor(
      text: $text
      first: $first
      after: $after
      where: $where
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        total
        count
        currentPage
        lastPage
      }
      edges {
        cursor
        node {
          id
          stand_type_group
          user_id
          description
          status
          created_at
          updated_at
          user {
            id
            name
            surname
          }
          attachments {
            id
            modelable_type
            modelable_id
            group_id
            server_name
            original_name
            extension
            size
            title
            description
            order
            user_id
            path
          }
        }
      }
    }
  }
`;

export const GET_STAND_TYPE_NOTE = gql`
  query GetStandTypeNote($id: ID) {
    standTypeNote(id: $id) {
      id
      stand_type_group
      user_id
      description
      status
      user {
        id
        name
        surname
      }
      attachments {
        id
        modelable_type
        modelable_id
        group_id
        server_name
        original_name
        extension
        size
        title
        description
        order
        user_id
        path
      }
    }
  }
`;
