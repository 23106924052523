import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_GRAPHIC_SUPPLIER } from "graphql/query/graphic-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import GraphicRoute from "components/routes/graphic/graphic-route";
import Localize, { localizeByKey } from "components/service/localize";


const GraphicPage: React.FC = () =>
{
  useActiveMenuItem([ "variable-lists" ], [ "variable-lists" ]);

  const { id } = useParams<{ id: string }>();
  const graphicID = id ? parseInt(id) : "create";

  const { data, loading } = useQuery(
    GET_GRAPHIC_SUPPLIER,
    {
      skip: graphicID === 'create',
      variables: {
        id: graphicID,
      },
    }
  );

  const graphicSupplier = data?.graphicSupplier;

  const pageNavbar = [
    {
      label: localizeByKey("NAVBAR_PAGES.Label_Overview"),
      path: "",
    },
    {
      label: localizeByKey("NAVBAR_PAGES.Label_Rules"),
      path: "rules",
    },
  ];


  return (
    <PageWrapDefault
      className="page-graphic"
      loading={loading}
      title={
        graphicID === "create" ? (
          <Localize>PAGES.Title_CreateGraphicRules</Localize>
        ) : !loading && graphicSupplier ? (
          <Localize>{graphicSupplier.title}</Localize>
        ) : (
          "Page 404"
        )
      }
      dataExist={graphicID === "create" ? true : !loading && graphicSupplier !== undefined}
      pageNavbar={
        !loading &&
          graphicSupplier &&
          !window.location.pathname.includes("exhibition-types/create")
          ? pageNavbar
          : undefined
      }
      staticPath={`/graphic-rules/${graphicID}`}
    >
      <GraphicRoute
        graphic={graphicSupplier}
      />
    </PageWrapDefault>
  );
};

export default GraphicPage;
