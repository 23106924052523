import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { EQUIPMENT_DELETE } from "graphql/mutation/equipment-gql";
import { GET_EQUIPMENT_CURSOR } from "graphql/query/equipment-gql";
import EquipmentConst from "../equipment-const";
import
{
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationEquipmentDelete = (id: string | undefined, variables?: Record<string, any>) =>
{
  const navigate = useNavigate();

  const [ _setMutationEquipmentDelete, { loading } ] = useMutation(
    EQUIPMENT_DELETE,
    {
      update(cache, { data })
      {
        const {
          equipmentDelete: { label, message },
        } = data;

        navigate(EquipmentConst.basePath);

        cache.evict({
          id: cache.identify({ id, __typename: "Equipment" }),
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message,
        });
      },
      onError(error)
      {
        errorNotification(error);
      },
      refetchQueries: [
        {
          query: GET_EQUIPMENT_CURSOR,
          variables,
        },
      ],
    }
  );

  const handleDelete = async (options: { variables: Record<string, any> }) =>
  {
    await _setMutationEquipmentDelete(options);
  };

  return {
    _setMutationEquipmentDelete: handleDelete,
    loadingMutationEquipmentDelete: loading,
  };
};

export default useMutationEquipmentDelete;
