import SupplierFields from "./fields";
import SupplierForms from "./forms";
import SupplierConst from "./supplier-const";
import SuppliersHelpers from "./helpers";
import SuppliersFilters from "./filters";
import { supplierUseMutation } from "./hooks";

const Supplier = {
  Filters: SuppliersFilters,
  Fields: SupplierFields,
  Forms: SupplierForms,
  Const: SupplierConst,
  Helpers: SuppliersHelpers,
  Hooks: supplierUseMutation,
};

export default Supplier;
