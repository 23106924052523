import VariationFields from "../fields";
import { Localize } from "components/service";
import { TVariation } from "../helpers/variation-types-helper";

const variationColumns = [
  {
    title: <Localize>TABLES.Column_Title_Name</Localize>,
    dataIndex: "title",
    columnIndex: "TITLE",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Organizer</Localize>,
    dataIndex: "organizer_id",
    columnIndex: "ORGANIZER_ID",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Article</Localize>,
    dataIndex: "article",
    columnIndex: "ARTICLE",
    sorter: true,
  },
  {
    title: (
      <>
        <Localize>TABLES.Column_Title_Price</Localize> &#8364;
      </>
    ),
    dataIndex: "price",
    columnIndex: "PRICE",
    sorter: true,
  },

  {
    // title: '',
    dataIndex: "action",
    align: "right",
    width: "5%",
  },
];

const variationData = (variation: TVariation[], variables: Record<string, any>) =>
{
  if (!variation) {
    return [];
  }

  return variation.map((variation) =>
  {
    return {
      key: variation.id,
      title: (
        <VariationFields.Info variation={variation} variables={variables} />
      ),
      organizer_id: <VariationFields.Organizer variation={variation} />,
      article: variation.article ?? <strong>&mdash;</strong>,
      price: variation.price.toFixed(2) + " €" ?? <strong>&mdash;</strong>,
      action: (
        <VariationFields.Action variation={variation} variables={variables} />
      ),
    };
  });
};

const VariationsTableHelper = {
  columns: variationColumns,
  data: variationData,
};

export default VariationsTableHelper;
