import React, { useState, useEffect, ReactNode } from "react";
import ReactDOMServer from 'react-dom/server';
import { useNavigate } from "react-router-dom";
import { Table, Empty } from "antd";
import { useQuery, DocumentNode } from "@apollo/client";
import SkeletonCustom from "components/skeleton";
import { useDebounce } from "components/use-hooks";
import { Localize } from "components/service";
import { SorterResult } from "antd/lib/table/interface";

const SkeletonData = SkeletonCustom.TableData;

interface ITableMainProps
{
    model: string;
    querySkip?: boolean;
    objectWhere?: Record<string, any>;
    tableVisible?: boolean;
    sortingName?: string;
    resetSorting?: { column: string; order: "ASC" | "DESC" }[];
    resetPaginationPage?: boolean;
    setVariables?: (variables: any) => void;
    objOrderBy?: { column: string; order: "ASC" | "DESC" }[];
    tableLayout?: "auto" | "fixed";
    query: DocumentNode;
    currentObj?: Record<string, any>;
    routeUrl: string;
    currentPage: number;
    searchText?: string;
    tableHelper: {
        columns: any[];
        data: (model: any, currentObj: any, variables: any) => any[];
    };
    children?: ReactNode;
    varSet?: {
        perPage?: number;
        [ key: string ]: any;
    };
    extraClass?: string;
}

const TableMain: React.FC<ITableMainProps> = ({
    model,
    querySkip = false,
    objectWhere,
    tableVisible = true,
    sortingName = '',
    resetSorting = [],
    resetPaginationPage = false,
    setVariables = () => { },
    objOrderBy = [ { column: "ID", order: "DESC" } ],
    tableLayout = "fixed",
    query,
    currentObj = {},
    routeUrl,
    currentPage,
    searchText,
    tableHelper,
    children,
    varSet,
    extraClass = "",
}) =>
{
    const navigate = useNavigate();
    const perPage = varSet?.perPage ?? 10;
    const [ orderBy, setOrderBy ] = useState(objOrderBy);

    const variables = {
        text: useDebounce(searchText),
        first: perPage,
        page: currentPage,
        where: objectWhere,
        orderBy,
        ...(varSet ?? undefined),
    };

    const { data, loading } = useQuery(query, {
        skip: querySkip,
        variables: { ...variables },
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
    });

    const modelData = data?.[ model ] ?? {};
    const { paginatorInfo } = modelData;

    useEffect(() =>
    {
        setVariables(variables);
    }, [ data ]);

    useEffect(() =>
    {
        if (resetSorting.length) setOrderBy(resetSorting);
    }, [ resetSorting ]);

    useEffect(() =>
    {
        if (searchText !== "" && searchText !== undefined) {
            navigate(routeUrl);
        }

        if (resetPaginationPage) {
            navigate(routeUrl);
        }
    }, [ searchText, navigate, routeUrl, resetPaginationPage ]);

    const dataSource = loading
        ? SkeletonData(tableHelper.columns, perPage, "my-2")
        : tableHelper.data(modelData.data, currentObj, variables);

    const getLocalizedText = (key: string) =>
    {
        const element = <Localize>{key}</Localize>;
        return ReactDOMServer.renderToString(element);
    };

    return (
        <>
            {children}


            {tableVisible && (
                <Table
                    className={`table-main ${extraClass}`}
                    locale={{
                        emptyText: (
                            <div className="no-data-box">
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                <span className="no-data-text">
                                    {getLocalizedText("GLOBAL.Text_NoData")}
                                </span>
                            </div>
                        ),
                        triggerDesc: getLocalizedText("TABLES.Column_Sorting_Tooltip_Descending"),
                        triggerAsc: getLocalizedText("TABLES.Column_Sorting_Tooltip_Ascending"),
                        cancelSort: getLocalizedText("TABLES.Column_Sorting_Tooltip_Cancel"),
                    }}
                    dataSource={dataSource}
                    columns={tableHelper.columns}
                    tableLayout={tableLayout}
                    size="middle"
                    pagination={{
                        className: "main-table-pagination",
                        showSizeChanger: false,
                        position: [ "bottomLeft" ],
                        pageSize: perPage,
                        total: paginatorInfo?.total,
                        current: currentPage,
                        hideOnSinglePage: !(paginatorInfo?.total / paginatorInfo?.perPage > 1),
                        onChange: (page) =>
                        {
                            const path = page === 1
                                ? `${routeUrl}`
                                : `${routeUrl}/page/${page}`;
                            navigate(path);
                        },
                    }}
                    onChange={(pagination, filters, sorter) =>
                    {
                        const { column, order } = sorter as SorterResult<any>;
                        if (column && order) {
                            const orderBy = [
                                {
                                    column: (column as any).columnIndex,
                                    order: order === "ascend" ? "ASC" : "DESC" as "ASC" | "DESC",
                                },
                            ];

                            setOrderBy(orderBy);
                            sortingName &&
                                localStorage.setItem(
                                    sortingName,
                                    JSON.stringify(orderBy)
                                );
                        }
                    }}
                />
            )}
        </>
    );
};

export default TableMain;
