import React, { FunctionComponent, JSX } from "react";
import Icon from "@ant-design/icons";

import { IIcon } from "./icons-interfaces";

const NoJobsSvg = () => (

	<svg
		id="Layer_1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		width="79px"
		height="111.2px"
		viewBox="0 0 79 111.2"
		xmlSpace="preserve"
	>
		<style type="text/css">
			{ "\n\t.st0{fill:#FFFFFF;}\n\t.st1{opacity:8.000000e-02;fill:#FFFFFF;}\n" }
		</style>
		<g>
			<path
				className="st0"
				d="M18,55.3c-0.1,0-0.2,0-0.3-0.1l-5.5-5.5c-0.1-0.1-0.1-0.4,0-0.5s0.4-0.1,0.5,0l5.2,5.2l8.9-8.9 c0.1-0.1,0.4-0.1,0.5,0s0.1,0.4,0,0.5l-9.2,9.2C18.2,55.3,18.1,55.3,18,55.3z"
			/>
			<path
				className="st0"
				d="M78.6,111.2H0.4c-0.2,0-0.4-0.2-0.4-0.4V15.6c0-0.2,0.2-0.4,0.4-0.4h17.8c0.2,0,0.4,0.2,0.4,0.4 c0,0.2-0.2,0.4-0.4,0.4H0.7v94.4h77.5V16H60.9c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h17.7c0.2,0,0.4,0.2,0.4,0.4v95.1 C79,111,78.8,111.2,78.6,111.2z"
			/>
			<g>
				<path
					className="st1"
					d="M49.5,9.9c-0.3-5.3-4.6-9.5-10-9.5s-9.7,4.2-10,9.5H18.3v17h42.6v-17H49.5z"
				/>
				<path
					className="st0"
					d="M60.8,27.3H18.3c-0.2,0-0.4-0.2-0.4-0.4v-17c0-0.2,0.2-0.4,0.4-0.4h11C29.7,4.2,34.1,0,39.5,0 c5.4,0,9.9,4.2,10.3,9.5h11c0.2,0,0.4,0.2,0.4,0.4v17C61.2,27.1,61,27.3,60.8,27.3z M18.6,26.5h41.8V10.3H49.5 c-0.2,0-0.4-0.2-0.4-0.4c-0.2-5.2-4.5-9.2-9.6-9.2c-5.1,0-9.4,4-9.6,9.2c0,0.2-0.2,0.4-0.4,0.4H18.6V26.5z"
				/>
			</g>
			<path
				className="st0"
				d="M64.3,51.9H35.2c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h29.1c0.2,0,0.4,0.2,0.4,0.4 C64.7,51.8,64.5,51.9,64.3,51.9z"
			/>
			<path
				className="st0"
				d="M18,71.9L18,71.9c-0.1,0-0.2,0-0.3-0.1l-5.5-5.5c-0.1-0.1-0.1-0.4,0-0.5c0.1-0.1,0.4-0.1,0.5,0L18,71l8.9-8.9 c0.1-0.1,0.4-0.1,0.5,0c0.1,0.1,0.1,0.4,0,0.5l-9.2,9.2C18.2,71.8,18.1,71.9,18,71.9z"
			/>
			<path
				className="st0"
				d="M64.3,68.5H35.2c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h29.1c0.2,0,0.4,0.2,0.4,0.4 C64.7,68.3,64.5,68.5,64.3,68.5z"
			/>
			<path
				className="st0"
				d="M18,88L18,88c-0.1,0-0.2,0-0.3-0.1l-5.5-5.5c-0.1-0.1-0.1-0.4,0-0.5c0.1-0.1,0.4-0.1,0.5,0l5.2,5.2l8.9-8.9 c0.1-0.1,0.4-0.1,0.5,0s0.1,0.4,0,0.5l-9.2,9.2C18.2,88,18.1,88,18,88z"
			/>
			<path
				className="st0"
				d="M64.3,84.6H35.2c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h29.1c0.2,0,0.4,0.2,0.4,0.4S64.5,84.6,64.3,84.6z"
			/>
		</g>
	</svg>
);


const NoJobsIcon: FunctionComponent<IIcon> = (): JSX.Element => {
	return <Icon component={ NoJobsSvg } />;
};


export default NoJobsIcon;