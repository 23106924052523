import React from "react";
import Equipment from "components/equipment";
import { DrawerStandart, Localize } from "components/service";
import { TEquipment } from "components/equipment/helpers/equipment-types-helper";
import { TStand, TStandTypeEquipment } from "../helpers/stand-types-helper";


interface IStandEquipmentInfoFieldProps
{
  stand: TStand;
  standEquipment: TStandTypeEquipment;
  equipment: TEquipment;
  lang: string;
}


const StandEquipmentInfoField: React.FC<IStandEquipmentInfoFieldProps> = ({
  stand,
  standEquipment,
  equipment,
  lang,
}) =>
{
  return (
    <DrawerStandart
      extraClass={"modal-form filters"}
      modalButton={
        <strong style={{ cursor: "pointer" }}>{equipment?.title}</strong>
      }
    >
      <strong className="modal-title">
        <span className="text">
          <Localize>FORMS.Title_Text_EditEquipmentRule</Localize>
        </span>
      </strong>

      <Equipment.Forms.AddRule
        stand={stand}
        standEquipment={standEquipment}
        equipment={equipment}
        lang={lang}
        action={() => { }}
      />
    </DrawerStandart>
  );
};

export default StandEquipmentInfoField;
