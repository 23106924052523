import VariableFields from "./fields";
import VariableForms from "./forms";
import VariableConst from "./variable-const";
import VariablesHelpers from "./helpers";
// import VariablesFilters from './filters';
import { variableUseMutation } from "./hooks";


const Variable = {
  // Filters: VariablesFilters,
  Fields: VariableFields,
  Forms: VariableForms,
  Const: VariableConst,
  Helpers: VariablesHelpers,
  Hooks: variableUseMutation
};

export default Variable;
