import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Pages } from 'components/pages';

const SuppliersRoute: React.FC = () =>
{

  return (
    <Routes>
      <Route path="/" element={<Pages.Suppliers />} />
      <Route path="/page/:pageNum" element={<Pages.Suppliers />} />
      <Route path="/page" element={<Pages.Suppliers />} />
      <Route path="/:id/*" element={<Pages.Supplier />} />
      <Route path="/create" element={<Pages.Supplier />} />
    </Routes>
  );
};



export default SuppliersRoute;

