type TDataItem = {
    node: {
        id: string;
        name: string;
        surname: string;
        role_id: string;
    };
};

type TSelectOption = {
    label: string;
    value: string;
    disabled: boolean;
};

function convertToSelect(data: TDataItem[]): TSelectOption[] {
    const result: TSelectOption[] = [];

    data?.forEach((item) => {
        result.push({
            label: `${item.node.name} ${item.node.surname}`,
            value: item.node.id,
            disabled: item.node.role_id === "1" || item.node.role_id === "2",
        });
    });

    return result;
}

const usersFuncHelper = {
    convertToSelect,
};

export default usersFuncHelper;
