import { gql } from "@apollo/client";

export const SUPPLIER_UPDATE = gql`
  mutation SupplierUpdate($input: SupplierFieldsInput!) {
    supplierUpdate(input: $input) {
      label
      message
      supplier {
        id
        title
        number
        type
        usersCount
        users {
          id
        }
        description
        created_at
        updated_at
      }
    }
  }
`;

export const SUPPLIER_CREATE = gql`
  mutation SupplierCreate($input: SupplierFieldsInput!) {
    supplierCreate(input: $input) {
      label
      message
      supplier {
        id
        title
        number
        type
        usersCount
        users {
          id
        }
        description
        created_at
        updated_at
      }
    }
  }
`;

export const SUPPLIER_DELETE = gql`
  mutation SupplierDelete($id: ID!) {
    supplierDelete(id: $id) {
      label
      message
    }
  }
`;

export const SUPPLIER_REMOVE_USER = gql`
  mutation SupplierRemoveUser($user_id: ID!, $supplier_id: ID!) {
    supplierRemoveUser(user_id: $user_id, supplier_id: $supplier_id) {
      label
      message
    }
  }
`;

export const SUPPLIER_SYNC_USERS = gql`
  mutation SupplierSyncToUsers($user_id: [ID]!, $supplier_id: ID!) {
    supplierSyncToUsers(user_id: $user_id, supplier_id: $supplier_id) {
      label
      message
    }
  }
`;

export const SUPPLIER_IMPORT_REIMPORT = gql`
  mutation SupplierImportOrReImport($file: Upload!) {
    supplierImportOrReImport(file: $file) {
      label
      message
      sync_info
    }
  }
`;
