import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  EXHIBITION_UPDATE,
  EXHIBITION_CREATE,
} from "graphql/mutation/exhibition-gql";
import ExhibitionConst from "../exhibition-const";
import Organizer from "components/organizer";
import type { TOrganizer } from "components/organizer/helpers/organizer-types-helper";
import {
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationExhibitionCreateUpdate = (id: string | undefined, organizer: TOrganizer | undefined) => {

  const navigate = useNavigate();

  const EXHIBITION_CREATE_UPDATE = id ? EXHIBITION_UPDATE : EXHIBITION_CREATE;
  const exhibitionCreateUpdate = id
    ? "exhibitionTypeUpdate"
    : "exhibitionTypeCreate";

  const [ _setExhibitionCreateUpdate, { loading } ] = useMutation(
    EXHIBITION_CREATE_UPDATE,
    {
      update(cache, { data })
      {
        const {
          [ exhibitionCreateUpdate ]: { label, message, exhibitionType },
        } = data;

        if (organizer) {
          navigate(
            `${Organizer.Const.basePath}/${organizer.id}${ExhibitionConst.basePath}`
          );
        } else if (id) {
          navigate(ExhibitionConst.basePath);
        } else {
          navigate(`${ExhibitionConst.basePath}/${exhibitionType.id}`);
        }

        successNotification({
          title: label,
          description: message,
        });
      },
      onError(error)
      {
        errorNotification(error);
      },
    }
  );

  return {
    _setExhibitionCreateUpdate,
    loadingMutationExhibitionCreateUpdate: loading,
  };
};

export default useMutationExhibitionCreateUpdate;
