import React, { useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { GET_ME } from "graphql/query/user-gql";
import { GET_FLOORING_CURSOR } from "graphql/query/flooring-gql";
import Flooring from "components/flooring";
import Icons from "components/icons";
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import Localize, { localizeByKey } from "components/service/localize";
import { gqlBuilderWhere } from "common/utils";
import { IFilter } from 'components/flooring/helpers/flooring-types-helper';
import "./flooring.scss";

const basePath = Flooring.Const.basePath;
const tableHelper = Flooring.Helpers.TableMain;

const FlooringsPage: React.FC = () =>
{
  useActiveMenuItem([ "flooring" ], [ "flooring" ]);

  const client = useApolloClient();
  const cachedMe = client.readQuery({
    query: GET_ME,
  });

  const disabledColor = cachedMe.me.roles.filter(
    ({ slug }: { slug: string }) => slug === "role_library_manager"
  ).length ? true : false;

  const breadcrumbs = [
    {
      label: localizeByKey("BREADCRUMBS.Label_Flooring"),
      path: `${basePath}`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const navigate = useNavigate();

  const objOrderBy = [ { column: "ID", order: "DESC" } ];

  const [ searchText, setSearchText ] = useState<string>("");
  const [ viewMode, setViewMode ] = useState<"table" | "grid">("grid");
  const [ filters, setFilters ] = useState<IFilter | undefined>();
  const [ resetSorting, setResetSorting ] = useState<{ column: string; order: "ASC" | "DESC" }[]>();

  const flooringFilters = JSON.parse(localStorage.getItem("flooringFilters") || "{}");
  const flooringsSorting = JSON.parse(localStorage.getItem("flooringsSorting") || "{}");

  const objectFilters = filters || gqlBuilderWhere(flooringFilters);

  return (
    <PageWrapDefault
      className="page-floorings"
      dataExist={true}
      title={<Localize>PAGES.Title_Flooring</Localize>}
      staticPath=""
    >
      <Tables.Cursor
        model="flooringsCursor"
        query={GET_FLOORING_CURSOR}
        varSet={{ perPage: 50 }}
        extraObj={disabledColor}
        routeUrl={`${basePath}`}
        searchText={searchText}
        tableHelper={tableHelper}
        viewMode={viewMode}
        viewGrid={Flooring.Helpers.GridMain}
        objectWhere={objectFilters}
        objOrderBy={flooringsSorting || objOrderBy}
        sortingName="flooringsSorting"
        resetSorting={resetSorting}
      >
        <div className="table-action-bar">
          <div className="col">
            <Flooring.Filters.Table
              {...{
                setSearchText,
                filters,
                setFilters,
                flooringFilters,
                setResetSorting,
                objOrderBy,
              }}
            />
          </div>

          <div className="col">
            <div className="switch-btn-holder">
              {viewMode === "table" ? (
                <Button
                  onClick={() => setViewMode("grid")}
                  icon={<Icons.Grid />}
                />
              ) : (
                <Button
                  onClick={() => setViewMode("table")}
                  icon={<Icons.Table />}
                />
              )}
            </div>

            <Button
              type="primary"
              icon={<Icons.Plus />}
              onClick={() => navigate(`${basePath}/create`)}
            >
              <span className="ellipsis">
                <Localize>GLOBAL.Button_Text_CreateNew</Localize>
              </span>
            </Button>
          </div>
        </div>
      </Tables.Cursor>
    </PageWrapDefault>
  );
};

export default FlooringsPage;