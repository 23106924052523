import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_USER } from "graphql/query/user-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import UserRoute from "components/routes/user/user-route";
import Localize, { localizeByKey } from "components/service/localize";
import type { TPageNavbarItem } from "common/types";
import type { TUser } from "components/user/helpers/user-types-helper";

const UserPage: React.FC = () =>
{
    useActiveMenuItem([ "users" ], [ "users" ]);

    const { id } = useParams<{ id: string }>();

    const userID = id ? parseInt(id) : "create";

    const { data, loading } = useQuery<{ user: TUser }>(GET_USER, {
        skip: userID === "create",
        variables: { id: userID },
    });

    const user = data?.user;

    const label = localizeByKey("NAVBAR_PAGES.Label_Overview");

    const pageNavbar: TPageNavbarItem[] | undefined = !loading && user ? [
        {
            label,
            path: "",
        },
    ] : undefined;

    return (
        <PageWrapDefault
            className="page-user"
            loading={loading}
            title={
                userID === "create" ? (
                    <Localize>PAGES.Title_CreateUser</Localize>
                ) : !loading && user ? (
                    `${user.name} ${user.surname}`
                ) : (
                    "Page 404"
                )
            }
            dataExist={userID === "create" ? true : !loading && user !== undefined}
            pageNavbar={!loading && user ? pageNavbar : undefined}
            staticPath={`/users/${userID}`}
        >
            <UserRoute user={user} />
        </PageWrapDefault>
    );
};

export default UserPage;