import React from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { roleUseMutation } from "../hooks";
import RoleConst from "../role-const";
import { EntityRemoveButton, Localize } from "components/service";
import { localizeByKey } from "components/service/localize";
import Icons from "components/icons";


interface IRoleActionFieldProps
{
    roleId: string;
    roleName: string;
}

const RoleActionField: React.FC<IRoleActionFieldProps> = ({ roleId, roleName }) =>
{
    const navigate = useNavigate();

    const { _setMutationRoleDelete, loadingMutationRoleDelete } =
        roleUseMutation.delete(roleId);


    const items = [
        {
            label: <Localize>MENUS_DROP.Label_Edit</Localize>,
            key: "1",
            icon: <Icons.Edit />,
            onClick: () => navigate(`${RoleConst.basePath}/${roleId}`),
        },
        {
            key: "2",
            label: (
                <EntityRemoveButton
                    modalButton={
                        <span style={{ display: "flex", alignItems: "center" }}>
                            <Icons.Delete />{" "}
                            <Localize>MENUS_DROP.Label_Delete</Localize>
                        </span>
                    }
                    nameEntity={localizeByKey("ENTITY.Modal_Title_Role")}
                    dataNameEntity={roleName}
                    loading={loadingMutationRoleDelete}
                    deleteMutation={_setMutationRoleDelete}
                    variables={{
                        id: roleId,
                    }}
                />
            ),
        },
    ];

    return (
        <Dropdown
            placement="bottomRight"
            menu={{ items }}
            overlayClassName="drop-more-menu"
            arrow
        >
            <EllipsisOutlined className="btn-more" />
        </Dropdown>
    );
};

export default RoleActionField;