import { Link } from "react-router-dom";
import Supplier from "components/supplier";
import { TUser } from "components/user/helpers/user-types-helper";

type TUserSupplierProps = {
    user: TUser;
};

const UserSupplierField: React.FC<TUserSupplierProps> = ({ user }) =>
    user?.supplier?.id ? (
        <Link
            className="table-link-underline"
            to={`${Supplier.Const.basePath}/${user?.supplier?.id}/`}
        >
            {user?.supplier?.title}
        </Link>
    ) : (
        <strong>&mdash;</strong>
    );

export default UserSupplierField;
