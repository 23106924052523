import React from "react";

import { equipmentUseMutation } from "../hooks";
import { EntityRemoveButton } from "components/service";
import { localizeByKey } from "components/service/localize";
import { TEquipment } from "../helpers/equipment-types-helper";
import Icons from "components/icons";


interface IEquipmentRemoveFieldProps
{
  equipment: TEquipment | undefined;
  groupID?: string | undefined;
  disabled?: boolean;
  variables: Record<string, any>;
}

const EquipmentRemoveField: React.FC<IEquipmentRemoveFieldProps> = ({
  groupID,
  equipment,
  disabled = false,
  variables,
}) =>
{
  const { _setMutationEquipmentRemove, loadingMutationEquipmentRemove } =
    equipmentUseMutation.remove(variables);

  return (
    <EntityRemoveButton
      okText={
        localizeByKey("GLOBAL.Button_Text_Remove")
      }
      disabled={disabled}
      modalButton={
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            fontSize: "22px",
            color: !disabled
              ? "var(--table_colorIconRemove)"
              : "var(--table_colorIconRemoveDisabled)",
            cursor: !disabled ? "pointer" : "default",
          }}
        >
          <Icons.Close />
        </span>
      }
      nameEntity={
        localizeByKey("ENTITY.Modal_Title_Equipment")
      }
      dataNameEntity={equipment?.title}
      loading={loadingMutationEquipmentRemove}
      deleteMutation={_setMutationEquipmentRemove}
      variables={{
        equipment_group: groupID,
        substitude_group: equipment?.group_id,
      }}
    />
  );
};

export default EquipmentRemoveField;
