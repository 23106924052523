import React from "react";
import { useParams } from "react-router-dom";
import Role from "components/role";
import { useQuery } from "@apollo/client";
import { GET_ROLE } from "graphql/query/role-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import RoleRoute from "components/routes/role/role-route";
import Localize, { localizeByKey } from "components/service/localize";
import type { TRole } from "components/role/helpers/role-types-helper";


const RolePage: React.FC = () =>
{
    useActiveMenuItem([ "users" ], [ "users" ]);

    const { id } = useParams<{ id: string }>();
    const roleID = id ? parseInt(id) : "create";
    const basePath = Role.Const.basePath;

    const { data, loading } = useQuery<{ role: TRole }>(GET_ROLE, {
        skip: roleID === "create",
        variables: {
            id: roleID,
        },
    });

    const role = data?.role;

    const pageNavbar = [
        {
            label: localizeByKey("NAVBAR_PAGES.Label_Overview"),
            path: "",
        },
        {
            label: localizeByKey("NAVBAR_PAGES.Label_Users"),
            path: "users",
        },
    ];


    return (
        <PageWrapDefault
            className="page-role"
            loading={loading}
            title={
                roleID === "create" ? (
                    <Localize>PAGES.Title_CreateRole</Localize>
                ) : !loading && role ? (
                    <Localize>{role.title}</Localize>
                ) : (
                    "Page 404"
                )
            }
            dataExist={roleID === "create" ? true : !loading && role !== undefined}
            pageNavbar={!loading && role ? pageNavbar : undefined}
            staticPath={`${basePath}/${roleID}`}
        >
           <RoleRoute role={role} />
        </PageWrapDefault>
    );
};

export default RolePage;