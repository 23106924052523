import dayjs, { Dayjs } from "dayjs";

import { EDateType } from "../types";

import type { RangePickerProps } from "antd/es/date-picker";


const getDateObj = (dateStr : string, type : EDateType): Dayjs => {

    return dayjs(dateStr, getDateFormat(type));
};

const getDateFormat = (type : EDateType) : string => {

    let format;

    switch (type){
        case EDateType.TIME:
            format = "HH:mm";
        break;

        case EDateType.DATETIME:
            format = "DD.MM.YYYY, hh:mm";
        break;

        case EDateType.DAYDATE:
            format = "ddd, DD.MM.YYYY";
        break;

        case EDateType.DAYDATETIME:
            format = "ddd, DD.MM.YYYY, hh:mm";
        break;

        case EDateType.DATE:
        default : format = "DD.MM.YYYY";
    }

    return format;
};

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current < dayjs().startOf('day');
};


const getDate = {
    fromStr : getDateObj,
    format : getDateFormat,
    disabled : disabledDate,
    dateTypes : EDateType
};

export default getDate;