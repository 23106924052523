import React, { useState, useEffect } from "react";
import { Input, Button, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { gqlBuilderWhere, helperFunc } from "common/utils";
import StandForms from "../../forms";
import { TStand, IFilter } from '../../helpers/stand-types-helper';
import Icons from "components/icons";
import { DrawerStandart, Localize } from "components/service";

const { Search } = Input;


interface IFilterList
{
  [ key: string ]: IFilter;
}

interface IStandFiltersFormProps
{
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setFilters: React.Dispatch<React.SetStateAction<IFilter | undefined>>;
  setFiltersExtra: React.Dispatch<React.SetStateAction<IFilter | undefined>>;
  setFiltersMore: React.Dispatch<React.SetStateAction<IFilter | undefined>>;
  setResetSorting: React.Dispatch<React.SetStateAction<{ column: string; order: "ASC" | "DESC" }[] | undefined>>;
  standFilters: { [ key: string ]: IFilter } | undefined;
  standFiltersExtra: { [ key: string ]: IFilter } | undefined;
  standFiltersMore: { [ key: string ]: IFilter } | undefined;
  filters: IFilter | undefined;
  filtersExtra: IFilter | undefined;
  filtersMore: IFilter | undefined;
  objOrderBy: { column: string; order: string }[];
  defaultLang: string;
  stand: TStand | undefined;
}


const StandTableFilter: React.FC<IStandFiltersFormProps> = ({
  setFilters = () => { },
  setFiltersExtra = () => { },
  setFiltersMore = () => { },
  setResetSorting = () => { },
  stand,
  defaultLang,
  setSearchText,
  filters,
  filtersExtra,
  filtersMore,
  standFilters,
  standFiltersExtra,
  standFiltersMore,
  objOrderBy,
}) =>
{
  const [ form ] = Form.useForm();

  const [ filterList, setFilterList ] = useState<IFilterList | undefined>(undefined);
  const [ filterListExtra, setFilterListExtra ] = useState<IFilterList | undefined>(undefined);
  const [ filterListMore, setFilterListMore ] = useState<IFilterList | undefined>(undefined);
  const [ count, setCount ] = useState<any>({});

  const filterCount = helperFunc.countFilterList(count);

  /*eslint-disable */
  useEffect(() =>
  {
    if (filters) {
      localStorage.setItem(
        `standFilters_groupID-${stand?.group_id || 0}`,
        JSON.stringify({ ...standFilters, ...filterList })
      );
    } else form.resetFields([ "COMPLEXITY", "MIN_PRICE", "MAX_PRICE" ]);

    if (filtersExtra) {
      localStorage.setItem(
        `standFiltersExtra_groupID-${stand?.group_id || 0}`,
        JSON.stringify({ ...standFiltersExtra, ...filterListExtra })
      );
    } else form.resetFields([ "ORIENTATION_KEY" ]);

    if (filtersMore) {
      localStorage.setItem(
        `standFiltersMore_groupID-${stand?.group_id || 0}`,
        JSON.stringify({ ...standFiltersMore, ...filterListMore })
      );
    } else form.resetFields([ "ORGANIZER_ID" ]);
  }, [ filters, filtersExtra, filtersMore ]);

  useEffect(() =>
  {
    if (standFilters) {
      setFilters(gqlBuilderWhere(standFilters));
    }

    if (standFiltersExtra) {
      setFiltersExtra(gqlBuilderWhere(standFiltersExtra));
    }

    if (standFiltersMore) {
      setFiltersMore(gqlBuilderWhere(standFiltersMore));
    }

    setCount({ ...standFilters, ...standFiltersExtra, ...standFiltersMore });
  }, []);
  /*eslint-enable */

  return (
    <>
      <Search
        className="filter-search"
        prefix={<SearchOutlined />}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <Form
        key="filters-form"
        className="clear-css-prop table-filter-form"
        layout="vertical"
        form={form}
      >
        <DrawerStandart
          extraClass={"modal-form filters"}
          modalButton={
            <Button icon={<Icons.Filter />}>
              <span className="ellipsis">
                <Localize>FORMS.Button_Text_Filters</Localize>
              </span>
              {!!filterCount && (
                <span className="filter-count">{filterCount}</span>
              )}
            </Button>
          }
        >
          <strong className="modal-title">
            <span className="text">
              <Localize>FORMS.Title_Text_Filters</Localize>
            </span>
          </strong>

          <StandForms.Filter
            defaultLang={defaultLang}
            setCount={setCount}
            setFilters={(filters) => setFilters(filters as IFilter)}
            setFiltersExtra={(filtersExtra) => setFiltersExtra(filtersExtra as IFilter)}
            setFiltersMore={(filtersMore) => setFiltersMore(filtersMore as IFilter)}
            setFilterList={setFilterList}
            setFilterListExtra={setFilterListExtra}
            setFilterListMore={setFilterListMore}
            filterList={filterList}
            filterListExtra={filterListExtra}
            filterListMore={filterListMore}
            standFilters={standFilters}
            standFiltersExtra={standFiltersExtra}
            standFiltersMore={standFiltersMore}
            gqlBuilderWhere={gqlBuilderWhere}
            form={form}
            stand={stand}
            action={() => { }}
          />
        </DrawerStandart>

        {(filters || filtersExtra || filtersMore) && (
          <Button
            className="underline"
            onClick={() =>
            {
              setCount({});
              setFilterList({});
              setFilterListExtra({});
              setFilterListMore({});
              setFilters(gqlBuilderWhere({}));
              setFiltersExtra(gqlBuilderWhere({}));
              setFiltersMore(gqlBuilderWhere({}));
              setResetSorting(objOrderBy as { column: string; order: "ASC" | "DESC" }[]);
              localStorage.removeItem(
                `standFilters_groupID-${stand?.group_id || 0}`
              );
              localStorage.removeItem(
                `standFiltersExtra_groupID-${stand?.group_id || 0}`
              );
              localStorage.removeItem(
                `standFiltersMore_groupID-${stand?.group_id || 0}`
              );
              localStorage.removeItem(
                `standsSorting_groupID-${stand?.group_id || 0}`
              );
            }}
          >
            <span>
              <Localize>FORMS.Button_Text_Reset</Localize>
            </span>
          </Button>
        )}
      </Form>
    </>
  );
};

export default StandTableFilter;
