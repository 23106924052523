const getColorHex = (colorName: string): string => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (!context) {
        return "#4471FF";
    }

    const parts = colorName.split("_");
    const color = parts[parts.length - 1];
    context.fillStyle = color;
    return context.fillStyle;
};

export default getColorHex;
