import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Pages } from 'components/pages';

const UsersRoute: React.FC = () =>
{
  return (
    <Routes>
      <Route path="/" element={<Pages.Users />} />
      <Route path="/page/:pageNum" element={<Pages.Users />} />
      <Route path="/page" element={<Pages.Users />} />
      <Route path="/:id/*" element={<Pages.User />} />
      <Route path="/create" element={<Pages.User />} />
    </Routes>
  );
};



export default UsersRoute;