import { useNavigate } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { SUPPLIER_DELETE } from "graphql/mutation/supplier-gql";
import SupplierConst from '../supplier-const';
import { successNotification, errorNotification } from "components/request-result";


const useMutationSupplierDelete = (id: string | undefined) =>
{
  const navigate = useNavigate();

  const [ _setMutationSupplierDelete, { loading } ] = useMutation(SUPPLIER_DELETE,
    {
      update(cache, { data })
      {

        const {
          supplierDelete: {
            label,
            message
          }
        } = data;

        navigate(SupplierConst.basePath);

        cache.evict({
          id: cache.identify({ id, __typename: "Supplier" })
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
      onError(error)
      {
        errorNotification(error);
      }
    });

  const handleDelete = async (options: { variables: Record<string, any> }) =>
  {
    await _setMutationSupplierDelete(options);
  };

  return {
    _setMutationSupplierDelete: handleDelete,
    loadingMutationSupplierDelete: loading,
  };
};

export default useMutationSupplierDelete;