import StandTableHelper from "./stand-table-helper";
import StandsGridHelper from "./stand-grid-helper";
import StandEquipmentsTableHelper from "./stand-equipment-table-helper";
import StandFilesTableHelper from "./stand-files-table-helper";
import StandFunctionsHelper from "./stand-functions-helper";


const StandHelpers = {
  TableMain: StandTableHelper,
  GridMain: StandsGridHelper,
  TableEquipments: StandEquipmentsTableHelper,
  TableFiles: StandFilesTableHelper,
  Functions: StandFunctionsHelper,
};

export default StandHelpers;
