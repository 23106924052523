import { useNavigate } from "react-router-dom";
import { Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { userUseMutation } from "../hooks";
import UserConst from "../users-const";
import UserAction from "../actions";
import
{
    EntityRemoveButton,
    Localize,
    ModalStandard,
} from "components/service";
import { TUser } from 'components/user/helpers/user-types-helper';
import Icons from "components/icons";
import { localizeByKey } from "components/service/localize";

type TUserActionFieldProps = {
    userID: TUser[ 'id' ];
    userName: TUser[ 'name' ],
};

const UserActionField = ({
    userID,
    userName,
}: TUserActionFieldProps): JSX.Element =>
{
    const navigate = useNavigate();

    const { _setMutationUserDelete, loadingMutationUserDelete } =
        userUseMutation.delete(userID);

    const items = [
        {
            key: "1",
            onClick: () => navigate(`${UserConst.basePath}/${userID}`),
            label: (
                <span style={{ display: "flex", alignItems: "center" }}>
                    <Icons.Edit />
                    <Localize>MENUS_DROP.Label_Edit</Localize>
                </span>
            ),
        },
        {
            key: "2",
            label: (
                <ModalStandard
                    width={400}
                    modalButton={
                        <span style={{ display: "flex", alignItems: "center" }}>
                            <Icons.SendPass />
                            <Localize>MENUS_DROP.Label_SendPass</Localize>
                        </span>
                    }
                >
                    <UserAction.Fields.SendPass userID={userID} action={() => { }} />
                </ModalStandard>
            ),
        },
        {
            key: "3",
            label: (
                <EntityRemoveButton
                    modalButton={
                        <span
                            style={{
                                display: "flex",
                                alignItems: "center",
                                width: 100,
                            }}
                        >
                            <Icons.Delete />{" "}
                            <Localize>MENUS_DROP.Label_Delete</Localize>
                        </span>
                    }
                    nameEntity={
                        localizeByKey("ENTITY.Modal_Title_User")
                    }
                    dataNameEntity={userName}
                    loading={loadingMutationUserDelete}
                    deleteMutation={_setMutationUserDelete}
                    variables={{
                        id: userID,
                    }}
                />
            ),
        },
    ];

    return (
        <Dropdown
            placement="bottomRight"
            menu={{ items }}
            overlayClassName="drop-more-menu"
            arrow
        >
            <EllipsisOutlined className="btn-more" />
        </Dropdown>
    );
};

export default UserActionField;