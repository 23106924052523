import React from "react";
import { useLocation } from "react-router-dom";
import Flooring from "components/flooring";
import Exhibition from "components/exhibition";
import Stand from "components/stand";
import { Localize } from "components/service";
import { TFlooring } from "../helpers/flooring-types-helper";

interface IFlooringsGridHelperProps
{
  model: TFlooring[];
  extraObj: Record<string, any> | boolean;
  currentObj: any;
  variables: Record<string, any>;
}

const FlooringsGridHelper: React.FC<IFlooringsGridHelperProps> = ({ model, currentObj, variables, extraObj }) =>
{
  const location = useLocation();
  const containsStandTypes = location.pathname.includes("stand-types");
  const containsOrganizers = location.pathname.includes("organizers");
  const containsExhibitions = location.pathname.includes("exhibition-types");

  return (
    <div className="card-view-horizontal">
      {model?.map((item: TFlooring) => (
        <div
          key={item.id}
          className={`card ${item?.standTypeDefault ? "default" : ""}`}
        >
          <strong className="badge" style={{ display: "none" }}>
            <Localize>CARD.Info_List_Default</Localize>
          </strong>
          <div className="card-image">
            <Flooring.Fields.Image flooring={item} grid={true} />
          </div>
          <div className="card-details">
            <div className="d-flex justify-content-between">
              <span className="title">
                <Flooring.Fields.Info flooring={item} />
              </span>

              <div className="nav-bar">
                {containsStandTypes ? (
                  <Stand.Fields.FlooringAction
                    standTypeGroup={currentObj?.group_id}
                    flooring={item}
                    variables={variables}
                  />
                ) : containsOrganizers ? (
                  <Exhibition.Fields.Remove
                    model="Flooring"
                    identifier="id"
                    et_id={currentObj?.et_default_id}
                    dataQuery={item}
                    variables={variables}
                  />
                ) : containsExhibitions ? (
                  <Exhibition.Fields.Remove
                    model="Flooring"
                    identifier="id"
                    et_id={currentObj?.id}
                    dataQuery={item}
                    variables={variables}
                  />
                ) : (
                  <Flooring.Fields.Action flooring={item} />
                )}
              </div>
            </div>

            <Flooring.Fields.Article flooring={item} />

            <ul className={`info-list ${extraObj ? "role" : ""}`}>
              <li>
                <Localize>CARD.Info_List_BaseColor</Localize>:
                <span className="info">
                  <Flooring.Fields.Color
                    flooring={item}
                    disabledColor={extraObj}
                  />
                </span>
              </li>
              <li>
                <Localize>CARD.Info_List_Type</Localize>:
                <span className="info">
                  {item?.type ? (
                    <Localize>{item.type}</Localize>
                  ) : (
                    <strong>&mdash;</strong>
                  )}
                </span>
              </li>
              <li>
                <Localize>CARD.Info_List_Supplier</Localize>:
                <span className="info">
                  <Flooring.Fields.Supplier flooring={item} />
                </span>
              </li>
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FlooringsGridHelper;