import React from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, Upload } from "antd";
import { FileOutlined } from "@ant-design/icons";
import { standUseMutation } from "../../hooks";
import { EntityRemoveButton, Localize } from "components/service";
import { localizeByKey } from "components/service/localize";
import { TStand, TStandTypeFile } from "../../helpers/stand-types-helper";
import Icons from "components/icons";


interface IStandFileEditFormProps
{
  stand: TStand | undefined;
  standFile: TStandTypeFile;
  variables: Record<string, any>;
  documentCreate?: boolean | undefined;
  action: () => void;
}


const StandFileEditForm: React.FC<IStandFileEditFormProps> = ({
  stand,
  standFile,
  action,
  variables,
  documentCreate,
}) =>
{
  const [ form ] = Form.useForm();

  const formItemValue = Form.useWatch("file", form);

  const { _setStandFileCreateUpdate, loadingMutationStandFileCreateUpdate } =
    standUseMutation.fileCreateUpdate({
      id: standFile?.id,
      variables,
    });

  const { _setMutationStandFileDelete, loadingMutationStandFileDelete } =
    standUseMutation.fileDelete({
      id: standFile?.id,
      documentCreate,
    });

  const handleSubmit = (values: any) =>
  {
    _setStandFileCreateUpdate({
      variables: {
        ...values,
        id: standFile?.id ? standFile?.id : undefined,
        stand_type_group: !standFile?.id ? stand?.group_id : undefined,
        file: !standFile?.id ? values.file[ 0 ]?.originFileObj : undefined,
      },
    });

    action();
    form.resetFields();
  };

  const deleteFile = () =>
  {
    if (documentCreate) {
      _setMutationStandFileDelete({
        variables: {
          id: standFile?.id,
        },
      });
    }
    action();
  };

  return (
    <Form
      key={`form-stand-file-edit`}
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
    >
      <Form.Item
        name="file"
        valuePropName="fileList"
        getValueFromEvent={(e) => e && e?.fileList}
      >
        <Upload
          className="form-upload-file"
          name="file"
          maxCount={1}
          listType="text"
          showUploadList={{
            showRemoveIcon: false,
            removeIcon: <Icons.Delete />,
          }}
        >
          {standFile?.original_name ? (
            <div
              className="d-flex align-items-center"
              style={{ textTransform: "none" }}
            >
              <FileOutlined style={{ marginRight: "8px", fontSize: "18px" }} />

              <div style={{ width: "100%", overflow: "hidden" }}>
                <div className="download-file-info">
                  <Link
                    to="#"
                    className="download-file-name"
                    onClick={() =>
                    {
                      window.open(standFile?.path, "_blank");
                    }}
                  >
                    <strong className="file-name">
                      {standFile?.original_name}
                    </strong>
                    <strong>.{standFile.extension}</strong>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            !formItemValue?.length && (
              <Button type="text" icon={<Icons.Plus />}>
                <span className="ellipsis">
                  <Localize>GLOBAL.Button_Text_AddFile</Localize>
                </span>
              </Button>
            )
          )}
        </Upload>
      </Form.Item>
      <Form.Item
        name="title"
        label={<Localize>FORMS.Input_Label_Title</Localize>}
        initialValue={standFile?.title}
      // rules={[
      //   {
      //     required: true,
      //     message: <Localize>FORM_RULES.Required_Title</Localize>,
      //   },
      // ]}
      >
        <Input name="title" />
      </Form.Item>

      <Form.Item
        name="description"
        label={<Localize>FORMS.Input_Label_Description</Localize>}
        initialValue={standFile?.description}
      >
        <Input.TextArea
          autoSize={{ minRows: 3, maxRows: 6 }}
          style={{ fontWeight: 700 }}
        />
      </Form.Item>

      <div className="form-btn-holder">
        {!documentCreate ? (
          <EntityRemoveButton
            action={action}
            modalButton={
              <Button icon={<Icons.Delete />} className="light-bg">
                <span className="ellipsis">
                  <Localize>GLOBAL.Button_Text_DeleteFile</Localize>
                </span>
              </Button>
            }
            nameEntity={
              localizeByKey("ENTITY.Modal_Title_File")
            }
            dataNameEntity={
              standFile?.original_name + "." + standFile?.extension
            }
            loading={loadingMutationStandFileDelete}
            deleteMutation={_setMutationStandFileDelete}
            variables={{
              id: standFile?.id,
            }}
          />
        ) : (
          <Button className="light-bg">
            <span className="ellipsis" onClick={deleteFile}>
              <Localize>GLOBAL.Button_Text_Cancel</Localize>
            </span>
          </Button>
        )}
        <Button
          className="btn-right"
          type="primary"
          htmlType="submit"
          icon={documentCreate ? <Icons.Plus /> : <Icons.Edit />}
          loading={loadingMutationStandFileCreateUpdate}
        >
          <span className="ellipsis">
            {documentCreate ? (
              <Localize>GLOBAL.Button_Text_Upload</Localize>
            ) : (
              <Localize>GLOBAL.Button_Text_Edit</Localize>
            )}
          </span>
        </Button>
      </div>
    </Form>
  );
};

export default StandFileEditForm;
