import { gql } from "@apollo/client";

export const GET_EXHIBITIONS_CURSOR = gql`
  query ExhibitionTypesCursor(
    $text: String
    $first: Int!
    $after: String
    $where: QueryExhibitionTypesCursorWhereWhereConditions
    $orderBy: [QueryExhibitionTypesCursorOrderByOrderByClause!]
  ) {
    exhibitionTypesCursor(
      text: $text
      first: $first
      after: $after
      where: $where
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        total
        count
        currentPage
        lastPage
      }
      edges {
        cursor
        node {
          id
          title
          organizer_id
          total_stands
          total_equipment
          total_floorings
          default
          status
          suppliers {
            id
            et_id
            supplier_id
            supplier_group
            supplier_title
          }
          organizer {
            id
            title
            logo_path
            et_default_id
            status
            total_stands
            total_equipment
            total_floorings
          }
        }
      }
    }
  }
`;

export const GET_EXHIBITION = gql`
  query GetExhibitionType($id: ID) {
    exhibitionType(id: $id) {
      id
      title
      organizer_id
      total_stands
      total_equipment
      total_floorings
      default
      status
      suppliers {
        id
        et_id
        supplier_id
        supplier_group
        supplier_title
      }
      organizer {
        id
        title
        logo_path
        et_default_id
        status
        total_stands
        total_equipment
        total_floorings
      }
    }
  }
`;
