import { gql } from "@apollo/client";

export const GET_ORGANIZERS_CURSOR = gql`
  query OrganizersCursor(
    $text: String
    $first: Int!
    $after: String
    $where: QueryOrganizersCursorWhereWhereConditions
    $orderBy: [QueryOrganizersCursorOrderByOrderByClause!]
  ) {
    organizersCursor(
      text: $text
      first: $first
      after: $after
      where: $where
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        total
        count
        currentPage
        lastPage
      }
      edges {
        cursor
        node {
          id
          title
          logo_path
          et_default_id
          status
          total_stands
          total_equipment
          total_floorings
          suppliers {
            id
            et_id
            supplier_id
            supplier_group
            supplier_title
          }
        }
      }
    }
  }
`;

export const GET_ORGANIZER = gql`
  query GetOrganizer($id: ID) {
    organizer(id: $id) {
      id
      title
      logo_path
      et_default_id
      status
      total_stands
      total_equipment
      total_floorings
      suppliers {
        id
        et_id
        supplier_id
        supplier_group
        supplier_title
      }
    }
  }
`;
