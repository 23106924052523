import FlooringFields from "./fields";
import FlooringForms from "./forms";
import FlooringConst from "./flooring-const";
import FlooringsHelpers from "./helpers";
import { flooringUseMutation } from "./hooks";
import FlooringsFilters from './filters';

const Flooring = {
  Fields: FlooringFields,
  Forms: FlooringForms,
  Const: FlooringConst,
  Helpers: FlooringsHelpers,
  Hooks: flooringUseMutation,
  Filters: FlooringsFilters,
};

export default Flooring;
