const deutsch = {
  'TABLES.Column_Title_Name' : 'Name',
  'TABLES.Column_Title_Phone' : 'Telefon',
  'TABLES.Column_Title_Email' : 'E-Mail',
  'TABLES.Column_Title_Company' : 'Unternehmen',
  'TABLES.Column_Title_Supplier' : 'Lieferant',
  'TABLES.Column_Title_User' : 'User',
  'TABLES.Column_Title_Time' : 'Zeit',
  'TABLES.Column_Title_JobType' : 'Jobtyp',
  'TABLES.Column_Title_Roles' : 'Userrechte',
  'TABLES.Column_Title_Teams' : 'Teams',
  'TABLES.Column_Title_Type' : 'Typ',
  'TABLES.Column_Title_Label' : 'Label',
  'TABLES.Column_Title_Title' : 'Bezeichnung',
  'TABLES.Column_Title_Status' : 'Status',
  'TABLES.Column_Title_Description' : 'Beschreibung',
  'TABLES.Column_Title_ID' : 'ID',
  'TABLES.Column_Sorting_Tooltip_Ascending' : 'Aufsteigend sortieren',
  'TABLES.Column_Sorting_Tooltip_Descending' : 'Absteigend sortieren',
  'TABLES.Column_Sorting_Tooltip_Cancel' : 'Sortierung löschen',
  'TABLES.Column_Title_FileName' : 'Dateiname',
  'TABLES.Column_Title_RoleName' : 'Rechtename',
  'TABLES.Column_Title_NumberRights' : 'Anzahl Userrechte',
  'TABLES.Column_Title_NumberUsers' : 'Anzahl User',
  'TABLES.Column_Title_SupplierName' : 'Lieferant',
  'TABLES.Column_Title_Number' : 'Kreditor',
  'TABLES.Column_Title_UsersNumber' : 'Nutzeranzahl',
  'TABLES.Column_Title_KeyAmount' : 'Anzahl Regeln',
  'TABLES.Column_Title_GroupName' : 'Gruppenname',
  'TABLES.Column_Title_Key' : 'Regel',
  'TABLES.Column_Title_Editable' : 'Editierbar',
  'TABLES.Column_Title_Records' : 'Statistik (Einträge)',
  'TABLES.Column_Title_Found' : 'Gefunden',
  'TABLES.Column_Title_New' : 'Neu',
  'TABLES.Column_Title_Updated' : 'Aktualisiert',
  'TABLES.Column_Title_TeamName' : 'Teamname',
  'TABLES.Column_Title_Head' : 'Verantwortlicher',
  'TABLES.Column_Title_NumbProjectManager' : 'Anzahl Projektmanager',
  'TABLES.Column_Title_NumbDraftsMen' : 'Anzahl CAD-Zeichner',
  'TABLES.Column_Title_Image' : 'Bild',
  'TABLES.Column_Title_Article' : 'Artikelnr.',
  'TABLES.Column_Title_Color' : 'Farbe',
  'TABLES.Column_Title_NavisionNumb' : 'NAV-Nr.',
  'TABLES.Column_Title_OctacadNumb' : 'CAD-Nr.',
  'TABLES.Column_Title_SupplierNumb' : 'Lieferantenartikelnr.',
  'TABLES.Column_Title_ContentLang' : 'Sprache',
  'TABLES.Column_Title_Organizer' : 'Veranstalter',
  'TABLES.Column_Title_Price' : 'Preis',
  'TABLES.Column_Title_SizeMinMax' : 'Größe (qm)',
  'TABLES.Column_Title_PricePer' : 'Preis pro qm (€)',
  'TABLES.Column_Title_Orientations' : 'Standtyp',
  'TABLES.Column_Title_RuleType' : 'Regeltyp',
  'TABLES.Column_Title_StandSizeSqm' : 'Standgröße (qm)',
  'TABLES.Column_Title_Amount' : 'Anzahl',
  'TABLES.Column_Title_NumberStands' : 'Anzahl Stände',
  'TABLES.Column_Title_NumberEquipment' : 'Anzahl Ausstattungselemente',
  'TABLES.Column_Title_NumberFlooring' : 'Anzahl Bodenbeläge',
  'TABLES.Column_Title_Logo' : 'Logo',
  'TABLES.Column_Title_PrintingCompany' : 'Grafikfirma',
  'TABLES.Column_Title_Leistungscode' : 'Leistungsbeschreibung',
  'TABLES.Column_Title_EPFertigungListe' : 'Einzelpreis Fertigungsliste',
  'TABLES.Column_Title_EPMontageWerkstatt' : 'Einzelpreis Montage in Werkstatt',
  'TABLES.Column_Title_EPMontageStand' : 'Einzelpreis Montage am Stand',
  'TABLES.Column_Title_Lettering' : 'Blendenbeschriftung',
  'FORM_RULES.Min_NewPassword' : 'Passwort muss mindestens { minLength } Zeichen enthalten.',
  'FORM_RULES.Required_NewPassword' : 'Neues Passwort eingeben',
  'FORM_RULES.Required_ConfirmPassword' : 'Neues Passwort bestätigen',
  'FORM_RULES.Required_ConfirmPasswordMatch' : 'Die eingegebenen Passwörter stimmen nicht überein.',
  'FORM_RULES.Required_Name' : 'Vornamen eingeben',
  'FORM_RULES.Required_ArticleEquipment' : 'Artikelbezeichnung eingeben',
  'FORM_RULES.Required_Surname' : 'Nachnamen eingeben',
  'FORM_RULES.Required_Email' : 'Die eingegebene E-Mail ist ungültig.',
  'FORM_RULES.Required_Choose': 'auswählen',
  'FORM_RULES.Required_ChooseType': 'Typ auswählen',
  'FORM_RULES.Required_ChooseColor': 'Material/Farbgruppe auswählen',
  'FORM_RULES.Required_Phone' : 'Telefonnummer eingeben',
  'FORM_RULES.Required_Title' : 'Beschreibung eingeben',
  'FORM_RULES.Required_Number' : 'Kreditorennr. eingeben',
  'FORM_RULES.Required_Where' : 'Bitte wählen Sie aus, wo Sie die Zeit ändern möchten',
  'FORM_RULES.Required_Password' : 'Passwort eingeben',
  'FORM_RULES.Required_RoleName' : 'Rechtename eingeben',
  'FORM_RULES.Required_Color' : 'Farbe eingeben',
  'FORM_RULES.Required_SupplierName' : 'Lieferanten eingeben',
  'FORM_RULES.Required_Key' : 'Key eingeben',
  'FORM_RULES.Required_Article' : 'Artikelnummer eingeben',
  'FORM_RULES.Required_SupplierArticle' : 'Lieferantenartikelnr. eingeben',
  'FORM_RULES.Required_OctacadNumb' : 'CAD-Nr. eingeben',
  'FORM_RULES.Required_NavisionArticle' : 'Navisionnr. eingeben',
  'FORM_RULES.Required_Price' : 'Preis eingeben',
  'FORM_RULES.Required_StandName' : 'Standnamen eingeben',
  'FORM_RULES.Required_MinSizeSqm' : 'Mindestgröße eingeben',
  'FORM_RULES.Required_MaxSizeSqm' : 'Maximalgröße eingeben',
  'FORM_RULES.Required_PricePerSqm' : 'Preis eingeben',
  'FORM_RULES.Required_SizeMinMax' : 'Größe eingeben',
  'FORM_RULES.Required_Amount' : 'Anzahl eingeben',
  'FORM_RULES.Required_RuleType' : 'Regeltyp eingeben',
  'FORM_RULES.Required_TeamName' : 'Teamnamen eingeben',
  'FORM_RULES.Required_OrganizerName' : 'Veranstalternamen eingeben',
  'FORM_RULES.Required_ExhibitionName' : 'Veranstaltungstypnamen eingeben',
  'FORM_RULES.Required_Leistungscode' : 'Leistungsbeschreibung eingeben',
  'FORM_RULES.Required_EPFertigungListe' : 'EP Fertigungsliste eingeben',
  'FORM_RULES.Required_EPMontageWerkstatt' : 'EP Montage in Werkstatt eingeben',
  'FORM_RULES.Required_EPMontageStand' : 'EP Montage am Stand eingeben',
  'FORM_RULES.Required_FreeCharactersAmount' : 'Anzahl der Inklusivzeichen eingeben',
  'FORM_RULES.Required_AdditionalPrice' : 'Preis pro Zusatzbuchstabe eingeben',
  'FORM_RULES.Invalid_Phone': 'Die eingegebene Telefonnummer ist ungültig.',
  'FORM_RULES.Required_Organizer': 'Veranstalter eingeben',
  'FORM_RULES.Required_PrintingCompany': 'Grafikfirma eingeben',
  'FORM_RULES.Required_MaxCharacter': 'Max. Zeichen eingeben',
  'FORMS.Input_Label_Password' : 'Passwort',
  'FORMS.Input_Label_Login' : 'Login',
  'FORMS.Input_Label_NewPassword' : 'Neues Passwort',
  'FORMS.Input_Label_ConfirmPassword' : 'Neues Passwort bestätigen',
  'FORMS.Input_Label_Language' : 'Sprache',
  'FORMS.Input_Label_Name' : 'Vorname',
  'FORMS.Input_Label_Surname' : 'Nachname',
  'FORMS.Input_Label_Phone' : 'Telefon',
  'FORMS.Input_Label_Email' : 'E-Mail',
  'FORMS.Input_Label_Title' : 'Dateiname',
  'FORMS.Input_Label_Number' : 'Nr.',
  'FORMS.Input_Label_Description' : 'Beschreibung',
  'FORMS.Input_Label_Status': 'Status',
  'FORMS.Input_Label_Lettering': 'Blendenbeschriftung',
  'FORMS.Input_Label_IntrfaceLanguage' : 'Menüsprache auswählen',
  'FORMS.Input_Label_ContentLanguage' : 'Contentsprache auswählen',
  'FORMS.Input_Label_RoleName' : 'Rechtename',
  'FORMS.Input_Label_Color' : 'Farbe',
  'FORMS.Input_Label_BaseColor' : 'Material/Farbgruppe',
  'FORMS.Input_Label_Role' : 'Rechte',
  'FORMS.Input_Label_SupplierName' : 'Lieferantenname',
  'FORMS.Input_Label_GroupName' : 'Gruppenname',
  'FORMS.Input_Label_Key' : 'Key',
  'FORMS.Input_Label_EditableKey' : 'Key editierbar',
  'FORMS.Input_Label_Supplier' : 'Lieferant',
  'FORMS.Input_Label_Head' : 'Verantwortlicher',
  'FORMS.Input_Label_TeamName' : 'Teamname',
  'FORMS.Input_Label_Team' : 'Team',
  'FORMS.Input_Label_ProjectManagers' : 'Projektmanager',
  'FORMS.Input_Label_ConstructionManagers' : 'Bauleiter',
  'FORMS.Input_Label_AutocadEngineers' : 'CAD-Zeichner',
  'FORMS.Input_Label_AssignedTeams' : 'Zugeordnete Teams',
  'FORMS.Input_Label_Type' : 'Typ',
  'FORMS.Input_Label_Article' : 'Bezeichnung',
  'FORMS.Input_Label_Images' : 'Bilder',
  'FORMS.Input_Label_SupplierArticle' : 'Lieferantenartikelnr.',
  'FORMS.Input_Label_OctacadArticle' : 'CAD-Nr.',
  'FORMS.Input_Label_NavisionNumb' : 'Navisionnr.',
  'FORMS.Input_Label_Price' : 'Preis',
  'FORMS.Input_Label_StandName' : 'Standvariante',
  'FORMS.Input_Label_MinSizeSqm' : 'Mindestgröße (qm)',
  'FORMS.Input_Label_MaxSizeSqm' : 'Maximalgröße (qm)',
  'FORMS.Input_Label_PricePerSqm' : 'Preis pro qm (€)',
  'FORMS.Input_Label_Complexity' : 'Standkomplexität',
  'FORMS.Input_Label_MinPrice' : 'Mindestpreis (€)',
  'FORMS.Input_Label_MaxPrice' : 'Maximalpreis (€)',
  'FORMS.Input_Label_Orientations' : 'Standtyp',
  'FORMS.Input_Label_SignIn' : 'Anmelden',
  'FORMS.Input_Label_StandSizeMinSqm' : 'Mindeststandgröße (qm)',
  'FORMS.Input_Label_StandSizeMaxSqm' : 'Maximalstandgröße (qm)',
  'FORMS.Input_Label_Amount' : 'Anzahl',
  'FORMS.Input_Label_StandRuleType' : 'Regeltyp',
  'FORMS.Input_Label_EquipmentName' : 'Artikelbezeichnung',
  'FORMS.Input_Label_AddText' : 'Text hinzufügen',
  'FORMS.Input_Label_OrganizerName' : 'Veranstaltername',
  'FORMS.Input_Label_Organizer' : 'Veranstalter',
  'FORMS.Input_Label_EquipmentSuppliers' : 'Lieferant Ausstattung',
  'FORMS.Input_Label_PrintHouseSuppliers' : 'Lieferant Grafik',
  'FORMS.Title_ChangePassword' : 'Passwort ändern',
  'FORMS.Title_SendPassword' : 'Passwort senden',
  'FORMS.Title_AddUsers' : 'User hinzufügen',
  'FORMS.Title_ChooseType' : 'Typ wählen',
  'FORMS.Title_Text_Filters' : 'Filter',
  'FORMS.Title_Text_AddEquipmentRule' : 'Neue Ausstattungsregel hinzufügen',
  'FORMS.Title_Text_EditEquipmentRule' : 'Ausstattungsregel ändern',
  'FORMS.Title_Text_AddFlooringStand' : 'Neuen Bodenbelag zur Standvariante hinzufügen',
  'FORMS.Title_Text_AddFlooringExhibition' : 'Neuen Bodenbelag zum Veranstaltungstyp hinzufügen',
  'FORMS.Title_Text_AddStandExhibition' : 'Neue Standvariante zum Veranstaltungstyp hinzufügen',
  'FORMS.Title_Text_AddFlooringOrganizer' : 'Neuen Bodenbelag zum Veranstalter hinzufügen',
  'FORMS.Title_Text_AddStandOrganizer' : 'Neuen Standtyp zum Veranstalter hinzufügen',
  'FORMS.Title_Text_EditFile' : 'Datei bearbeiten',
  'FORMS.Title_AddNote' : 'Notiz hinzufügen',
  'FORMS.Title_Text_EditNote' : 'Notiz bearbeiten',
  'FORMS.Title_EditFile' : 'Datei bearbeiten',
  'FORMS.Title_AddRoles' : 'Rechterollen hinzufügen',
  'FORMS.Title_CreateNewKey' : 'Neuen Key anlegen',
  'FORMS.Title_EditKey' : 'Key bearbeiten',
  'FORMS.Title_AddSupplier' : 'Lieferant hinzufügen',
  'FORMS.Title_SelectSupplier' : 'Lieferant auswählen',
  'FORMS.Title_SelectPrintingCompany' : 'Grafikfirma auswählen',
  'FORMS.Title_AddHead' : 'Verantwortlichen hinzufügen',
  'FORMS.Title_AddProjectManagers' : 'Projektmanager hinzufügen',
  'FORMS.Title_AddConstructionManagers' : 'Bauleiter hinzufügen',
  'FORMS.Title_AddAutocadEngineers' : 'CAD-Zeichner hinzufügen',
  'FORMS.Title_AddTeams' : 'Team hinzufügen',
  'FORMS.Title_AddOrientations' : 'Standtyp hinzufügen',
  'FORMS.Title_CreateVariation' : 'Artikelvariante anlegen',
  'FORMS.Title_EditVariation' : 'Artikelvariante bearbeiten',
  'FORMS.Title_AddSubstitude' : 'Ersatzartikel hinzufügen',
  'FORMS.Title_ChooseEquipment' : 'Ausstattung auswählen',
  'FORMS.Title_UploadFile' : 'Datei hochladen',
  'FORMS.Title_AddEquipmentSuppliers' : 'Ausstattungslieferant hinzufügen',
  'FORMS.Title_AddPrintHouseSuppliers' : 'Grafiklieferant hinzufügen',
  'FORMS.Title_AddFlooringSuppliers' : 'Bodenbelaglieferant hinzufügen',
  'FORMS.Title_AddOrganizer': 'Veranstalter hinzufügen',
  'FORMS.Title_SelectOrganizer' : 'Veranstalter wählen',
  'FORMS.Title_Text_AddEquipmentOrganizer' : 'Neue Ausstattungsartikel hinzufügen',
  'FORMS.Title_Text_AddEquipmentExhibition' : 'Neue Ausstattungsartikel zum Veranstaltungstyp hinzufügen',
  'FORMS.Title_AddExhibitionTypes' : 'Veranstaltungstyp hinzufügen',
  'FORMS.Title_AddOrganizers' : 'Veranstalter hinzufügen',
  'FORMS.Title_AddGraphicMaterials' : 'Druckmaterial hinzufügen',
  'FORMS.Title_AddGraphicColorsOracal' : 'Grafikfarben (Oracal) hinzufügen',
  'FORMS.Title_AddAssemblyTypes' : 'Montageort hinzufügen',
  'FORMS.Title_EditGraphicRule' : 'Grafikpreise bearbeiten',
  'FORMS.Title_AddPrintingCompany' : 'Grafikfirma hinzufügen',
  'FORMS.Title_CreateGraphicRule' : 'Grafikregel anlegen',
  'FORMS.Input_Label_FlooringSuppliers' : 'Lieferant Bodenbelag',
  'FORMS.Input_Label_Logo' : 'Logo',
  'FORMS.Input_Label_ExhibitionName' : 'Veranstaltungstypname',
  'FORMS.Input_Label_ExhibitionTypes' : 'Veranstaltungstyp',
  'FORMS.Input_Label_Organizers' : 'Veranstalter',
  'FORMS.Input_Label_Materials' : 'Druckartikel',
  'FORMS.Input_Label_ColorsOracal' : 'Farben (Oracal)',
  'FORMS.Input_Label_AssemblyTypes' : 'Montagetyp',
  'FORMS.Input_Label_PrintingCompany' : 'Grafikfirma',
  'FORMS.Input_Label_Leistungscode' : 'Leistungscode',
  'FORMS.Input_Label_EPFertigungListe' : 'EP Fertigungsliste',
  'FORMS.Input_Label_EPMontageWerkstatt' : 'EP Montage in Werkstatt',
  'FORMS.Input_Label_EPMontageStand' : 'EP Montage am Stand',
  'FORMS.Input_Label_FreeCharactersAmount' : 'Anzahl der Inklusivzeichen',
  'FORMS.Input_Label_AdditionalPrice': 'Preis Zusatzbuchstaben',
  'FORMS.Input_Label_NameArticle': 'Artikelbezeichnung',
  'FORMS.Input_Label_NameOfArticle' : 'Bezeichnung',
  'FORMS.Select_OptionLabel_NotAssigned' : 'Nicht zugewiesen',
  'FORMS.Select_OptionLabel_TypeNotSet' : 'Nicht zugewiesen',
  'FORMS.Select_OptionLabel_English' : 'Englisch',
  'FORMS.Select_OptionLabel_Deutsch' : 'Deutsch',
  'FORMS.Select_OptionLabel_ChooseColor' : 'Farbe wählen',
  'FORMS.Select_OptionLabel_Active' : 'Aktiv',
  'FORMS.Select_OptionLabel_Blocked' : 'Gesperrt',
  'FORMS.Input_Placeholder_Email' : 'E-Mail',
  'FORMS.Input_Placeholder_Password' : 'Passwort',
  'FORMS.Input_Placeholder_Choose' : 'Auswählen',
  'FORMS.Input_Placeholder_ChooseColor' : 'Farbe wählen',
  'FORMS.Input_Placeholder_Name' : 'Name',
  'FORMS.Input_Placeholder_PleaseChose' : 'Auswählen',
  'FORMS.Input_Placeholder_NotAssigned' : 'Nicht zugewiesen',
  'FORMS.Headig_Text_AppRights' : 'App Userrechte',
  'FORMS.Headig_Text_Special' : 'Sonderrechte',
  'FORMS.Headig_Text_User' : 'User',
  'FORMS.Headig_Text_Exhibitions' : 'Veranstaltungen',
  'FORMS.Headig_Text_Description' : 'Beschreibung',
  'FORMS.Headig_Text_Organizers' : 'Veranstalter',
  'FORMS.Button_Text_ApplyFilters' : 'Filter setzen',
  'FORMS.Button_Text_ResetFilters' : 'Filter löschen',
  'FORMS.Button_Text_Filters' : 'Filter',
  'FORMS.Button_Text_Reset' : 'Filter löschen',
  'SEARCH.Input_Placeholder_Table_User' : 'Name, E-Mail, Telefon suchen',
  'SEARCH.Input_Placeholder_MultiSelect' : 'Suche',
  'GLOBAL.Button_Text_Save' : 'Speichern',
  'GLOBAL.Button_Text_Send' : 'Senden',
  'GLOBAL.Button_Text_SaveSend' : 'Speichern und senden',
  'GLOBAL.Button_Text_SaveChanges' : 'Änderungen speichern',
  'GLOBAL.Button_Text_CreateNew' : 'Neu',
  'GLOBAL.Button_Text_Create' : 'Anlegen',
  'GLOBAL.Button_Text_Cancel' : 'Abbrechen',
  'GLOBAL.Button_Text_Delete' : 'Löschen',
  'GLOBAL.Button_Text_Remove' : 'Löschen',
  'GLOBAL.Button_Text_Confirm' : 'Bestätigen',
  'GLOBAL.Button_Text_Upload' : 'Hochladen',
  'GLOBAL.Button_Text_Login' : 'Login',
  'GLOBAL.Button_Text_BackHome' : 'Zum Start',
  'GLOBAL.Modal_Text_UserSendPass' : 'Neues Passwort wirklich versenden?',
  'GLOBAL.Modal_Text_RemoveText' : '{ deleteObjectType } wirklich enfernen?',
  'GLOBAL.Modal_Text_DeleteText' : '{ deleteObjectType } wirklich löschen?',
  'GLOBAL.Modal_Text_ResetFilters' : 'Filter löschen',
  'GLOBAL.Button_Text_AddNew' : 'Neu anlegen',
  'GLOBAL.Button_Text_AddKey' : 'Key hinzufügen',
  'GLOBAL.Button_Text_Creat' : 'Anlegen',
  'GLOBAL.Button_Text_DeleteGroup' : 'Gruppe löschen',
  'GLOBAL.Button_Text_DeleteItem' : 'Artikel löschen',
  'GLOBAL.Button_Text_DeleteStand' : 'Standvariante löschen',
  'GLOBAL.Button_Text_AddTranslation' : 'Übersetzung hinzufügen',
  'GLOBAL.Button_Text_AddMore' : 'Hinzufügen',
  'GLOBAL.Button_Text_AddFile' : 'Datei hinzufügen',
  'GLOBAL.Button_Text_AddDescription' : 'Beschreibung hinzufügen',
  'GLOBAL.Button_Text_AddRule' : 'Hinzufügen',
  'GLOBAL.Button_Text_EditRule' : 'Ändern',
  'GLOBAL.Button_Text_Add' : 'Hinzufügen',
  'GLOBAL.Button_Text_Edit' : 'Bearbeiten',
  'GLOBAL.Button_Text_DeleteFile' : 'Datei löschen',
  'GLOBAL.Button_Text_UploadNew' : 'Hochladen',
  'GLOBAL.Button_Text_AddNote' : 'Notiz hinzufügen',
  'GLOBAL.Button_Text_DeleteNote' : 'Notiz löschen',
  'GLOBAL.Button_Text_DeleteOrganizer' : 'Veranstalter löschen',
  'GLOBAL.Button_Text_AddOrganizer' : 'Veranstalter hinzufügen',
  'GLOBAL.Text_NoData' : 'Keine Daten vorhanden',
  'GLOBAL.Text_Status_Disabled' : 'Deaktiviert',
  'GLOBAL.Text_Status_InProgress' : 'In Bearbeitung',
  'GLOBAL.Text_Status_Completed' : 'Fertiggestellt',
  'GLOBAL.Text_AddImage' : 'Bild hinzufügen',
  'GLOBAL.Text_AddLogo': 'Logo hinzufügen',
  'GLOBAL.Text_Active' : 'Aktiv',
  'GLOBAL.Text_Blocked' : 'Gesperrt',
  'GLOBAL.Text_Sqm' : 'qm',
  'MODAL.Button_Text_AddRole' : 'Rechterolle hinzufügen',
  'MODAL.Button_Text_AddMore' : 'Hinzufügen',
  'MODAL.Button_Text_AddTeam' : 'Team hinzufügen',
  'MODAL.Button_Text_AddSupplier' : 'Lieferant hinzufügen',
  'MODAL.Button_Text_AddOrientation' : 'Standtyp wählen',
  'MODAL.Button_Text_AddExhibitionType': 'Veranstaltungstyp hinzufügen',
  'MODAL.Button_Text_LogoSize' : 'mind. 220 x 65',
  'MODAL.Button_Text_ImgSize' : 'mind. 800 x 800',
  'MODAL.Title_Text_SuppliersFileImporting' : 'Die Lieferantendatei wird importiert...',
  'MODAL.Title_Text_SuppliersFileImported' : 'Die Lieferantendatei wurde importiert:',
  'ENTITY.Modal_Title_User' : 'User',
  'ENTITY.Modal_Title_Role' : 'Rechterolle',
  'ENTITY.Modal_Title_Supplier' : 'Lieferant',
  'ENTITY.Modal_Title_Key' : 'Key',
  'ENTITY.Modal_Title_Group' : 'Gruppe',
  'ENTITY.Modal_Title_Flooring' : 'Bodenbelag',
  'ENTITY.Modal_Title_Equipment' : 'Ausstattung',
  'ENTITY.Modal_Title_Variation' : 'Artikelvariante',
  'ENTITY.Modal_Title_Stand' : 'Stand',
  'ENTITY.Modal_Title_StandEquipment' : 'Grundausstattung',
  'ENTITY.Modal_Title_File' : 'Datei',
  'ENTITY.Modal_Title_Note' : 'Notiz',
  'ENTITY.Modal_Title_Team' : 'Team',
  'ENTITY.Modal_Title_Organizer' : 'Veranstalter',
  'ENTITY.Modal_Title_ExhibitionType' : 'Veranstaltungstyp',
  'ENTITY.Modal_Title_StandType' : 'Standtyp',
  'ENTITY.Modal_Title_Graphic' : 'Grafik',
  'ENTITY.Modal_Title_GraphicRule' : 'Grafikregel',
  'MENUS_DROP.Label_Users' : 'User',
  'MENUS_DROP.Label_Logout' : 'Logout',
  'MENUS_DROP.Label_Edit' : 'Ändern',
  'MENUS_DROP.Label_SendPass' : 'Passwort senden',
  'MENUS_DROP.Label_Delete' : 'Löschen',
  'MENUS_DROP.Label_Organizers' : 'Veranstalter',
  'MENUS_DROP.Label_Suppliers' : 'Lieferanten',
  'MENUS_DROP.Label_Teams' : 'Teams',
  'MENUS_DROP.Label_StandTypes' : 'Standvarianten',
  'MENUS_DROP.Label_Equipment' : 'Ausstattung',
  'MENUS_DROP.Label_Flooring' : 'Bodenbelag',
  'MENUS_DROP.Label_Settings' : 'Grundeinstellungen',
  'MENUS_DROP.Label_MyProfile' : 'Mein Profil',
  'MENUS_DROP.Label_UploadIcon' : 'Icon hochladen',
  'MENUS_DROP.Label_MakeDefault' : 'Als Standard festlegen',
  'NAVBAR_PAGES.Label_Overview' : 'Details',
  'NAVBAR_PAGES.Label_Users' : 'User',
  'NAVBAR_PAGES.Label_UserRoles' : 'Userrollen',
  'NAVBAR_PAGES.Label_VariableLists' : 'Variablenliste',
  'NAVBAR_PAGES.Label_Variations' : 'Varianten',
  'NAVBAR_PAGES.Label_Substitude' : 'Ersatzartikel',
  'NAVBAR_PAGES.Label_Equipments' : 'Ausstattung',
  'NAVBAR_PAGES.Label_Equipment' : 'Ausstattung',
  'NAVBAR_PAGES.Label_Floorings' : 'Bodenbelag',
  'NAVBAR_PAGES.Label_Flooring' : 'Bodenbelag',
  'NAVBAR_PAGES.Label_Files' : 'Dateien',
  'NAVBAR_PAGES.Label_Notes' : 'Notizen',
  'NAVBAR_PAGES.Label_Organizers' : 'Veranstalter',
  'NAVBAR_PAGES.Label_ExhibitionTypes' : 'Veranstaltungstypen',
  'NAVBAR_PAGES.Label_StandTypes' : 'Standvarianten',
  'NAVBAR_PAGES.Label_GraphicRules' : 'Grafikregeln',
  'NAVBAR_PAGES.Label_Rules' : 'Regeln',
  'BREADCRUMBS.Label_Users' : 'User',
  'BREADCRUMBS.Label_Roles' : 'Userrollen',
  'BREADCRUMBS.Label_Create' : 'Anlegen',
  'BREADCRUMBS.Label_Suppliers' : 'Lieferanten',
  'BREADCRUMBS.Label_VariableLists' : 'Variablenliste',
  'BREADCRUMBS.Label_Teams' : 'Teams',
  'BREADCRUMBS.Label_Flooring' : 'Bodenbelag',
  'BREADCRUMBS.Label_Equipment' : 'Ausstattung',
  'BREADCRUMBS.Label_Variations' : 'Artikelvarianten',
  'BREADCRUMBS.Label_Substitude' : 'Ersatzartikel',
  'BREADCRUMBS.Label_StandTypes' : 'Standtypen',
  'BREADCRUMBS.Label_Equipments' : 'Ausstattung',
  'BREADCRUMBS.Label_Floorings' : 'Bodenbelag',
  'BREADCRUMBS.Label_Files' : 'Dateien',
  'BREADCRUMBS.Label_Notes' : 'Notizen',
  'BREADCRUMBS.Label_Organizers' : 'Veranstalter',
  'BREADCRUMBS.Label_Exhibitions' : 'Veranstaltungstypen',
  'BREADCRUMBS.Label_ExhibitionCreate' : 'Veranstaltungstypen anlegen',
  'BREADCRUMBS.Label_GraphicRules' : 'Grafikregeln',
  'BREADCRUMBS.Label_Rules' : 'Regeln',
  'PAGES.Title_CreateGroup' : 'Gruppe anlegen',
  'PAGES.Title_Dashboard' : 'Dashboard',
  'PAGES.Title_Users' : 'User',
  'PAGES.Title_CreateUser' : 'User anlegen',
  'PAGES.Title_CreateRole' : 'Userrolle anlegen',
  'PAGES.Title_CreateSupplier' : 'Lieferant anlegen',
  'PAGES.Title_CreateFlooring' : 'Bodenbelag anlegen',
  'PAGES.Title_CreateEquipment' : 'Ausstattungsartikel anlegen',
  'PAGES.Title_CreateTeam' : 'Team anlegen',
  'PAGES.Title_Suppliers' : 'Lieferanten',
  'PAGES.Title_Settings' : 'Einstellungen',
  'PAGES.Title_Teams' : 'Teams',
  'PAGES.Title_Flooring' : 'Bodenbelag',
  'PAGES.Title_Equipment' : 'Ausstattung',
  'PAGES.Title_StandTypes' : 'Standvarianten',
  'PAGES.Title_CreateStand' : 'Standvariante anlegen',
  'PAGES.Title_Organizers' : 'Veranstalter',
  'PAGES.Title_CreateOrganizer' : 'Veranstalter anlegen',
  'PAGES.Title_Exhibitions' : 'Veranstaltungstypen',
  'PAGES.Title_CreateExhibition' : 'Veranstaltungstyp anlegen',
  'PAGES.Title_CreateGraphicRules' : 'Grafikregel anlegen',
  'PAGES.Text_404' : 'Die gesuchte Seite existiert nicht.',
  'CARD.Info_List_BaseColor' : 'Farbgruppe',
  'CARD.Info_List_Type' : 'Materialgruppe',
  'CARD.Info_List_Supplier' : 'Lieferant',
  'CARD.Info_List_OctacadNumb' : 'CAD-Nr.',
  'CARD.Info_List_SupplierNumb' : 'Lieferantenartikelnr.',
  'CARD.Info_List_NavisionNumb' : 'NAV-Nr.',
  'CARD.Info_List_Size' : 'Größe',
  'CARD.Info_List_PricePer' : 'Preis pro qm',
  'CARD.Info_List_Default' : 'Standard',
  'STANDTYPE.Equipment_Rule_EQ' : 'Gleich',
  'STANDTYPE.Equipment_Rule_GTE' : 'Größer/gleich',
  'STANDTYPE.Equipment_Rule_LTE' : 'Kleiner/gleich ',
  'STANDTYPE.Equipment_Rule_BETWEEN' : 'von ... bis',
  'VARIABLE.Label_EMPTY' : '! Keine Übersetzung vorhanden !',
  'USER.Button_Text_Delete' : 'User löschen',
  'ROLE.Button_Text_Delete' : 'Userrechterolle löschen',
  'SUPPLIER.Button_Text_Delete' : 'Lieferant löschen',
  'SUPPLIER.Button_Text_Import' : 'Lieferanten importieren',
  'TEAM.Button_Text_Delete' : 'Team löschen',
  'ORGANIZER.Button_Text_Delete' : 'Veranstalter löschen',
  'EXHIBITION.Button_Text_Delete' : 'Veranstaltungstyp löschen',
  'GRAPHIC.Button_Text_Delete' : 'Grafikregel löschen',
};

export default deutsch;
