import React from "react";
import { Button, Form, Select, FormInstance } from "antd";
import { Localize } from "components/service";
import { GET_SUPPLIER_CURSOR } from "graphql/query/supplier-gql";
import { GET_ROLES_CURSOR } from "graphql/query/role-gql";
import { GET_TEAMS_CURSOR } from "graphql/query/team-gql";
import { FormElements } from "components/layout";
import { IFilter } from 'components/user/helpers/user-types-helper';
import Icons from "components/icons";



interface IFilterList
{
  [ key: string ]: IFilter;
}

interface IUserFiltersFormProps
{
  setCount: React.Dispatch<any>;
  setFilters: React.Dispatch<React.SetStateAction<IFilter | undefined>>;
  setFiltersExtra: React.Dispatch<React.SetStateAction<IFilter | undefined>>;
  setFiltersMore: React.Dispatch<React.SetStateAction<IFilter | undefined>>;
  setFilterList: React.Dispatch<React.SetStateAction<IFilterList | undefined>>;
  setFilterListExtra: React.Dispatch<React.SetStateAction<IFilterList | undefined>>;
  setFilterListMore: React.Dispatch<React.SetStateAction<IFilterList | undefined>>;
  filterList: IFilterList | undefined;
  filterListExtra: IFilterList | undefined;
  filterListMore: IFilterList | undefined;
  usersFilters: IFilterList | undefined;
  usersFiltersExtra: IFilterList | undefined;
  usersFiltersMore: IFilterList | undefined;
  action: () => void;
  gqlBuilderWhere: (filters: any) => any;
  form: FormInstance;
}


const UserFiltersForm: React.FC<IUserFiltersFormProps> = ({
  form,
  action,
  setCount,
  usersFilters,
  usersFiltersExtra,
  usersFiltersMore,
  setFilters,
  setFiltersExtra,
  setFiltersMore,
  setFilterList,
  setFilterListExtra,
  setFilterListMore,
  filterList,
  filterListExtra,
  filterListMore,
  gqlBuilderWhere,
}) =>
{
  const onChangeSetFilter = (
    e: React.ChangeEvent<HTMLInputElement> | false,
    name: string,
    value: string | string[]
  ) =>
  {
    if (e) {
      name = e.target.name;
      value = e.target.value;
    }

    if (name === "ID") {
      if (value.toString().length > 0) {
        setFilterListExtra({
          ...filterListExtra,
          [ name ]: {
            column: name,
            operator: "IN",
            value: Array.isArray(value) ? value : [ value ],
          },
        });
      } else {
        const newFilterListExtra = { ...filterListExtra };
        delete newFilterListExtra[ name ];
        setFilterListExtra(newFilterListExtra);
      }
    } else if (name === "ID_TEAM") {
      if (value.toString().length > 0) {
        setFilterListMore({
          ...filterListMore,
          [ name ]: {
            column: name.substring(0, 2),
            operator: "IN",
            value: Array.isArray(value) ? value : [ value ],
          },
        });
      } else {
        const newFilterListMore = { ...filterListMore };
        delete newFilterListMore[ name ];
        setFilterListMore(newFilterListMore);
      }
    } else {
      if (value.toString().length > 0) {
        setFilterList({
          ...filterList,
          [ name ]: {
            column: name,
            operator: "IN",
            value: Array.isArray(value) ? value : [ value ],
          },
        });
      } else {
        const newFilterList = { ...filterList };
        delete newFilterList[ name ];
        setFilterList(newFilterList);
      }
    }
  };

  return (
    <div className="ant-form ant-form-vertical filters-form">
      <div>
        <FormElements.RadioSelect
          skipQuery={!usersFilters}
          location={true}
          form={form}
          query={GET_SUPPLIER_CURSOR}
          model="suppliersCursor"
          name="SUPPLIER_ID"
          label="FORMS.Input_Label_Supplier"
          modalTitle="FORMS.Title_SelectSupplier"
          initialValue={
            usersFilters?.SUPPLIER_ID?.value
              ? [ { value: usersFilters?.SUPPLIER_ID?.value[ 0 ] } ]
              : undefined
          }
          onChangeSetFilter={() => onChangeSetFilter(false, "SUPPLIER_ID", form.getFieldValue("SUPPLIER_ID"))}
        />

        <FormElements.CheckboxSelect
          skipQuery={!usersFiltersExtra}
          location={true}
          query={GET_ROLES_CURSOR}
          form={form}
          model="rolesCursor"
          name="ID"
          label="FORMS.Input_Label_Role"
          modalTitle="FORMS.Title_AddRoles"
          modalButtonText="MODAL.Button_Text_AddRole"
          extraClassName="light-theme"
          disableSearch={true}
          extraClass="searh-border-bottom-none"
          initialValue={
            usersFiltersExtra?.ID?.value
              ? usersFiltersExtra?.ID?.value?.map((item: any) => ({ value: item }))
              : undefined
          }
          onChangeSetFilter={() => onChangeSetFilter(false, "ID", form.getFieldValue("ID"))}
        />

        <FormElements.CheckboxSelect
          skipQuery={!usersFiltersMore}
          location={true}
          form={form}
          query={GET_TEAMS_CURSOR}
          model="teamsCursor"
          name="ID_TEAM"
          label="FORMS.Input_Label_Team"
          modalTitle="FORMS.Title_AddTeams"
          modalButtonText="MODAL.Button_Text_AddTeam"
          extraClassName="light-theme"
          initialValue={
            usersFiltersMore?.ID_TEAM?.value
              ? usersFiltersMore?.ID_TEAM?.value.map((item: any) => ({
                value: item,
              }))
              : undefined
          }
          onChangeSetFilter={() => onChangeSetFilter(false, "ID_TEAM", form.getFieldValue("ID_TEAM"))}
        />

        <Form.Item
          name="STATUS"
          label={<Localize>FORMS.Input_Label_Status</Localize>}
          initialValue={usersFilters?.STATUS?.value}
        >
          <Select
            showSearch
            suffixIcon={<Icons.Arrow />}
            popupClassName="select-serch-dropdown"
            optionFilterProp="value"
            placeholder={<Localize>FORMS.Input_Placeholder_PleaseChose</Localize>}
            onChange={(value) =>
            {
              form.setFieldsValue({ STATUS: value });
              onChangeSetFilter(false, "STATUS", value);
            }}
          >
            <Select.Option key="active" value="active">
              <Localize>FORMS.Select_OptionLabel_Active</Localize>
            </Select.Option>
            <Select.Option key="block" value="block">
              <Localize>FORMS.Select_OptionLabel_Blocked</Localize>
            </Select.Option>
          </Select>
        </Form.Item>
      </div>

      <div className="form-btn-holder">
        <Button
          className="light-bg"
          htmlType="submit"
          onClick={() =>
          {
            setCount({});
            setFilterList(undefined);
            setFilterListExtra(undefined);
            setFilterListMore(undefined);
            setFilters(gqlBuilderWhere({}));
            setFiltersExtra(gqlBuilderWhere({}));
            setFiltersMore(gqlBuilderWhere({}));
            localStorage.removeItem("usersFilters");
            localStorage.removeItem("usersSorting");
            localStorage.removeItem("usersFiltersExtra");
            localStorage.removeItem("usersFiltersMore");
          }}
        >
          <Localize>FORMS.Button_Text_ResetFilters</Localize>
        </Button>

        <Button
          className="btn-right"
          type="primary"
          htmlType="submit"
          onClick={() =>
          {
            setFilters(gqlBuilderWhere({ ...usersFilters, ...filterList }));
            setFiltersExtra(gqlBuilderWhere({ ...usersFiltersExtra, ...filterListExtra }));
            setFiltersMore(gqlBuilderWhere({ ...usersFiltersMore, ...filterListMore }));
            setCount({
              ...usersFilters,
              ...filterList,
              ...usersFiltersExtra,
              ...filterListExtra,
              ...usersFiltersMore,
              ...filterListMore,
            });
            action();
          }}
        >
          <Localize>FORMS.Button_Text_ApplyFilters</Localize>
        </Button>
      </div>
    </div>
  );
};

export default UserFiltersForm;