import React from "react";
import { Link } from "react-router-dom";
import { TStand } from "../helpers/stand-types-helper";
import StandConst from "../stand-const";
import flagGermany from "assets/flag-germany.png";
import flagEngland from "assets/flag-england.png";
import { langExists } from "common/utils";


interface IFlagLinkProps
{
    lang: string;
    stand: TStand | undefined;
    standTypesGroup: any;
    path: string;
    imgSrc: string;
    text: string;
    activeLang: string;
}


const FlagLink: React.FC<IFlagLinkProps> = ({
    lang,
    stand,
    standTypesGroup,
    path,
    imgSrc,
    text,
    activeLang,
}) =>
{

    const standToCheck = stand?.relatives ? stand : { relatives: standTypesGroup };

    return (
        <li
            className={
                (langExists(standToCheck, lang)[ 0 ]?.title ? "exists " : "") +
                (lang === activeLang ? "active" : "")
            }
        >
            <Link to={path}>
                <div className="box-img">
                    <img src={imgSrc} alt="flag" />
                </div>
                <span className="text">{text}</span>
            </Link>
        </li>
    );
};

interface IStandLangsFieldProps
{
    stand: TStand | undefined;
    standTypesGroup?: TStand[];
    extraClass?: string;
    activeLang: string;
    conditionCreate: boolean;
}

const StandLangsField: React.FC<IStandLangsFieldProps> = ({
    stand,
    standTypesGroup,
    extraClass = "",
    activeLang,
    conditionCreate,
}) =>
{
    return (
        <ul className={`lang-flags ${extraClass}`}>
            <FlagLink
                lang="de"
                stand={stand}
                standTypesGroup={standTypesGroup}
                path={`${conditionCreate
                    ? StandConst.basePath + "/create/de/"
                    : `${StandConst.basePath}/edit/${stand?.group_id}/de/`
                    }`}
                imgSrc={flagGermany}
                activeLang={activeLang}
                text="Deutsch"
            />

            <FlagLink
                lang="en"
                stand={stand}
                standTypesGroup={standTypesGroup}
                path={`${conditionCreate
                    ? StandConst.basePath + "/create/en/"
                    : `${StandConst.basePath}/edit/${stand?.group_id}/en/`
                    }`}
                imgSrc={flagEngland}
                activeLang={activeLang}
                text="English"
            />
        </ul>
    );
};

export default StandLangsField;