import React from "react";
import { Button, Select, FormInstance } from "antd";
import Variable from "components/variable";
import Localize, { localizeByKey } from "components/service/localize";
import { IFilter } from 'components/supplier/helpers/supplier-types-helper';


interface IFilterList
{
    [ key: string ]: IFilter;
}

interface ISupplieriltersFormProps
{
    setCount: React.Dispatch<any>;
    setFilters: React.Dispatch<React.SetStateAction<IFilter | undefined>>;
    setFilterList: React.Dispatch<React.SetStateAction<IFilterList | undefined>>;
    filterList: IFilterList | undefined;
    supplierFilters: IFilterList | undefined;
    action: () => void;
    gqlBuilderWhere: (filters: any) => any;
    form: FormInstance;
}


const SupplieriltersForm: React.FC<ISupplieriltersFormProps> = ({
    form,
    action,
    setCount,
    supplierFilters,
    setFilters,
    setFilterList,
    filterList,
    gqlBuilderWhere,
}) =>
{

    const onChangeSetFilter = (
        e: React.ChangeEvent<HTMLInputElement> | false,
        name: string,
        value: string | string[]
    ) =>
    {
        if (e) {
            name = e.target.name;
            value = e.target.value;
        }

        if (value[ 0 ] === null) {
            setFilterList({
                ...filterList,
                [ name ]: {
                    column: name,
                    operator: "IS_NULL",
                    value: [],
                },
            });
        } else {
            if (value.toString().length > 0) {
                setFilterList({
                    ...filterList,
                    [ name ]: {
                        column: name,
                        operator: "IN",
                        value: Array.isArray(value) ? value : [ value ],
                    },
                });
            } else {
                const newFilterList = { ...filterList };
                delete newFilterList[ name ];
                setFilterList(newFilterList);
            }
        }
    };


    return (
        <div className="ant-form ant-form-vertical filters-form light-theme-form">
            <div>
                <Variable.Forms.Fields.VariablesSelect
                    form={form}
                    name="TYPE"
                    groupName="supplier type"
                    mode="multiple"
                    initialValue={Array.isArray(supplierFilters?.TYPE?.value) ? supplierFilters?.TYPE?.value[ 0 ] : supplierFilters?.TYPE?.value}
                    onChangeSetFilter={() => onChangeSetFilter(false, "TYPE", form.getFieldValue("TYPE"))}
                    label={
                        localizeByKey("FORMS.Input_Label_Type")
                    }
                    notAssignedOption={
                        <Select.Option key="typeNotSet" value={null}>
                            <Localize>FORMS.Select_OptionLabel_TypeNotSet</Localize>
                        </Select.Option>
                    }
                />
            </div>

            <div className="form-btn-holder">
                <Button
                    className="light-bg"
                    htmlType="submit"
                    onClick={() =>
                    {
                        setCount({});
                        setFilterList({});
                        setFilters(gqlBuilderWhere({}));
                        localStorage.removeItem("supplierFilters");
                        localStorage.removeItem("suppliersSorting");
                    }}
                >
                    <Localize>FORMS.Button_Text_ResetFilters</Localize>
                </Button>

                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    onClick={() =>
                    {
                        setFilters(
                            gqlBuilderWhere({
                                ...supplierFilters,
                                ...filterList,
                            })
                        );
                        setCount({
                            ...supplierFilters,
                            ...filterList,
                        });
                        action();
                    }}
                >
                    <Localize>FORMS.Button_Text_ApplyFilters</Localize>
                </Button>
            </div>
        </div>
    );
};

export default SupplieriltersForm;
