import React from "react";
import GraphicRuleForms from "../forms";
import { Localize, ModalStandard } from "components/service";
import { TGraphicSupplierRules } from "components/graphic/helpers/graphic-types-helper";

interface IGraphicRuleInfoFieldProps
{
  graphic?: TGraphicSupplierRules | undefined;
  graphSupplierID: string | undefined;
  variables: Record<string, any>;
  action: () => void;
}


const GraphicRuleInfoField: React.FC<IGraphicRuleInfoFieldProps> = ({ graphic, graphSupplierID, variables }) => (
  <ModalStandard
    width={550}
    extraClass={"modal-form"}
    modalButton={
      <strong style={{ cursor: "pointer" }}>{graphic?.title}</strong>
    }
    modalTitle={
      <strong className="modal-title">
        <span className="text">
          <Localize>FORMS.Title_EditGraphicRule</Localize>
        </span>
      </strong>
    }
  >

    <GraphicRuleForms.RuleEdit
      graphic={graphic}
      graphSupplierID={graphSupplierID}
      variables={variables}
      action={() => { }}
    />
  </ModalStandard>
);

export default GraphicRuleInfoField;
