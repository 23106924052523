import { getColorHex as flooringColors } from "common/utils";

const flooringBasePath = "/flooring";

// const flooringColors = {
//   flooring_color_blue: "#4471FF",
//   flooring_color_red: "#FF1B1B",
//   flooring_color_green: "#45FF1B",
//   flooring_color_gray: "#D3D3D3",
//   flooring_color_black: "#000000",
// };

const FlooringConst = {
    basePath: flooringBasePath,
    colorHexList: flooringColors,
};

export default FlooringConst;
