import React, { useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

import { GET_GRAPHIC_SUPPLIERS_CURSOR } from "graphql/query/graphic-gql";

import Graphic from "components/graphic";
import Icons from "components/icons";
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import Localize, { localizeByKey } from "components/service/localize";
import { gqlBuilderWhere } from "common/utils";
import { IFilter } from 'components/graphic/helpers/graphic-types-helper';
import "./graphic.scss";

const basePath = Graphic.Const.basePath;
const tableHelper = Graphic.Helpers.TableMain;


const GraphicsPage: React.FC = () =>
{
  useActiveMenuItem([ "variable-lists" ], [ "variable-lists" ]);

  const breadcrumbs = [
    {
      label: localizeByKey("BREADCRUMBS.Label_GraphicRules"),
      path: `${basePath}`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const pageNavbar = [
    {
      label: localizeByKey("NAVBAR_PAGES.Label_VariableLists"),
      path: "variable-lists",
    },
    {
      label: localizeByKey("NAVBAR_PAGES.Label_GraphicRules"),
      path: "graphic-rules",
    },
  ];

  const navigate = useNavigate();

  const objOrderBy = [ { column: "ID", order: "DESC" } ];

  const [ searchText, setSearchText ] = useState<string>("");
  const [ filters, setFilters ] = useState<IFilter | undefined>();
  const [ resetSorting, setResetSorting ] = useState<{ column: string; order: "ASC" | "DESC" }[]>();

  const graphicFilters = JSON.parse(localStorage.getItem("graphicFilters") || "{}");
  const graphicsSorting = JSON.parse(localStorage.getItem("graphicsSorting") || "{}");

  const objectFilters = filters || gqlBuilderWhere(graphicFilters);

  return (
    <PageWrapDefault
      className="page-graphics"
      dataExist={true}
      title={<Localize>PAGES.Title_Settings</Localize>}
      pageNavbar={pageNavbar}
      staticPath=""
    >
      <Tables.Cursor
        model="graphicSuppliersCursor"
        query={GET_GRAPHIC_SUPPLIERS_CURSOR}
        varSet={{ perPage: 50 }}
        routeUrl={`${basePath}`}
        searchText={searchText}
        tableHelper={tableHelper}
        objectWhere={objectFilters}
        objOrderBy={graphicsSorting || objOrderBy}
        sortingName="graphicsSorting"
        resetSorting={resetSorting}
      >
        <div className="table-action-bar">
          <div className="col">
            <Graphic.Filters.Table
              {...{
                setSearchText,
                filters,
                setFilters,
                graphicFilters,
                setResetSorting,
                objOrderBy,
              }}
            />
          </div>

          <div className="col">
            <Button
              type="primary"
              icon={<Icons.Plus />}
              onClick={() => navigate(`${basePath}/create`)}
            >
              <span className="ellipsis">
                <Localize>GLOBAL.Button_Text_CreateNew</Localize>
              </span>
            </Button>
          </div>
        </div>
      </Tables.Cursor>
    </PageWrapDefault>
  );
};

export default GraphicsPage;
