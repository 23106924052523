import React from "react";
import { Link } from "react-router-dom";
import { TEquipment } from "../helpers/equipment-types-helper";
import EquipmentConst from "../equipment-const";


interface IEquipmentInfoFieldProps
{
  equipment: TEquipment | undefined;
  lang: string;
}

const EquipmentInfoField: React.FC<IEquipmentInfoFieldProps> = ({ equipment, lang }) => (
  <Link
    className="table-link-underline"
    to={`${EquipmentConst.basePath}/edit/${equipment?.group_id}/${lang}/`}
  >
    {`${equipment?.title}`}
  </Link>
);

export default EquipmentInfoField;
