import EquipmentOverviewSubPage from "./equipment-owerview-sub-page";
import EquipmentVariationsSubPage from "./equipment-variations-sub-page";
import EquipmentSubstitudeSubPage from "./equipment-substitude-sub-page";

const SubPage = {
  OverView: EquipmentOverviewSubPage,
  Variations: EquipmentVariationsSubPage,
  Substitude: EquipmentSubstitudeSubPage,
};

export default SubPage;
