import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Pages } from 'components/pages';

const FlooringsRoute: React.FC = () =>
{
    return (
        <Routes>
            <Route path="/" element={<Pages.Floorings />} />
            <Route path="/:id/*" element={<Pages.Flooring />} />
            <Route path="/create" element={<Pages.Flooring />} />
        </Routes>
    );
};



export default FlooringsRoute;
