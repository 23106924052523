import { gql } from "@apollo/client";

export const EQUIPMENT_UPDATE = gql`
  mutation EquipmentUpdate($input: EquipmentFieldsInput!) {
    equipmentUpdate(input: $input) {
      label
      message
      equipment {
        id
        group_id
        title
        lang
        type
        navision_number
        octacad_number
        color
        supplier_article
        supplier_id
        supplier {
          id
          title
        }
      }
    }
  }
`;

export const EQUIPMENT_CREATE = gql`
  mutation EquipmentCreate($input: EquipmentFieldsInput!) {
    equipmentCreate(input: $input) {
      label
      message
      equipment {
        id
        group_id
        title
        lang
        type
        navision_number
        octacad_number
        color
        supplier_article
        supplier_id
        supplier {
          id
          title
        }
      }
    }
  }
`;

export const EQUIPMENT_DELETE = gql`
  mutation EquipmentDelete($group_id: ID!) {
    equipmentDelete(group_id: $group_id) {
      label
      message
    }
  }
`;

export const EQUIPMENT_VARIATION_UPDATE = gql`
  mutation EquipmentVariationUpdate($input: EquipmentVariationFieldsInput!) {
    equipmentVariationUpdate(input: $input) {
      label
      message
      equipmentVariation {
        id
        equipment_group
        title
        organizer_id
        article
        price
        created_at
        updated_at
      }
    }
  }
`;

export const EQUIPMENT_VARIATION_CREATE = gql`
  mutation EquipmentVariationCreate($input: EquipmentVariationFieldsInput!) {
    equipmentVariationCreate(input: $input) {
      label
      message
      equipmentVariation {
        id
        equipment_group
        title
        organizer_id
        article
        price
        created_at
        updated_at
      }
    }
  }
`;

export const EQUIPMENT_VARIATION_DELETE = gql`
  mutation EquipmentVariationDelete($id: ID!) {
    equipmentVariationDelete(id: $id) {
      label
      message
    }
  }
`;

export const EQUIPMENT_SYNC_SUBSTITUDE = gql`
  mutation EquipmentSyncSubstitude(
    $equipment_group: ID!
    $substitude_group: [ID]!
  ) {
    equipmentSyncSubstitude(
      equipment_group: $equipment_group
      substitude_group: $substitude_group
    ) {
      label
      message
      equipment {
        id
        group_id
        title
        lang
        type
        navision_number
        octacad_number
        color
        supplier_article
        supplier_id
        supplier {
          id
          title
        }
        images {
          id
          extension
          description
          field_group
          order
          server_name
          sizes
          title
        }
      }
    }
  }
`;

export const EQUIPMENT_REMOVE_SUBSTITUDE = gql`
  mutation EquipmentRemoveSubstitude(
    $equipment_group: ID!
    $substitude_group: ID!
  ) {
    equipmentRemoveSubstitude(
      equipment_group: $equipment_group
      substitude_group: $substitude_group
    ) {
      label
      message
    }
  }
`;
