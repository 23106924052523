import React from "react";
import { Routes, Route } from "react-router-dom";
import SubPage from "components/pages/graphic/sub-pages";
import type { TGraphicSupplier } from "components/graphic/helpers/graphic-types-helper";


interface IGraphicRouteProps
{
    graphic: TGraphicSupplier | undefined;
}


const GraphicRoute: React.FC<IGraphicRouteProps> = ({ graphic }) =>
{
    return (
        <Routes>
            <Route path="/rules" element={<SubPage.Rules graphic={graphic} />} />
            <Route path="/" element={<SubPage.OverView graphic={graphic} />} />
        </Routes>
    );
};

export default GraphicRoute;