import React from "react";
import { Routes, Route, useParams, Navigate } from "react-router-dom";
import { Pages } from "components/pages";

const EquipmentsRoute: React.FC = () => {
  function RedirectToLang() {
    const { group_id } = useParams<{ group_id: string }>();
    return <Navigate to={`/equipment/edit/${group_id}/de/`} />;
  }

  return (
    <Routes>
      <Route path="/" element={<Pages.Equipments />} />

      <Route
        path="edit/:group_id/"
        element={<RedirectToLang />}
      />

      <Route
        path="edit/:group_id/:lang/*"
        element={<Pages.Equipment />}
      />

      <Route
        path="create/:lang"
        element={<Pages.Equipment />}
      />
    </Routes>
  );
};

export default EquipmentsRoute;