import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Pages } from 'components/pages';

const OrganizersRoute: React.FC = () =>
{

  return (
    <Routes>
      <Route path="/" element={<Pages.Organizers />} />
      <Route path="/:id/*" element={<Pages.Organizer />} />
      <Route path="/create" element={<Pages.Organizer />} />
    </Routes>
  );
};


export default OrganizersRoute;