import FlooringInfoField from "./flooring-info-field";
import FlooringActionField from "./flooring-action-field";
import FlooringColorField from "./flooring-color-field";
import FlooringSupplierField from "./flooring-supplier-field";
import FlooringArticleField from "./flooring-article-field";
import FlooringImageField from "./flooring-image-field";

const FlooringFields = {
  Info: FlooringInfoField,
  Color: FlooringColorField,
  Supplier: FlooringSupplierField,
  Article: FlooringArticleField,
  Image: FlooringImageField,
  Action: FlooringActionField,
};

export default FlooringFields;
