import OrganizerOverviewSubPage from "./organizer-owerview-sub-page";
import OrganizerExhibitionSubPage from "./organizer-exhibitions-sub-page";
import OrganizerExhibitionCreateSubPage from "./organizer-exhibition-create-sub-page";
import OrganizerStandSubPage from "./organizer-stands-sub-page";
import OrganizerFlooringSubPage from "./organizer-floorings-sub-page";
import OrganizerEquipmentSubPage from "./organizer-equipments-sub-page";

const SubPage = {
  OverView: OrganizerOverviewSubPage,
  Stand: OrganizerStandSubPage,
  Flooring: OrganizerFlooringSubPage,
  Equipment: OrganizerEquipmentSubPage,
  Exhibition: OrganizerExhibitionSubPage,
  ExhibitionCreate: OrganizerExhibitionCreateSubPage,
};

export default SubPage;
