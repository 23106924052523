import React, { useState, useEffect } from "react";
import { Waypoint } from "react-waypoint";
import { Form, Checkbox, Skeleton, Button, FormInstance } from "antd";
import { useQuery, DocumentNode } from "@apollo/client";
import MultiSelect from "../multi-select";
import EditableTagGroup from "../editable-tag-group";
import { ModalStandard, cursorPagination, Localize } from "components/service";
import { localizeByKey } from "components/service/localize";
import { useDebounce } from "components/use-hooks";
import { Loader } from "components/request-result";
import Icons from "components/icons";
import { helperFunc } from "common/utils";

import "./checkbox-select.scss";


interface IInitialValueItem
{
    value: string;
    label?: string;
}


interface ICheckboxSelectProps
{
    form: FormInstance;
    initialValue?: IInitialValueItem[];
    model: string;
    name: string;
    query: DocumentNode;
    filteredNodes?: boolean | string;
    location?: boolean;
    filterValue?: string[] | string;
    onChangeSetFilter?: (arg1: boolean, arg2: string, arg3: any) => void;
    modalButtonText?: string;
    modalTitle?: string;
    label?: string;
    extraClassName?: string;
    extraClass?: string;
    modelCursorLabel?: string[];
    modelCursorValue?: string;
    numberOfCol?: string | boolean;
    objectWhere?: object;
    extraVariables?: object;
    first?: number;
    initialLang?: string | boolean;
    tagInfo?: boolean;
    jsx?: (imgPath?: string) => JSX.Element;
    skipQuery?: boolean;
    setDataModel?: (data: any) => void;
    disableSearch?: boolean;
    sort?: boolean;
    sortByCustomOrder?: (tags: any[], key: string) => any[];
}

const CheckboxSelect: React.FC<ICheckboxSelectProps> = ({
    form,
    initialValue = [],
    model,
    name,
    query,
    filteredNodes = false,
    location = false,
    filterValue = [],
    onChangeSetFilter = () => { },
    modalButtonText = "MODAL.Button_Text_Add",
    modalTitle = "MODAL.Title_Add",
    label = "FORMS.Input_Label",
    extraClassName = "",
    extraClass = "",
    modelCursorLabel = [ "title" ],
    modelCursorValue = "id",
    numberOfCol = false,
    objectWhere = {},
    extraVariables = {},
    first = 50,
    initialLang = false,
    tagInfo = true,
    jsx = () => <></>,
    skipQuery = true,
    setDataModel = () => { },
    disableSearch = false,
    sort = false,
    sortByCustomOrder = () => { },
}) =>
{
    const [ skip, setSkip ] = useState(skipQuery);

    const dataInit = Array.isArray(initialValue)
        ? initialValue?.map((modelObj) => modelObj?.value?.toString())
        : "";
    const value = Form.useWatch(name, form);
    const [ searchValue, setSearchValue ] = useState("");

    const { data, loading, fetchMore } = useQuery(query, {
        skip,
        variables: {
            text: useDebounce(searchValue),
            first,
            where: objectWhere,
            ...extraVariables,
        },
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
    });

    const { fetchMoreAction, nodes, pageInfo } = cursorPagination({
        label: modelCursorLabel,
        value: modelCursorValue,
        model,
        data,
        loading,
        fetchMore,
        initialLang: typeof initialLang === 'boolean' ? initialLang : false,
        jsx,
    });

    const setTags = (value: any) =>
    {
        form.setFieldsValue({ [ name ]: value });
    };

    const onCancel = () =>
    {
        if (JSON.stringify(value) !== JSON.stringify(dataInit)) {
            form.setFieldsValue({ [ name ]: dataInit });
        }
    };

    const filteredNodesArray =
        filteredNodes &&
        nodes.filter((node: any) => node.value !== filteredNodes);

    const nodesAndInitValue = filteredNodesArray
        ? [
            ...filteredNodesArray,
            ...(Array.isArray(initialValue)
                ? initialValue.filter((item) => item.label && !filterValue.includes(item.label))
                : []),
        ]
        : [ ...nodes, ...(Array.isArray(initialValue) ? initialValue : []) ];

    useEffect(() =>
    {
        setDataModel(data);
    }, [ data ]);

    const result = helperFunc.getLabel(value, nodesAndInitValue);
    const tags: any = Array.isArray(result) ? result : [];


    return (
        <Form.Item
            initialValue={dataInit}
            name={name}
            label={localizeByKey(label)}
        >
            <EditableTagGroup
                name={name}
                numberOfCol={numberOfCol}
                setTags={setTags}
                tagInfo={tagInfo}
                initialLang={initialLang}
                jsx={jsx}
                onChangeSetFilter={onChangeSetFilter}
                tags={sort ? sortByCustomOrder(tags, "value") : tags}
                extraClassName={extraClassName}
                location={location}
                model={model}
            >
                <ModalStandard
                    width={400}
                    extraClass={"modal-form"}
                    onCancel={onCancel}
                    modalButton={
                        <div>
                            <Button type="text" icon={<Icons.Plus />}>
                                <span
                                    onClick={() =>
                                    {
                                        setSkip(false);
                                    }}
                                    className="ellipsis"
                                >
                                    <Localize>{modalButtonText}</Localize>
                                </span>
                            </Button>
                        </div>
                    }
                    modalTitle={
                        <strong className="modal-title">
                            <span className="text">
                                <Localize>{modalTitle}</Localize>
                            </span>
                        </strong>
                    }
                >
                    <MultiSelect
                        extraClass={extraClass}
                        setSearch={setSearchValue}
                        search={searchValue}
                        onCancel={onCancel}
                        disableSearch={disableSearch}
                    >
                        {loading && <Loader />}
                        {searchValue && loading ? (
                            <Skeleton active paragraph={{ rows: 6 }} />
                        ) : (
                            <Checkbox.Group
                                className="group-checkbox"
                                //@ts-expect-error: Type mismatch between Antd Checkbox.Group options and nodes
                                options={
                                    filteredNodesArray
                                        ? filteredNodesArray
                                        : sort
                                            ? sortByCustomOrder(nodes, "value")
                                            : nodes
                                }
                                value={value}
                                onChange={(value) =>
                                {
                                    form.setFieldsValue({ [ name ]: value });
                                    onChangeSetFilter(false, name, value);
                                }}
                            />
                        )}

                        {pageInfo && pageInfo.hasNextPage && (
                            <>
                                {loading && (
                                    <Loader style={{ marginTop: "-30px" }} />
                                )}
                                <Waypoint onEnter={() => fetchMoreAction()} />
                            </>
                        )}
                    </MultiSelect>
                </ModalStandard>
            </EditableTagGroup>
        </Form.Item >
    );
};

export default CheckboxSelect;
