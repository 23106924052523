import { useNavigate } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { SUPPLIER_UPDATE, SUPPLIER_CREATE } from "graphql/mutation/supplier-gql";
import SupplierConst from '../supplier-const';
import { successNotification, errorNotification } from "components/request-result";



const useMutationSupplierCreateUpdate = (id: string | undefined, redirect: boolean = true) => {

  const navigate = useNavigate();

  const SUPPLIER_CREATE_UPDATE = id ? SUPPLIER_UPDATE : SUPPLIER_CREATE;
  const supplierCreateUpdate = id ? 'supplierUpdate' : 'supplierCreate';

  const [ _setSupplierCreateUpdate, { loading } ] = useMutation(SUPPLIER_CREATE_UPDATE,
    {
      update(cache, { data }) {

        const {
          [ supplierCreateUpdate ]: {
            label,
            message,
          }
        } = data;


        redirect && navigate(SupplierConst.basePath);

        successNotification({
          title: label,
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      }
    }
  );


  return {
    _setSupplierCreateUpdate,
    loadingMutationSupplierCreateUpdate: loading,
  };
};

export default useMutationSupplierCreateUpdate;

