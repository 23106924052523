import VariableEditForm from "./variable-edit-form";
import VariableFormItems from "./fields";


const VariableForms = {
    Edit: VariableEditForm,
    Fields: VariableFormItems,
};

export default VariableForms;
