import { gql } from "@apollo/client";

export const GET_SUPPLIER_CURSOR = gql`
    query SuppliersCursor(
        $text: String
        $where: QuerySuppliersCursorWhereWhereConditions
        $whereExhibitionTypeSuppliers: QuerySuppliersCursorWhereExhibitionTypeSuppliersWhereHasConditions
        $first: Int!
        $after: String
    ) {
        suppliersCursor(
            text: $text
            where: $where
            whereExhibitionTypeSuppliers: $whereExhibitionTypeSuppliers
            first: $first
            after: $after
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
                total
                count
                currentPage
                lastPage
            }
            edges {
                cursor
                node {
                    id
                    title
                    type
                }
            }
        }
    }
`;

export const GET_SUPPLIERS = gql`
    query GetSuppliers(
        $text: String
        $where: QuerySuppliersWhereWhereConditions
        $first: Int
        $page: Int
        $orderBy: [QuerySuppliersOrderByOrderByClause!]
    ) {
        suppliers(
            text: $text
            where: $where
            first: $first
            page: $page
            orderBy: $orderBy
        ) {
            paginatorInfo {
                total
                count
                currentPage
                perPage
                lastPage
            }
            data {
                id
                title
                number
                type
                usersCount
                users {
                    id
                    name
                }
                description
                created_at
                updated_at
            }
        }
    }
`;

export const GET_SUPPLIER = gql`
    query GetSupplier($id: ID) {
        supplier(id: $id) {
            id
            title
            number
            type
            usersCount
            users {
                id
                name
            }
            description
            created_at
            updated_at
        }
    }
`;
