import React from "react";
import { Link } from "react-router-dom";
import Supplier from "components/supplier";
import { TEquipment } from "../helpers/equipment-types-helper";

interface IEquipmentActionFieldProps
{
  equipment: TEquipment | undefined;
}

const EquipmentSupplierField: React.FC<IEquipmentActionFieldProps> = ({ equipment }) =>
{
  return equipment?.supplier?.title ? (
    <Link
      className="table-link-underline"
      to={`${Supplier.Const.basePath}/${equipment?.supplier?.id}`}
    >
      {`${equipment?.supplier?.title}`}
    </Link>
  ) : (
    <strong>&mdash;</strong>
  );
};

export default EquipmentSupplierField;
