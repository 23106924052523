import React from "react";
import { Form, Input, InputNumber, Button, Switch } from "antd";

import { graphicUseMutation } from "../../hooks";

import Localize from "components/service/localize";
import { TGraphicSupplierRules } from "components/graphic/helpers/graphic-types-helper";
import Icons from "components/icons";


interface IGraphicRuleEditFormProps
{
  graphic?: TGraphicSupplierRules | undefined;
  graphSupplierID: string | undefined;
  variables: Record<string, any>;
  action: () => void;
}


const GraphicRuleEditForm: React.FC<IGraphicRuleEditFormProps> = ({
  graphic,
  graphSupplierID,
  variables,
  action,
}) =>
{
  const [ form ] = Form.useForm();

  const {
    _setGraphicRuleCreateUpdate,
    loadingMutationGraphicRuleCreateUpdate,
  } = graphicUseMutation.ruleCreateUpdate(graphic?.id, variables);

  const valueFieldLettering = Form.useWatch("lettering", form);

  return (
    <Form
      key="edit-graphic-rule-form"
      layout="vertical"
      form={form}
      className="model-form edit-graphic-form"
      onFinish={(values) =>
      {
        _setGraphicRuleCreateUpdate({
          variables: {
            input: {
              id: graphic?.id ? +graphic.id : undefined,
              graphic_supplier_id: graphSupplierID,
              ...values,
            },
          },
        });
        action();
        form.resetFields();
      }}
    >
      <Form.Item
        name="title"
        label={<Localize>FORMS.Input_Label_Leistungscode</Localize>}
        initialValue={graphic?.title}
        rules={[
          {
            required: true,
            message: <Localize>FORM_RULES.Required_Leistungscode</Localize>,
          },
        ]}
      >
        <Input name="title" />
      </Form.Item>

      <div className="row-grid grid-gap-10 row-gap-0 col-md-3">
        <Form.Item
          name="price_manufacture"
          label={
            <>
              <Localize>FORMS.Input_Label_EPFertigungListe</Localize> &#8364;
            </>
          }
          initialValue={graphic?.price_manufacture}
          rules={[
            {
              required: true,
              message: (
                <Localize>FORM_RULES.Required_EPFertigungListe</Localize>
              ),
            },
          ]}
        >
          <InputNumber name="price_manufacture" min={0} style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          name="price_on_supplier"
          label={
            <>
              <Localize>FORMS.Input_Label_EPMontageWerkstatt</Localize> &#8364;
            </>
          }
          initialValue={graphic?.price_on_supplier}
          rules={[
            {
              required: false,
              message: (
                <Localize>FORM_RULES.Required_EPMontageWerkstatt</Localize>
              ),
            },
          ]}
        >
          <InputNumber name="price_on_supplier" min={0} style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          name="price_on_stand"
          label={
            <>
              <Localize>FORMS.Input_Label_EPMontageStand</Localize> &#8364;
            </>
          }
          initialValue={graphic?.price_on_stand}
          rules={[
            {
              required: false,
              message: <Localize>FORM_RULES.Required_EPMontageStand</Localize>,
            },
          ]}
        >
          <InputNumber name="price_on_stand" min={0} style={{ width: "100%" }} />
        </Form.Item>
      </div>

      <Form.Item
        name="lettering"
        label={<Localize>FORMS.Input_Label_Lettering</Localize>}
        initialValue={graphic?.lettering ? true : false}
        className="form-item-switch"
      >
        <Switch defaultChecked={graphic?.lettering ? true : false} />
      </Form.Item>

      {valueFieldLettering && (
        <div className="row-grid grid-gap-10 row-gap-0 col-md-2">
          <Form.Item
            name="free_symbols"
            label={<Localize>FORMS.Input_Label_FreeCharactersAmount</Localize>}
            initialValue={graphic?.free_symbols}
            rules={[
              {
                required: true,
                message: (
                  <Localize>FORM_RULES.Required_FreeCharactersAmount</Localize>
                ),
              },
            ]}
          >
            <InputNumber name="free_symbols" min={0} style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="price_additional"
            label={<Localize>FORMS.Input_Label_AdditionalPrice</Localize>}
            initialValue={graphic?.price_additional}
            rules={[
              {
                required: true,
                message: (
                  <Localize>FORM_RULES.Required_AdditionalPrice</Localize>
                ),
              },
            ]}
          >
            <InputNumber name="price_additional" min={0} style={{ width: "100%" }} />
          </Form.Item>
        </div>
      )}

      <div className="form-btn-holder">
        <Button className="light-bg" onClick={action}>
          <Localize>GLOBAL.Button_Text_Cancel</Localize>
        </Button>
        <Button
          className="btn-right"
          type="primary"
          htmlType="submit"
          icon={graphic?.id ? <Icons.Edit /> : <Icons.Plus />}
          loading={loadingMutationGraphicRuleCreateUpdate}
        >
          <span className="ellipsis">
            {graphic?.id ? (
              <Localize>GLOBAL.Button_Text_SaveChanges</Localize>
            ) : (
              <Localize>GLOBAL.Button_Text_CreateNew</Localize>
            )}
          </span>
        </Button>
      </div>
    </Form>
  );
};

export default GraphicRuleEditForm;
