import React, { useState, useEffect } from "react";
import { Input, Button, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { gqlBuilderWhere, helperFunc } from "common/utils";
import UserForms from "../../forms";
import { IFilter } from '../../helpers/user-types-helper';
import Icons from "components/icons";
import { DrawerStandart, Localize } from "components/service";
import { localizeByKey } from "components/service/localize";

const { Search } = Input;


interface IFilterList
{
    [ key: string ]: IFilter;
}

interface IUserFiltersFormProps
{
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    setFilters: React.Dispatch<React.SetStateAction<IFilter | undefined>>;
    setFiltersExtra: React.Dispatch<React.SetStateAction<IFilter | undefined>>;
    setFiltersMore: React.Dispatch<React.SetStateAction<IFilter | undefined>>;
    setResetSorting: React.Dispatch<React.SetStateAction<{ column: string; order: "ASC" | "DESC" }[] | undefined>>;
    usersFilters: { [ key: string ]: IFilter } | undefined;
    usersFiltersExtra: { [ key: string ]: IFilter } | undefined;
    usersFiltersMore: { [ key: string ]: IFilter } | undefined;
    filters: IFilter | undefined;
    filtersExtra: IFilter | undefined;
    filtersMore: IFilter | undefined;
    objOrderBy: { column: string; order: string }[];
}

const UserTableFilter: React.FC<IUserFiltersFormProps> = ({
    setSearchText,
    setFilters,
    setFiltersExtra,
    setFiltersMore,
    setResetSorting,
    usersFilters,
    usersFiltersExtra,
    usersFiltersMore,
    filters,
    filtersExtra,
    filtersMore,
    objOrderBy,
}) =>
{

    const [ form ] = Form.useForm();

    const [ filterList, setFilterList ] = useState<IFilterList | undefined>(undefined);
    const [ filterListExtra, setFilterListExtra ] = useState<IFilterList | undefined>(undefined);
    const [ filterListMore, setFilterListMore ] = useState<IFilterList | undefined>(undefined);
    const [ count, setCount ] = useState<any>({});

    const filterCount = helperFunc.countFilterList(count);

    useEffect(() =>
    {
        if (filters) {
            localStorage.setItem(
                "usersFilters",
                JSON.stringify({ ...usersFilters, ...filterList })
            );
        } else form.resetFields([ "SUPPLIER_ID", "STATUS" ]);

        if (filtersExtra) {
            localStorage.setItem(
                "usersFiltersExtra",
                JSON.stringify({ ...usersFiltersExtra, ...filterListExtra })
            );
        } else form.resetFields([ "ID" ]);

        if (filtersMore) {
            localStorage.setItem(
                "usersFiltersMore",
                JSON.stringify({ ...usersFiltersMore, ...filterListMore })
            );
        } else form.resetFields([ "ID_TEAM" ]);
    }, [ filters, filtersExtra, filtersMore ]);

    useEffect(() =>
    {
        if (usersFilters) {
            setFilters(gqlBuilderWhere(usersFilters));
        }

        if (usersFiltersExtra) {
            setFiltersExtra(gqlBuilderWhere(usersFiltersExtra));
        }

        if (usersFiltersMore) {
            setFiltersMore(gqlBuilderWhere(usersFiltersMore));
        }

        setCount({
            ...usersFilters,
            ...usersFiltersExtra,
            ...usersFiltersMore,
        });
    }, []);


    return (
        <>
            <Search
                className="filter-search"
                prefix={<SearchOutlined />}
                placeholder={localizeByKey("SEARCH.Input_Placeholder_Table_User")}
                onChange={(e) => setSearchText(e.target.value)}
            />
            <Form
                key="filters-form"
                className="clear-css-prop table-filter-form"
                layout="vertical"
                form={form}
            >
                <DrawerStandart
                    extraClass={"modal-form filters"}
                    modalButton={
                        <Button icon={<Icons.Filter />}>
                            <span className="ellipsis">
                                <Localize>FORMS.Button_Text_Filters</Localize>
                            </span>
                            {!!filterCount && (
                                <span className="filter-count">{filterCount}</span>
                            )}
                        </Button>
                    }
                >
                    <strong className="modal-title">
                        <span className="text">
                            <Localize>FORMS.Title_Text_Filters</Localize>
                        </span>
                    </strong>

                    <UserForms.Filter
                        setCount={setCount}
                        setFilters={(filters) => setFilters(filters as IFilter)}
                        setFiltersExtra={(filtersExtra) => setFiltersExtra(filtersExtra as IFilter)}
                        setFiltersMore={(filtersMore) => setFiltersMore(filtersMore as IFilter)}
                        setFilterList={setFilterList}
                        setFilterListExtra={setFilterListExtra}
                        setFilterListMore={setFilterListMore}
                        filterList={filterList}
                        filterListExtra={filterListExtra}
                        filterListMore={filterListMore}
                        usersFilters={usersFilters}
                        usersFiltersExtra={usersFiltersExtra}
                        usersFiltersMore={usersFiltersMore}
                        gqlBuilderWhere={gqlBuilderWhere}
                        form={form}
                        action={() => { }}
                    />
                </DrawerStandart>

                {(filters || filtersExtra || filtersMore) && (
                    <Button
                        className="underline"
                        onClick={() =>
                        {
                            setCount({});
                            setFilterList({});
                            setFilterListExtra({});
                            setFilterListMore({});
                            setFilters(gqlBuilderWhere({}));
                            setFiltersExtra(gqlBuilderWhere({}));
                            setFiltersMore(gqlBuilderWhere({}));
                            setResetSorting(objOrderBy as { column: string; order: "ASC" | "DESC" }[]);
                            localStorage.removeItem("usersFilters");
                            localStorage.removeItem("usersFiltersExtra");
                            localStorage.removeItem("usersFiltersMore");
                            localStorage.removeItem("usersSorting");
                        }}
                    >
                        <span>
                            <Localize>FORMS.Button_Text_Reset</Localize>
                        </span>
                    </Button>
                )}
            </Form>
        </>
    );
};

export default UserTableFilter;
