import { useMutation } from "@apollo/client";
import { DocumentNode } from "graphql";
import { EXHIBITION_TYPE_REMOVE_MODEL } from "graphql/mutation/exhibition-gql";

import
  {
    successNotification,
    errorNotification,
  } from "components/request-result";

const useMutationExhibitionRemove = (query: DocumentNode | undefined, variables: Record<string, any>) =>
{
  if (!query) {
    throw new Error("Query must be a valid DocumentNode");
  }

  const [ _setMutationExhibitionRemove, { loading } ] = useMutation(
    EXHIBITION_TYPE_REMOVE_MODEL,
    {
      update(cache, { data })
      {
        const {
          exhibitionTypeRemoveModel: { label, message },
        } = data;

        successNotification({
          title: label.toUpperCase(),
          description: message,
        });
      },

      refetchQueries: [
        {
          query: query,
          variables,
        },
      ],

      onError(error)
      {
        errorNotification(error);
      },
    }
  );

  return {
    _setMutationExhibitionRemove,
    loadingMutationExhibitionRemove: loading,
  };
};

export default useMutationExhibitionRemove;