import useMutationGraphicCreateUpdate from "./graphic-use-mutation-create-update";
import useMutationGraphicDelete from "./graphic-use-mutation-delete";
import useMutationGraphicRuleCreateUpdate from "./graphic-rule-use-mutation-create-update";
import useMutationGraphicRuleDelete from "./graphic-rule-use-mutation-delete";

export const graphicUseMutation = {
  createUpdate: useMutationGraphicCreateUpdate,
  ruleCreateUpdate: useMutationGraphicRuleCreateUpdate,
  delete: useMutationGraphicDelete,
  ruleDelete: useMutationGraphicRuleDelete,
};
