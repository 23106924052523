import React from 'react';
import { Button, Table } from 'antd';
import { Localize, ModalStandard } from 'components/service';
import { IColumn } from "common/types";

import './elements.scss';


enum ModelType
{
    Supplier = 'supplier',
}

interface ISyncInfo
{
    records: number;
    new: number;
    updated: number;
}

type TData = Partial<Record<ModelType, ISyncInfo>>;


interface ImportedProps
{
    data: {
        sync_info: TData;
    };
    modalTitle?: string;
    isImport?: boolean;
    model?: ModelType;
}

const Imported: React.FC<ImportedProps> = ({ data, modalTitle = 'The file is import', isImport = false, model }) =>
{
    if (model && model in data.sync_info) {

        const syncInfo = data.sync_info[ model ]!;
        data.sync_info[ model ] = {
            records: syncInfo.records,
            new: syncInfo.new,
            updated: syncInfo.updated,
        };
    }

    function convertData(syncInfo: TData)
    {
        const newData = [];
        for (const key in syncInfo) {
            if (key in syncInfo) {
                const modelKey = key as ModelType;
                newData.push({
                    key: modelKey,
                    records: modelKey,
                    found: syncInfo[ modelKey ]!.records,
                    new: syncInfo[ modelKey ]!.new,
                    update: syncInfo[ modelKey ]!.updated,
                });
            }
        }
        return newData;
    }

    let columns: IColumn[] = [
        {
            title: <Localize>TABLES.Column_Title_Records</Localize>,
            dataIndex: 'records',
            className: 'primary-color',
        },
        {
            title: <Localize>TABLES.Column_Title_Found</Localize>,
            dataIndex: 'found',
        },
        {
            title: <Localize>TABLES.Column_Title_New</Localize>,
            dataIndex: 'new',
        },
        {
            title: <Localize>TABLES.Column_Title_Updated</Localize>,
            dataIndex: 'update',
        },
    ];

    columns = isImport ? columns.filter(column => (column.dataIndex as unknown as string).includes('new')) : columns;


    return (
        <ModalStandard
            width={400}
            extraClass={'modal-form'}
            defaultShow={true}>
            <div className='holder-importing'>
                <Localize className='modal-title' tag="h5" wrap>{modalTitle}</Localize>
                <Table className='modal-table' columns={columns} dataSource={convertData(data.sync_info)} pagination={false} />
                <Button className="light-bg" onClick={() => { }}>Ok</Button>
            </div>
        </ModalStandard >
    );
};

export default Imported;
