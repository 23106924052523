import { useMutation } from "@apollo/client";
import { GRAPHIC_SUPPLIER_RULE_DELETE } from "graphql/mutation/graphic-gql";
import { GET_GRAPHIC_SUPPLIER_RULES_CURSOR } from "graphql/query/graphic-gql";

import
{
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationGraphicRuleDelete = (id: string | undefined, variables: Record<string, any>) =>
{
  const [ _setMutationGraphicRuleDelete, { loading } ] = useMutation(
    GRAPHIC_SUPPLIER_RULE_DELETE,
    {
      update(cache, { data })
      {
        const {
          graphicSupplierRuleDelete: { label, message },
        } = data;

        cache.evict({
          id: cache.identify({ id, __typename: "GraphicRuleSupplier" }),
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message,
        });
      },
      onError(error)
      {
        errorNotification(error);
      },
      refetchQueries: [
        {
          query: GET_GRAPHIC_SUPPLIER_RULES_CURSOR,
          variables,
        },
      ],
    }
  );

  const handleDelete = async (options: {
    variables: Record<string, any>,
  }) =>
  {
    await _setMutationGraphicRuleDelete(options);
  };


  return {
    _setMutationGraphicRuleDelete: handleDelete,
    loadingMutationGraphicRuleDelete: loading,
  };
};

export default useMutationGraphicRuleDelete;
