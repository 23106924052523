import { makeVar, ReactiveVar } from "@apollo/client";

import { IPrivateReactiveVar } from "./reactive-var-interfaces";
import packageInfo from '../../../package.json';


const version = packageInfo.version;


export const privateParamsInitial : IPrivateReactiveVar = {
    headerClass : [],
    mainBlockClass : [],
    appBlockClass : [],
    leftHeaderControl : null,
    headerTitle : "",
    rightHeaderControl : null,
    searchOn : true,
    logoOn : false,
    notifications : {
        errorOn : true,
        successOn : false
    },
    rememberQueryParams : {},
    appVersion : version,
    defaultLanguage : "en",
    me: null,
    translationInterface: [],
};

export const privateReactiveVar: ReactiveVar<IPrivateReactiveVar> = makeVar<IPrivateReactiveVar>(privateParamsInitial);