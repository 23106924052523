import React from "react";
import { Routes, Route } from "react-router-dom";
import SubPage from "components/pages/organizer/sub-pages";
import type { TOrganizer } from "components/organizer/helpers/organizer-types-helper";

interface IOrganizerRouteProps
{
  organizer: TOrganizer | undefined;
}

const OrganizerRoute: React.FC<IOrganizerRouteProps> = ({ organizer }) =>
{
  return (
    <Routes>
      <Route path="/" element={<SubPage.OverView organizer={organizer} />} />
      <Route path="/exhibition-types/create" element={<SubPage.ExhibitionCreate organizer={organizer} />} />
      <Route path="/equipment" element={<SubPage.Equipment organizer={organizer} />} />
      <Route path="/flooring" element={<SubPage.Flooring organizer={organizer} />} />
      <Route path="/exhibition-types" element={<SubPage.Exhibition organizer={organizer} />} />
      <Route path="/stand-types" element={<SubPage.Stand organizer={organizer} />} />
    </Routes>
  );
};

export default OrganizerRoute;