import React from "react";
import { Link } from "react-router-dom";
import StandConst from "../stand-const";
import { Localize } from "components/service";
import { TStand } from "../helpers/stand-types-helper";


interface IStandInfoFieldProps
{
  stand: TStand;
  lang: string;
}


const StandInfoField: React.FC<IStandInfoFieldProps> = ({ stand, lang }) => (
  <Link
    className="table-link-underline"
    to={`${StandConst.basePath}/edit/${stand?.group_id}/${lang}/`}
  >
    {stand?.title ? stand?.title : <Localize>VARIABLE.Label_EMPTY</Localize>}
  </Link>
);

export default StandInfoField;
