import { Localize } from "components/service";
import React from "react";
import { Link } from "react-router-dom";
import RoleConst from "../role-const";
import { TRole } from "components/role/helpers/role-types-helper";


interface IRoleRoleFieldProps
{
  role: TRole;
}

const RoleRoleField: React.FC<IRoleRoleFieldProps> = ({ role }) =>
{
  return Object.keys(role).length ? (
    <Link
      className="table-link-underline"
      style={{ color: role?.colorHex }}
      to={`${RoleConst.basePath}/${role?.id}/`}
    >
      <strong>
        <Localize>{role.title}</Localize>
      </strong>
    </Link>
  ) : (
    <strong>&mdash;</strong>
  );
};

export default RoleRoleField;