import RoleFields from "../fields";
import { Localize } from "components/service";
import { TRole } from "components/role/helpers/role-types-helper";


const roleColumns = [
    {
        title: <Localize>TABLES.Column_Title_ID</Localize>,
        dataIndex: 'id',
        columnIndex: 'ID',
        sorter: true,
        width: '4%'
    },
    {
        title: <Localize>TABLES.Column_Title_RoleName</Localize>,
        dataIndex: 'title',
        columnIndex: 'TITLE',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_NumberRights</Localize>,
        dataIndex: 'number_rights',
        columnIndex: 'NUMBER_RIGHTS',
        // sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_NumberUsers</Localize>,
        dataIndex: 'number_users',
        columnIndex: 'NUMBER_USERS',
        // sorter: true
    },
    {
        // title: '',
        dataIndex: 'action',
        align: 'right',
        width: '4%',
    }
];

const roleData = (roles: TRole[] | undefined) =>
{
    if (!roles) {
        return [];
    }

    return roles.map(role =>
    {
        return {
            key: role.id,
            id: role.id,
            title: <RoleFields.Info role={role} />,
            number_rights: role.permissionsCount.library ?? <strong>&mdash;</strong>,
            number_users: role.usersCount ?? <strong>&mdash;</strong>,
            action: <RoleFields.Action roleId={role.id} roleName={role.title} />
        };
    });
};

const RolesTableHelper = {
    columns: roleColumns,
    data: roleData,
};

export default RolesTableHelper;