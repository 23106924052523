import React from "react";
import { Link } from "react-router-dom";
import OrganizerConst from "../organizer-const";
import type { TOrganizer } from "components/organizer/helpers/organizer-types-helper";


interface IOrganizerInfoFieldProps
{
  organizer: TOrganizer | undefined;
}


const OrganizerInfoField: React.FC<IOrganizerInfoFieldProps> = ({ organizer }) =>
{
  return (
    <Link
      className="table-link-underline"
      to={`${OrganizerConst.basePath}/${organizer?.id}/`}
    >
      {organizer?.title}
    </Link>
  );
};

export default OrganizerInfoField;
