import React from "react";
import { SortableHandle } from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import type { TAttachment } from "components/stand/helpers/stand-types-helper";
import Icons from "components/icons";


interface IStandFileAttachmentFieldProps
{
  attachment: TAttachment;
  dragHandle?: boolean;
}


const StandFileAttachmentField: React.FC<IStandFileAttachmentFieldProps> = ({ attachment, dragHandle = false }) =>
{
  const DragHandle = SortableHandle(() => (
    <MenuOutlined
      style={{
        cursor: "grab",
        color: "#999",
        padding: "15px 20px",
      }}
    />
  ));

  return (
    <div className="d-flex align-items-center">
      {dragHandle && <DragHandle />}

      <Icons.Documents
        type={attachment?.extension}
        style={{ fontSize: "35px", marginRight: "15px" }}
      />

      <div style={{ width: "100%", overflow: "hidden" }}>
        <div className="download-file-info">
          <a
            href={attachment?.path}
            className="download-file-name"
            target="_blank"
            rel="noreferrer"
          >
            <strong className="file-name">{attachment?.original_name}</strong>
            <strong>.{attachment?.extension}</strong>
          </a>
        </div>
        <span style={{ fontSize: "11px", color: "#888888" }}>
          {attachment?.size}
        </span>
      </div>
    </div>
  );
};

export default StandFileAttachmentField;
