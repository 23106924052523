import { Routes, Route, Navigate } from "react-router-dom";
import UsersRoute from "./user/users-route";
import RolesRoute from "./role/roles-route";
import SuppliersRoute from "./supplier/suppliers-route";
import TeamsRoute from "./team/teams-route";
import FlooringsRoute from "./flooring/floorings-route";
import EquipmentsRoute from "./equipment/equipments-route";
import VariablesRoute from "./variable/variables-route";
import GraphicsRoute from "./graphic/graphics-route";
import StandsRoute from "./stand/stands-route";
import ExhibitionsRoute from "./exhibition/exhibitions-route";
import OrganizersRoute from "./organizer/organizers-route";
import { Pages } from "components/pages";

const AppRoutes = () =>
{
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/users" />} />
            <Route path="/users/*" element={<UsersRoute />} />
            <Route path="/user-roles/*" element={<RolesRoute />} />
            <Route path="/suppliers/*" element={<SuppliersRoute />} />
            <Route path="/teams/*" element={<TeamsRoute />} />
            <Route path="/flooring/*" element={<FlooringsRoute />} />
            <Route path="/equipment/*" element={<EquipmentsRoute />} />
            <Route path="/stand-types/*" element={<StandsRoute />} />
            <Route path="/variable-lists/*" element={<VariablesRoute />} />
            <Route path="/graphic-rules/*" element={<GraphicsRoute />} />
            <Route path="/exhibition-types/*" element={<ExhibitionsRoute />} />
            <Route path="/organizers/*" element={<OrganizersRoute />} />
            <Route path="*" element={<Pages.Page404 />} />
        </Routes>
    );
};

export default AppRoutes;
