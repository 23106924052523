import { useNavigate } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { TEAM_DELETE } from "graphql/mutation/team-gql";
import TeamConst from '../team-const';
import { successNotification, errorNotification } from "components/request-result";


const useMutationTeamDelete = (id: string | undefined) =>
{

  const navigate = useNavigate();

  const [ _setMutationTeamDelete, { loading } ] = useMutation(TEAM_DELETE,
    {
      update(cache, { data })
      {

        const {
          teamDelete: {
            label,
            message
          }
        } = data;

        navigate(TeamConst.basePath);

        cache.evict({
          id: cache.identify({ id, __typename: "Team" })
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
      onError(error)
      {
        errorNotification(error);
      }
    });

  const handleDeleteUser = async (options: { variables: Record<string, any> }) =>
  {
    await _setMutationTeamDelete(options);
  };

  return {
    _setMutationTeamDelete: handleDeleteUser,
    loadingMutationTeamDelete: loading,
  };
};

export default useMutationTeamDelete;