import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_FLOORING } from "graphql/query/flooring-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import Flooring from "components/flooring";
import FlooringRoute from "components/routes/flooring/flooring-route";
import Localize, { localizeByKey } from "components/service/localize";

const FlooringPage: React.FC = () =>
{
  useActiveMenuItem([ "flooring" ], [ "flooring" ]);

  const { id } = useParams<{ id: string }>();
  const flooringID = id ? parseInt(id) : "create";
  const basePath = Flooring.Const.basePath;

  const { data: { flooring } = {}, loading } = useQuery(GET_FLOORING, {
    skip: flooringID === "create",
    variables: {
      id: flooringID,
    },
  });

  const pageNavbar = [
    {
      label: localizeByKey("NAVBAR_PAGES.Label_Overview"),
      path: "",
    },
  ];

  return (
    <PageWrapDefault
      className="page-flooring"
      loading={loading}
      title={
        flooringID === "create" ? (
          <Localize>PAGES.Title_CreateFlooring</Localize>
        ) : !loading && flooring ? (
          <Localize>{flooring.title}</Localize>
        ) : (
          "Page 404"
        )
      }
      dataExist={flooringID === "create" ? true : !loading && flooring !== undefined}
      pageNavbar={!loading && flooring ? pageNavbar : undefined}
      staticPath={`${basePath}/${flooringID}`}
    >
      <FlooringRoute flooring={flooring} />
    </PageWrapDefault>
  );
};

export default FlooringPage;
