import { gql } from "@apollo/client";

export const GET_MODELS_IDS = gql`
  query GetRelatedModelIds(
    $modelFrom: modelFrom!
    $modelTo: modelTo!
    $rel_id: ID!
  ) {
    getRelatedModelIds(
      modelFrom: $modelFrom
      modelTo: $modelTo
      rel_id: $rel_id
    )
  }
`;
