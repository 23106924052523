import VariableGroupTableHelper from './variable-group-table-helper';
import VariableListTableHelper from './variable-list-table-helper';



const VariableHelpers = {
    TableGroup: VariableGroupTableHelper,
    TableList: VariableListTableHelper

};

export default VariableHelpers;