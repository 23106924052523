import { privateParamsInitial, privateReactiveVar } from "./private-reactive-var";
import { publicParamsInitial, publicReactiveVar } from "./public-reactive-var";

import type { IPrivateReactiveVar, IPublicReactiveVar, TNotificationState } from "./reactive-var-interfaces";


const vars = {
    public : {
        data : publicParamsInitial,
        handler : publicReactiveVar
    },
    private : {
        data : privateParamsInitial,
        handler : privateReactiveVar
    },
};


export type{
    IPrivateReactiveVar,
    IPublicReactiveVar,
    TNotificationState
};

export default vars;