import Organizer from "components/organizer";
import Exhibition from "components/exhibition";
import { useBreadCrumbs } from "components/use-hooks";
import { localizeByKey } from "components/service/localize";
import type { TOrganizer } from "components/organizer/helpers/organizer-types-helper";
const organizerBasePath = Organizer.Const.basePath;
const basePath = Exhibition.Const.basePath;


interface IExhibitionOverviewSubPageProps
{
  organizer: TOrganizer | undefined;
}


const ExhibitionOverviewSubPage: React.FC<IExhibitionOverviewSubPageProps> = ({ organizer }) =>
{
  const breadcrumbs = [
    {
      label: localizeByKey("BREADCRUMBS.Label_Organizers"),
      path: `${organizerBasePath}`,
    },
    {
      label: organizer?.title ?? localizeByKey("VARIABLE.Label_EMPTY"),
      path: `${organizerBasePath}/${organizer?.id}`,
    },
    {
      label: localizeByKey("BREADCRUMBS.Label_Exhibitions"),
      path: `${organizerBasePath}/${organizer?.id}${basePath}`,
    },
    {
      label: localizeByKey("BREADCRUMBS.Label_ExhibitionCreate"),
      path: `${organizerBasePath}/${organizer?.id}${basePath}/create`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  return (
    <div className="row-grid col-lg-2">
      <div className="col-left">
        <Exhibition.Forms.Edit organizer={organizer} />
      </div>
      <div className="col-right"></div>
    </div>
  );
};

export default ExhibitionOverviewSubPage;
