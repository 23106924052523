const roleBasePath = '/user-roles';

const roleColors = [
  { text: 'Aquamarine', value: '#1BFFEF' },
  { text: 'Blue', value: '#4F80FE' },
  { text: 'Green', value: '#45FF1B' },
  { text: 'Orange', value: '#FF8C14' },
  { text: 'Pink', value: '#FF1BFF' },
  { text: 'Violet', value: '#8759FF' },
  { text: 'Yellow', value: '#FFE41B' },
];


const RoleConst = {
  basePath: roleBasePath,
  colorHexList: roleColors
};


export default RoleConst;