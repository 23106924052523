import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Pages } from 'components/pages';

const VariablesRoute: React.FC = () =>
{
    return (
        <Routes>
            <Route path="/" element={<Pages.VariableGroup />} />
            <Route path="/page/:pageNum" element={<Pages.VariableGroup />} />
            <Route path="/page" element={<Pages.VariableGroup />} />
            <Route path="/:group/*" element={<Pages.VariableList />} />
            <Route path="/create" element={<Pages.VariableList />} />
        </Routes>
    );
};


export default VariablesRoute;