import TeamInfoField from "./team-info-field";
import TeamActionField from './team-action-field';



const TeamFields = {
    Info: TeamInfoField,
    Action: TeamActionField,
};

export default TeamFields;