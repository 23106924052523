import { gql } from "@apollo/client";

export const VARIABLE_LIST_UPDATE = gql`
  mutation VariableListUpdate($input: VariableListFieldsInput!) {
    variableListUpdate(input: $input) {
      label
      message
      variableList {
        id
        group_name
        lang
        slug
        value
        editable
        img_path
        translation(lang: "de") {
          id
          group_name
          lang
          slug
          value
          editable
          img_path
        }
      }
    }
  }
`;

export const VARIABLE_LIST_CREATE = gql`
  mutation VariableListCreate($input: VariableListFieldsInput!) {
    variableListCreate(input: $input) {
      label
      message
      variableList {
        id
        group_name
        lang
        slug
        value
        editable
        img_path
        translation(lang: "de") {
          id
          group_name
          lang
          slug
          value
          editable
          img_path
        }
      }
    }
  }
`;

export const VARIABLE_LIST_DELETE = gql`
 mutation VariableListDelete($group_name: String! $slug: String!){
    variableListDelete(group_name: $group_name slug: $slug){
        label
        message
    }
}
`;

export const VARIABLE_LIST_UPLOAD_FILE = gql`
  mutation FileUploadModel($input: FileUploadInput) {
    fileUploadModel(input: $input) {
      label
      message
    }
  }
`;

export const VARIABLE_GROUP_DELETE = gql`
  mutation VariableListGroupDelete($group_name: String!) {
    variableListGroupDelete(group_name: $group_name) {
      label
      message
    }
  }
`;
