import { gql } from "@apollo/client";

export const STAND_TYPE_UPDATE = gql`
  mutation StandTypeUpdate($input: StandTypeFieldsInput!) {
    standTypeUpdate(input: $input) {
      label
      message
      standType {
        id
        group_id
        title
        lang
        navision_number
        size_min
        size_max
        price
        complexity
        images {
          id
          extension
          description
          field_group
          order
          server_name
          sizes
          title
        }
        getImages {
          id
          sizes
        }
        meta_fields {
          id
          stand_type_id
          meta_key
          meta_value
          meta_group
        }
        orientations {
          id
          stand_type_group
          orientation_key
        }
        relatives {
          id
          group_id
          title
          lang
          navision_number
          size_min
          size_max
          price
          complexity
          images {
            id
            extension
            description
            field_group
            order
            server_name
            sizes
            title
          }
        }
      }
    }
  }
`;

export const STAND_TYPE_CREATE = gql`
  mutation StandTypeCreate($input: StandTypeFieldsInput!) {
    standTypeCreate(input: $input) {
      label
      message
      standType {
        id
        group_id
        title
        lang
        navision_number
        size_min
        size_max
        price
        complexity
        images {
          id
          extension
          description
          field_group
          order
          server_name
          sizes
          title
        }
        getImages {
          id
          sizes
        }
        meta_fields {
          id
          stand_type_id
          meta_key
          meta_value
          meta_group
        }
        orientations {
          id
          stand_type_group
          orientation_key
        }
        relatives {
          id
          group_id
          title
          lang
          navision_number
          size_min
          size_max
          price
          complexity
          images {
            id
            extension
            description
            field_group
            order
            server_name
            sizes
            title
          }
        }
      }
    }
  }
`;

export const STAND_TYPE_META_SET = gql`
  mutation StandTypeMetaSet($stand_type_id: ID!, $input: [MetaInput]) {
    standTypeMetaSet(stand_type_id: $stand_type_id, input: $input) {
      label
      message
      standType {
        id
        group_id
        title
        lang
        meta_fields {
          id
          stand_type_id
          meta_key
          meta_value
          meta_group
        }
      }
    }
  }
`;

export const STAND_TYPE_UPDATE_AND_META_SET = gql`
  mutation UpdateAndSetStandType(
    $updateInput: StandTypeFieldsInput!
    $stand_type_id: ID!
    $metaSetInput: [MetaInput]
    $group_id: ID!
    $syncOrganizer: [StandTypeOrganizerExhibitionTypeInput!]!
  ) {
    standTypeUpdate(input: $updateInput) {
      label
      message
    }

    standTypeMetaSet(stand_type_id: $stand_type_id, input: $metaSetInput) {
      label
      message
    }

    standTypeSyncOrganizerExhibitionType(
      group_id: $group_id
      input: $syncOrganizer
    ) {
      label
      message
    }
  }
`;

export const STAND_TYPE_DELETE = gql`
  mutation StandTypeDelete($group_id: ID!) {
    standTypeDelete(group_id: $group_id) {
      label
      message
    }
  }
`;

export const STAND_TYPE_EQUIPMENT_CREATE = gql`
  mutation standTypeEquipmentCreate($input: StandTypeEquipmentFieldsInput!) {
    standTypeEquipmentCreate(input: $input) {
      label
      message
      standTypeEquipment {
        id
        stand_type_group
        equipment_group
        organizer_id
        rule_type
        size_min
        size_max
        amount
      }
    }
  }
`;

export const STAND_TYPE_EQUIPMENT_UPDATE = gql`
  mutation standTypeEquipmentUpdate($input: StandTypeEquipmentFieldsInput!) {
    standTypeEquipmentUpdate(input: $input) {
      label
      message
      standTypeEquipment {
        id
        stand_type_group
        equipment_group
        organizer_id
        rule_type
        size_min
        size_max
        amount
      }
    }
  }
`;

export const STAND_TYPE_EQUIPMENT_DELETE = gql`
  mutation StandTypeEquipmentDelete($id: ID!) {
    standTypeEquipmentDelete(id: $id) {
      label
      message
    }
  }
`;

export const STAND_TYPE_FLOORING_REMOVE = gql`
  mutation StandTypeFlooringRemove($flooring_id: ID!, $stand_type_group: ID!) {
    standTypeFlooringRemove(
      flooring_id: $flooring_id
      stand_type_group: $stand_type_group
    ) {
      label
      message
    }
  }
`;

export const STAND_TYPE_SYNC_FLOORINGS = gql`
  mutation StandTypeSyncFlooring($stand_type_group: ID!, $flooring_id: [ID]!) {
    standTypeSyncFlooring(
      stand_type_group: $stand_type_group
      flooring_id: $flooring_id
    ) {
      label
      message
    }
  }
`;

export const STAND_TYPE_FLOORING_UPDATE = gql`
  mutation StandTypeFlooringUpdate($stand_type_group: ID!, $flooring_id: ID!) {
    standTypeFlooringUpdate(
      stand_type_group: $stand_type_group
      flooring_id: $flooring_id
    ) {
      label
      message
    }
  }
`;

export const STAND_TYPE_FILE_CREATE = gql`
  mutation StandTypeFileCreate(
    $stand_type_group: ID!
    $title: String
    $description: String
    $file: Upload!
  ) {
    standTypeFileCreate(
      stand_type_group: $stand_type_group
      title: $title
      description: $description
      file: $file
    ) {
      label
      message
      standTypeFiles {
        id
        stand_type_group
        server_name
        original_name
        extension
        size
        title
        description
        path
      }
    }
  }
`;

export const STAND_TYPE_FILE_UPDATE = gql`
  mutation StandTypeFileUpdate($id: ID!, $title: String, $description: String) {
    standTypeFileUpdate(id: $id, title: $title, description: $description) {
      label
      message
      standTypeFiles {
        id
        stand_type_group
        server_name
        original_name
        extension
        size
        title
        description
        path
      }
    }
  }
`;

export const STAND_TYPE_FILE_DELETE = gql`
  mutation StandTypeFileDelete($id: ID!) {
    standTypeFileDelete(id: $id) {
      label
      message
      standTypeFiles {
        id
        stand_type_group
        server_name
        original_name
        extension
        size
        title
        description
        path
      }
    }
  }
`;

export const STAND_TYPE_NOTE_CREATE = gql`
  mutation StandTypeNoteCreate($input: StandTypeNoteFieldsInput!) {
    standTypeNoteCreate(input: $input) {
      label
      message
    }
  }
`;

export const STAND_TYPE_NOTE_UPDATE = gql`
  mutation StandTypeNotetUpdate($input: StandTypeNoteFieldsInput!) {
    standTypeNotetUpdate(input: $input) {
      label
      message
    }
  }
`;

export const STAND_TYPE_NOTE_DELETE = gql`
  mutation StandTypeNoteDelete($id: ID!) {
    standTypeNoteDelete(id: $id) {
      label
      message
    }
  }
`;

export const STAND_TYPE_NOTE_ATTACHMENT_DELETE = gql`
  mutation AttachmentDelete($id: ID!) {
    attachmentDelete(id: $id) {
      label
      message
    }
  }
`;
