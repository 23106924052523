import React from "react";
import { Avatar } from "antd";
import { FileImageOutlined } from "@ant-design/icons";
import VariableForms from "../forms";
import { ModalStandard, Localize } from "components/service";
import { TVariableList } from "../helpers/variable-types-helper";

interface VariableSlugFieldProps
{
    variableList: TVariableList;
    variables: Record<string, any>;
}

const VariableSlugField: React.FC<VariableSlugFieldProps> = ({
    variableList,
    variables,
}) =>
{
    return (
        <div className="d-flex align-items-center">
            <div style={{ minWidth: "45px" }}>
                <Avatar
                    shape="square"
                    size="small"
                    icon={
                        variableList?.img_path ? (
                            <img src={variableList?.img_path} alt="avatar" />
                        ) : (
                            <FileImageOutlined />
                        )
                    }
                />
            </div>

            <ModalStandard
                width={500}
                extraClass={"modal-form"}
                modalButton={
                    <strong className="title" style={{ cursor: "pointer" }}>
                        {variableList?.slug}
                    </strong>
                }
                modalTitle={
                    <strong className="modal-title">
                        <span className="text">
                            <Localize>FORMS.Title_EditKey</Localize>
                        </span>
                    </strong>
                }
            >
                <VariableForms.Edit
                    action={() => { }}
                    variableList={variableList}
                    variables={variables}
                />
            </ModalStandard>
        </div>
    );
};

export default VariableSlugField;
