const equipmentBasePath = "/equipment";

const equipmentColors = {
  equipment_color_black: "#000000",
  equipment_color_blue: "#4471FF",
  equipment_color_red: "#FF1B1B",
  equipment_color_green: "#45FF1B",
  equipment_color_white: "#FFFFFF",
  equipment_color_steal: "#C0C0C0",
  equipment_color_gray: "#D3D3D3",
  equipment_color_glass: "#F0F8FF",
  equipment_color_aluminium: "#808080",
  equipment_color_oak: "#F5F5DC",
  equipment_color_beech: "#F5DEB3",
  equipment_color_birch: "#F5F5F5",
};

const EquipmentConst = {
  basePath: equipmentBasePath,
  colorHexList: equipmentColors,
};

export default EquipmentConst;
