import React from "react";
import { Dropdown } from "antd";
import { EllipsisOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import { variableUseMutation } from "../hooks";
import { EntityRemoveButton, Localize, UploadFile } from "components/service";
import { localizeByKey } from "components/service/localize";
import Icons from "components/icons";

interface IVariableActionFieldProps
{
    variableList: {
        id: string;
        slug: string;
        group_name: string;
    };
    variables: {
        [ key: string ]: any;
    };
}

const VariableActionField: React.FC<IVariableActionFieldProps> = ({ variableList: { id, slug, group_name }, variables }) =>
{
    const { _setVariableUploadFile, loadingMutationVariableUploadFile } =
        variableUseMutation.uploadFile({ variables });

    const { _setMutationVariableDelete, loadingMutationVariableDelete } =
        variableUseMutation.delete(id);

    const items = [
        {
            key: "1",
            label: (
                <UploadFile
                    setMutation={_setVariableUploadFile}
                    loading={loadingMutationVariableUploadFile}
                    accept="image/*"
                    varSetMutation={{
                        input: {
                            model: "VariableList",
                            model_id: id,
                            model_field: "img_path",
                        },
                    }}
                    uploadBtn={
                        <div className="">
                            <span
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {loadingMutationVariableUploadFile ? (
                                    <Loading3QuartersOutlined spin />
                                ) : (
                                    <Icons.Upload />
                                )}{" "}
                                <Localize>MENUS_DROP.Label_UploadIcon</Localize>
                            </span>
                        </div>
                    }
                />
            ),
        },
        {
            key: "2",
            label: (
                <EntityRemoveButton
                    modalButton={
                        <span style={{ display: "flex", alignItems: "center" }}>
                            <Icons.Delete />{" "}
                            <Localize>MENUS_DROP.Label_Delete</Localize>
                        </span>
                    }
                    nameEntity={
                        localizeByKey("ENTITY.Modal_Title_Key")
                    }
                    dataNameEntity={slug}
                    loading={loadingMutationVariableDelete}
                    deleteMutation={_setMutationVariableDelete}
                    variables={{
                        group_name,
                        slug,
                    }}
                />
            ),
        },
    ];

    return (
        <Dropdown
            // trigger={['click']}
            placement="bottomRight"
            menu={{ items }}
            overlayClassName="drop-more-menu"
            arrow
        >
            <EllipsisOutlined className="btn-more" />
        </Dropdown>
    );
};

export default VariableActionField;