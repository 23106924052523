import React, { useState, useEffect } from "react";
import { Input, Button, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { gqlBuilderWhere, helperFunc } from "common/utils";
import EquipmentForms from "../../forms";
import Icons from "components/icons";
import { DrawerStandart, Localize } from "components/service";
import { IFilter } from 'components/graphic/helpers/graphic-types-helper';

const { Search } = Input;


interface IFilterList
{
  [ key: string ]: IFilter;
}

interface IGraphicTableFilterProps
{
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setFilters: React.Dispatch<React.SetStateAction<IFilter | undefined>>;
  setResetSorting: React.Dispatch<React.SetStateAction<{ column: string; order: "ASC" | "DESC" }[] | undefined>>;
  graphicFilters: { [ key: string ]: IFilter } | undefined;
  filters: IFilter | undefined;
  objOrderBy: { column: string; order: string }[];
}


const GraphicTableFilter: React.FC<IGraphicTableFilterProps> = ({
  setFilters = () => { },
  setResetSorting = () => { },
  graphicFilters,
  filters,
  setSearchText,
  objOrderBy,
}) =>
{
  const [ form ] = Form.useForm();

  const [ filterList, setFilterList ] = useState<IFilterList | undefined>(undefined);
  const [ count, setCount ] = useState<any>({});

  const filterCount = helperFunc.countFilterList(count);

  /*eslint-disable */
  useEffect(() =>
  {
    if (filters) {
      localStorage.setItem(
        "graphicFilters",
        JSON.stringify({ ...graphicFilters, ...filterList })
      );
    } else form.resetFields([ "SUPPLIER_ID", "ORGANIZER_ID" ]);
  }, [ filters ]);

  useEffect(() =>
  {
    if (graphicFilters) {
      setFilters(gqlBuilderWhere(graphicFilters));
    }

    setCount({
      ...graphicFilters,
    });
  }, []);
  /*eslint-enable */

  return (
    <>
      <Search
        className="filter-search"
        prefix={<SearchOutlined />}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <Form
        key="filters-form"
        className="clear-css-prop table-filter-form"
        layout="vertical"
        form={form}
      >
        <DrawerStandart
          extraClass={"modal-form filters"}
          modalButton={
            <Button icon={<Icons.Filter />}>
              <span className="ellipsis">
                <Localize>FORMS.Button_Text_Filters</Localize>
              </span>
              {!!filterCount && (
                <span className="filter-count">{filterCount}</span>
              )}
            </Button>
          }
        >
          <strong className="modal-title">
            <span className="text">
              <Localize>FORMS.Title_Text_Filters</Localize>
            </span>
          </strong>

          <EquipmentForms.Filter
            setCount={setCount}
            setFilters={(filters) => setFilters(filters as IFilter)}
            setFilterList={setFilterList}
            filterList={filterList}
            graphicFilters={graphicFilters}
            gqlBuilderWhere={gqlBuilderWhere}
            form={form}
            action={() => { }}
          />
        </DrawerStandart>

        {filters && (
          <Button
            className="underline"
            onClick={() =>
            {
              setCount({});
              setFilterList({});
              setFilters(gqlBuilderWhere({}));
              setResetSorting(objOrderBy as { column: string; order: "ASC" | "DESC" }[]);
              localStorage.removeItem("graphicFilters");
              localStorage.removeItem("graphicsSorting");
            }}
          >
            <span>
              <Localize>FORMS.Button_Text_Reset</Localize>
            </span>
          </Button>
        )}
      </Form>
    </>
  );
};

export default GraphicTableFilter;
