import { gql } from "@apollo/client";

export const FLOORING_UPDATE = gql`
  mutation FlooringUpdate($input: FlooringFieldsInput!) {
    flooringUpdate(input: $input) {
      label
      message
      flooring {
        id
        title
        article
        type
        color
        supplier_id
        images {
          id
          field_group
          title
          description
          sizes
          extension
          user {
            id
            name
            surname
          }
        }
      }
    }
  }
`;

export const FLOORING_CREATE = gql`
  mutation FlooringCreate($input: FlooringFieldsInput!) {
    flooringCreate(input: $input) {
      label
      message
      flooring {
        id
        title
        article
        type
        color
        supplier_id
        images {
          id
          field_group
          title
          description
          sizes
          extension
          user {
            id
            name
            surname
          }
        }
      }
    }
  }
`;

export const FLOORING_DELETE = gql`
  mutation FlooringDelete($id: ID!) {
    flooringDelete(id: $id) {
      label
      message
    }
  }
`;
