import React, { useState } from "react";
import { useMutation, ApolloError } from "@apollo/client";
import { Upload, Modal, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { IMAGE_CREATE, IMAGE_DELETE } from "graphql/mutation/image-gql";
import { Localize } from "components/service";
import
{
  successNotification,
  errorNotification,
} from "components/request-result";
import Icons from "components/icons";
import { UploadFileStatus } from "antd/lib/upload/interface";

import "./upload-media.scss";

interface IUploadMediaProps
{
  defaultMedia: Array<{ id: string; sizes: { original: string; "200x200": string } }>;
  identifier?: string;
  modelId: string;
  model: string;
  query: any;
  disabled?: boolean;
  uploadImgSize?: string;
}

const UploadMedia: React.FC<IUploadMediaProps> = ({
  defaultMedia,
  identifier = "id",
  modelId,
  model,
  query,
  disabled = false,
  uploadImgSize = "MODAL.Button_Text_ImgSize",
}) =>
{
  const refetchQueries = [
    {
      query,
      variables: {
        [ identifier ]: modelId,
      },
    },
  ];
  const [ imageCreate, { loading } ] = useMutation(IMAGE_CREATE, {
    refetchQueries,
  });
  const [ imageDelete ] = useMutation(IMAGE_DELETE, { refetchQueries });

  const [ filesLoading, setFilesLoading ] = useState(0);
  const [ previewImage, setPreviewImage ] = useState<string | null>(null);
  const [ previewVisible, setPreviewVisible ] = useState(false);

  const handlePreview = (file: any) =>
  {
    setPreviewImage(file.url);
    setPreviewVisible(true);
  };

  const handleCancel = () =>
  {
    setPreviewVisible(false);
  };

  const handleRemove = async (file: any) =>
  {
    if (file.status === "done") {
      try {
        const { uid } = file;

        message.loading("Deleting image...", 0);

        await imageDelete({
          variables: {
            id: uid,
          },
          update: (cache, { data }) =>
          {
            const {
              imageDelete: { label, message },
            } = data;

            setTimeout(() =>
            {
              successNotification({
                title: label,
                description: message,
              });
            }, 2500);
          },
        });
      } catch (error) {
        errorNotification(error as ApolloError);
      } finally {
        setTimeout(() =>
        {
          message.destroy();
        }, 2000);
      }
    }
  };

  const fileList = defaultMedia?.map(({ id, sizes }) =>
  {
    return {
      uid: id,
      name: id,
      url: sizes?.original,
      thumbUrl: sizes[ "200x200" ],
      status: "done" as UploadFileStatus,
    };
  });

  const formUploadProps = {
    accept: "image/*",
    beforeUpload: (file: any) =>
    {
      setFilesLoading((prevState) => prevState + 1);

      imageCreate({
        variables: {
          input: {
            model_id: modelId,
            model,
            file,
          },
        },
        update(cache, { data })
        {
          const { imageCreate } = data;
          const { label, message } = imageCreate;

          setFilesLoading((prevState) => prevState - 1);

          successNotification({
            title: label,
            description: message,
          });
        },
      }).catch((error) =>
      {
        setFilesLoading((prevState) => prevState - 1);
        errorNotification(error as ApolloError);
      });

      return false;
    },
    onRemove: handleRemove,
    fileList: fileList,
    listType: "picture-card" as const,
    multiple: true,
    onPreview: handlePreview,
    showUploadList: {
      showPreviewIcon: true,
      showDownloadIcon: false,
      previewIcon: <Icons.Zoom />,
      removeIcon: <Icons.Delete />,
    },
  };

  return (
    <div className="media-holder">
      <span className="label-text">
        <Localize>FORMS.Input_Label_Images</Localize>
      </span>

      <Upload {...formUploadProps} disabled={disabled}>
        <div>
          {loading || filesLoading > 0 ? (
            <LoadingOutlined />
          ) : (
            <>
              <div className="site-badge">
                <Icons.Plus />
              </div>
              <div className="site-icon">
                <Localize>GLOBAL.Text_AddImage</Localize>
                <br />
                <Localize>{uploadImgSize}</Localize>
              </div>
            </>
          )}
        </div>
      </Upload>

      <Modal
        className="modal-image"
        width={800}
        open={previewVisible}
        closeIcon={<Icons.Close />}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="Preview" style={{ width: "100%" }} src={previewImage || undefined} />
      </Modal>
    </div>
  );
};

export default UploadMedia;