import Supplier from "components/supplier";
import { useBreadCrumbs } from "components/use-hooks";
import { localizeByKey } from "components/service/localize";
import type { TSupplier } from "components/supplier/helpers/supplier-types-helper";

const supplierBasePath = Supplier.Const.basePath;

interface ISupplierOverviewSubPageProps
{
    supplier: TSupplier | undefined;
}

const SupplierOverviewSubPage: React.FC<ISupplierOverviewSubPageProps> = ({ supplier }) =>
{
    const breadcrumbs = [
        {
            label: localizeByKey("BREADCRUMBS.Label_Suppliers"),
            path: `${supplierBasePath}`
        },
        {
            label: (supplier && `${supplier?.title}`) || localizeByKey("BREADCRUMBS.Label_Create"),
            path: (supplier && `${supplierBasePath}/${supplier?.id}`) || `${supplierBasePath}/create`
        }
    ];

    useBreadCrumbs(breadcrumbs);

    return (
        <div className="row-grid col-lg-2">

            <div className="col-left">
                <Supplier.Forms.Edit supplier={supplier} />
            </div>
            <div className="col-right"></div>
        </div>
    );
};

export default SupplierOverviewSubPage;