import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import
{
  GRAPHIC_SUPPLIER_UPDATE,
  GRAPHIC_SUPPLIER_CREATE,
} from "graphql/mutation/graphic-gql";
import GraphicConst from "../graphic-const";
import
{
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationGraphicCreateUpdate = (id: string | undefined, redirect: boolean = true) =>
{
  const navigate = useNavigate();

  const GRAPHIC_SUPPLIER_CREATE_UPDATE = id
    ? GRAPHIC_SUPPLIER_UPDATE
    : GRAPHIC_SUPPLIER_CREATE;
  const graphicCreateUpdate = id ? "graphicSupplierUpdate" : "graphicSupplierCreate";

  const [ _setGraphicCreateUpdate, { loading } ] = useMutation(
    GRAPHIC_SUPPLIER_CREATE_UPDATE,
    {
      update(cache, { data })
      {
        const {
          [ graphicCreateUpdate ]: { label, message },
        } = data;

        redirect && navigate(GraphicConst.basePath);

        successNotification({
          title: label,
          description: message,
        });
      },
      onError(error)
      {
        errorNotification(error);
      },
    }
  );

  return {
    _setGraphicCreateUpdate,
    loadingMutationGraphicCreateUpdate: loading,
  };
};

export default useMutationGraphicCreateUpdate;
