import useMutationStandCreateUpdate from "./stand-use-mutation-create-update";
import useMutationStandDelete from "./stand-use-mutation-delete";
import useMutationStandEquipmentCreateUpdate from "./stand-equipment-use-mutation-create-update";
import useMutationStandEquipmentDelete from "./stand-equipment-use-mutation-delete";
import useMutationStandFlooringRemove from "./stand-flooring-use-mutation-remove";
import useMutationStandTypeFlooringUpdate from "./stand-flooring-use-mutation-update";
import useMutationStandFileCreateUpdate from "./stand-file-use-mutation-create-update";
import useMutationStandFileDelete from "./stand-file-use-mutation-delete";
import useMutationStandNoteCreateUpdate from "./stand-note-use-mutation-create-update";
import useMutationStandNoteDelete from "./stand-note-use-mutation-delete";
import useMutationStandNoteAttachmentNoteAttachmentDelete from "./stand-note-attachment-use-mutation-delete";

export const standUseMutation = {
  createUpdate: useMutationStandCreateUpdate,
  delete: useMutationStandDelete,
  equipmentCreateUpdate: useMutationStandEquipmentCreateUpdate,
  equipmentDelete: useMutationStandEquipmentDelete,
  flooringRemove: useMutationStandFlooringRemove,
  flooringUpdate: useMutationStandTypeFlooringUpdate,
  fileCreateUpdate: useMutationStandFileCreateUpdate,
  fileDelete: useMutationStandFileDelete,
  noteCreateUpdate: useMutationStandNoteCreateUpdate,
  noteDelete: useMutationStandNoteDelete,
  noteAttachmentDelete: useMutationStandNoteAttachmentNoteAttachmentDelete,
};
