import React, { useState } from "react";
import { Button } from "antd";
import { useApolloClient } from "@apollo/client";

import { EQUIPMENT_SYNC_SUBSTITUDE } from "graphql/mutation/equipment-gql";
import { GET_EQUIPMENT_CURSOR } from "graphql/query/equipment-gql";
import { GET_MODELS_IDS } from "graphql/query/model-gql";
import { GET_ME } from "graphql/query/user-gql";

import { useBreadCrumbs } from "components/use-hooks";
import { Tables, Forms } from "components/layout";
import { Localize, DrawerStandart } from "components/service";
import { localizeByKey } from "components/service/localize";
import Icons from "components/icons";
import Equipment from "components/equipment";
import { gqlBuilderWhere } from "common/utils";
import { TEquipment, IFilter } from 'components/equipment/helpers/equipment-types-helper';

const equipmentBasePath = Equipment.Const.basePath;

interface IEquipmentSubstitudeSubPageProps
{
  equipment?: TEquipment | undefined;
  lang: string;
}

const EquipmentSubstitudeSubPage: React.FC<IEquipmentSubstitudeSubPageProps> = ({ equipment, lang }) =>
{
  const basePath = Equipment.Const.basePath;
  const tableHelper = Equipment.Helpers.TableMain;

  const breadcrumbs = [
    {
      label: localizeByKey("BREADCRUMBS.Label_Equipment"),
      path: `${equipmentBasePath}`,
    },
    {
      label: equipment?.title ?? localizeByKey("VARIABLE.Label_EMPTY"),
      path: `${basePath}/edit/${equipment?.group_id}/${lang}`,
    },
    {
      label: localizeByKey("BREADCRUMBS.Label_Substitude"),
      path: `${equipmentBasePath}/edit/${equipment?.group_id}/${lang}/substitude`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const client = useApolloClient();
  const cachedMe = client.readQuery({
    query: GET_ME,
  });

  const defaultLang = cachedMe.me.lang_api;

  const disabledColor = cachedMe.me.roles.filter(
    (role: { slug: string }) => role.slug === "role_library_manager"
  ).length ? true : false;

  const objOrderBy = [ { column: "ID", order: "DESC" } ];

  const [ searchText, setSearchText ] = useState("");
  const [ viewMode, setViewMode ] = useState<"table" | "grid">("grid");
  const [ filters, setFilters ] = useState<IFilter | undefined>();
  const [ resetSorting, setResetSorting ] = useState<{ column: string; order: "ASC" | "DESC" }[]>();

  const equipmentFilters = JSON.parse(
    localStorage.getItem(`equipmentFilters_groupID-${equipment?.group_id || 0}`) || "{}"
  );
  const equipmentsSorting = JSON.parse(
    localStorage.getItem(`equipmentsSorting_groupID-${equipment?.group_id || 0}`) || "[]"
  );

  const objectFilters = filters || gqlBuilderWhere(equipmentFilters);

  return (
    <Tables.Cursor
      model="equipmentsCursor"
      query={GET_EQUIPMENT_CURSOR}
      varSet={{ perPage: 50, group_id: equipment?.group_id }}
      extraObj={disabledColor}
      routeUrl={`${basePath}`}
      language={defaultLang}
      searchText={searchText}
      tableHelper={tableHelper}
      viewMode={viewMode}
      viewGrid={Equipment.Helpers.GridMain}
      currentObj={equipment}
      objectWhere={{
        ...objectFilters,
        AND: [
          {
            column: "LANG",
            value: defaultLang,
          },
        ],
      }}
      objOrderBy={equipmentsSorting || objOrderBy}
      sortingName={`equipmentsSorting_groupID-${equipment?.group_id || 0}`}
      resetSorting={resetSorting}
    >
      <div className="table-action-bar">
        <div className="col">
          <Equipment.Filters.Table
            {...{
              equipment,
              setSearchText,
              filters,
              setFilters,
              equipmentFilters,
              setResetSorting,
              objOrderBy,
            }}
          />
        </div>

        <div className="col">
          <div className="switch-btn-holder">
            {viewMode === "table" ? (
              <Button
                onClick={() => setViewMode("grid")}
                icon={<Icons.Grid />}
              />
            ) : (
              <Button
                onClick={() => setViewMode("table")}
                icon={<Icons.Table />}
              />
            )}
          </div>

          <DrawerStandart
            extraClass={"modal-form filters"}
            modalButton={
              <Button type="primary" icon={<Icons.Plus />}>
                <span className="ellipsis">
                  <Localize>GLOBAL.Button_Text_AddNew</Localize>
                </span>
              </Button>
            }
          >
            <strong className="modal-title">
              <span className="text">
                <Localize>FORMS.Title_AddSubstitude</Localize>
              </span>
            </strong>
            <Forms.ModelAddToForm
              name="substitude_group"
              action={() => { }}
              // showModal={true}
              modelCursorLabel={[ "title", "navision_number" ]}
              filteredNodes={equipment?.group_id}
              propsObj={{
                mutation: EQUIPMENT_SYNC_SUBSTITUDE,
                queries: {
                  query: GET_EQUIPMENT_CURSOR,
                  queryInitalVal: GET_MODELS_IDS,
                },
                queryNames: {
                  queryName: "equipmentsCursor",
                  queryInitalValName: "getRelatedModelIds",
                },
                queryVars: {
                  queryVar: {
                    where: {
                      column: "LANG",
                      value: defaultLang,
                    },
                    whereExtra: {},
                  },
                  queryInitalValVar: {
                    modelFrom: "Equipment",
                    modelTo: "EquipmentSubstitude",
                    rel_id: equipment?.group_id,
                  },
                },
                mutationName: "equipmentSyncSubstitude",
                modelID: { equipment_group: equipment?.group_id },
              }}
            />
          </DrawerStandart>
        </div>
      </div>
    </Tables.Cursor>
  );
};

export default EquipmentSubstitudeSubPage;