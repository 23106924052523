import GraphicFields from "../fields";
import { Localize } from "components/service";
import { TGraphicSupplier } from "../helpers/graphic-types-helper";


const graphicsColumns = [
  {
    title: <Localize>TABLES.Column_Title_Title</Localize>,
    dataIndex: "title",
    columnIndex: "TITLE",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Organizer</Localize>,
    dataIndex: "organizer",
    columnIndex: "ORGANIZER_ID",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_PrintingCompany</Localize>,
    dataIndex: "supplier",
    columnIndex: "SUPPLIER_ID",
    sorter: true,
  },

  {
    // title: '',
    dataIndex: "action",
    align: "right",
    width: "5%",
  },
];

const graphicsData = (graphics: TGraphicSupplier[]) =>
{
  if (!graphics) {
    return [];
  }

  return graphics.map((graphic) =>
  {
    return {
      key: graphic.id,
      title: <GraphicFields.Info graphic={graphic} />,
      organizer: <GraphicFields.Organizer graphic={graphic} />,
      supplier: <GraphicFields.Supplier graphic={graphic} />,
      action: <GraphicFields.Action graphic={graphic} />,
    };
  });
};

const GraphicsTableHelper = {
  columns: graphicsColumns,
  data: graphicsData,
};

export default GraphicsTableHelper;
