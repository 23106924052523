import React from "react";
import { Link } from "react-router-dom";
// import Role from "components/role";
import { Localize } from "components/service";
import { TRole } from 'components/role/helpers/role-types-helper';

type TUserRoleFieldProps = {
  role: TRole[];
};

const UserRoleField: React.FC<TUserRoleFieldProps> = ({ role }) =>
{
  return role.length > 0 ? (
    <>
      {role.map((roleItem, index) => (
        <div key={index} style={{ marginBottom: "3px" }}>
          <Link
            className="table-link-underline"
            style={{ color: roleItem?.colorHex }}
            to="#"
          >
            <strong>
              <Localize>{roleItem?.title}</Localize>
            </strong>
          </Link>
          <br />
        </div>
      ))}
    </>
  ) : (
    <strong>&mdash;</strong>
  );
};

export default UserRoleField;
