import React from "react";
import { Link } from "react-router-dom";
import GraphicConst from "../graphic-const";
import { TGraphicSupplier } from "../helpers/graphic-types-helper";


interface IGraphicInfoFieldProps
{
  graphic: TGraphicSupplier;
}


const GraphicInfoField: React.FC<IGraphicInfoFieldProps> = ({ graphic }) =>
{
  return (
    <Link
      className="table-link-underline"
      to={`${GraphicConst.basePath}/${graphic?.id}`}
    >
      {graphic?.title}
    </Link>
  );
};

export default GraphicInfoField;
