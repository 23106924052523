import React from "react";

import { Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { graphicUseMutation } from "../hooks";
import GraphicRuleForms from "../forms";
import
{
  EntityRemoveButton,
  Localize,
  ModalStandard,
} from "components/service";
import { localizeByKey } from "components/service/localize";
import { TGraphicSupplierRules } from "components/graphic/helpers/graphic-types-helper";
import Icons from "components/icons";


interface IGraphicRuleActionFieldProps
{
  graphic?: TGraphicSupplierRules | undefined;
  graphSupplierID: string | undefined;
  variables: Record<string, any>;
  action: () => void;
}


const GraphicRuleActionField: React.FC<IGraphicRuleActionFieldProps> = ({ graphic, graphSupplierID, variables }) =>
{
  const { _setMutationGraphicRuleDelete, loadingMutationGraphicRuleDelete } =
    graphicUseMutation.ruleDelete(graphic?.id, variables);

  const items = [
    {
      key: "1",
      label: (
        <ModalStandard
          width={550}
          extraClass={"modal-form"}
          modalButton={
            <span style={{ display: "flex", alignItems: "center", width: 100 }}>
              <Icons.Edit /> <Localize>MENUS_DROP.Label_Edit</Localize>
            </span>
          }
          modalTitle={
            <strong className="modal-title">
              <span className="text">
                <Localize>FORMS.Title_EditGraphicRule</Localize>
              </span>
            </strong>}
        >

          <GraphicRuleForms.RuleEdit
            graphic={graphic}
            variables={variables}
            graphSupplierID={graphSupplierID}
            action={() => { }}
          />
        </ModalStandard>
      ),
    },
    {
      key: "2",
      label: (
        <EntityRemoveButton
          modalButton={
            <span style={{ display: "flex", alignItems: "center", width: 100 }}>
              <Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize>
            </span>
          }
          nameEntity={
            localizeByKey("ENTITY.Modal_Title_GraphicRule")
          }
          dataNameEntity={graphic?.title}
          loading={loadingMutationGraphicRuleDelete}
          deleteMutation={_setMutationGraphicRuleDelete}
          variables={{
            id: graphic?.id,
          }}
        />
      ),
    },
  ];

  return (
    <Dropdown
      // trigger={['click']}
      placement="bottomRight"
      menu={{ items }}
      overlayClassName="drop-more-menu"
      arrow
    >
      <EllipsisOutlined className="btn-more" />
    </Dropdown>
  );
};

export default GraphicRuleActionField;
