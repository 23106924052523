import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { useLogout } from "components/use-hooks";
import { ErrorBlock, ErrorResult, Loader } from "components/request-result";

interface ISuspenseProps
{
    state: any;
    type?: 'block' | 'item';
    loaderType?: 'block' | 'item';
    tipText?: string;
    children: ReactElement | ReactElement[];
    blockClass?: string;
}

const Suspense = ({
    state,
    type = "block",
    loaderType = "block",
    tipText = "",
    children,
    blockClass,
}: ISuspenseProps): ReactElement | null =>
{
    const { loading, error = false, data = false } = state;
    const navigate = useNavigate();
    const { clearStore } = useLogout();

    if (loading) {
        return (
            <Loader
                type={loaderType}
                tipText={tipText}
                blockClass={blockClass}
            />
        );
    }

    if (error) {
        if (type === "block") {
            return <ErrorBlock action={() => clearStore({ route: "/" })} />;
        } else {
            return (
                <ErrorResult
                    error={error}
                    action={() => navigate(-1)}
                    goToText="Go back"
                />
            );
        }
    }

    if (data === undefined) {
        return null;
    }

    if (React.isValidElement(children)) {
        return React.cloneElement(children, { ...data });
    } else if (Array.isArray(children)) {
        return <>{children.map((item: ReactElement) => React.cloneElement(item, { ...data }))}</>;
    }

    return null;
};

export default Suspense;