import { useState } from "react";
import { Button, Input } from "antd";
import { GET_FLOORING_CURSOR } from "graphql/query/flooring-gql";
import { GET_MODELS_IDS } from "graphql/query/model-gql";
import { STAND_TYPE_SYNC_FLOORINGS } from "graphql/mutation/stand-gql";
import { useBreadCrumbs } from "components/use-hooks";
import { DrawerStandart, Localize } from "components/service";
import { localizeByKey } from "components/service/localize";
import { Tables, Forms } from "components/layout";
import Stand from "components/stand";
import Flooring from "components/flooring";
import type { TStand } from "components/stand/helpers/stand-types-helper";
import Icons from "components/icons";

const { Search } = Input;


interface IStandEquipmentsSubPageProps
{
  stand: TStand | undefined;
  lang: string;
}


const StandFlooringsSubPage: React.FC<IStandEquipmentsSubPageProps> = ({ stand, lang }) =>
{
  const basePath = Stand.Const.basePath;
  const tableHelper = Flooring.Helpers.TableMain;

  const breadcrumbs = [
    {
      label: localizeByKey("BREADCRUMBS.Label_StandTypes"),
      path: `${basePath}`,
    },
    {
      label: stand?.title ?? localizeByKey("VARIABLE.Label_EMPTY"),
      path: `${basePath}/edit/${stand?.group_id}/${lang}`,
    },
    {
      label: localizeByKey("BREADCRUMBS.Label_Floorings"),
      path: `${basePath}/edit/${stand?.group_id}/${lang}/floorings`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const [ searchText, setSearchText ] = useState("");
  const [ viewMode, setViewMode ] = useState<"table" | "grid">("grid");

  const whereStandTypeFlooring = {
    column: "STAND_TYPE_GROUP",
    value: parseInt(stand?.group_id ?? "0"),
  };

  return (
    <Tables.Cursor
      model="flooringsCursor"
      query={GET_FLOORING_CURSOR}
      tableHelper={tableHelper}
      currentObj={stand}
      searchText={searchText}
      viewMode={viewMode}
      viewGrid={Flooring.Helpers.GridMain}
      varSet={{
        stand_type_group: parseInt(stand?.group_id ?? "0"),
        whereStandTypeFlooring,
      }}
    >
      <div className="table-action-bar">
        <div className="col">
          <Search
            className="filter-search"
            prefix={<Icons.Search />}
            onChange={(e) => setSearchText(e.target.value)}
          />

          <Button disabled icon={<Icons.Filter />}>
            <span className="ellipsis">
              <Localize>FORMS.Button_Text_Filters</Localize>
            </span>
          </Button>
        </div>

        <div className="col">
          <div className="switch-btn-holder">
            {viewMode === "table" ? (
              <Button
                onClick={() => setViewMode("grid")}
                icon={<Icons.Grid />}
              />
            ) : (
              <Button
                onClick={() => setViewMode("table")}
                icon={<Icons.Table />}
              />
            )}
          </div>

          <DrawerStandart
            extraClass={"modal-form filters"}
            modalButton={
              <Button type="primary" icon={<Icons.Plus />}>
                <span className="ellipsis">
                  <Localize>GLOBAL.Button_Text_AddNew</Localize>
                </span>
              </Button>
            }
          >
            <strong className="modal-title">
              <span className="text">
                <Localize>FORMS.Title_Text_AddFlooringStand</Localize>
              </span>
            </strong>

            <Forms.ModelAddToForm
              modelCursorValue="id"
              modelCursorLabel={[ "title", "article" ]}
              modelImagePath="images[0]?.sizes['200x200']"
              name="flooring_id"
              action={() => { }}
              propsObj={{
                mutation: STAND_TYPE_SYNC_FLOORINGS,
                queries: {
                  query: GET_FLOORING_CURSOR,
                  queryInitalVal: GET_MODELS_IDS,
                },
                queryNames: {
                  queryName: "flooringsCursor",
                  queryInitalValName: "getRelatedModelIds",
                },
                queryVars: {
                  queryVar: {
                    where: {},
                    whereExtra: {},
                  },
                  queryInitalValVar: {
                    modelFrom: "StandType",
                    modelTo: "Flooring",
                    rel_id: stand?.group_id,
                  },
                },
                mutationName: "standTypeSyncFlooring",
                modelID: { stand_type_group: stand?.group_id },
              }}
            />
          </DrawerStandart>
        </div>
      </div>
    </Tables.Cursor>
  );
};

export default StandFlooringsSubPage;
