import { useLocation } from "react-router-dom";
import StandFields from "../fields";
import Exhibition from "components/exhibition";
import { Localize } from "components/service";
import { TStand } from "./stand-types-helper";


const standColumns = [
    {
        title: <Localize>TABLES.Column_Title_Image</Localize>,
        dataIndex: "image",
        columnIndex: "IMAGE",
        width: "6%",
        // sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Name</Localize>,
        dataIndex: "title",
        columnIndex: "TITLE",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_NavisionNumb</Localize>,
        dataIndex: "navision_number",
        columnIndex: "NAVISION_NUMBER",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_SizeMinMax</Localize>,
        dataIndex: "size_min",
        columnIndex: "SIZE_MIN",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_PricePer</Localize>,
        dataIndex: "price",
        columnIndex: "PRICE",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Orientations</Localize>,
        dataIndex: "orientations",
        columnIndex: "ORIENTATIONS",
        width: 306,
        // sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_ContentLang</Localize>,
        dataIndex: "lang",
        columnIndex: "LANG",
        sorter: true,
    },
    {
        // title: '',
        dataIndex: "action",
        align: "right",
        width: "5%",
    },
];


const StandData = (stands: TStand[], currentObject: Record<string, any>, variables: Record<string, any>, language: string) =>
{
    const location = useLocation();
    if (!stands) {
        return [];
    }

    const containsOrganizers = location.pathname.includes("organizers");
    const containsExhibitions = location.pathname.includes("exhibition-types");

    return stands.map((stand) =>
    {
        return {
            key: stand.id,
            image: <StandFields.Image stand={stand} />,
            title: <StandFields.Info stand={stand} lang={language} />,
            navision_number: stand.navision_number ?? <strong>&mdash;</strong>,
            size_min: (
                <>{`${stand.size_min ?? <strong>&mdash;</strong>} - ${stand.size_max ?? <strong>&mdash;</strong>
                    }`}</>
            ),
            price: stand.price.toFixed(2) + " €" ?? <strong>&mdash;</strong>,
            orientations: <StandFields.Orientation stand={stand} />,
            lang: <StandFields.Langs stand={stand} activeLang="" conditionCreate={false} />,
            action: containsOrganizers ? (
              <Exhibition.Fields.Remove
                model="StandType"
                et_id={currentObject?.et_default_id}
                dataQuery={stand}
                variables={variables}
              />
            ) : containsExhibitions ? (
              <Exhibition.Fields.Remove
                model="StandType"
                et_id={currentObject?.id}
                dataQuery={stand}
                variables={variables}
              />
            ) : (
              <StandFields.Action
                stand={stand}
                language={language}
                variables={variables}
              />
            ),
        };
    });
};

const StandsTableHelper = {
    columns: standColumns,
    data: StandData,
};

export default StandsTableHelper;
