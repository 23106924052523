import GraphicEditForm from "./graphic-edit-form";
import GraphicRuleEditForm from "./graphic-rule-edit-form";
import GraphicFiltersForm from "./graphic-filters-form";

const GraphicForms = {
  Edit: GraphicEditForm,
  RuleEdit: GraphicRuleEditForm,
  Filter: GraphicFiltersForm,
};

export default GraphicForms;
