import { useLocation } from "react-router-dom";
import FlooringFields from "../fields";
import Stand from "components/stand";
import Exhibition from "components/exhibition";
import { Localize } from "components/service";
import { TFlooring } from "../helpers/flooring-types-helper";

interface ICurrentObject
{
  group_id?: string;
  id?: string;
  et_default_id?: string;
  [ key: string ]: any;
}

const flooringColumns = [
  {
    title: <Localize>TABLES.Column_Title_Image</Localize>,
    dataIndex: "image",
    columnIndex: "IMAGE",
    width: "6%",
    // sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Name</Localize>,
    dataIndex: "title",
    columnIndex: "TITLE",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Article</Localize>,
    dataIndex: "article",
    columnIndex: "ARTICLE",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Type</Localize>,
    dataIndex: "type",
    columnIndex: "TYPE",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Color</Localize>,
    dataIndex: "color",
    columnIndex: "COLOR",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Supplier</Localize>,
    dataIndex: "supplier",
    columnIndex: "SUPPLIER",
    // sorter: true,
  },
  {
    // title: '',
    dataIndex: "action",
    align: "right",
    width: "5%",
  },
];

const FlooringData = (
  flooring: TFlooring[],
  currentObject: ICurrentObject,
  variables: Record<string, any>,
  _: any,
  extraObj: Record<string, any> | boolean
) =>
{
  const location = useLocation();

  if (!flooring) {
    return [];
  }

  const containsStandTypes = location.pathname.includes("stand-types");
  const containsOrganizers = location.pathname.includes("organizers");
  const containsExhibitions = location.pathname.includes("exhibition-types");

  return flooring.map((flooring) =>
  {
    return {
      key: flooring.id,
      image: <FlooringFields.Image flooring={flooring} />,
      title: <FlooringFields.Info flooring={flooring} />,
      article: <FlooringFields.Article flooring={flooring} />,
      type: flooring?.type ? (
        <Localize>{flooring.type}</Localize>
      ) : (
        <strong>&mdash;</strong>
      ),
      color: (
        <FlooringFields.Color flooring={flooring} disabledColor={extraObj} />
      ),
      supplier: <FlooringFields.Supplier flooring={flooring} />,
      action: containsStandTypes ? (
        <Stand.Fields.FlooringAction
          standTypeGroup={currentObject?.group_id}
          flooring={flooring}
          variables={variables}
        />
      ) : containsExhibitions ? (
        <Exhibition.Fields.Remove
          model="Flooring"
          identifier="id"
          et_id={currentObject?.id || ""}
          dataQuery={flooring}
          variables={variables}
        />
      ) : containsOrganizers ? (
        <Exhibition.Fields.Remove
          model="Flooring"
          identifier="id"
          et_id={currentObject?.et_default_id || ""}
          dataQuery={flooring}
          variables={variables}
        />
      ) : (
        <FlooringFields.Action flooring={flooring} />
      ),
    };
  });
};

const FlooringsTableHelper = {
  columns: flooringColumns,
  data: FlooringData,
};

export default FlooringsTableHelper;