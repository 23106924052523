import { useMutation, MutationTuple } from "@apollo/client";
import { ApolloError } from "@apollo/client/errors";

import { VARIABLE_LIST_UPLOAD_FILE } from 'graphql/mutation/variable-gql';
import { GET_VARIABLE_LISTS } from 'graphql/query/variable-gql';

import { successNotification, errorNotification } from "components/request-result";

interface IVariables
{
  [ key: string ]: any;
}

interface UseMutationVariableUploadFileProps
{
  variables: IVariables;
}

interface IFileUploadModel
{
  label: string;
  message: string;
}

interface MutationData
{
  fileUploadModel: IFileUploadModel;
}

const useMutationVariableUploadFile = ({ variables }: UseMutationVariableUploadFileProps) =>
{
  const [ _setVariableUploadFile, { loading } ]: MutationTuple<MutationData, IVariables> = useMutation(VARIABLE_LIST_UPLOAD_FILE, {
    update(cache, { data })
    {
      if (data) {
        const {
          fileUploadModel: {
            label,
            message,
          }
        } = data;

        successNotification({
          title: label,
          description: message
        });
      }
    },
    onError(error: ApolloError)
    {
      errorNotification(error);
    },
    refetchQueries: [
      {
        query: GET_VARIABLE_LISTS,
        variables
      },
    ]
  });

  return {
    _setVariableUploadFile,
    loadingMutationVariableUploadFile: loading,
  };
};

export default useMutationVariableUploadFile;