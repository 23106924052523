import React from "react";
import { userUseMutation } from '../hooks';
import { EntityRemoveButton } from 'components/service';
import Icons from 'components/icons';
import { localizeByKey } from "components/service/localize";
import type { TUser } from "components/user/helpers/user-types-helper";

type TUserRemoveFieldProps = {
    userId: TUser[ 'id' ];
    userName: TUser[ 'name' ];
    modelID: Record<string, any>;
    mutation: 'roleRemoveUser' | 'supplierRemoveUser';
    disabled?: boolean;
};

const UserRemoveField: React.FC<TUserRemoveFieldProps> = ({ userId, userName, modelID, mutation, disabled = false }) =>
{
    const { _setMutationUserRemove, loadingMutationUserRemove } = userUseMutation.remove({ mutation });

    return (
        <EntityRemoveButton
            okText={localizeByKey("GLOBAL.Button_Text_Remove")}
            disabled={disabled}
            modalButton={
                <span
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'end',
                        fontSize: '22px',
                        color: !disabled ? 'var(--table_colorIconRemove)' : 'var(--table_colorIconRemoveDisabled)',
                        cursor: !disabled ? 'pointer' : 'default',
                    }}>
                    <Icons.Close />
                </span>
            }
            nameEntity={localizeByKey("ENTITY.Modal_Title_User")}
            dataNameEntity={userName}
            loading={loadingMutationUserRemove}
            deleteMutation={_setMutationUserRemove}
            variables={{
                user_id: userId,
                ...modelID
            }}
        />
    );
};

export default UserRemoveField;