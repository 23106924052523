import EquipmentInfoField from "./equipment-info-field";
import EquipmentActionField from "./equipment-action-field";
import EquipmentSupplierField from "./equipment-supplier-field";
import EquipmentArticleField from "./equipment-article-field";
import EquipmentImageField from "./equipment-image-field";
import EquipmentColorField from "./equipment-color-field";
import EquipmentLangsField from "./equipment-langs-field";
import EquipmentRemoveField from "./equipment-remove-field";


const EquipmentFields = {
  Info: EquipmentInfoField,
  Supplier: EquipmentSupplierField,
  Article: EquipmentArticleField,
  Image: EquipmentImageField,
  Color: EquipmentColorField,
  Langs: EquipmentLangsField,
  Action: EquipmentActionField,
  Remove: EquipmentRemoveField,
};

export default EquipmentFields;
