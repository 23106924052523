import { useMutation } from "@apollo/client";
import { STAND_TYPE_NOTE_DELETE } from "graphql/mutation/stand-gql";
import
{
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationStandNoteDelete = ({ id }: { id: string | undefined }) =>
{
  const [ _setMutationStandNoteDelete, { loading } ] = useMutation(
    STAND_TYPE_NOTE_DELETE,
    {
      update(cache, { data })
      {
        const {
          standTypeNoteDelete: { label, message },
        } = data;

        cache.evict({
          id: cache.identify({ id, __typename: "StandTypeNote" }),
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message,
        });
      },
      onError(error)
      {
        errorNotification(error);
      },
    }
  );

  const handleDelete = async (options: { variables: Record<string, any> }) =>
  {
    await _setMutationStandNoteDelete(options);
  };

  return {
    _setMutationStandNoteDelete: handleDelete,
    loadingMutationStandNoteDelete: loading,
  };
};

export default useMutationStandNoteDelete;
