import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Pages } from 'components/pages';

const ExhibitionsRoute: React.FC = () =>
{

  return (
    <Routes>
      <Route path="/" element={<Pages.Exhibitions />} />
      <Route path="/:id/*" element={<Pages.Exhibition />} />
      <Route path="/create" element={<Pages.Exhibition />} />
    </Routes>
  );
};


export default ExhibitionsRoute;
