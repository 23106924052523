import { useMutation } from "@apollo/client";
import { STAND_TYPE_FILE_DELETE } from "graphql/mutation/stand-gql";
import
{
  successNotification,
  errorNotification,
} from "components/request-result";


const useMutationStandFileDelete = ({ id, documentCreate }: { id: string, documentCreate?: boolean | undefined }) =>
{
  const [ _setMutationStandFileDelete, { loading } ] = useMutation(
    STAND_TYPE_FILE_DELETE,
    {
      update(cache, { data })
      {
        if (!documentCreate) {
          const {
            standTypeFileDelete: { label, message },
          } = data;

          successNotification({
            title: label.toUpperCase(),
            description: message,
          });
        }

        cache.evict({
          id: cache.identify({ id, __typename: "StandTypeFile" }),
        });
        cache.gc();
      },
      onError(error)
      {
        errorNotification(error);
      },
    }
  );

  const handleDelete = async (options: { variables: Record<string, any> }) =>
  {
    await _setMutationStandFileDelete(options);
  };

  return {
    _setMutationStandFileDelete: handleDelete,
    loadingMutationStandFileDelete: loading,
  };
};

export default useMutationStandFileDelete;
