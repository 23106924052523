import React from "react";
import { Link } from "react-router-dom";
// import Team from "components/team";
import { Localize } from "components/service";
import { TTeam } from "components/team/helpers/team-types-helper";

type TUserTeamFieldProps = {
    team: TTeam[];
};

const UserTeamField: React.FC<TUserTeamFieldProps> = ({ team }) =>
{
    const uniqueTitles: Record<string, boolean> = {};

    const uniqueObjects = team.filter((obj) =>
    {
        const title = obj.title;
        if (uniqueTitles[ title ]) {
            return false;
        } else {
            uniqueTitles[ title ] = true;
            return true;
        }
    });

    return uniqueObjects.length > 0 ? (
        <>
            {uniqueObjects.map((uniqueObj, index) => (
                <div key={index} style={{ marginBottom: "3px" }}>
                    <Link
                        className="table-link-underline"
                        to="#"
                    // to={`${Team.Const.basePath}/${uniqueObj.id}`}
                    >
                        <span style={{ fontWeight: 400 }}>
                            <Localize>{uniqueObj.title}</Localize>
                        </span>
                    </Link>
                    <br />
                </div>
            ))}
        </>

    ) : (
        <strong>&mdash;</strong>
    );
};

export default UserTeamField;
