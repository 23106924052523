import { useMutation, DocumentNode } from "@apollo/client";
import { ROLE_REMOVE_USER } from "graphql/mutation/role-gql";
import { SUPPLIER_REMOVE_USER } from "graphql/mutation/supplier-gql";
import { GET_USERS } from 'graphql/query/user-gql';

import { successNotification, errorNotification } from "components/request-result";

interface IUseMutationUserRemoveProps
{
  mutation: 'roleRemoveUser' | 'supplierRemoveUser';
}

const useMutationUserRemove = ({ mutation }: IUseMutationUserRemoveProps) =>
{
  let USER_REMOVE: DocumentNode | null = null;

  switch (mutation) {
    case 'roleRemoveUser':
      USER_REMOVE = ROLE_REMOVE_USER;
      break;
    case 'supplierRemoveUser':
      USER_REMOVE = SUPPLIER_REMOVE_USER;
      break;
    default:
      USER_REMOVE = null;
  }

  const [ _setMutationUserRemove, { loading } ] = useMutation(USER_REMOVE as DocumentNode,
    {
      update(cache, { data })
      {
        const {
          [ mutation ]: {
            label,
            message
          }
        } = data;

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },

      refetchQueries: [ GET_USERS ],

      onError(error)
      {
        errorNotification(error);
      }
    });

    const handleRemoveUser = async (options: { variables: Record<string, any> }) =>
    {
        await _setMutationUserRemove(options);
    };

  return {
    _setMutationUserRemove: handleRemoveUser,
    loadingMutationUserRemove: loading,
  };
};

export default useMutationUserRemove;