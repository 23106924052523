import EquipmentEditForm from "./equipment-edit-form";
import EquipmentAddRuleForm from "./equipment-add-rule-form";
import EquipmentFiltersForm from "./equipment-filters-form";

const EquipmentForms = {
  Edit: EquipmentEditForm,
  AddRule: EquipmentAddRuleForm,
  Filter: EquipmentFiltersForm,
};

export default EquipmentForms;
