import React, { useState } from "react";
import { Button, Input } from "antd";
import { useApolloClient } from "@apollo/client";

import { GET_ME } from "graphql/query/user-gql";
import { GET_STAND_TYPES_CURSOR } from "graphql/query/stand-gql";
import { GET_MODELS_IDS } from "graphql/query/model-gql";
import { EXHIBITION_TYPE_SYNC_MODEL } from "graphql/mutation/exhibition-gql";

import Stand from "components/stand";
import Organizer from "components/organizer";
import Icons from "components/icons";
import { useBreadCrumbs } from "components/use-hooks";
import { Tables, Forms } from "components/layout";
import { Localize, DrawerStandart } from "components/service";
import { localizeByKey } from "components/service/localize";
import type { TOrganizer } from "components/organizer/helpers/organizer-types-helper";

const { Search } = Input;

const basePath = Stand.Const.basePath;
const organizerBasePath = Organizer.Const.basePath;
const tableHelper = Stand.Helpers.TableMain;


interface IOrganizerStandSubPageProps
{
  organizer: TOrganizer | undefined;
}


const OrganizerStandSubPage: React.FC<IOrganizerStandSubPageProps> = ({ organizer }) => {
  const breadcrumbs = [
    {
      label: localizeByKey("BREADCRUMBS.Label_Organizers"),
      path: `${organizerBasePath}`,
    },
    {
      label: organizer?.title ?? localizeByKey("VARIABLE.Label_EMPTY"),
      path: `${organizerBasePath}/${organizer?.id}`,
    },
    {
      label: localizeByKey("BREADCRUMBS.Label_StandTypes"),
      path: `${organizerBasePath}/${organizer?.id}${basePath}`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const client = useApolloClient();
  const cachedMe = client.readQuery({
    query: GET_ME,
  });

  const defaultLang = cachedMe.me.lang_api;

  const disabledColor = cachedMe.me.roles.filter(
    ({ slug }: { slug: string }) => slug === "role_library_manager"
  ).length
    ? true
    : false;

  const langDefault = {
    column: "LANG",
    operator: "EQ",
    value: defaultLang,
  };

  const whereExhibitionTypeStandTypes = {
    column: "ET_ID",
    value: organizer?.et_default_id,
  };

  const [searchText, setSearchText] = useState("");
  const [ viewMode, setViewMode ] = useState<"table" | "grid">("grid");

  return (
    <Tables.Cursor
      model="standTypesCursor"
      query={GET_STAND_TYPES_CURSOR}
      varSet={{
        perPage: 50,
        whereExhibitionTypeStandTypes,
      }}
      extraObj={disabledColor}
      routeUrl={`${basePath}`}
      searchText={searchText}
      tableHelper={tableHelper}
      viewMode={viewMode}
      viewGrid={Stand.Helpers.GridMain}
      objectWhere={langDefault}
      language={defaultLang}
      currentObj={organizer}
    >
      <div className="table-action-bar">
        <div className="col">
          <Search
            className="filter-search"
            prefix={<Icons.Search />}
            onChange={(e) => setSearchText(e.target.value)}
          />

          <Button disabled icon={<Icons.Filter />}>
            <span className="ellipsis">
              <Localize>FORMS.Button_Text_Filters</Localize>
            </span>
          </Button>
        </div>

        <div className="col">
        <div className="switch-btn-holder">
            {viewMode === "table" ? (
              <Button
                onClick={() => setViewMode("grid")}
                icon={<Icons.Grid />}
              />
            ) : (
              <Button
                onClick={() => setViewMode("table")}
                icon={<Icons.Table />}
              />
            )}
          </div>

          <DrawerStandart
            extraClass={"modal-form filters"}
            modalButton={
              <Button type="primary" icon={<Icons.Plus />}>
                <span className="ellipsis">
                  <Localize>GLOBAL.Button_Text_AddNew</Localize>
                </span>
              </Button>
            }
          >
            <strong className="modal-title">
              <span className="text">
                <Localize>FORMS.Title_Text_AddStandOrganizer</Localize>
              </span>
            </strong>
            <Forms.ModelAddToForm
              modelCursorLabel={[ "title", "navision_number" ]}
              action={() => { }}
              propsObj={{
                mutation: EXHIBITION_TYPE_SYNC_MODEL,
                queries: {
                  query: GET_STAND_TYPES_CURSOR,
                  queryInitalVal: GET_MODELS_IDS,
                },
                queryNames: {
                  queryName: "standTypesCursor",
                  queryInitalValName: "getRelatedModelIds",
                },
                queryVars: {
                  queryVar: {
                    where: langDefault,
                    whereExtra: {}
                  },
                  queryInitalValVar: {
                    modelFrom: "ExhibitionType",
                    modelTo: "StandType",
                    rel_id: organizer?.et_default_id,
                  },
                },
                mutationName: "exhibitionTypeSyncModel",
                modelID: {
                  et_id: organizer?.et_default_id,
                  model: "StandType",
                },
              }}
            />
          </DrawerStandart>
        </div>
      </div>
    </Tables.Cursor>
  );
};

export default OrganizerStandSubPage;
