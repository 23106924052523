import React, { useEffect } from "react";
import { Form, Input } from "antd";
import { FormInstance } from "antd/lib/form";
import { variableUseMutation } from "../hooks";

interface VariableTranslationFieldProps
{
    id?: string;
    lang: string;
    initialValue: string;
    variableList: {
        group_name?: string;
        slug: string;
        editable: boolean;
    };
    variables: Record<string, any>;
}

const VariableTranslationField: React.FC<VariableTranslationFieldProps> = ({
    id,
    lang,
    initialValue,
    variableList,
    variables,
}) =>
{
    const [ form ] = Form.useForm<FormInstance>();

    const { _setVariableCreateUpdate } = variableUseMutation.createUpdate({
        id,
        variables,
    });

    useEffect(() =>
    {
        form.setFieldsValue({ value: initialValue } as any);
    }, [ initialValue, form ]);

    const handleBlur = (value: string) =>
    {
        if (initialValue !== value && value !== "") {
            _setVariableCreateUpdate({
                variables: {
                    input: {
                        id: id ? id : undefined,
                        group_name: variableList?.group_name,
                        slug: variableList?.slug,
                        value: value,
                        lang,
                        editable: variableList?.editable,
                    },
                },
            });
        }
    };

    return (
        <Form
            key="variable-translation-field"
            className="form-variable-translate"
            layout="vertical"
            form={form}
        >
            <Form.Item name="value">
                <Input
                    onBlur={(e) => handleBlur(e.target.value)}
                    name="value"
                    style={{ width: "100%", fontWeight: "400" }}
                />
            </Form.Item>
        </Form>
    );
};

export default VariableTranslationField;