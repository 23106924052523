import React, { FunctionComponent, JSX } from "react";
import Icon from "@ant-design/icons";

import { IIcon } from "./icons-interfaces";

const KeySvg = () => (

	<svg fill="currentColor" width="1em" height="1em" viewBox="0 0 40 40" strokeWidth="1">
		<path d="M22.004 0c-5.523 0-10 4.478-10 10 0 1.285 0.269 2.501 0.713 3.629l-12.149 12.148c-0.351 0.352-0.568 0.686-0.568 1.223v3c0 1.070 0.929 2 2 2h3c0.536 0 0.875-0.215 1.226-0.564l1.435-1.436h2.343c1.104 0 2-0.896 2-2v-2h2c1.104 0 2-0.896 2-2v-2.344l2.369-2.371c1.129 0.445 2.344 0.715 3.631 0.715 5.521 0 10-4.478 10-10s-4.479-10-10-10zM22.004 18c-1.48 0-2.852-0.43-4.041-1.132l-0.344 0.343-3.030 3.031c-0.375 0.375-0.586 0.883-0.586 1.414v2.344h-2c-1.104 0-2 0.895-2 2v2h-2.342c-0.53 0-1.039 0.211-1.414 0.586l-1.418 1.418-2.826-0.004-0.003-2.85 11.665-11.644c0 0 0 0.001 0.001 0.002l1.469-1.469c-0.702-1.189-1.132-2.56-1.132-4.040 0-4.418 3.583-8 8-8s8 3.582 8 8-3.581 8.001-7.999 8.001zM27.82 8.239c-1.121-1.562-2.486-2.925-4.055-4.054-0.255-0.185-0.585-0.231-0.882-0.127-1.389 0.489-2.34 1.439-2.826 2.828-0.037 0.104-0.055 0.212-0.055 0.319 0 0.199 0.062 0.396 0.182 0.563 1.125 1.564 2.488 2.928 4.053 4.053 0.256 0.184 0.584 0.231 0.881 0.128 1.391-0.486 2.342-1.438 2.83-2.828 0.037-0.104 0.055-0.212 0.055-0.319-0.001-0.199-0.064-0.396-0.183-0.563zM24.82 11.010c-1.478-1.063-2.765-2.35-3.82-3.793 0.387-1.103 1.111-1.827 2.182-2.221 1.479 1.065 2.764 2.349 3.816 3.811-0.391 1.095-1.113 1.815-2.178 2.203z"></path>
	</svg>

);

const KeyIcon: FunctionComponent<IIcon> = (): JSX.Element => {
	return <Icon component={ KeySvg } />;
};


export default KeyIcon;