import { useMutation } from "@apollo/client";
import
  {
    GRAPHIC_SUPPLIER_RULE_UPDATE,
    GRAPHIC_SUPPLIER_RULE_CREATE,
  } from "graphql/mutation/graphic-gql";

import { GET_GRAPHIC_SUPPLIER_RULES_CURSOR } from "graphql/query/graphic-gql";

import
  {
    successNotification,
    errorNotification,
  } from "components/request-result";

const useMutationGraphicRuleCreateUpdate = (id: string | undefined, variables: Record<string, any>) =>
{
  const GRAPHIC_SUPPLIER_RULE_CREATE_UPDATE = id
    ? GRAPHIC_SUPPLIER_RULE_UPDATE
    : GRAPHIC_SUPPLIER_RULE_CREATE;
  const graphicCreateUpdate = id
    ? "graphicSupplierRuleUpdate"
    : "graphicSupplierRuleCreate";

  const [ _setGraphicRuleCreateUpdate, { loading } ] = useMutation(
    GRAPHIC_SUPPLIER_RULE_CREATE_UPDATE,
    {
      update(cache, { data })
      {
        const {
          [ graphicCreateUpdate ]: { label, message },
        } = data;

        successNotification({
          title: label,
          description: message,
        });
      },
      onError(error)
      {
        errorNotification(error);
      },
      refetchQueries: [
        {
          query: GET_GRAPHIC_SUPPLIER_RULES_CURSOR,
          variables,
        },
      ],
    }
  );

  return {
    _setGraphicRuleCreateUpdate,
    loadingMutationGraphicRuleCreateUpdate: loading,
  };
};

export default useMutationGraphicRuleCreateUpdate;
