import React from "react";
import translation from "../../translation";
import { reactiveVarHelper } from "common/helpers";

import { TTranslations } from "common/types";

import { ITranslationItem } from "graphql/reactive-variables/reactive-var-interfaces";


const useTranslation = (): TTranslations =>
{
    const rVar = reactiveVarHelper(),
        { me, defaultLanguage, translationInterface } = rVar.get('all');

    const convertDataFormat = React.useCallback((data: ITranslationItem[]): Record<string, string> =>
    {
        const convertedData: Record<string, string> = {};

        if (!data || !Array.isArray(data)) {
            return convertedData;
        }

        data.forEach((item) =>
        {
            if (item?.slug) {
                const key = item?.slug.toLowerCase();
                const value = item?.value;
                convertedData[ key ] = value;
            }
        });

        return convertedData;
    }, []);

    const translationServer = React.useCallback((lang: string) =>
        convertDataFormat(
            translationInterface?.filter((item: { lang: string }) => item?.lang === lang)
        ), [ translationInterface, convertDataFormat ]);


    React.useEffect(() =>
    {
        if (me) {
            document.documentElement.setAttribute("lang", me.lang_app);
        } else {
            document.documentElement.setAttribute("lang", defaultLanguage);
        }
    }, [ me, defaultLanguage ]);

    if (me) {
        return { ...translation[ me.lang_app ], ...translationServer(me.lang_app) };
    }


    return { ...translation[ defaultLanguage ], ...translationServer(defaultLanguage) };
};

export default useTranslation;