import { useMutation } from "@apollo/client";
import { GET_EQUIPMENT_VARIATIONS } from "graphql/query/equipment-gql";
import
  {
    EQUIPMENT_VARIATION_UPDATE,
    EQUIPMENT_VARIATION_CREATE,
  } from "graphql/mutation/equipment-gql";
import
  {
    successNotification,
    errorNotification,
  } from "components/request-result";

const useMutationVariationCreateUpdate = (id: string | undefined, variables: Record<string, any>) =>
{
  const EQUIPMENT_VARIATION_CREATE_UPDATE = id
    ? EQUIPMENT_VARIATION_UPDATE
    : EQUIPMENT_VARIATION_CREATE;
  const variationCreateUpdate = id
    ? "equipmentVariationUpdate"
    : "equipmentVariationCreate";

  const [ _setVariationCreateUpdate, { loading } ] = useMutation(
    EQUIPMENT_VARIATION_CREATE_UPDATE,
    {
      update(cache, { data })
      {
        const {
          [ variationCreateUpdate ]: { label, message },
        } = data;

        successNotification({
          title: label,
          description: message,
        });
      },
      onError(error)
      {
        errorNotification(error);
      },
      refetchQueries: [
        {
          query: GET_EQUIPMENT_VARIATIONS,
          variables,
        },
      ],
    }
  );

  return {
    _setVariationCreateUpdate,
    loadingMutationVariationCreateUpdate: loading,
  };
};

export default useMutationVariationCreateUpdate;
