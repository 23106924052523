import { useNavigate, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { GET_STAND_TYPES_GROUP } from "graphql/query/stand-gql";
import
{
  STAND_TYPE_UPDATE_AND_META_SET,
  STAND_TYPE_CREATE,
} from "graphql/mutation/stand-gql";
import StandConst from "../stand-const";
import
{
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationStandCreateUpdate = (id: string | undefined) =>
{
  const navigate = useNavigate();
  const location = useLocation();

  const containsCreate = (url: string) => url.includes("create");

  const condition = containsCreate(location.pathname);

  const STAND_TYPE_CREATE_UPDATE = condition
    ? STAND_TYPE_CREATE
    : STAND_TYPE_UPDATE_AND_META_SET;
  const standCreateUpdate = condition ? "standTypeCreate" : "standTypeUpdate";

  const [ _setStandCreateUpdate, { loading } ] = useMutation(
    STAND_TYPE_CREATE_UPDATE,
    {
      update(cache, { data })
      {
        const {
          [ standCreateUpdate ]: { label, message },
        } = data;

        if (condition) {
          navigate(
            `${StandConst.basePath}/edit/${data[ standCreateUpdate ]?.standType?.group_id}/${data[ standCreateUpdate ]?.standType?.lang}`
          );
        }

        successNotification({
          title: label,
          description: message,
        });
      },
      onError(error)
      {
        errorNotification(error);
      },
      refetchQueries: [
        {
          query: GET_STAND_TYPES_GROUP,
          variables: {
            group_id: id,
          },
        },
      ],
    }
  );

  return {
    _setStandCreateUpdate,
    loadingMutationStandCreateUpdate: loading,
  };
};

export default useMutationStandCreateUpdate;