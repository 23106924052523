import { gql } from "@apollo/client";

export const GET_FLOORING_CURSOR = gql`
  query FlooringsCursor(
    $text: String
    $first: Int!
    $after: String
    $where: QueryFlooringsCursorWhereWhereConditions
    $whereStandTypeFlooring: QueryFlooringsCursorWhereStandTypeFlooringWhereHasConditions
    $whereExhibitionTypeFlooring: QueryFlooringsCursorWhereExhibitionTypeFlooringWhereHasConditions
    $stand_type_group: ID
    $orderBy: [QueryFlooringsCursorOrderByOrderByClause!]
  ) {
    flooringsCursor(
      text: $text
      first: $first
      after: $after
      where: $where
      whereStandTypeFlooring: $whereStandTypeFlooring
      whereExhibitionTypeFlooring: $whereExhibitionTypeFlooring
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        total
        count
        currentPage
        lastPage
      }
      edges {
        cursor
        node {
          id
          title
          article
          type
          color
          standTypeDefault(stand_type_group: $stand_type_group)
          images {
            id
            extension
            description
            field_group
            order
            server_name
            sizes
            title
          }
          supplier {
            id
            title
          }
        }
      }
    }
  }
`;

export const GET_FLOORING = gql`
  query GetFlooring($id: ID) {
    flooring(id: $id) {
      id
      title
      article
      type
      color
      images {
        id
        extension
        description
        field_group
        order
        server_name
        sizes
        title
      }
      supplier {
        id
        title
      }
    }
  }
`;
