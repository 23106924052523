import StandFields from "./fields";
import StandForms from "./forms";
import StandConst from "./stand-const";
import StandsHelpers from "./helpers";
import { standUseMutation } from "./hooks";
import StandsFilters from './filters';

const Stand = {
  Fields: StandFields,
  Forms: StandForms,
  Const: StandConst,
  Helpers: StandsHelpers,
  Hooks: standUseMutation,
  Filters: StandsFilters,
};

export default Stand;
