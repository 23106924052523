import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_TEAM } from "graphql/query/team-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import Team from "components/team";
import TeamRoute from 'components/routes/team/team-route';
import Localize, { localizeByKey } from "components/service/localize";
import type { TTeam } from "components/team/helpers/team-types-helper";


const TeamPage: React.FC = () =>
{

    useActiveMenuItem([ "teams" ], [ "teams" ]);


    const { id } = useParams<{ id: string }>();
    const teamID = id ? parseInt(id) : "create";
    const basePath = Team.Const.basePath;


    const { data, loading } = useQuery<{ team: TTeam }>(GET_TEAM, {
        skip: teamID === 'create',
        variables: {
            id: teamID
        }
    });

    const team = data?.team;

    const pageNavbar = [
        {
            label: localizeByKey("NAVBAR_PAGES.Label_Overview"),
            path: ""
        },
    ];


    return (
        <PageWrapDefault
            className="page-team"
            loading={loading}
            title={
                teamID === "create" ? (
                    <Localize>PAGES.Title_CreateTeam</Localize>
                ) : !loading && team ? (
                    <Localize>{team.title}</Localize>
                ) : (
                    "Page 404"
                )
            }
            dataExist={teamID === "create" ? true : !loading && team !== undefined}
            pageNavbar={!loading && team ? pageNavbar : undefined}
            staticPath={`${basePath}/${teamID}`}
        >
            <TeamRoute team={team} />

        </PageWrapDefault>
    );
};

export default TeamPage;