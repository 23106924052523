import React from "react";
import { DocumentNode } from "graphql";
import { GET_STAND_TYPES_CURSOR } from "graphql/query/stand-gql";
import { GET_EQUIPMENT_CURSOR } from "graphql/query/equipment-gql";
import { GET_FLOORING_CURSOR } from "graphql/query/flooring-gql";

import { exhibitionUseMutation } from "../hooks";
import { EntityRemoveButton } from "components/service";
import { localizeByKey } from "components/service/localize";
import { TFlooring } from "components/flooring/helpers/flooring-types-helper";
import { TStand } from "components/stand/helpers/stand-types-helper";
import { TEquipment } from "components/equipment/helpers/equipment-types-helper";
import Icons from "components/icons";


interface ExhibitionRemoveFieldProps
{
  disabled?: boolean;
  et_id: string;
  dataQuery: TFlooring | TStand | TEquipment;
  variables: Record<string, string>;
  model: "Equipment" | "StandType" | "Flooring";
  identifier?: string;
}

const ExhibitionRemoveField: React.FC<ExhibitionRemoveFieldProps> = ({
  disabled = false,
  et_id,
  dataQuery,
  variables,
  model,
  identifier = "group_id",
}) =>
{
  let query: DocumentNode;

  switch (model) {
    case "Equipment":
      query = GET_EQUIPMENT_CURSOR;
      break;
    case "StandType":
      query = GET_STAND_TYPES_CURSOR;
      break;
    case "Flooring":
      query = GET_FLOORING_CURSOR;
      break;
    default:
      throw new Error("Invalid model type");
  }

  const { _setMutationExhibitionRemove, loadingMutationExhibitionRemove } =
    exhibitionUseMutation.remove(query, variables);

  const handleDelete = async (options: { variables: Record<string, any> }) =>
  {
    await _setMutationExhibitionRemove(options);
  };

  return (
    <EntityRemoveButton
      okText={localizeByKey("GLOBAL.Button_Text_Remove")}
      disabled={disabled}
      modalButton={
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            fontSize: "22px",
            color: !disabled
              ? "var(--table_colorIconRemove)"
              : "var(--table_colorIconRemoveDisabled)",
            cursor: !disabled ? "pointer" : "default",
          }}
        >
          <Icons.Close />
        </span>
      }
      nameEntity={localizeByKey(`ENTITY.Modal_Title_${model}`)}
      dataNameEntity={dataQuery?.title}
      loading={loadingMutationExhibitionRemove}
      deleteMutation={handleDelete}
      variables={{
        et_id,
        model,
        model_id: [ (dataQuery as any)[identifier] ],
      }}
    />
  );
};

export default ExhibitionRemoveField;