import React from "react";
import { TStandTypeEquipment } from "../helpers/stand-types-helper";


interface IStandEquipmentSizeFieldProps
{
  standEquipment: TStandTypeEquipment;
}


const StandEquipmentSizeField: React.FC<IStandEquipmentSizeFieldProps> = ({ standEquipment }) =>
{
  let ruleType = standEquipment?.rule_type;

  switch (ruleType) {
    case "EQ":
      ruleType = standEquipment.size_min;
      break;
    case "GTE":
      ruleType = standEquipment.size_min;
      break;
    case "LTE":
      ruleType = standEquipment.size_max;
      break;
    case "BETWEEN":
      ruleType = standEquipment.size_min + " - " + standEquipment.size_max;
      break;

    default:
      ruleType = "-";
  }

  return <div>{ruleType}</div>;
};

export default StandEquipmentSizeField;
