import { gql } from "@apollo/client";

export const EXHIBITION_CREATE = gql`
  mutation ExhibitionTypeCreate($input: ExhibitionTypeFieldsInput!) {
    exhibitionTypeCreate(input: $input) {
      label
      message
      exhibitionType {
        id
        title
        organizer_id
        total_stands
        total_equipment
        total_floorings
        default
        status
        suppliers {
          id
        }
        organizer {
          id
          et_default_id
        }
      }
    }
  }
`;

export const EXHIBITION_UPDATE = gql`
  mutation ExhibitionTypeUpdate($input: ExhibitionTypeFieldsInput!) {
    exhibitionTypeUpdate(input: $input) {
      label
      message
      exhibitionType {
        id
        title
        organizer_id
        total_stands
        total_equipment
        total_floorings
        default
        status
        suppliers {
          id
        }
        organizer {
          id
          et_default_id
        }
      }
    }
  }
`;

export const EXHIBITION_DELETE = gql`
  mutation ExhibitionTypeDelete($id: ID!) {
    exhibitionTypeDelete(id: $id) {
      label
      message
    }
  }
`;

export const EXHIBITION_TYPE_SYNC_MODEL = gql`
  mutation exhibitionTypeSyncModel(
    $et_id: ID!
    $model_id: [ID!]!
    $model: ExhibitionTypeSyncModelEnum!
  ) {
    exhibitionTypeSyncModel(et_id: $et_id, model_id: $model_id, model: $model) {
      label
      message
    }
  }
`;

export const EXHIBITION_TYPE_REMOVE_MODEL = gql`
  mutation ExhibitionTypeRemoveModel(
    $et_id: ID!
    $model_id: [ID!]!
    $model: ExhibitionTypeSyncModelEnum!
  ) {
    exhibitionTypeRemoveModel(
      et_id: $et_id
      model_id: $model_id
      model: $model
    ) {
      label
      message
    }
  }
`;
