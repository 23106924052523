import { useMutation } from "@apollo/client";
import { STAND_TYPE_FLOORING_REMOVE } from "graphql/mutation/stand-gql";
import { GET_FLOORING_CURSOR } from "graphql/query/flooring-gql";

import
{
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationStandFlooringRemove = () =>
{
  const [ _setMutationFlooringRemove, { loading } ] = useMutation(
    STAND_TYPE_FLOORING_REMOVE,
    {
      update(cache, { data })
      {
        const {
          standTypeFlooringRemove: { label, message },
        } = data;

        successNotification({
          title: label.toUpperCase(),
          description: message,
        });
      },

      refetchQueries: [ GET_FLOORING_CURSOR ],

      onError(error)
      {
        errorNotification(error);
      },
    }
  );

  const handleDelete = async (options: { variables: Record<string, any> }) =>
  {
    await _setMutationFlooringRemove(options);
  };

  return {
    _setMutationFlooringRemove: handleDelete,
    loadingMutationFlooringRemove: loading,
  };
};

export default useMutationStandFlooringRemove;
