import RoleFields from "./fields";
import RoleForms from "./forms";
import RoleConst from "./role-const";
import RolesHelpers from "./helpers";
// import RolesFilters from './filters';
import { roleUseMutation } from "./hooks";


const Role = {
  // Filters: RolesFilters,
  Fields: RoleFields,
  Forms: RoleForms,
  Const: RoleConst,
  Helpers: RolesHelpers,
  Hooks: roleUseMutation
};

export default Role;
