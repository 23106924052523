import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_SUPPLIER } from "graphql/query/supplier-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import Supplier from "components/supplier";
import SupplierRoute from 'components/routes/supplier/supplier-route';
import Localize, { localizeByKey } from "components/service/localize";
import type { TSupplier } from "components/supplier/helpers/supplier-types-helper";


const SupplierPage: React.FC = () =>
{
    useActiveMenuItem([ "suppliers" ], [ "suppliers" ]);


    const { id } = useParams<{ id: string }>();
    const basePath = Supplier.Const.basePath;
    const supplierID = id ? parseInt(id) : "create";

    const { data, loading } = useQuery<{ supplier: TSupplier }>(GET_SUPPLIER, {
        skip: !supplierID,
        variables: {
            id: supplierID
        }
    });

    const supplier = data?.supplier;

    const pageNavbar = [
        {
            label: localizeByKey("NAVBAR_PAGES.Label_Overview"),
            path: ""
        },
        {
            label: localizeByKey("NAVBAR_PAGES.Label_Users"),
            path: "users"
        },
    ];


    return (
        <PageWrapDefault
            className="page-supplier"
            loading={loading}
            title={
                supplierID === "create" ? (
                    <Localize>PAGES.Title_CreateSupplier</Localize>
                ) : !loading && supplier ? (
                    <Localize>{supplier.title}</Localize>
                ) : (
                    "Page 404"
                )
            }
            dataExist={supplierID === "create" ? true : !loading && supplier !== undefined}
            pageNavbar={!loading && supplier ? pageNavbar : undefined}
            staticPath={`${basePath}/${supplierID}`}
        >
            <SupplierRoute supplier={supplier} />

        </PageWrapDefault>
    );
};

export default SupplierPage;
