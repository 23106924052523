import React from "react";
import { Link } from "react-router-dom";
// import Organizer from "components/organizer";
import { TVariation } from "../helpers/variation-types-helper";

interface IVariationOrganizerFieldProps
{
  variation: TVariation | undefined;
}

const VariationOrganizerField: React.FC<IVariationOrganizerFieldProps> = ({ variation }) =>
  variation?.organizer?.id ? (
    <Link
      className="table-link-underline"
    // to={`${Organizer.Const.basePath}/${variation?.organizer?.id}`}
      to="#"
    >
      {variation?.organizer?.title}
    </Link>
  ) : (
    <strong>&mdash;</strong>
  );

export default VariationOrganizerField;
