import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import FlooringConst from "../flooring-const";
import { TFlooring } from "../helpers/flooring-types-helper";
import { Localize } from "components/service";

interface IFlooringInfoFieldProps
{
  flooring: TFlooring | undefined;
}

const FlooringInfoField: React.FC<IFlooringInfoFieldProps> = ({ flooring }) =>
{
  useEffect(() =>
  {
    const rows = document.querySelectorAll(".ant-table-row");

    rows.forEach((row) =>
    {
      const defaultElement = row.querySelector(".badge");

      if (defaultElement) {
        row.classList.add("with-border");
      } else {
        row.classList.remove("with-border");
      }
    });
  }, [ flooring ]);

  return (
    <div className="d-flex align-items-center">
      <Link
        className="table-link-underline"
        to={`${FlooringConst.basePath}/${flooring?.id}/`}
      >
        {`${flooring?.title}`}
      </Link>

      {flooring?.standTypeDefault && (
        <strong className="badge">
          <Localize>CARD.Info_List_Default</Localize>
        </strong>
      )}
    </div>
  );
};

export default FlooringInfoField;
