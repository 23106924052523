import { useState } from "react";
import { Button, Input } from "antd";
import { GET_STAND_TYPE_FILES_CURSOR } from "graphql/query/stand-gql";
import { useBreadCrumbs } from "components/use-hooks";
import { ModalStandard, Localize, UploadFile } from "components/service";
import { localizeByKey } from "components/service/localize";
import { Tables } from "components/layout";
import Stand from "components/stand";
import type { TStand } from "components/stand/helpers/stand-types-helper";
import Icons from "components/icons";

const { Search } = Input;

interface IStandFilesSubPageProps
{
  stand: TStand | undefined;
  lang: string;
}

const StandFilesSubPage: React.FC<IStandFilesSubPageProps> = ({ stand, lang }) =>
{
  const basePath = Stand.Const.basePath;
  const tableHelper = Stand.Helpers.TableFiles;

  const breadcrumbs = [
    {
      label: localizeByKey("BREADCRUMBS.Label_StandTypes"),
      path: `${basePath}`,
    },
    {
      label: stand?.title ?? localizeByKey("VARIABLE.Label_EMPTY"),
      path: `${basePath}/edit/${stand?.group_id}/${lang}`,
    },
    {
      label: localizeByKey("BREADCRUMBS.Label_Files"),
      path: `${basePath}/edit/${stand?.group_id}/${lang}/files`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const [ variables, setVariables ] = useState<Record<string, any>>({});
  const [ searchText, setSearchText ] = useState("");
  const [ dataModel, setDataModel ] = useState<any[]>([]);

  const {
    _setStandFileCreateUpdate,
    loadingMutationStandFileCreateUpdate,
    dataMutationStandFile,
  } = Stand.Hooks.fileCreateUpdate({
    variables,
  });

  const standFiles = dataMutationStandFile?.standTypeFileCreate?.standTypeFiles;

  const standFile = standFiles?.filter(
    (obj1: any) => !dataModel.some((obj2: any) => obj2.id === obj1.id)
  )[ 0 ];

  return (
    <Tables.Cursor
      model="standTypeFilesCursor"
      query={GET_STAND_TYPE_FILES_CURSOR}
      varSet={{ perPage: 50 }}
      tableHelper={tableHelper}
      currentObj={stand}
      setVariables={setVariables}
      searchText={searchText}
      setDataModel={setDataModel}
      objectWhere={{
        column: "STAND_TYPE_GROUP",
        operator: "EQ",
        value: parseInt(stand?.group_id ?? "0"),
      }}
      routeUrl={`${basePath}/edit/${stand?.group_id}/${lang}/files`}
    >
      <div className="table-action-bar">
        <div className="col">
          <Search
            className="filter-search"
            prefix={<Icons.Search />}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>

        <div className="col">
          <UploadFile
            accept="*/*"
            varSetMutation={{
              stand_type_group: stand?.group_id,
            }}
            setMutation={_setStandFileCreateUpdate}
            loading={loadingMutationStandFileCreateUpdate}
            uploadBtn={
              <Button type="primary" icon={<Icons.Plus />}>
                <span className="ellipsis">
                  <Localize>GLOBAL.Button_Text_UploadNew</Localize>
                </span>
              </Button>
            }
          />

          {standFiles && (
            <ModalStandard
              width={500}
              maskClosable={false}
              defaultShow={true}
              closable={false}
              extraClass={"modal-form"}
              modalTitle={
                <strong className="modal-title">
                  <span className="text">
                    <Localize>FORMS.Title_UploadFile</Localize>
                  </span>
                </strong>
              }
            >
              <Stand.Forms.EditFile
                stand={stand}
                standFile={standFile}
                variables={variables}
                documentCreate={true}
                action={() => { }}
              />
            </ModalStandard>
          )}
        </div>
      </div>
    </Tables.Cursor>
  );
};

export default StandFilesSubPage;