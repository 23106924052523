import React from "react";
import { Localize } from "components/service";
import { TEquipment } from "../helpers/equipment-types-helper";

interface IEquipmentArticleFieldProps
{
    equipment: TEquipment | undefined;
}

const EquipmentArticleField: React.FC<IEquipmentArticleFieldProps> = ({ equipment }) =>
{
    return (
        <div
            className="d-inline-flex justify-content-center align-items-center"
            style={{
                padding: "1px 15px",
                borderRadius: "10px",
                color: "#fff",
                background: "#4471FF",
                minWidth: "72px",
            }}
        >
            <span>
                <Localize>{equipment?.navision_number}</Localize>
            </span>
        </div>
    );
};

export default EquipmentArticleField;
