import React from "react";
import { Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { standUseMutation } from "../hooks";
import StandForms from "../forms/index";
import
{
  EntityRemoveButton,
  Localize,
  ModalStandard,
} from "components/service";
import { localizeByKey } from "components/service/localize";
import { TStand, TStandTypeNote } from "../helpers/stand-types-helper";
import Icons from "components/icons";


interface IStandNoteActionFieldProps
{
  stand: TStand | undefined;
  standNote: TStandTypeNote;
  variables: Record<string, any>;
}


const StandNoteActionField: React.FC<IStandNoteActionFieldProps> = ({ stand, standNote, variables }) =>
{
  const { _setMutationStandNoteDelete, loadingMutationStandNoteDelete } =
    standUseMutation.noteDelete({
      id: standNote?.id,
    });

  const items = [
    {
      key: "1",
      label: (
        <ModalStandard
          width={500}
          extraClass={"modal-form filters"}
          modalButton={
            <span style={{ display: "flex", alignItems: "center", width: 100 }}>
              <Icons.Edit /> <Localize>MENUS_DROP.Label_Edit</Localize>
            </span>
          }
          modalTitle={
            <strong className="modal-title">
              <span className="text">
                <Localize>FORMS.Title_Text_EditNote</Localize>
              </span>
            </strong>
          }
        >
          <StandForms.EditNote
            stand={stand}
            standNote={standNote}
            variables={variables}
            action={() => { }}
          />
        </ModalStandard>
      ),
    },
    {
      key: "2",
      label: (
        <EntityRemoveButton
          modalButton={
            <span style={{ display: "flex", alignItems: "center", width: 100 }}>
              <Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize>
            </span>
          }
          nameEntity={
            localizeByKey("ENTITY.Modal_Title_Note")
          }
          dataNameEntity={standNote?.description || ""}
          loading={loadingMutationStandNoteDelete}
          deleteMutation={_setMutationStandNoteDelete}
          variables={{
            id: standNote?.id,
          }}
        />
      ),
    },
  ];

  return (
    <Dropdown
      // trigger={['click']}
      placement="bottomRight"
      menu={{ items }}
      overlayClassName="drop-more-menu"
      arrow
    >
      <EllipsisOutlined className="btn-more" />
    </Dropdown>
  );
};

export default StandNoteActionField;
