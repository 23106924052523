import EquipmentFields from "./fields";
import EquipmentForms from "./forms";
import EquipmentConst from "./equipment-const";
import EquipmentsHelpers from "./helpers";
import { equipmentUseMutation } from "./hooks";
import EquipmentsFilters from './filters';

const Equipment = {
  Fields: EquipmentFields,
  Forms: EquipmentForms,
  Const: EquipmentConst,
  Helpers: EquipmentsHelpers,
  Hooks: equipmentUseMutation,
  Filters: EquipmentsFilters,
};

export default Equipment;
