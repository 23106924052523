import OrganizerInfoField from "./organizer-info-field";
import OrganizerActionField from "./organizer-action-field";
import OrganizerStatus from "./organizer-status-field";
import OrganizerImageField from "./organizer-image-field";

const OrganizerFields = {
  Info: OrganizerInfoField,
  Status: OrganizerStatus,
  Image: OrganizerImageField,
  Action: OrganizerActionField,
};

export default OrganizerFields;
