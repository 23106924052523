import UserChangePasswordForm from "./user-change-password-form";
import UserEditForm from "./user-edit-form";
import UserAddForm from "./user-add-form";
import UserFiltersForm from "./user-filters-form";
import UserFormItems from "./fields";


const UserForms = {
    ChangePassword: UserChangePasswordForm,
    Edit: UserEditForm,
    Add: UserAddForm,
    Filter: UserFiltersForm,
    Fields: UserFormItems,
};

export default UserForms;
