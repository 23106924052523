import { useMutation } from "@apollo/client";
import { VARIABLE_LIST_DELETE } from "graphql/mutation/variable-gql";
import { successNotification, errorNotification } from "components/request-result";


const useMutationVariableDelete = (id: string) =>
{

  const [ _setMutationVariableDelete, { loading } ] = useMutation(VARIABLE_LIST_DELETE,
    {
      update(cache, { data })
      {

        const {
          variableListDelete: {
            label,
            message
          }
        } = data;


        cache.evict({
          id: cache.identify({ id, __typename: "VariableList" })
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
      onError(error)
      {
        errorNotification(error);
      }
    });

  const handleDelete = async (options: { variables: Record<string, any> }) =>
  {
    await _setMutationVariableDelete(options);
  };

  return {
    _setMutationVariableDelete: handleDelete,
    loadingMutationVariableDelete: loading,
  };
};

export default useMutationVariableDelete;