import React, { useState } from "react";
import { useMutation, ApolloError } from "@apollo/client";
import { Upload, Modal, message, UploadFile } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { FILE_UPLOAD } from "graphql/mutation/image-gql";
import { Localize } from "components/service";
import
  {
    successNotification,
    errorNotification,
  } from "components/request-result";
import Icons from "components/icons";

import "./upload-logo.scss";

interface IUploadLogoProps
{
  defaultMedia?: string;
  modelId: string;
  model: string;
  modelField: string;
  query: any;
  label?: string;
  uploadButtonText?: string;
  uploadImgSize?: string;
  disabled?: boolean;
}

const UploadLogo: React.FC<IUploadLogoProps> = ({
  defaultMedia,
  modelId,
  model,
  modelField,
  query,
  label = "FORMS.Input_Label",
  uploadButtonText = "MODAL.Button_Text_Add",
  uploadImgSize = "MODAL.Button_Text_ImgSize",
  disabled = false,
}) =>
{
  const refetchQueries = [
    {
      query,
      variables: {
        id: modelId,
      },
    },
  ];
  const [ fileUploadModel, { loading } ] = useMutation(FILE_UPLOAD, {
    refetchQueries,
  });

  const [ filesLoading, setFilesLoading ] = useState(0);
  const [ previewImage, setPreviewImage ] = useState<string | null>(null);
  const [ previewVisible, setPreviewVisible ] = useState(false);

  const handlePreview = (file: any) =>
  {
    setPreviewImage(file.url);
    setPreviewVisible(true);
  };

  const handleCancel = () =>
  {
    setPreviewVisible(false);
  };

  const handleRemove = async (file: any) =>
  {
    if (file.status === "done") {
      try {
        message.loading("Deleting image...", 0);

        await fileUploadModel({
          variables: {
            input: {
              model,
              model_id: modelId,
              model_field: modelField,
            },
          },
          update: (cache, { data }) =>
          {
            const {
              fileUploadModel: { label, message },
            } = data;

            setTimeout(() =>
            {
              successNotification({
                title: label,
                description: message,
              });
            }, 2500);
          },
        });
      } catch (error) {
        errorNotification(error as ApolloError);
      } finally {
        setTimeout(() =>
        {
          message.destroy();
        }, 2000);
      }
    }
  };

  const fileList: UploadFile[] = defaultMedia
    ? [
      {
        uid: "1",
        name: "defaultMedia",
        url: defaultMedia,
        thumbUrl: defaultMedia,
        status: "done" as const,
      },
    ]
    : [];

  const formUploadProps = {
    accept: "image/*",
    beforeUpload: (file: any) =>
    {
      setFilesLoading((prevState) => prevState + 1);

      fileUploadModel({
        variables: {
          input: {
            model,
            model_id: modelId,
            model_field: modelField,
            file,
          },
        },
        update(cache, { data })
        {
          const { fileUploadModel } = data;
          const { label, message } = fileUploadModel;

          setFilesLoading((prevState) => prevState - 1);

          successNotification({
            title: label,
            description: message,
          });
        },
      }).catch((error) =>
      {
        setFilesLoading((prevState) => prevState - 1);
        errorNotification(error);
      });

      return false;
    },
    onRemove: handleRemove,
    fileList: fileList,
    listType: "picture-card" as const,
    multiple: false,
    onPreview: handlePreview,
    showUploadList: {
      showPreviewIcon: true,
      showDownloadIcon: false,
      previewIcon: <Icons.Zoom />,
      removeIcon: <Icons.Delete />,
    },
  };

  return (
    <div className="media-logo-holder">
      <span className="label-text">
        <Localize>{label}</Localize>
      </span>

      <Upload {...formUploadProps} disabled={disabled}>
        <div>
          {loading || filesLoading > 0 ? (
            <LoadingOutlined />
          ) : (
            <>
              <div className="site-badge">
                <Icons.Plus />
              </div>
              <div className="site-icon">
                <Localize>{uploadButtonText}</Localize>
                <br />
                <Localize>{uploadImgSize}</Localize>
              </div>
            </>
          )}
        </div>
      </Upload>

      <Modal
        className="modal-image"
        width={800}
        open={previewVisible}
        closeIcon={<Icons.Close />}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="Preview" style={{ width: "100%" }} src={previewImage!} />
      </Modal>
    </div>
  );
};

export default UploadLogo;