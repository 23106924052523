import { useMutation } from "@apollo/client";
import { STAND_TYPE_EQUIPMENT_DELETE } from "graphql/mutation/stand-gql";
import
{
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationStandEquipmentDelete = (id: string | undefined) =>
{
  const [ _setMutationStandEquipmentDelete, { loading } ] = useMutation(
    STAND_TYPE_EQUIPMENT_DELETE,
    {
      update(cache, { data })
      {
        const {
          standTypeEquipmentDelete: { label, message },
        } = data;

        cache.evict({
          id: cache.identify({ id, __typename: "StandTypeEquipment" }),
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message,
        });
      },
      onError(error)
      {
        errorNotification(error);
      },
    }
  );

  const handleDelete = async (options: { variables: Record<string, any> }) =>
  {
    await _setMutationStandEquipmentDelete(options);
  };

  return {
    _setMutationStandEquipmentDelete: handleDelete,
    loadingMutationStandEquipmentDelete: loading,
  };
};

export default useMutationStandEquipmentDelete;
