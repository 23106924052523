import { useNavigate } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { VARIABLE_GROUP_DELETE } from "graphql/mutation/variable-gql";
import VariableConst from '../variable-const';
import { successNotification, errorNotification } from "components/request-result";


const useMutationVariableGroupDelete = () =>
{


  const navigate = useNavigate();

  const [ _setMutationVariableGroupDelete, { loading } ] = useMutation(VARIABLE_GROUP_DELETE,
    {
      update(cache, { data })
      {

        const {
          variableListGroupDelete: {
            label,
            message
          }
        } = data;

        navigate(VariableConst.basePath);

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
      onError(error)
      {
        errorNotification(error);
      }
    });

  const handleDelete = async (options: { variables: Record<string, any> }) =>
  {
    await _setMutationVariableGroupDelete(options);
  };

  return {
    _setMutationVariableGroupDelete: handleDelete,
    loadingMutationVariableGroupDelete: loading,
  };
};

export default useMutationVariableGroupDelete;