import { gql } from "@apollo/client";

export const GET_TEAMS_CURSOR = gql`
  query TeamsCursor(
    $text: String
    $where: QueryTeamsCursorWhereWhereConditions
    $first: Int
    $after: String
  ) {
    teamsCursor(text: $text, where: $where, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        total
        count
        currentPage
        lastPage
      }
      edges {
        cursor
        node {
          id
          title
        }
      }
    }
  }
`;

export const GET_TEAMS = gql`
  query GetTeams(
    $text: String
    $first: Int
    $page: Int
    $where: QueryTeamsWhereWhereConditions
    $orderBy: [QueryTeamsOrderByOrderByClause!]
  ) {
    teams(
      text: $text
      first: $first
      page: $page
      where: $where
      orderBy: $orderBy
    ) {
      paginatorInfo {
        total
        count
        currentPage
        perPage
        lastPage
      }
      data {
        id
        title
        head_id
        count_project_manager
        count_draftsmen
        count_construction_manager
        relHead {
          id
          supplier_id
          surname
          name
        }
        relTeamUsers {
          id
          team_id
          user_id
          team_role
          relUser {
            id
            name
            surname
          }
        }
        created_at
        updated_at
      }
    }
  }
`;

export const GET_TEAM = gql`
  query GetTeam($id: ID) {
    team(id: $id) {
      id
      title
      head_id
      count_project_manager
      count_draftsmen
      count_construction_manager
      relHead {
        id
        supplier_id
        surname
        name
      }
      relTeamUsers {
        id
        team_id
        user_id
        team_role
        relUser {
          id
          name
          surname
        }
      }
      created_at
      updated_at
    }
  }
`;
