import ExhibitionOverviewSubPage from "./exhibition-owerview-sub-page";
import ExhibitionStandSubPage from "./exhibition-stands-sub-page";
import ExhibitionEquipmentSubPage from "./exhibition-equipments-sub-page";
import ExhibitionFlooringSubPage from "./exhibition-floorings-sub-page";

const SubPage = {
  OverView: ExhibitionOverviewSubPage,
  Stand: ExhibitionStandSubPage,
  Equipment: ExhibitionEquipmentSubPage,
  Flooring: ExhibitionFlooringSubPage,
};

export default SubPage;
