import { makeVar, ReactiveVar } from "@apollo/client";

import { IPublicReactiveVar } from "./reactive-var-interfaces";
import packageInfo from '../../../package.json';

const version = packageInfo.version;


export const publicParamsInitial: IPublicReactiveVar = {
    mainBlockClass : [],
    appBlockClass : [],
    defaultLanguage : "en",
    appVersion : version,
};

export const publicReactiveVar : ReactiveVar<IPublicReactiveVar> = makeVar<IPublicReactiveVar>(publicParamsInitial);