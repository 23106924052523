import { useMutation } from "@apollo/client";
import { EQUIPMENT_REMOVE_SUBSTITUDE } from "graphql/mutation/equipment-gql";
import { GET_EQUIPMENT_CURSOR } from "graphql/query/equipment-gql";

import
{
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationEquipmentRemove = (variables: Record<string, any>) =>
{
  const [ _setMutationEquipmentRemove, { loading } ] = useMutation(
    EQUIPMENT_REMOVE_SUBSTITUDE,
    {
      update(cache, { data })
      {
        const {
          equipmentRemoveSubstitude: { label, message },
        } = data;

        successNotification({
          title: label.toUpperCase(),
          description: message,
        });
      },

      refetchQueries: [
        {
          query: GET_EQUIPMENT_CURSOR,
          variables,
        },
      ],

      onError(error)
      {
        errorNotification(error);
      },
    }
  );

  const handleRemoveUser = async (options: { variables: Record<string, any> }) =>
  {
    await _setMutationEquipmentRemove(options);
  };

  return {
    _setMutationEquipmentRemove: handleRemoveUser,
    loadingMutationEquipmentRemove: loading,
  };
};

export default useMutationEquipmentRemove;
