import React from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { GET_USERS_CURSOR } from "graphql/query/user-gql";

import { teamUseMutation } from "../../hooks";

import User from "components/user";
import { Localize, EntityRemoveButton } from "components/service";
import { localizeByKey } from "components/service/localize";
import { FormElements } from "components/layout";
import { TTeam } from "components/team/helpers/team-types-helper";
import Icons from "components/icons";

interface ITeamEditFormProps
{
  team: TTeam | undefined;
}

interface ITeamData
{
  [ role: string ]: number[];
}

interface IInitialValueItem
{
  value: string;
  label: string;
}

const TeamEditForm: React.FC<ITeamEditFormProps> = ({ team }) =>
{
  const [ form ] = Form.useForm();

  const { _setTeamCreateUpdate, loadingMutationTeamCreateUpdate } =
    teamUseMutation.createUpdate(team?.id);

  const { _setMutationTeamDelete, loadingMutationTeamDelete } =
    teamUseMutation.delete(team?.id);

  function generateTeamData(data: ITeamData)
  {
    const result: { team_role: string; user_id: number }[] = [];

    for (const role in data) {
      const users = data[ role ];

      if (Array.isArray(users)) {
        users.forEach((userId) =>
        {
          result.push({
            team_role: role,
            user_id: userId,
          });
        });
      }
    }

    return result;
  }

  function getInitialValueForRole(role: string): IInitialValueItem[] | undefined
  {
    return team?.relTeamUsers.length
      ? team?.relTeamUsers
        .filter((item) => item.team_role === role)
        .map(({ relUser: { id, name, surname } }) => ({
          value: id,
          label: `${name} ${surname}`,
        }))
      : undefined;
  }

  const valueFieldUser = Form.useWatch("head_id", form);

  return (
    <Form
      key="edit-team-form"
      layout="vertical"
      form={form}
      className="model-form edit-team-form"
      onFinish={(values) =>
      {
        _setTeamCreateUpdate({
          variables: {
            input: {
              id: team?.id ? +team.id : undefined,
              title: values.title,
              head_id: values.head_id,
              team_user: generateTeamData(values),
            },
          },
        });
      }}
    >
      <div className="row-grid grid-gap-10 row-gap-0 col-md-2">
        <Form.Item
          name="title"
          label={<Localize>FORMS.Input_Label_TeamName</Localize>}
          initialValue={team?.title}
          rules={[
            {
              required: true,
              message: <Localize>FORM_RULES.Required_TeamName</Localize>,
            },
          ]}
        >
          <Input name="title" />
        </Form.Item>

        <FormElements.RadioSelect
          form={form}
          query={GET_USERS_CURSOR}
          name="head_id"
          model="usersCursor"
          modelCursorLabel={[ "name", "surname" ]}
          label="FORMS.Input_Label_Head"
          modalTitle="FORMS.Title_AddHead"
          tagInfo={
            valueFieldUser && (
              <Link
                className="select-link"
                to={`${User.Const.basePath}/${valueFieldUser}`}
              >
                <Icons.Information />
              </Link>
            )
          }
          initialValue={
            team?.relHead?.id
              ? [
                {
                  value: team.head_id,
                  label: `${team.relHead.name} ${team.relHead.surname}`,
                },
              ]
              : undefined
          }
        />
      </div>

      {team?.id && (
        <>
          <FormElements.CheckboxSelect
            form={form}
            query={GET_USERS_CURSOR}
            model="usersCursor"
            name="project_manager"
            label="FORMS.Input_Label_ProjectManagers"
            modalTitle="FORMS.Title_AddProjectManagers"
            modalButtonText="MODAL.Button_Text_AddMore"
            modelCursorLabel={[ "name", "surname" ]}
            initialValue={getInitialValueForRole("project_manager")}
          />

          <FormElements.CheckboxSelect
            form={form}
            query={GET_USERS_CURSOR}
            model="usersCursor"
            name="construction_manager"
            label="FORMS.Input_Label_ConstructionManagers"
            modalTitle="FORMS.Title_AddConstructionManagers"
            modalButtonText="MODAL.Button_Text_AddMore"
            modelCursorLabel={[ "name", "surname" ]}
            initialValue={getInitialValueForRole("construction_manager")}
          />

          <FormElements.CheckboxSelect
            form={form}
            query={GET_USERS_CURSOR}
            model="usersCursor"
            name="draftsmen"
            label="FORMS.Input_Label_AutocadEngineers"
            modalTitle="FORMS.Title_AddAutocadEngineers"
            modalButtonText="MODAL.Button_Text_AddMore"
            modelCursorLabel={[ "name", "surname" ]}
            initialValue={getInitialValueForRole("draftsmen")}
          />
        </>
      )}

      <div className="form-btn-holder">
        {team?.id && (
          <EntityRemoveButton
            modalButton={
              <Button icon={<Icons.Delete />}>
                <span className="ellipsis">
                  <Localize>TEAM.Button_Text_Delete</Localize>
                </span>
              </Button>
            }
            nameEntity={
              localizeByKey("ENTITY.Modal_Title_Team")
            }
            dataNameEntity={`${team?.title}`}
            loading={loadingMutationTeamDelete}
            deleteMutation={_setMutationTeamDelete}
            variables={{
              id: team?.id,
            }}
          />
        )}
        <Button
          className="btn-right"
          type="primary"
          htmlType="submit"
          icon={team?.id ? <Icons.Edit /> : <Icons.Plus />}
          loading={loadingMutationTeamCreateUpdate}
        >
          <span className="ellipsis">
            {team?.id ? (
              <Localize>GLOBAL.Button_Text_SaveChanges</Localize>
            ) : (
              <Localize>GLOBAL.Button_Text_CreateNew</Localize>
            )}
          </span>
        </Button>
      </div>
    </Form>
  );
};

export default TeamEditForm;