import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Localize } from "components/service";
import ExhibitionConst from "../exhibition-const";
import type { TExhibitionType } from "components/exhibition/helpers/exhibition-types-helper";

interface IExhibitionInfoFieldProps
{
  exhibition: TExhibitionType | undefined;
}


const ExhibitionInfoField: React.FC<IExhibitionInfoFieldProps> = ({ exhibition }) => {

  const { pathname } = useLocation();

  const border = pathname.includes("/organizers/");

  useEffect(() => {
    const rows = document.querySelectorAll(".ant-table-row");

    rows.forEach((row) => {
      const defaultElement = row.querySelector(".badge");

      if (defaultElement && border) {
        row.classList.add("with-border");
      } else {
        row.classList.remove("with-border");
      }
    });
  }, [exhibition, border]);
  return (
    <div className="d-flex align-items-center">
      <Link
        className="table-link-underline"
        to={`${ExhibitionConst.basePath}/${exhibition?.id}`}
      >
        {exhibition?.title}
      </Link>

      {exhibition?.default && (
        <strong className="badge">
          <Localize>CARD.Info_List_Default</Localize>
        </strong>
      )}
    </div>
  );
};

export default ExhibitionInfoField;
