import RoleOverviewSubPage from "./role-owerview-sub-page";
import RoleUsersSubPage from "./role-users-sub-page";


const SubPage = {
    OverView: RoleOverviewSubPage,
    Users: RoleUsersSubPage
};

export default SubPage;
