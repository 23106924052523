import React from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { organizerUseMutation } from "../hooks";
import OrganizerConst from "../organizer-const";
import { EntityRemoveButton, Localize } from "components/service";
import { localizeByKey } from "components/service/localize";
import type { TOrganizer } from "components/organizer/helpers/organizer-types-helper";
import Icons from "components/icons";


interface IOrganizerActionFieldProps
{
  organizer: TOrganizer | undefined;
}


const OrganizerActionField: React.FC<IOrganizerActionFieldProps> = ({ organizer }) =>
{
  const navigate = useNavigate();

  const { _setMutationOrganizerDelete, loadingMutationOrganizerDelete } =
    organizerUseMutation.delete(organizer?.id);

  const items = [
    {
      label: <Localize>MENUS_DROP.Label_Edit</Localize>,
      key: "1",
      icon: <Icons.Edit />,
      onClick: () =>
        navigate(`${OrganizerConst.basePath}/${organizer?.id}/`),
    },
    {
      key: "2",
      label: (
        <EntityRemoveButton
          modalButton={
            <span style={{ display: "flex", alignItems: "center", width: 100 }}>
              <Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize>
            </span>
          }
          nameEntity={
            localizeByKey("ENTITY.Modal_Title_Organizer")
          }
          dataNameEntity={organizer?.title}
          loading={loadingMutationOrganizerDelete}
          deleteMutation={_setMutationOrganizerDelete}
          variables={{
            id: organizer?.id,
          }}
        />
      ),
    },
  ];

  return (
    <Dropdown
      // trigger={['click']}
      placement="bottomRight"
      menu={{ items }}
      overlayClassName="drop-more-menu"
      arrow
    >
      <EllipsisOutlined className="btn-more" />
    </Dropdown>
  );
};

export default OrganizerActionField;
