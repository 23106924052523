import React from "react";
import Graphic from "components/graphic";
import { useBreadCrumbs } from "components/use-hooks";
import { localizeByKey } from "components/service/localize";
import type { TGraphicSupplier } from "components/graphic/helpers/graphic-types-helper";
const graphicBasePath = Graphic.Const.basePath;


interface IGraphicOverviewSubPageProps
{
  graphic: TGraphicSupplier | undefined;
}


const GraphicOverviewSubPage: React.FC<IGraphicOverviewSubPageProps> = ({ graphic }) =>
{
  const breadcrumbs = [
    {
      label: localizeByKey("BREADCRUMBS.Label_GraphicRules"),
      path: `${graphicBasePath}`,
    },
    {
      label:
        (graphic && graphic?.title) ||
        localizeByKey("BREADCRUMBS.Label_Create"),
      path:
        (graphic && `${graphicBasePath}/${graphic?.id}`) ||
        `${graphicBasePath}/create`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  return (
    <div className="row-grid col-lg-2">
      <div className="col-left">
        <Graphic.Forms.Edit graphic={graphic} />
      </div>
      <div className="col-right"></div>
    </div>
  );
};

export default GraphicOverviewSubPage;
