import TeamFields from "./fields";
import TeamForms from "./forms";
import TeamConst from "./team-const";
import TeamsHelpers from "./helpers";
// import TeamsFilters from './filters';
import { teamUseMutation } from "./hooks";


const Team = {
  // Filters: TeamsFilters,
  Fields: TeamFields,
  Forms: TeamForms,
  Const: TeamConst,
  Helpers: TeamsHelpers,
  Hooks: teamUseMutation
};

export default Team;
