import React from "react";
import EquipmentFields from "../fields";
import { Localize } from "components/service";
import { TEquipment } from "../helpers/equipment-types-helper";

interface EquipmentColumn
{
    title?: React.ReactNode;
    dataIndex: string;
    columnIndex?: string;
    width?: string;
    sorter?: boolean;
    align?: "right" | "left" | "center";
}

const equipmentColumns: EquipmentColumn[] = [
    {
        title: <Localize>TABLES.Column_Title_Image</Localize>,
        dataIndex: "image",
        columnIndex: "IMAGE",
        width: "6%",
    },
    {
        title: <Localize>TABLES.Column_Title_Name</Localize>,
        dataIndex: "title",
        columnIndex: "TITLE",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_NavisionNumb</Localize>,
        dataIndex: "navision_number",
        columnIndex: "NAVISION_NUMBER",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_OctacadNumb</Localize>,
        dataIndex: "octacad_number",
        columnIndex: "OCTACAD_NUMBER",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Color</Localize>,
        dataIndex: "color",
        columnIndex: "COLOR",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_SupplierNumb</Localize>,
        dataIndex: "supplier_article",
        columnIndex: "SUPPLIER_ARTICLE",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Supplier</Localize>,
        dataIndex: "supplier",
        columnIndex: "SUPPLIER",
    },
    {
        title: <Localize>TABLES.Column_Title_ContentLang</Localize>,
        dataIndex: "lang",
        columnIndex: "LANG",
        sorter: true,
    },
    {
        dataIndex: "action",
        align: "right",
        width: "5%",
    },
];

const EquipmentData = (
    equipment: TEquipment[],
    variables: Record<string, any>,
    language: string,
    extraObj: any
) =>
{
    if (!equipment) {
        return [];
    }

    return equipment.map((equipment) =>
    {
        return {
            key: equipment.id,
            image: <EquipmentFields.Image equipment={equipment} lang={language} />,
            title: <EquipmentFields.Info equipment={equipment} lang={language} />,
            navision_number: <EquipmentFields.Article equipment={equipment} />,
            octacad_number: equipment?.octacad_number ?? <strong>&mdash;</strong>,
            color: <EquipmentFields.Color equipment={equipment} disabledColor={extraObj} />,
            supplier_article: equipment?.supplier_article ?? <strong>&mdash;</strong>,
            supplier: <EquipmentFields.Supplier equipment={equipment} />,
            lang: <EquipmentFields.Langs equipment={equipment} />,
            action: <EquipmentFields.Action equipment={equipment} variables={variables} language={language} />,
        };
    });
};

const EquipmentsTableHelper = {
    columns: equipmentColumns,
    data: EquipmentData,
};

export default EquipmentsTableHelper;