import { Localize } from "components/service";

interface IPageInfo
{
    endCursor: string | null;
    hasNextPage?: boolean;
}

interface INodeType
{
    [ key: string ]: any;
}

interface IEdge
{
    node: INodeType;
}

interface IDataModel
{
    pageInfo: IPageInfo;
    __typename: string;
    edges: IEdge[];
}

interface IFetchMoreResult
{
    [ key: string ]: {
        edges: IEdge[];
        pageInfo: IPageInfo;
        __typename: string;
    };
}

interface ICursorPaginationParams
{
    model: string;
    data: {
        [ key: string ]: IDataModel;
    } | null;
    loading: boolean;
    fetchMore: (params: { variables: { after: string | null | undefined }; updateQuery: (previousResult: any, options: { fetchMoreResult: IFetchMoreResult }) => any }) => void;
    label?: string[];
    value?: string;
    img_path?: string;
    extraProp?: string;
    initialLang?: boolean;
    jsx?: (imgPath?: string) => JSX.Element;
}

const cursorPagination = ({
    model,
    data,
    loading,
    fetchMore,
    label = [ "title" ],
    value = "id",
    img_path = "img_path",
    extraProp = "extraProp",
    initialLang = false,
    jsx = () => <></>,
}: ICursorPaginationParams) =>
{
    const {
        [ model ]: { pageInfo = null, __typename: typename = '', edges: node = [] } = {},
    } = data ?? {};

    const nodes = node.map(({ node }) => ({
        value: node[ value! ],
        img_path: node[ img_path! ],
        extraProp: node?.[ extraProp! ],
        label:
            model === "usersCursor" ? (
                `${node[ label[ 0 ] ]}${node[ label[ 1 ] ] ? ` ${node[ label[ 1 ] ]}` : ""}`
            ) : (
                <div className="d-flex align-items-center">
                        {jsx && jsx(eval(`node?.${img_path}`))}

                    <span>
                        <Localize initialLang={initialLang}>
                            {node[ label[ 0 ] ]}
                        </Localize>

                        <div
                            className="additional-label"
                            style={{ color: "var(--stormGray)", lineHeight: 1 }}
                        >
                            {node[ label[ 1 ] ] ? ` ${node[ label[ 1 ] ]}` : ""}
                        </div>
                    </span>
                </div>
            ),
    }));

    const fetchMoreAction = () =>
    {
        if (!loading && data) {
            fetchMore({
                variables: {
                    after: pageInfo?.endCursor,
                },
                updateQuery: (previousResult, { fetchMoreResult }) =>
                {
                    const newEdges = fetchMoreResult[ model ]?.edges;
                    const pageInfo = fetchMoreResult[ model ]?.pageInfo;

                    const newData = {
                        [ model ]: {
                            edges: [ ...node, ...newEdges ],
                            __typename: typename,
                            pageInfo,
                        },
                    };

                    return newEdges.length ? newData : previousResult;
                },
            });
        }
    };

    const onPopupScroll = (e: React.UIEvent<HTMLDivElement>) =>
    {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollTop + clientHeight >= scrollHeight - 150) {
            fetchMoreAction();
        }
    };

    return {
        onPopupScroll,
        fetchMoreAction,
        node,
        nodes,
        pageInfo,
    };
};

export default cursorPagination;