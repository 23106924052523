import React from "react";
import { Link } from 'react-router-dom';
import TeamConst from '../team-const';
import { TTeam } from 'components/team/helpers/team-types-helper';


interface ITeamInfoFieldProps
{
    team: TTeam
}

const TeamInfoField: React.FC<ITeamInfoFieldProps> = ({ team }) => <Link className="table-link-underline" to={`${TeamConst.basePath}/${team?.id}/`}> {`${team?.title}`}</Link>;

export default TeamInfoField;