import { gql } from "@apollo/client";

export const GRAPHIC_SUPPLIER_CREATE = gql`
  mutation GraphicSupplierCreate($input: GraphicSupplierFieldsInput!) {
    graphicSupplierCreate(input: $input) {
      label
      message
      graphicSupplier {
        id
        title
        organizer_id
        supplier_id
        graphicSupplierVariableList {
          id
          group_name
          graphic_supplier_id
          slug
        }
        organizer {
          id
          title
          logo_path
          et_default_id
          status
          total_stands
          total_equipment
          total_floorings
        }
        supplier {
          id
          title
          number
          type
          usersCount
          description
        }
      }
    }
  }
`;

export const GRAPHIC_SUPPLIER_UPDATE = gql`
  mutation GraphicSupplierUpdate($input: GraphicSupplierFieldsInput!) {
    graphicSupplierUpdate(input: $input) {
      label
      message
      graphicSupplier {
        id
        title
        organizer_id
        supplier_id
        graphicSupplierVariableList {
          id
          group_name
          graphic_supplier_id
          slug
        }
        organizer {
          id
          title
          logo_path
          et_default_id
          status
          total_stands
          total_equipment
          total_floorings
        }
        supplier {
          id
          title
          number
          type
          usersCount
          description
        }
      }
    }
  }
`;

export const GRAPHIC_SUPPLIER_DELETE = gql`
  mutation GraphicSupplierDelete($id: ID!) {
    graphicSupplierDelete(id: $id) {
      label
      message
    }
  }
`;

export const GRAPHIC_SUPPLIER_RULE_CREATE = gql`
  mutation GraphicSupplierRuleCreate($input: GraphicSupplierRuleFieldsInput!) {
    graphicSupplierRuleCreate(input: $input) {
      label
      message
      graphicSupplierRule {
        id
        title
        graphic_supplier_id
        price_manufacture
        price_on_supplier
        price_on_stand
        price_additional
        free_symbols
        lettering
        created_at
        updated_at
      }
    }
  }
`;

export const GRAPHIC_SUPPLIER_RULE_UPDATE = gql`
  mutation GraphicSupplierRuleUpdate($input: GraphicSupplierRuleFieldsInput!) {
    graphicSupplierRuleUpdate(input: $input) {
      label
      message
      graphicSupplierRule {
        id
        title
        graphic_supplier_id
        price_manufacture
        price_on_supplier
        price_on_stand
        price_additional
        free_symbols
        lettering
        created_at
        updated_at
      }
    }
  }
`;

export const GRAPHIC_SUPPLIER_RULE_DELETE = gql`
  mutation GraphicSupplierRuleDelete($id: ID!) {
    graphicSupplierRuleDelete(id: $id) {
      label
      message
    }
  }
`;
