import { useMutation } from "@apollo/client";
import { GET_FLOORING_CURSOR } from "graphql/query/flooring-gql";
import { STAND_TYPE_FLOORING_UPDATE } from "graphql/mutation/stand-gql";
import
  {
    successNotification,
    errorNotification,
  } from "components/request-result";

const useMutationStandTypeFlooringUpdate = (variables: Record<string, any>) =>
{
  const [ _setStandTypeFlooringUpdate, { loading } ] = useMutation(
    STAND_TYPE_FLOORING_UPDATE,
    {
      update(cache, { data })
      {
        const {
          standTypeFlooringUpdate: { label, message },
        } = data;

        successNotification({
          title: label,
          description: message,
        });
      },
      onError(error)
      {
        errorNotification(error);
      },
      refetchQueries: [
        {
          query: GET_FLOORING_CURSOR,
          variables,
        },
      ],
    }
  );

  return {
    _setStandTypeFlooringUpdate,
    loadingMutationStandTypeFlooringUpdate: loading,
  };
};

export default useMutationStandTypeFlooringUpdate;
