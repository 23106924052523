import deutsch from "./de";
import english from "./en";

import type { ITranslationsContainer } from "common/types";


const translation : ITranslationsContainer = {
    de : deutsch,
    en : english
};

export default translation;
