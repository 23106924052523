import React, { ReactNode } from "react";
import { Table, TableProps } from 'antd';

interface ITableHelper {
    data: (mockData: boolean) => any[];
    columns: TableProps<any>['columns'];
}

interface ITableMockProps {
    mockData?: boolean;
    tableHelper: ITableHelper;
    children?: ReactNode;
    tableVisible?: boolean;
    tableLayout?: 'auto' | 'fixed';
}

const TableMock: React.FC<ITableMockProps> = ({
    mockData = false,
    tableHelper,
    children,
    tableVisible = true,
    tableLayout = 'fixed',
}) => {

    const dataSource = tableHelper.data(mockData);

    return (
        <>
            { children }
            { tableVisible &&
                <Table
                    className="table-main"
                    dataSource={ dataSource }
                    columns={ tableHelper.columns }
                    tableLayout={ tableLayout }
                    // size="middle"
                />
            }
        </>
    );
};

export default TableMock;
