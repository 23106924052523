import UserFields from "../fields";
import { Localize } from "components/service";
import { ReactNode } from "react";
import { TUser } from '../helpers/user-types-helper';


type TColumn = {
    title?: ReactNode;
    dataIndex?: string;
    columnIndex?: string;
    align?: "left" | "right" | "center";
    width?: string;
    sorter?: boolean;
};

type TUserDataFunc = (users: TUser[] | null | undefined) => TDataItem[];

type TDataItem = {
    key: string;
    id: string;
    name: ReactNode;
    email: ReactNode;
    phone: ReactNode;
    supplier_id: ReactNode;
    job_type: ReactNode;
    roles: ReactNode;
    teams: ReactNode;
    status: ReactNode;
    action: ReactNode;
};

const usersColumns: TColumn[] = [
    {
        title: <Localize>TABLES.Column_Title_ID</Localize>,
        dataIndex: "id",
        columnIndex: "ID",
        sorter: true,
        width: "4%",
    },
    {
        title: <Localize>TABLES.Column_Title_Name</Localize>,
        dataIndex: "name",
        columnIndex: "NAME",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Email</Localize>,
        dataIndex: "email",
        columnIndex: "EMAIL",
        width: "13%",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Phone</Localize>,
        dataIndex: "phone",
        columnIndex: "PHONE",
        // sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Supplier</Localize>,
        dataIndex: "supplier_id",
        columnIndex: "SUPPLIER",
        // sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_JobType</Localize>,
        dataIndex: "job_type",
        columnIndex: "JOB_TYPE",
        sorter: true,
    },
    {
        title: <Localize>TABLES.Column_Title_Roles</Localize>,
        dataIndex: "roles",
        columnIndex: "ROLES",
        // sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Teams</Localize>,
        dataIndex: "teams",
        columnIndex: "TEAMS",
        // sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Status</Localize>,
        dataIndex: "status",
        columnIndex: "STATUS",
        // sorter: true
    },
    {
        // title: '',
        dataIndex: "action",
        align: "right",
        width: "5%",
    },
];

const usersData: TUserDataFunc = (users) =>
{
    if (!users) {
        return [];
    }

    return users.map((user) =>
    {
        return {
            key: user.id,
            id: user.id,
            name: <UserFields.Info user={user} />,
            email: user.email ? (
                <a href={`mailto:${user.email}`}>{user.email}</a>
            ) : (
                <strong>&mdash;</strong>
            ),
            phone: user.phone ?? <strong>&mdash;</strong>,
            supplier_id: <UserFields.Supplier user={user} />,
            job_type: user.job_type ?? <strong>&mdash;</strong>,
            roles: <UserFields.Role role={user.roles} />,
            teams: <UserFields.Team team={user.teams} />,
            status: <UserFields.Status id={user.id} status={user.status} />,
            action: <UserFields.Action userID={user.id} userName={user.name} />,
        };
    });
};

const UsersTableHelper = {
    columns: usersColumns,
    data: usersData,
};

export default UsersTableHelper;
