import React, { useState, useEffect } from "react";
import { Waypoint } from "react-waypoint";
import { Form, Radio, Skeleton, FormInstance } from "antd";
import type { Rule } from "antd/es/form";
import { useQuery, DocumentNode } from "@apollo/client";
import { ModalStandard, cursorPagination, Localize } from "components/service";
import { localizeByKey } from "components/service/localize";
import { useDebounce } from "components/use-hooks";
import { Loader } from "components/request-result";
import Icons from "components/icons";
import { helperFunc } from "common/utils";
import { TStandTypeOrganizerExhibitionType } from "components/stand/helpers/stand-types-helper";
import MultiSelect from "../multi-select";

import "./radio-select.scss";

interface IInitialValueItem
{
    value: string;
    label?: string;
}

interface INode
{
    value: string;
    label?: string;
    extraProp?: string;
    organizer_id?: string;
}

interface IRadioSelectProps
{
    form: FormInstance;
    initialValue?: IInitialValueItem[];
    model: string;
    name: string;
    query: DocumentNode;
    disabled?: boolean;
    location?: boolean;
    tagInfo?: React.ReactNode;
    onChangeSetFilter?: (arg1: boolean, arg2: string, arg3: string) => void;
    modalTitle?: string;
    label?: string;
    modelCursorLabel?: string[];
    objectWhere?: object;
    extraVariables?: object;
    skipQuery?: boolean;
    modalButtonContent?: (setSkip: React.Dispatch<React.SetStateAction<boolean>>) => React.ReactNode;
    setLabelModel?: (labelModel: string[]) => void;
    setExtraProp?: (extraPropModel: string[]) => void;
    setBtnConfirm?: () => void;
    formResetConfirm?: () => void;
    filteredNodes?: INode[] | TStandTypeOrganizerExhibitionType[];
    rules?: Rule[];
    extraProp?: string;
}

const RadioSelect: React.FC<IRadioSelectProps> = ({
    form,
    initialValue = [],
    model,
    name,
    query,
    disabled = false,
    location = false,
    tagInfo = false,
    onChangeSetFilter = () => { },
    modalTitle = "FORMS.Title_AddData",
    label = "FORMS.Input_Label",
    modelCursorLabel = [ "title" ],
    objectWhere = {},
    extraVariables = {},
    skipQuery = true,
    modalButtonContent = () => null,
    setLabelModel = () => { },
    setExtraProp = () => { },
    setBtnConfirm = () => { },
    formResetConfirm = () => { },
    filteredNodes = [],
    rules = [],
    extraProp = "extraProp",
}) =>
{

    const [ skip, setSkip ] = useState(skipQuery);

    const dataInit = Array.isArray(initialValue)
        ? initialValue.map((modelObj) => modelObj.value.toString())
        : "";
    const value = Form.useWatch(name, form);
    const [ searchValue, setSearchValue ] = useState("");

    const { data, loading, fetchMore } = useQuery(query, {
        skip,
        variables: {
            text: useDebounce(searchValue),
            first: 50,
            where: objectWhere,
            ...extraVariables,
        },
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
    });

    const { fetchMoreAction, nodes, pageInfo } = cursorPagination({
        label: modelCursorLabel,
        model,
        data,
        loading,
        fetchMore,
        extraProp,
    });

    // @ts-expect-error: nodes and initialValue might have different structures
    const nodesAndInitValue: INode[] = [
        ...nodes,
        ...(Array.isArray(initialValue) ? initialValue : []),
    ];

    nodes.unshift({
        value: "",
        label: <Localize>FORMS.Select_OptionLabel_NotAssigned</Localize>,
        img_path: "",
        extraProp: ""
    });

    const onCancel = () =>
    {
        if (value !== dataInit[ 0 ]) {
            form.setFieldsValue({ [ name ]: dataInit[ 0 ] });
        }
    };

    const labelModel = helperFunc
        ?.getLabel(value, nodesAndInitValue)
        ?.map((node: INode) => node.label)
        .filter((label): label is string => label !== undefined);

    const extraPrpoModel = helperFunc
        ?.getLabel(value, nodesAndInitValue)
        ?.map((node: INode) => node.extraProp)
        .filter((extraProp): extraProp is string => extraProp !== undefined);

    /*eslint-disable */
    useEffect(() =>
    {
        setLabelModel(labelModel);
        setExtraProp(extraPrpoModel);
    }, [ value ]);
    /*eslint-enable */

    const filteredNodesArray = filteredNodes &&
        nodes.filter(
            (obj2) =>
                !filteredNodes.some((obj1) => obj1.organizer_id === obj2.value)
        );

    return (
        <Form.Item
            initialValue={initialValue.length ? dataInit[ 0 ] : ""}
            name={name}
            label={localizeByKey(label)}
            rules={rules}
        >
            <ModalStandard
                disabled={disabled}
                width={400}
                extraClass={"modal-form"}
                afterClose={() =>
                {
                    // setSearchValue("");
                }}
                onCancel={onCancel}
                modalButton={
                    <div className="d-flex align-items-center w-100">
                        {modalButtonContent(setSkip) || (
                            <span
                                onClick={() =>
                                {
                                    setSkip(false);
                                }}
                                className={`select-model-modal-button ${disabled && "disabled"
                                    } ${location ? "light-theme" : "dark-theme"
                                    } w-100`}
                            >
                                {value ? (
                                    <span>{labelModel}</span>
                                ) : location ? (
                                    <span className="placeholder-text">
                                        <Localize>
                                            FORMS.Input_Placeholder_PleaseChose
                                        </Localize>
                                    </span>
                                ) : (
                                    <span className="placeholder-text">
                                        <Localize>
                                            FORMS.Input_Placeholder_NotAssigned
                                        </Localize>
                                    </span>
                                )}

                                {tagInfo}

                                <Icons.Arrow className="arrow-down" />
                            </span>
                        )}
                    </div>
                }
                modalTitle={
                    <strong className="modal-title">
                        <span className="text">
                            <Localize>{modalTitle}</Localize>
                        </span>
                    </strong>
                }
            >
                <MultiSelect
                    setSearch={setSearchValue}
                    search={searchValue}
                    onCancel={onCancel}
                    setBtnConfirm={setBtnConfirm}
                    formResetConfirm={formResetConfirm}
                >
                    {loading && <Loader tipText="Loading..." style={{}} />}
                    {searchValue && loading ? (
                        <Skeleton active paragraph={{ rows: 6 }} />
                    ) : (
                        <Radio.Group
                            className="group-radio"
                            options={filteredNodesArray ? filteredNodesArray : nodes}
                            value={value}
                            disabled={disabled}
                            onChange={(e) =>
                            {
                                form.setFieldsValue({ [ name ]: e.target.value });
                                onChangeSetFilter(false, name, e.target.value);
                            }}
                        />
                    )}

                    {pageInfo && pageInfo.hasNextPage && (
                        <>
                            {loading && (
                                <Loader style={{ marginTop: "-30px" }} tipText="Loading more..." />
                            )}
                            <Waypoint onEnter={() => fetchMoreAction()} />
                        </>
                    )}
                </MultiSelect>
            </ModalStandard>
        </Form.Item>
    );
};

export default RadioSelect;