import { Table, TableProps } from 'antd';
import { Link } from 'react-router-dom';
import { GET_VARIABLE_GROUPS } from 'graphql/query/variable-gql';
import { useQuery } from '@apollo/client';
import SkeletonCustom from 'components/skeleton';
import Localize, { localizeByKey } from "components/service/localize";
import VariableConst from '../variable-const';

interface IVariableGroup
{
    group_name: string;
    key_amount: { lang: string; amount: number }[];
}

interface IQueryData
{
    variableGroups: IVariableGroup[];
}

const VariableGroupTableHelper: React.FC = () =>
{
    const { data: { variableGroups } = {}, loading, refetch } = useQuery<IQueryData>(GET_VARIABLE_GROUPS, {
        variables: { sort: 'ASC' },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const SkeletonData = SkeletonCustom.TableData;

    const languages = [ ...new Set((variableGroups ?? []).flatMap((group) => group?.key_amount?.map((item) => item.lang) ?? [])) ];

    const titleKeyAmount = `${localizeByKey("TABLES.Column_Title_KeyAmount")}`;

    const columns = [
        {
            title: <Localize>TABLES.Column_Title_GroupName</Localize>,
            dataIndex: 'group_name',
            columnIndex: 'GROUP_NAME',
            key: 'group_name',
            sorter: true,
        },
        ...(languages ? languages.map((lang) => ({
            title: `${titleKeyAmount} (${lang})`,
            dataIndex: `key_amount_${lang}`,
            key: `key_amount_${lang}`,
        })) : []),
    ];

    const dataSource = loading
        ? SkeletonData(columns, 30, 'my-2')
        : variableGroups?.map((group, index) =>
        {
            const row: Record<string, any> = {
                key: index,
                group_name: <Link className="table-link-underline" to={`${VariableConst.basePath}/${group.group_name.replace(/ /g, "-").toLowerCase()}`}> {`${group.group_name}`}</Link>,
            };

            languages?.forEach((lang) =>
            {
                const keyAmount = group.key_amount.find((item) => item.lang === lang);
                row[ `key_amount_${lang}` ] = keyAmount ? keyAmount.amount : null;
            });

            return row;
        });

    const handleTableChange: TableProps<any>[ 'onChange' ] = (pagination, filters, sorter) =>
    {
        const sortOrder = Array.isArray(sorter) ? sorter[ 0 ].order === 'descend' ? 'DESC' : 'ASC' : sorter.order === 'descend' ? 'DESC' : 'ASC';
        refetch({ sort: sortOrder });
    };

    return (
        <Table
            className="table-variable"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            onChange={handleTableChange}
        />
    );
};

export default VariableGroupTableHelper;