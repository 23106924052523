import { GET_FLOORING } from "graphql/query/flooring-gql";
import Flooring from "components/flooring";
import { useBreadCrumbs } from "components/use-hooks";
import { localizeByKey } from "components/service/localize";
import { UploadMedia } from "components/layout";
import type { TFlooring } from "components/flooring/helpers/flooring-types-helper";

const flooringBasePath = Flooring.Const.basePath;

interface IFlooringOverviewSubPageProps
{
  flooring: TFlooring | undefined;
}

const FlooringOverviewSubPage: React.FC<IFlooringOverviewSubPageProps> = ({ flooring }) =>
{
  const breadcrumbs = [
    {
      label: localizeByKey("BREADCRUMBS.Label_Flooring"),
      path: `${flooringBasePath}`,
    },
    {
      label:
        (flooring && `${flooring?.title}`) ||
        localizeByKey("BREADCRUMBS.Label_Create"),
      path:
        (flooring && `${flooringBasePath}/${flooring?.id}`) ||
        `${flooringBasePath}/create`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  return (
    <div className="row-grid col-lg-2">
      <div className={`col-left ${flooring?.id && "col-border-right"} `}>
        <Flooring.Forms.Edit flooring={flooring} />
      </div>
      <div className="col-right">
        {flooring?.id && (
          <UploadMedia
            model="Flooring"
            modelId={flooring?.id}
            defaultMedia={flooring?.images}
            query={GET_FLOORING}
          />
        )}
      </div>
    </div>
  );
};

export default FlooringOverviewSubPage;
