interface ISortableItem {
    [key: string]: any;
}

function sortByCustomOrder(
    arr: ISortableItem[],
    property: string
): ISortableItem[] {
    const customOrder = [
        "stand_orientation_row",
        "stand_orientation_corner_left",
        "stand_orientation_corner_right",
        "stand_orientation_peninsula",
        "stand_orientation_island",
    ];

    const arrCopy = [...arr];

    return arrCopy.sort(
        (a, b) =>
            customOrder.indexOf(a[property]) - customOrder.indexOf(b[property])
    );
}

const StandFunctionsHelper = {
    sort: sortByCustomOrder,
};

export default StandFunctionsHelper;
