import React, { useState } from "react";
import { Button, Input } from "antd";
import { useMatch } from "react-router-dom";

import { GET_USERS } from "graphql/query/user-gql";
import { ROLE_SYNC_USERS } from "graphql/mutation/role-gql";

import Role from "components/role";
import User from "components/user";
import { useBreadCrumbs } from "components/use-hooks";
import { Tables } from "components/layout";
import { Localize, ModalStandard } from "components/service";
import { localizeByKey } from "components/service/localize";
import type { TRole } from "components/role/helpers/role-types-helper";
import Icons from "components/icons";

const { Search } = Input;

interface IRoleUsersSubPageProps
{
    role: TRole | undefined;
}

const RoleUsersSubPage: React.FC<IRoleUsersSubPageProps> = ({ role }) =>
{
    const basePath = Role.Const.basePath;
    const tableHelper = Role.Helpers.TableUsers;

    const breadcrumbs = [
        {
            label: localizeByKey("BREADCRUMBS.Label_Roles"),
            path: `${basePath}`,
        },
        {
            label: role?.title || "",
            path: `${basePath}/${role?.id}`,
        },
        {
            label: localizeByKey("BREADCRUMBS.Label_Users"),
            path: `${basePath}/${role?.id}/users`,
        },
    ];

    useBreadCrumbs(breadcrumbs);

    const roleID = { column: "ID", operator: "EQ", value: parseInt(role?.id || "0") };

    const match = useMatch(`${basePath}/page/:pageNum`);

    const objOrderBy = [ { column: "ID", order: "DESC" as const } ];

    const [ searchText, setSearchText ] = useState("");

    return (
        <Tables.Main
            model="users"
            query={GET_USERS}
            routeUrl={`${basePath}/${role?.id}/users`}
            currentPage={match ? parseInt(match.params.pageNum || "1") : 1}
            searchText={searchText}
            objOrderBy={objOrderBy}
            varSet={{ whereRoles: roleID, perPage: 30 }}
            currentObj={role}
            tableHelper={tableHelper}
        >
            <div className="table-action-bar">
                <div className="col">
                    <Search
                        className="filter-search"
                        prefix={<Icons.Search />}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                </div>

                <div className="col">
                    <ModalStandard
                        width={400}
                        extraClass={"modal-form"}
                        modalButton={
                            <Button type="primary" icon={<Icons.Plus />}>
                                <span className="ellipsis">
                                    <Localize>
                                        GLOBAL.Button_Text_AddNew
                                    </Localize>
                                </span>
                            </Button>
                        }
                        modalTitle={
                            <strong className="modal-title">
                                <span className="text">
                                    <Localize>FORMS.Title_AddUsers</Localize>
                                </span>
                            </strong>
                        }
                    >
                        <User.Forms.Add
                            action={() => { }}
                            propsObj={{
                                query: GET_USERS,
                                queryVar: {
                                    first: 10000,
                                    whereRoles: roleID,
                                },
                                mutation: ROLE_SYNC_USERS,
                                mutationName: "roleSyncToUsers",
                                modelID: { role_id: role?.id },
                            }}
                        />
                    </ModalStandard>
                </div>
            </div>
        </Tables.Main>
    );
};

export default RoleUsersSubPage;