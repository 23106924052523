import React, { useState } from "react";
import { Button, Input } from "antd";

import { GET_GRAPHIC_SUPPLIER_RULES_CURSOR } from "graphql/query/graphic-gql";

import Graphic from "components/graphic";
import Icons from "components/icons";
import { ModalStandard } from "components/service";
import { useBreadCrumbs } from "components/use-hooks";
import { Tables } from "components/layout";
import { Localize } from "components/service";
import { localizeByKey } from "components/service/localize";
import type { TGraphicSupplier } from "components/graphic/helpers/graphic-types-helper";

const { Search } = Input;

const graphicBasePath = Graphic.Const.basePath;
const tableHelper = Graphic.Helpers.TableRules;


interface IGraphicRulesSubPageProps
{
  graphic: TGraphicSupplier | undefined;
}


const GraphicRulesSubPage: React.FC<IGraphicRulesSubPageProps> = ({ graphic }) =>
{

  const breadcrumbs = [
    {
      label: localizeByKey("BREADCRUMBS.Label_GraphicRules"),
      path: `${graphicBasePath}`,
    },
    {
      label: graphic?.title ?? localizeByKey("VARIABLE.Label_EMPTY"),
      path: `${graphicBasePath}/${graphic?.id}`,
    },
    {
      label: localizeByKey("BREADCRUMBS.Label_Rules"),
      path: `${graphicBasePath}/${graphic?.id}/rules`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const [ searchText, setSearchText ] = useState("");
  const [ variables, setVariables ] = useState<Record<string, any>>({});

  return (
    <Tables.Cursor
      model="graphicSupplierRulesCursor"
      query={GET_GRAPHIC_SUPPLIER_RULES_CURSOR}
      varSet={{
        perPage: 50,
      }}
      routeUrl={`${graphicBasePath}/${graphic?.id}/rules`}
      searchText={searchText}
      setVariables={setVariables}
      tableHelper={tableHelper}
      currentObj={graphic}
    >
      <div className="table-action-bar">
        <div className="col">
          <Search
            className="filter-search"
            prefix={<Icons.Search />}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>

        <div className="col">
          <ModalStandard
            width={600}
            extraClass={"modal-form"}
            modalButton={
              <Button type="primary" icon={<Icons.Plus />}>
                <span className="ellipsis">
                  <Localize>GLOBAL.Button_Text_CreateNew</Localize>
                </span>
              </Button>
            }
            modalTitle={
              <strong className="modal-title">
                <span className="text">
                  <Localize>FORMS.Title_CreateGraphicRule</Localize>
                </span>
              </strong>
            }
          >

            <Graphic.Forms.RuleEdit
              graphSupplierID={graphic?.id}
              variables={variables}
              action={() => { }}
            />
          </ModalStandard>
        </div>
      </div>
    </Tables.Cursor>
  );
};

export default GraphicRulesSubPage;
