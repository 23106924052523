import React, { useState } from "react";
import { useMatch } from "react-router-dom";
import { Button, Input } from "antd";

import { GET_EQUIPMENT_VARIATIONS } from "graphql/query/equipment-gql";

import { useBreadCrumbs } from "components/use-hooks";
import { Tables } from "components/layout";
import { Localize, ModalStandard } from "components/service";
import { localizeByKey } from "components/service/localize";
import Icons from "components/icons";
import Variation from "components/varation";
import Equipment from "components/equipment";
import { TEquipment } from "components/equipment/helpers/equipment-types-helper";

const { Search } = Input;

const equipmentBasePath = Equipment.Const.basePath;

interface IEquipmentVariationsSubPageProps
{
  equipment: TEquipment | undefined;
  lang: string;
}

const EquipmentVariationsSubPage: React.FC<IEquipmentVariationsSubPageProps> = ({ equipment, lang }) =>
{
  const basePath = Variation.Const.basePath;
  const tableHelper = Variation.Helpers.TableMain;

  const breadcrumbs = [
    {
      label: localizeByKey("BREADCRUMBS.Label_Equipment"),
      path: `${equipmentBasePath}`,
    },
    {
      label: equipment?.title ?? localizeByKey("VARIABLE.Label_EMPTY"),
      path: `${basePath}/edit/${equipment?.group_id}/${lang}`,
    },
    {
      label: localizeByKey("BREADCRUMBS.Label_Variations"),
      path: `${equipmentBasePath}/edit/${equipment?.group_id}/${lang}/variations`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const equipmentID = {
    column: "EQUIPMENT_GROUP ",
    operator: "EQ",
    value: equipment && parseInt(equipment.group_id),
  };

  const match = useMatch(`${basePath}/page/:pageNum`);

  const objOrderBy = [ { column: "ID", order: "DESC" as const } ];

  const [ searchText, setSearchText ] = useState("");
  const [ variables, setVariables ] = useState<Record<string, any>>({});

  return (
    <Tables.Main
      model="equipmentVariations"
      query={GET_EQUIPMENT_VARIATIONS}
      routeUrl={`${equipmentBasePath}/edit/${equipment?.group_id}/variations`}
      currentPage={match ? parseInt(match.params.pageNum || "1") : 1}
      searchText={searchText}
      objOrderBy={objOrderBy}
      varSet={{ perPage: 30 }}
      objectWhere={{ ...equipmentID, AND: [ {} ] }}
      tableHelper={tableHelper}
      setVariables={setVariables}
    >
      <div className="table-action-bar">
        <div className="col">
          <Search
            className="filter-search"
            prefix={<Icons.Search />}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>

        <div className="col">
          <ModalStandard
            width={500}
            extraClass={"modal-form"}
            modalButton={
              <Button type="primary" icon={<Icons.Plus />}>
                <span className="ellipsis">
                  <Localize>GLOBAL.Button_Text_CreateNew</Localize>
                </span>
              </Button>
            }
            modalTitle={
              <strong className="modal-title">
                <span className="text">
                  <Localize>FORMS.Title_CreateVariation</Localize>
                </span>
              </strong>
            }
          >
            <Variation.Forms.Edit
              groupID={equipment?.group_id ?? ""}
              variables={variables}
              action={() => { }}
            />
          </ModalStandard>
        </div>
      </div>
    </Tables.Main>
  );
};

export default EquipmentVariationsSubPage;