import React from "react";
import { Button, FormInstance } from "antd";
import Variable from "components/variable";
import Localize, { localizeByKey } from "components/service/localize";
import { GET_SUPPLIER_CURSOR } from "graphql/query/supplier-gql";
import { FormElements } from "components/layout";
import { IFilter } from 'components/flooring/helpers/flooring-types-helper';


interface IFilterList
{
  [ key: string ]: IFilter;
}

interface IFlooringFiltersFormProps
{
  setCount: React.Dispatch<any>;
  setFilters: React.Dispatch<React.SetStateAction<IFilter | undefined>>;
  setFilterList: React.Dispatch<React.SetStateAction<IFilterList | undefined>>;
  filterList: IFilterList | undefined;
  flooringFilters: IFilterList | undefined;
  action: () => void;
  gqlBuilderWhere: (filters: any) => any;
  form: FormInstance;
}

const FlooringFiltersForm: React.FC<IFlooringFiltersFormProps> = ({
  form,
  action,
  setCount,
  flooringFilters,
  setFilters,
  setFilterList,
  filterList,
  gqlBuilderWhere,
}) =>
{
  const onChangeSetFilter = (
    e: React.ChangeEvent<HTMLInputElement> | false,
    name: string,
    value: string | string[]
  ) =>
  {
    if (e) {
      name = e.target.name;
      value = e.target.value;
    }

    if (value.toString().length > 0) {
      setFilterList({
        ...filterList,
        [ name ]: {
          column: name,
          operator: "IN",
          value: Array.isArray(value) ? value : [ value ],
        },
      });
    } else {
      const newFilterList = { ...filterList };
      delete newFilterList[ name ];
      setFilterList(newFilterList);
    }
  };

  return (
    <div className="ant-form ant-form-vertical filters-form">
      <div>
        <Variable.Forms.Fields.VariablesSelect
          form={form}
          name="TYPE"
          groupName="flooring type"
          initialValue={Array.isArray(flooringFilters?.TYPE?.value) ? flooringFilters?.TYPE?.value[ 0 ] : flooringFilters?.TYPE?.value}
          label={
            localizeByKey("FORMS.Input_Label_Type")
          }
          onChangeSetFilter={() => onChangeSetFilter(false, "TYPE", form.getFieldValue("TYPE"))}
        />

        <Variable.Forms.Fields.VariablesSelect
          form={form}
          name="COLOR"
          groupName="flooring color"
          initialValue={Array.isArray(flooringFilters?.COLOR?.value) ? flooringFilters?.COLOR?.value[ 0 ] : flooringFilters?.COLOR?.value}
          label={
            localizeByKey("FORMS.Input_Label_Color")
          }
          onChangeSetFilter={() => onChangeSetFilter(false, "COLOR", form.getFieldValue("COLOR"))}
        />

        <FormElements.RadioSelect
          skipQuery={!flooringFilters}
          location={true}
          form={form}
          query={GET_SUPPLIER_CURSOR}
          model="suppliersCursor"
          name="SUPPLIER_ID"
          label="FORMS.Input_Label_Supplier"
          modalTitle="FORMS.Title_SelectSupplier"
          initialValue={
            flooringFilters?.SUPPLIER_ID?.value
              ? [ { value: flooringFilters?.SUPPLIER_ID?.value[ 0 ] } ]
              : undefined
          }
          onChangeSetFilter={() => onChangeSetFilter(false, "SUPPLIER_ID", form.getFieldValue("SUPPLIER_ID"))}
        />
      </div>

      <div className="form-btn-holder">
        <Button
          className="light-bg"
          htmlType="submit"
          onClick={() =>
          {
            setCount({});
            setFilterList({});
            setFilters(gqlBuilderWhere({}));
            localStorage.removeItem("flooringFilters");
            localStorage.removeItem("flooringSorting");
          }}
        >
          <Localize>FORMS.Button_Text_ResetFilters</Localize>
        </Button>

        <Button
          className="btn-right"
          type="primary"
          htmlType="submit"
          onClick={() =>
          {
            setFilters(gqlBuilderWhere({ ...flooringFilters, ...filterList }));
            setCount({
              ...flooringFilters,
              ...filterList,
            });
            action();
          }}
        >
          <Localize>FORMS.Button_Text_ApplyFilters</Localize>
        </Button>
      </div>
    </div>
  );
};

export default FlooringFiltersForm;
