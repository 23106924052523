import GraphicInfoField from "./graphic-info-field";
import GraphicActionField from "./graphic-action-field";
import GraphicSupplierField from "./graphic-supplier-field";
import GraphicOrganizerField from "./graphic-organizer-field";
import GraphicRuleActionField from "./graphic-rule-action-field";
import GraphicRuleInfoField from "./graphic-rule-info-field";

const GraphicFields = {
  Info: GraphicInfoField,
  Supplier: GraphicSupplierField,
  Organizer: GraphicOrganizerField,
  Action: GraphicActionField,
  RuleAction: GraphicRuleActionField,
  RuleInfo: GraphicRuleInfoField,
};

export default GraphicFields;
