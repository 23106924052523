import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { ROLE_DELETE } from "graphql/mutation/role-gql";
import RoleConst from "../role-const";
import {
    successNotification,
    errorNotification,
} from "components/request-result";

interface IRoleDeleteResponse {
    roleDelete: {
        label: string;
        message: string;
    };
}

const useMutationRoleDelete = (id: string | undefined) => {
    const navigate = useNavigate();

    const [_setMutationRoleDelete, { loading }] = useMutation<IRoleDeleteResponse>(ROLE_DELETE, {
        update(cache, { data }) {
            if (data) {
                const {
                    roleDelete: { label, message },
                } = data;

                navigate(RoleConst.basePath);

                cache.evict({
                    id: cache.identify({ id, __typename: "Role" }),
                });
                cache.gc();

                successNotification({
                    title: label.toUpperCase(),
                    description: message,
                });
            }
        },
        onError(error) {
            errorNotification(error);
        },
    });

    const handleDelete = async (options: { variables: Record<string, any> }) =>
    {
      await _setMutationRoleDelete(options);
    };

    return {
        _setMutationRoleDelete: handleDelete,
        loadingMutationRoleDelete: loading,
    };
};

export default useMutationRoleDelete;