import React from "react";
import { Localize } from "components/service";
import { TFlooring } from "../helpers/flooring-types-helper";


interface IFlooringArticleFieldProps
{
  flooring: TFlooring | undefined;
}

const FlooringArticleField: React.FC<IFlooringArticleFieldProps> = ({ flooring }) =>
{
  return (
    <div
      className="d-inline-flex justify-content-center align-items-center"
      style={{
        padding: "1px 15px",
        borderRadius: "10px",
        color: "#fff",
        background: "#4471FF",
        minWidth: "72px",
      }}
    >
      <span>
        <Localize>{flooring?.article}</Localize>
      </span>
    </div>
  );
};

export default FlooringArticleField;
