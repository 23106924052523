import { useNavigate } from "react-router-dom";
import { useMutation, DocumentNode } from "@apollo/client";
import { USER_UPDATE, USER_CREATE } from "graphql/mutation/user-gql";
import { TUser } from 'components/user/helpers/user-types-helper';
import UserConst from "../users-const";
import
{
    successNotification,
    errorNotification,
} from "components/request-result";

interface IMutationData
{
    [ key: string ]: {
        label: string;
        message: string;
    };
}

const useMutationUserCreateUpdate = (
    id: TUser[ 'id' ] | undefined,
    redirect: boolean = true,
) =>
{
    const navigate = useNavigate();

    const USER_CREATE_UPDATE: DocumentNode = id ? USER_UPDATE : USER_CREATE;
    const userCreateUpdate: string = id ? "userUpdate" : "userCreate";

    const [ _setUserCreateUpdate, { loading } ] = useMutation(USER_CREATE_UPDATE, {
        update(cache, { data })
        {
            const mutationData: IMutationData = data as IMutationData;
            const { label, message } = mutationData[ userCreateUpdate ];

            if (redirect) {
                navigate(UserConst.basePath);
            }

            successNotification({
                title: label,
                description: message,
            });
        },
        onError(error)
        {
            errorNotification(error);
        },
    });

    return {
        _setUserCreateUpdate,
        loadingMutationUserCreateUpdate: loading,
    };
};

export default useMutationUserCreateUpdate;
