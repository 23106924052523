import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { GRAPHIC_SUPPLIER_DELETE } from "graphql/mutation/graphic-gql";
import GraphicConst from "../graphic-const";
import
{
    successNotification,
    errorNotification,
} from "components/request-result";

const useMutationGraphicDelete = (id: string | undefined) =>
{
    const navigate = useNavigate();

    const [ _setMutationGraphicDelete, { loading } ] = useMutation(
        GRAPHIC_SUPPLIER_DELETE,
        {
            update(cache, { data })
            {
                const {
                    graphicSupplierDelete: { label, message },
                } = data;

                navigate(GraphicConst.basePath);

                cache.evict({
                    id: cache.identify({ id, __typename: "GraphicSupplier" }),
                });
                cache.gc();

                successNotification({
                    title: label.toUpperCase(),
                    description: message,
                });
            },
            onError(error)
            {
                errorNotification(error);
            },
        }
    );

    const handleDelete = async (options: {
        variables: Record<string, any>,
    }) =>
    {
        await _setMutationGraphicDelete(options);
    };

    return {
        _setMutationGraphicDelete: handleDelete,
        loadingMutationGraphicDelete: loading,
    };
};

export default useMutationGraphicDelete;
