import { useMutation } from "@apollo/client";

import { SUPPLIER_IMPORT_REIMPORT } from "graphql/mutation/supplier-gql";
import { GET_SUPPLIERS } from "graphql/query/supplier-gql";

import { successNotification, errorNotification } from "components/request-result";


type TVariables = {
    [ key: string ]: any;
};

const useMutationSupplierImportReimport = ({ variables }: { variables: TVariables }) =>
{

    const [ _setImportReimport, { loading, data } ] = useMutation(SUPPLIER_IMPORT_REIMPORT,
        {
            update(cache, { data })
            {

                const {
                    supplierImportOrReImport: {
                        label,
                        message,
                        //eslint-disable-next-line
                        sync_info
                    }
                } = data;


                successNotification({
                    title: label,
                    description: message
                });
            },

            onError(error)
            {
                errorNotification(error);
            },

            refetchQueries: [
                {
                    query: GET_SUPPLIERS,
                    variables
                },
            ]
        }
    );


    return {
        _setImportReimport,
        dataMutationImportReimport: data,
        loadingMutationImportReimport: loading,
    };
};

export default useMutationSupplierImportReimport;