import React from "react";
import { Link } from "react-router-dom";
import Supplier from "components/supplier";
import { TFlooring } from "../helpers/flooring-types-helper";

interface IFlooringSupplierFieldProps
{
  flooring: TFlooring | undefined;
}

const FlooringSupplierField: React.FC<IFlooringSupplierFieldProps> = ({ flooring }) =>
{
  return flooring?.supplier?.title ? (
    <Link
      className="table-link-underline"
      to={`${Supplier.Const.basePath}/${flooring?.supplier?.id}`}
    >
      {`${flooring?.supplier?.title}`}
    </Link>
  ) : (
    <strong>&mdash;</strong>
  );
};

export default FlooringSupplierField;
