import React from "react";
import { Link } from "react-router-dom";
import Supplier from "components/supplier";
import { TGraphicSupplier } from "components/graphic/helpers/graphic-types-helper";


interface IGraphicSupplierFieldProps
{
  graphic?: TGraphicSupplier | undefined;
}


const GraphicSupplierField: React.FC<IGraphicSupplierFieldProps> = ({ graphic }) =>

  graphic?.supplier?.id ? (
    <Link
      className="table-link-underline"
      to={`${Supplier.Const.basePath}/${graphic?.supplier?.id}`}
    >
      {graphic?.supplier?.title}
    </Link>
  ) : (
    <strong>&mdash;</strong>
  );

export default GraphicSupplierField;
