import React from "react";
import { Avatar } from "antd";
import { FileImageOutlined } from "@ant-design/icons";
import { localizeByKey } from "components/service/localize";
import StandHelpers from "../helpers";
import { TStand } from "../helpers/stand-types-helper";


interface IStandOrientationFieldProps
{
  stand: TStand;
}


const StandOrientationField: React.FC<IStandOrientationFieldProps> = ({ stand }) =>
{
  const {
    Functions: { sort },
  } = StandHelpers;

  return (
    <div className="orientation-box">
      {stand?.orientations.length ? (
        sort(stand?.orientations, "orientation_key").map(
          ({ orientation_key, img_path }) => (
            <div
              className="d-flex align-items-center orientation-item"
              key={orientation_key}
              style={{
                color: "#fff",
              }}
            >
              <div style={{ minWidth: "32px" }}>
                <Avatar
                  style={{
                    borderRadius: 0,
                    border: "1px solid var(--page_colorBgLayoutInner)",
                  }}
                  shape="square"
                  size={28}
                  icon={
                    img_path ? (
                      <img src={img_path} alt="avatar" />
                    ) : (
                      <FileImageOutlined />
                    )
                  }
                />
              </div>
              <strong>
                {localizeByKey(orientation_key)
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase())
                  .join("")}
              </strong>
            </div>
          )
        )
      ) : (
        <strong>&mdash;</strong>
      )}
    </div>
  );
};

export default StandOrientationField;
