import React from "react";
import { Routes, Route } from "react-router-dom";
import SubPage from "components/pages/flooring/sub-pages";
import type { TFlooring } from "components/flooring/helpers/flooring-types-helper";

interface IFlooringRouteProps
{
  flooring: TFlooring | undefined;
}

const FlooringRoute: React.FC<IFlooringRouteProps> = ({ flooring }) =>
{
  return (
    <Routes>
      <Route path="/" element={<SubPage.OverView flooring={flooring} />} />
    </Routes>
  );
};

export default FlooringRoute;
