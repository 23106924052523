import Exhibition from "components/exhibition";
import { useBreadCrumbs } from "components/use-hooks";
import { localizeByKey } from "components/service/localize";
import type { TExhibitionType } from "components/exhibition/helpers/exhibition-types-helper";

const exhibitionBasePath = Exhibition.Const.basePath;

interface IExhibitionOverviewSubPageProps
{
  exhibition: TExhibitionType | undefined;
}

const ExhibitionOverviewSubPage: React.FC<IExhibitionOverviewSubPageProps> = ({ exhibition }) => {
  const breadcrumbs = [
    {
      label: localizeByKey("BREADCRUMBS.Label_Exhibitions"),
      path: `${exhibitionBasePath}`,
    },
    {
      label:
        (exhibition && exhibition?.title) ||
        localizeByKey("BREADCRUMBS.Label_Create"),
      path:
        (exhibition && `${exhibitionBasePath}/${exhibition?.id}`) ||
        `${exhibitionBasePath}/create`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  return (
    <div className="row-grid col-lg-2">
      <div className="col-left">
        <Exhibition.Forms.Edit exhibition={exhibition} />
      </div>
      <div className="col-right"></div>
    </div>
  );
};

export default ExhibitionOverviewSubPage;
