import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import UserConst from "../users-const";
import Icons from "components/icons";
import { TUser } from "components/user/helpers/user-types-helper";

type TUserInfoFieldProps = {
    user: TUser;
};

const UserInfoField: React.FC<TUserInfoFieldProps> = ({ user }) => {
    const { sendPassword, id, name, surname } = user;

    return (
        <div className="flex-field-box">
            {sendPassword?.created_at && (
                <span className="col">
                    <Tooltip
                        destroyTooltipOnHide={true}
                        title={sendPassword?.message}
                    >
                        <span className="icon">
                            <Icons.Padlock />
                        </span>
                    </Tooltip>
                </span>
            )}

            <span className="col">
                <Link to={`${UserConst.basePath}/${id}/`}> {`${name} ${surname}`}</Link>
            </span>
        </div>
    );
};

export default UserInfoField;
