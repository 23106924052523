import React, { useState } from "react";
import { Waypoint } from "react-waypoint";
import { Form, Checkbox, Skeleton } from "antd";
import { useQuery, DocumentNode, WatchQueryFetchPolicy } from "@apollo/client";
import MultiSelect from "../multi-select";
import { cursorPagination } from "components/service";
import { localizeByKey } from "components/service/localize";
import { useDebounce } from "components/use-hooks";
import { Loader } from "components/request-result";

import "./checkbox-group.scss";


interface IInitialValueItem
{
    value: string;
    label?: string;
}

interface ICheckboxGroupProps
{
    form: any;
    initialValue?: IInitialValueItem[];
    model: any;
    name: string;
    label?: string;
    query: DocumentNode;
    filteredNodes?: boolean | string;
    modelCursorLabel?: string[];
    modelCursorValue?: string;
    modelImagePath?: string;
    objectWhere?: object;
    objectWhereExtra?: object;
    jsx?: (imgPath?: string) => JSX.Element;
    first?: number;
    initialLang?: boolean;
    extraClass?: string;
    skipQuery?: boolean;
    fetchPolicy?: WatchQueryFetchPolicy;
}

const CheckboxGroup: React.FC<ICheckboxGroupProps> = ({
    form,
    initialValue = [],
    model,
    name,
    label = "",
    query,
    filteredNodes = false,
    modelCursorLabel = [ "title" ],
    modelCursorValue = "id",
    modelImagePath = "img_path",
    objectWhere = {},
    objectWhereExtra = {},
    jsx = () => <></>,
    first = 50,
    initialLang = false,
    extraClass = "",
    skipQuery = false,
    fetchPolicy = "cache-and-network",
}) =>
{
    const dataInit = Array.isArray(initialValue)
        ? initialValue.map((modelObj) => modelObj.value.toString())
        : "";
    const value = Form.useWatch(name, form);
    const [ searchValue, setSearchValue ] = useState("");

    const { data, loading, fetchMore } = useQuery(query, {
        skip: skipQuery,
        variables: {
            text: useDebounce(searchValue),
            first,
            where: objectWhere,
            ...objectWhereExtra,
        },
        fetchPolicy,
        nextFetchPolicy: "cache-first",
        notifyOnNetworkStatusChange: true,
    });

    const { fetchMoreAction, nodes, pageInfo } = cursorPagination({
        label: modelCursorLabel,
        value: modelCursorValue,
        img_path: modelImagePath,
        model,
        data,
        loading,
        fetchMore,
        initialLang,
        jsx,
    });

    const filteredNodesArray = nodes.filter((node: any) => node.value !== filteredNodes);

    return (
        <Form.Item
            initialValue={dataInit}
            name={name}
            label={localizeByKey(label)}
        >
            <MultiSelect
                setSearch={setSearchValue}
                extraClass={extraClass}
                disableBtn={true}
            >
                {loading && <Loader />}
                {searchValue && loading ? (
                    <Skeleton active paragraph={{ rows: 6 }} />
                ) : (
                    <Checkbox.Group
                        className="group-checkbox"
                        options={filteredNodesArray ? filteredNodesArray : nodes}
                        value={value}
                        onChange={(value) =>
                        {
                            form.setFieldsValue({ [ name ]: value });
                        }}
                    />
                )}

                {pageInfo && pageInfo.hasNextPage && (
                    <>
                        {loading && <Loader style={{ marginTop: "-30px" }} />}
                        <Waypoint onEnter={() => fetchMoreAction()} />
                    </>
                )}
            </MultiSelect>
        </Form.Item>
    );
};

export default CheckboxGroup;