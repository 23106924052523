import React from "react";
import { Routes, Route } from "react-router-dom";
import SubPage from "components/pages/role/sub-pages";
import type { TRole } from "components/role/helpers/role-types-helper";

interface IRoleRouteProps
{
    role: TRole | undefined;
}

const RoleRoute: React.FC<IRoleRouteProps> = ({ role }) =>
{
    return (
        <Routes>
            <Route path="/" element={<SubPage.OverView role={role} />} />
            <Route path="/users/page/:pageNum" element={<SubPage.Users role={role} />} />
            <Route path="/users/page" element={<SubPage.Users role={role} />} />
            <Route path="/users" element={<SubPage.Users role={role} />} />
        </Routes>
    );
};

export default RoleRoute;