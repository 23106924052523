import StandEditForm from "./stand-edit-form";
import StandFileEditForm from "./stand-file-edit-form";
import StandNoteEditForm from "./stand-note-edit-form";
import StandFiltersForm from "./stand-filters-form";

const StandForms = {
  Edit: StandEditForm,
  EditFile: StandFileEditForm,
  EditNote: StandNoteEditForm,
  Filter: StandFiltersForm,
};

export default StandForms;
