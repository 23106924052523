import React from "react";
import { Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { standUseMutation } from "../hooks";
import Equipment from "components/equipment";
import
{
  DrawerStandart,
  EntityRemoveButton,
  Localize,
} from "components/service";
import { localizeByKey } from "components/service/localize";
import { TEquipment } from "components/equipment/helpers/equipment-types-helper";
import { TStand, TStandTypeEquipment } from "../helpers/stand-types-helper";
import Icons from "components/icons";


interface IStandEquipmentActionFieldProps
{
  stand: TStand;
  standEquipment: TStandTypeEquipment;
  equipment: TEquipment;
  lang: string;
}


const StandEquipmentActionField: React.FC<IStandEquipmentActionFieldProps> = ({
  stand,
  standEquipment,
  equipment,
  lang,
}) =>
{
  const {
    _setMutationStandEquipmentDelete,
    loadingMutationStandEquipmentDelete,
  } = standUseMutation.equipmentDelete(standEquipment?.id);

  const items = [
    {
      key: "1",
      label: (
        <DrawerStandart
          extraClass={"modal-form filters"}
          modalButton={
            <span style={{ display: "flex", alignItems: "center", width: 100 }}>
              <Icons.Edit /> <Localize>MENUS_DROP.Label_Edit</Localize>
            </span>
          }
        >
          <strong className="modal-title">
            <span className="text">
              <Localize>FORMS.Title_Text_EditEquipmentRule</Localize>
            </span>
          </strong>

          <Equipment.Forms.AddRule
            stand={stand}
            standEquipment={standEquipment}
            equipment={equipment}
            lang={lang}
            action={() => { }}
          />
        </DrawerStandart>
      ),
    },
    {
      key: "2",
      label: (
        <EntityRemoveButton
          modalButton={
            <span style={{ display: "flex", alignItems: "center", width: 100 }}>
              <Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize>
            </span>
          }
          nameEntity={
            localizeByKey("ENTITY.Modal_Title_StandEquipment")
          }
          dataNameEntity={equipment?.title}
          loading={loadingMutationStandEquipmentDelete}
          deleteMutation={_setMutationStandEquipmentDelete}
          variables={{
            id: standEquipment?.id,
          }}
        />
      ),
    },
  ];

  return (
    <Dropdown
      // trigger={['click']}
      placement="bottomRight"
      menu={{ items }}
      overlayClassName="drop-more-menu"
      arrow
    >
      <EllipsisOutlined className="btn-more" />
    </Dropdown>
  );
};

export default StandEquipmentActionField;
