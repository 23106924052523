import React from "react";
import User from "components/user";
import { useBreadCrumbs } from "components/use-hooks";
import { localizeByKey } from "components/service/localize";
import type { TUser } from "components/user/helpers/user-types-helper";

const userBasePath = User.Const.basePath;

interface IUserOverviewSubPageProps
{
    user: TUser | undefined;
}

const UserOverviewSubPage: React.FC<IUserOverviewSubPageProps> = ({ user }) =>
{
    const breadcrumbs = [
        {
            label: localizeByKey("BREADCRUMBS.Label_Users"),
            path: `${userBasePath}`,
        },
        {
            label: user
                ? `${user.name} ${user.surname}`
                : localizeByKey("BREADCRUMBS.Label_Create"),
            path: user
                ? `${userBasePath}/${user.id}`
                : `${userBasePath}/create`,
        },
    ];

    useBreadCrumbs(breadcrumbs);

    return (
        <div className="row-grid col-lg-2">
            <div className="col-left">
                <User.Forms.Edit user={user} />
            </div>
            <div className="col-right">
                {user?.id && <User.Actions.List userID={user.id} />}
            </div>
        </div>
    );
};

export default UserOverviewSubPage;