import React from "react";
import StandFields from "../fields";
import { Localize } from "components/service";
import { TStand, TStandTypeEquipment } from "../helpers/stand-types-helper";

const standEquipmentColumns = [
  {
    title: <Localize>TABLES.Column_Title_Name</Localize>,
    dataIndex: "title",
    columnIndex: "TITLE",
    // sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_RuleType</Localize>,
    dataIndex: "rule_type",
    columnIndex: "RULE_TYPE",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_StandSizeSqm</Localize>,
    dataIndex: "size_min",
    columnIndex: "SIZE_MIN",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Amount</Localize>,
    dataIndex: "amount",
    columnIndex: "AMOUNT",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_NavisionNumb</Localize>,
    dataIndex: "navision_number",
    columnIndex: "NAVISION_NUMBER",
    // sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_OctacadNumb</Localize>,
    dataIndex: "octacad_number",
    columnIndex: "OCTACAD_NUMBER",
    // sorter: true,
  },
  {
    // title: '',
    dataIndex: "action",
    align: "right",
    width: "5%",
  },
];


const StandEquipmentData = (
  standEquipment: TStandTypeEquipment[],
  currentObj: TStand,
  unusedArg2: number,
  language: string,
) =>
{
  if (!standEquipment) {
    return [];
  }

  return standEquipment.map((standEquipment) =>
  {
    const equipment = standEquipment?.equipment?.filter(
      ({ lang }) => lang === language
    )[ 0 ];

    return {
      key: standEquipment.id,
      title: (
        <StandFields.EquipmentInfo
          stand={currentObj}
          standEquipment={standEquipment}
          equipment={equipment}
          lang={language}
        />
      ),
      rule_type: <StandFields.EquipmentRule standEquipment={standEquipment} />,
      size_min: <StandFields.EquipmentSize standEquipment={standEquipment} />,
      amount: standEquipment.amount ?? <strong>&mdash;</strong>,
      navision_number: equipment?.navision_number ?? <strong>&mdash;</strong>,
      octacad_number: equipment?.octacad_number ?? <strong>&mdash;</strong>,
      action: (
        <StandFields.EquipmentAction
          stand={currentObj}
          standEquipment={standEquipment}
          equipment={equipment}
          lang={language}
        />
      ),
    };
  });
};

const StandEquipmentsTableHelper = {
  columns: standEquipmentColumns,
  data: StandEquipmentData,
};

export default StandEquipmentsTableHelper;