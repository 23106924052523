import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "antd";

import { GET_EXHIBITIONS_CURSOR } from "graphql/query/exhibition-gql";

import Exhibition from "components/exhibition";
import Icons from "components/icons";
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import Localize, { localizeByKey } from "components/service/localize";

import "./exhibition.scss";

const { Search } = Input;

const basePath = Exhibition.Const.basePath;
const tableHelper = Exhibition.Helpers.TableMain;


const ExhibitionsPage: React.FC = () =>
{
  useActiveMenuItem([ "exhibition-types" ], [ "exhibition-types" ]);

  const breadcrumbs = [
    {
      label: localizeByKey("BREADCRUMBS.Label_Exhibitions"),
      path: `${basePath}`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const pageNavbar = [
    {
      label: localizeByKey("NAVBAR_PAGES.Label_Organizers"),
      path: "organizers",
    },
    {
      label: localizeByKey("NAVBAR_PAGES.Label_ExhibitionTypes"),
      path: "exhibition-types",
    },
  ];

  const navigate = useNavigate();
  const [ searchText, setSearchText ] = useState("");

  return (
    <PageWrapDefault
      className="page-exhibitions"
      dataExist={true}
      title={<Localize>PAGES.Title_Exhibitions</Localize>}
      pageNavbar={pageNavbar}
      staticPath=""
    >
      <Tables.Cursor
        model="exhibitionTypesCursor"
        query={GET_EXHIBITIONS_CURSOR}
        varSet={{ perPage: 50 }}
        routeUrl={`${basePath}`}
        searchText={searchText}
        tableHelper={tableHelper}
      >
        <div className="table-action-bar">
          <div className="col">
            <Search
              className="filter-search"
              prefix={<Icons.Search />}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>

          <div className="col">
            <Button
              type="primary"
              icon={<Icons.Plus />}
              onClick={() => navigate(`${basePath}/create`)}
            >
              <span className="ellipsis">
                <Localize>GLOBAL.Button_Text_CreateNew</Localize>
              </span>
            </Button>
          </div>
        </div>
      </Tables.Cursor>
    </PageWrapDefault>
  );
};

export default ExhibitionsPage;
