import StandFields from "../fields";
import { Localize } from "components/service";
import { TStand, TAttachment } from "./stand-types-helper";

const standFilesColumns = [
  {
    title: <Localize>TABLES.Column_Title_FileName</Localize>,
    dataIndex: "original_name",
    columnIndex: "ORIGINAL_NAME",
    // sorter: true
  },
  {
    title: <Localize>TABLES.Column_Title_Title</Localize>,
    dataIndex: "title",
    columnIndex: "TITLE",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Description</Localize>,
    dataIndex: "description",
    columnIndex: "DESCRIPTION",
    sorter: true,
  },
  {
    // title: '',
    dataIndex: "action",
    align: "right",
    width: "5%",
  },
];

const StandFilesData = (
  standFiles: TAttachment[],
  currentObj: TStand,
  variables: Record<string, any>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  language: string) =>
{
  if (!standFiles) {
    return [];
  }

  return standFiles.map((standFile) =>
  {
    return {
      key: standFile.id,
      original_name: <StandFields.FileAttachment attachment={standFile} />,
      title: standFile.title ?? <strong>&mdash;</strong>,
      description: standFile.description ?? <strong>&mdash;</strong>,
      action: (
        <StandFields.FilesAction
          stand={currentObj}
          standFile={standFile}
          variables={variables}
        />
      ),
    };
  });
};

const StandFilesTableHelper = {
  columns: standFilesColumns,
  data: StandFilesData,
};

export default StandFilesTableHelper;