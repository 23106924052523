import RoleEditForm from "./role-edit-form";
import RoleFormItems from "./fields";


const RoleForms = {
    Edit: RoleEditForm,
    Fields: RoleFormItems,
};

export default RoleForms;
