import UserFields from "./fields";
import UserForms from "./forms";
import UserActions from "./actions";
import UserConst from "./users-const";
import UsersHelpers from "./helpers";
import UsersFilters from './filters';
import { userUseMutation } from "./hooks";


const User = {
  Actions: UserActions,
  Filters: UsersFilters,
  Fields: UserFields,
  Forms: UserForms,
  Const: UserConst,
  Helpers: UsersHelpers,
  Hooks: userUseMutation
};

export default User;
