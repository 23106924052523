import React from "react";
import { Form, Select, FormInstance } from "antd";
import { useApolloClient, ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { GET_VARIABLE_LIST_ALL } from "graphql/query/variable-gql";
import { GET_ME } from "graphql/query/user-gql";
import Flooring from "components/flooring";
import Equipment from "components/equipment";
import Localize, { localizeByKey } from "components/service/localize";
import Icons from "components/icons";

interface IVariableVariablesSelectProps
{
  form: FormInstance;
  disabled?: boolean;
  disabledColor?: boolean;
  rules?: boolean;
  onChangeSetFilter?: (arg1: boolean, arg2: string, arg3: any) => void;
  initialValue?: string;
  activeLang?: string | boolean;
  mode?: "multiple" | "tags";
  name?: string;
  groupName?: string;
  label?: string | boolean;
  requiredText?: string;
  notAssignedOption?: React.ReactNode;
}

const VariableVariablesSelect: React.FC<IVariableVariablesSelectProps> = ({
  form,
  disabled = false,
  disabledColor = false,
  rules = false,
  onChangeSetFilter = () => { },
  initialValue,
  activeLang = false,
  mode = undefined,
  name = "role_id",
  groupName = "",
  label = localizeByKey("FORMS.Input_Label_Model"),
  requiredText = localizeByKey("FORM_RULES.Required_Choose"),
  notAssignedOption = (
    <Select.Option key="notAssigned" value="">
      <Localize>FORMS.Select_OptionLabel_NotAssigned</Localize>
    </Select.Option>
  ),
}) =>
{
  const client: ApolloClient<NormalizedCacheObject> = useApolloClient() as ApolloClient<NormalizedCacheObject>;

  const cachedVariables = client.readQuery({
    query: GET_VARIABLE_LIST_ALL,
  });

  const cachedMe = client.readQuery({
    query: GET_ME,
  });

  const lang = cachedMe?.me?.lang_app;

  const variableListAll = cachedVariables?.variableListAll;

  const variables = variableListAll?.filter(
    ({ group_name }: { group_name: string }) => group_name.toLowerCase() === groupName.toLowerCase()
  );

  const uniqueItems = new Set();
  const result: any[] = [];

  variables?.forEach((item: any) =>
  {
    const itemStr = JSON.stringify(item);
    if (!uniqueItems.has(itemStr)) {
      uniqueItems.add(itemStr);
      result.push(item);
    }
  });

  const translationCache = (lang: string | boolean) =>
    result.filter((item) => item?.lang === lang);

  const handleChange = (value: any) =>
  {
    form.setFieldsValue({ [ name ]: value });
    onChangeSetFilter(false, name, value);
  };

  const flooringColors: (colorName: string) => string = Flooring.Const.colorHexList;
  const equipmentColors: { [ key: string ]: string } = Equipment.Const.colorHexList;

  const renderOption = ({ slug, value }: { slug: string; value: string }) =>
  {
    if (value === null || value === undefined || value === "") {
      return (
        <Select.Option key={slug} value={slug}>
          <Localize>VARIABLE.Label_EMPTY</Localize>
        </Select.Option>
      );
    }


    return (
      <Select.Option key={slug} value={slug}>
        {name.toLowerCase() === "color" && disabledColor ? (
          <div className="d-flex align-items-center">
            <div
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                marginRight: "10px",
                background:
                  groupName === "flooring color"
                    ? flooringColors(slug)
                    : equipmentColors[ slug ],
              }}
            ></div>
            <Localize>{value}</Localize>
          </div>
        ) : (
          <span>{value}</span>
        )}
      </Select.Option>
    );
  };

  const formRules = rules ? [ { required: true, message: requiredText } ] : undefined;
  const selectMode = mode || undefined;

  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={initialValue}
      rules={formRules}
    >
      <Select
        showSearch
        showArrow
        suffixIcon={<Icons.Arrow />}
        mode={selectMode}
        className="select-serch-input"
        disabled={disabled}
        onChange={handleChange}
        placeholder={localizeByKey("FORMS.Input_Placeholder_Choose")}
        popupClassName="select-serch-dropdown"
        optionFilterProp="value"
      >
        {notAssignedOption}
        {translationCache(activeLang ? activeLang : lang)?.map(renderOption)}
      </Select>
    </Form.Item>
  );
};

export default VariableVariablesSelect;