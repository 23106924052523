import React, { useState, useEffect } from "react";
import { Input, Button, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { gqlBuilderWhere, helperFunc } from "common/utils";
import EquipmentForms from "../../forms";
import Icons from "components/icons";
import { DrawerStandart, Localize } from "components/service";
import
{
    TEquipment,
    IFilter,
} from "components/equipment/helpers/equipment-types-helper";

const { Search } = Input;

interface IFilterList
{
    [ key: string ]: IFilter;
}

interface IEquipmentTableFilterProps
{
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    setFilters: React.Dispatch<React.SetStateAction<IFilter | undefined>>;
    setResetSorting: React.Dispatch<
        React.SetStateAction<
            { column: string, order: "ASC" | "DESC" }[] | undefined
        >
    >;
    equipment: TEquipment | undefined;
    equipmentFilters: { [ key: string ]: IFilter } | undefined;
    filters: IFilter | undefined;
    objOrderBy: { column: string, order: string }[];
}

const EquipmentTableFilter: React.FC<IEquipmentTableFilterProps> = ({
    setFilters = () => { },
    setResetSorting = () => { },
    equipment,
    equipmentFilters,
    filters,
    setSearchText,
    objOrderBy,
}) =>
{
    const [ form ] = Form.useForm();

    const [ filterList, setFilterList ] = useState<IFilterList | undefined>(undefined);
    const [ count, setCount ] = useState<any>({});

    const filterCount = helperFunc.countFilterList(count);

    /*eslint-disable */
    useEffect(() =>
    {
        if (filters) {
            localStorage.setItem(
                `equipmentFilters_groupID-${equipment?.group_id || 0}`,
                JSON.stringify({ ...equipmentFilters, ...filterList })
            );
        } else form.resetFields([ "SUPPLIER_ID", "COLOR", "TYPE" ]);
    }, [ filters ]);

    useEffect(() =>
    {
        if (equipmentFilters) {
            setFilters(gqlBuilderWhere(equipmentFilters));
        }

        setCount({
            ...equipmentFilters,
        });
    }, []);
    /*eslint-enable */

    return (
        <>
            <Search
                className="filter-search"
                prefix={<SearchOutlined />}
                onChange={(e) => setSearchText(e.target.value)}
            />
            <Form
                key="filters-form"
                className="clear-css-prop table-filter-form"
                layout="vertical"
                form={form}
            >
                <DrawerStandart
                    extraClass={"modal-form filters"}
                    modalButton={
                        <Button icon={<Icons.Filter />}>
                            <span className="ellipsis">
                                <Localize>FORMS.Button_Text_Filters</Localize>
                            </span>
                            {!!filterCount && (
                                <span className="filter-count">
                                    {filterCount}
                                </span>
                            )}
                        </Button>
                    }
                >
                    <strong className="modal-title">
                        <span className="text">
                            <Localize>FORMS.Title_Text_Filters</Localize>
                        </span>
                    </strong>

                    <EquipmentForms.Filter
                        equipment={equipment}
                        setCount={setCount}
                        setFilters={(filters) => setFilters(filters as IFilter)}
                        setFilterList={setFilterList}
                        filterList={filterList}
                        equipmentFilters={equipmentFilters}
                        gqlBuilderWhere={gqlBuilderWhere}
                        form={form}
                        action={() => { }}
                    />
                </DrawerStandart>

                {filters && (
                    <Button
                        className="underline"
                        onClick={() =>
                        {
                            setCount({});
                            setFilterList({});
                            setFilters(gqlBuilderWhere({}));
                            setResetSorting(objOrderBy as { column: string; order: "ASC" | "DESC" }[]);
                            localStorage.removeItem(
                                `equipmentFilters_groupID-${equipment?.group_id || 0
                                }`
                            );
                            localStorage.removeItem(
                                `equipmentsSorting_groupID-${equipment?.group_id || 0
                                }`
                            );
                        }}
                    >
                        <span>
                            <Localize>FORMS.Button_Text_Reset</Localize>
                        </span>
                    </Button>
                )}
            </Form>
        </>
    );
};

export default EquipmentTableFilter;
