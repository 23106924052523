import { useMutation } from "@apollo/client";
import { GET_STAND_TYPE_NOTES_CURSOR } from "graphql/query/stand-gql";
import
  {
    STAND_TYPE_NOTE_CREATE,
    STAND_TYPE_NOTE_UPDATE,
  } from "graphql/mutation/stand-gql";

import
  {
    successNotification,
    errorNotification,
  } from "components/request-result";

const useMutationStandNoteCreateUpdate = ({ id, variables }: { id: string | undefined, variables: Record<string, any> }) =>
{
  const STAND_TYPE_NOTE_CREATE_UPDATE = !id
    ? STAND_TYPE_NOTE_CREATE
    : STAND_TYPE_NOTE_UPDATE;
  const standCreateUpdate = !id ? "standTypeNoteCreate" : "standTypeNotetUpdate";

  const [ _setStandNoteCreateUpdate, { loading } ] = useMutation(
    STAND_TYPE_NOTE_CREATE_UPDATE,
    {
      update(cache, { data })
      {
        const {
          [ standCreateUpdate ]: { label, message },
        } = data;

        successNotification({
          title: label,
          description: message,
        });
      },
      onError(error)
      {
        errorNotification(error);
      },
      refetchQueries: [
        {
          query: GET_STAND_TYPE_NOTES_CURSOR,
          variables,
        },
      ],
    }
  );

  return {
    _setStandNoteCreateUpdate,
    loadingMutationStandNoteCreateUpdate: loading,
  };
};

export default useMutationStandNoteCreateUpdate;
