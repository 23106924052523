import React from "react";
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { supplierUseMutation } from '../hooks';
import SupplierConst from '../supplier-const';
import { EntityRemoveButton, Localize } from 'components/service';
import { localizeByKey } from "components/service/localize";
import Icons from 'components/icons';


interface ISupplierActionFieldProps
{
    supplierId: string;
    supplierName: string;
}


const SupplierActionField: React.FC<ISupplierActionFieldProps> = ({ supplierId, supplierName }) =>
{

    const navigate = useNavigate();

    const {
        _setMutationSupplierDelete,
        loadingMutationSupplierDelete,
    } = supplierUseMutation.delete(supplierId);



    const items =
        [
            {
                label: <Localize>MENUS_DROP.Label_Edit</Localize>,
                key: '1',
                icon: <Icons.Edit />,
                onClick: () => navigate(`${SupplierConst.basePath}/${supplierId}`)
            },
            {
                key: '2',
                icon:
                    <EntityRemoveButton
                        modalButton={
                            <span style={{ display: 'flex', alignItems: 'center' }} ><Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize></span>
                        }
                        nameEntity={localizeByKey("ENTITY.Modal_Title_Supplier")}
                        dataNameEntity={supplierName}
                        loading={loadingMutationSupplierDelete}
                        deleteMutation={_setMutationSupplierDelete}
                        variables={{
                            id: supplierId,
                        }}
                    />,
            },
        ];


    return (
        <Dropdown
            // trigger={['click']}
            placement="bottomRight"
            menu={{ items }}
            overlayClassName="drop-more-menu"
            arrow
        >
            <EllipsisOutlined className="btn-more" />
        </Dropdown>
    );
};

export default SupplierActionField;

