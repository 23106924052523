import React from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { equipmentUseMutation } from "../hooks";
import EquipmentConst from "../equipment-const";
import { EntityRemoveButton, Localize } from "components/service";
import { localizeByKey } from "components/service/localize";
import { TEquipment } from "../helpers/equipment-types-helper";
import Icons from "components/icons";


interface IEquipmentActionFieldProps
{
  equipment: TEquipment | undefined;
  variables: Record<string, any>;
  language: string;
}


const EquipmentActionField: React.FC<IEquipmentActionFieldProps> = ({ equipment, variables, language }) =>
{
  const navigate = useNavigate();

  const { _setMutationEquipmentDelete, loadingMutationEquipmentDelete } =
    equipmentUseMutation.delete(equipment?.group_id, variables);

  const items = [
    {
      label: <Localize>MENUS_DROP.Label_Edit</Localize>,
      key: "1",
      icon: <Icons.Edit />,
      onClick: () =>
        navigate(
          `${EquipmentConst.basePath}/edit/${equipment?.group_id}/${language}`
        ),
    },
    {
      key: "2",
      label: (
        <EntityRemoveButton
          modalButton={
            <span style={{ display: "flex", alignItems: "center", width: 100 }}>
              <Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize>
            </span>
          }
          nameEntity={
            localizeByKey("ENTITY.Modal_Title_Equipment")
          }
          dataNameEntity={equipment?.title}
          loading={loadingMutationEquipmentDelete}
          deleteMutation={_setMutationEquipmentDelete}
          variables={{
            group_id: equipment?.group_id,
          }}
        />
      ),
    },
  ];

  return (
    <Dropdown
      // trigger={['click']}
      placement="bottomRight"
      menu={{ items }}
      overlayClassName="drop-more-menu"
      arrow
    >
      <EllipsisOutlined className="btn-more" />
    </Dropdown>
  );
};

export default EquipmentActionField;
