import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { USER_DELETE } from "graphql/mutation/user-gql";
import UserConst from "../users-const";
import
{
    successNotification,
    errorNotification,
} from "components/request-result";


const useMutationUserDelete = (id: string | undefined) =>
{
    const navigate = useNavigate();

    const [ _setMutationUserDelete, { loading } ] = useMutation(USER_DELETE, {
        update(cache, { data })
        {
            const {
                userDelete: { label, message },
            } = data;

            navigate(UserConst.basePath);

            cache.evict({
                id: cache.identify({ id, __typename: "User" }),
            });
            cache.gc();

            successNotification({
                title: label.toUpperCase(),
                description: message,
            });
        },
        onError(error)
        {
            errorNotification(error);
        },
    });

    const handleDeleteUser = async (options: { variables: Record<string, any> }) =>
    {
        await _setMutationUserDelete(options);
    };

    return {
        _setMutationUserDelete: handleDeleteUser,
        loadingMutationUserDelete: loading,
    };
};

export default useMutationUserDelete;
