import ExhibitionFields from "./fields";
import ExhibitionForms from "./forms";
import ExhibitionConst from "./exhibition-const";
import ExhibitionsHelpers from "./helpers";
import { exhibitionUseMutation } from "./hooks";


const Exhibition = {
  Fields: ExhibitionFields,
  Forms: ExhibitionForms,
  Const: ExhibitionConst,
  Helpers: ExhibitionsHelpers,
  Hooks: exhibitionUseMutation
};

export default Exhibition;
