import React from "react";
import { Routes, Route } from "react-router-dom";
import SubPage from "components/pages/equipment/sub-pages";
import type { TEquipment } from "components/equipment/helpers/equipment-types-helper";

interface IEquipmentRouteProps
{
    equipment: TEquipment | undefined;
    equipmentsGroup: TEquipment[] | undefined;
    conditionCreate: boolean;
    lang: string;
}

const EquipmentRoute: React.FC<IEquipmentRouteProps> = ({
    equipment,
    equipmentsGroup,
    conditionCreate,
    lang,
}) =>
{
    return (

        <Routes>
            <Route path="/" element={
                <SubPage.OverView
                    equipment={equipment}
                    equipmentsGroup={equipmentsGroup}
                    conditionCreate={conditionCreate}
                    lang={lang} />}
            />
            <Route path="/variations/page/:pageNum" element={<SubPage.Variations equipment={equipment} lang={lang} />} />
            <Route path="/variations/page" element={<SubPage.Variations equipment={equipment} lang={lang} />} />
            <Route path="/variations" element={<SubPage.Variations equipment={equipment} lang={lang} />} />
            <Route path="/substitude" element={<SubPage.Substitude equipment={equipment} lang={lang} />} />
        </Routes>
    );
};

export default EquipmentRoute;
