import React from 'react';
import { Form, Button, Skeleton } from 'antd';
import { useMutation, useQuery, DocumentNode } from '@apollo/client';
import { GET_USERS_CURSOR } from 'graphql/query/user-gql';
import { Localize } from 'components/service';
import { errorNotification, successNotification } from 'components/request-result';
import { FormElements } from 'components/layout';

interface IUserAddFormProps
{
    action: () => void;
    propsObj: {
        query: DocumentNode;
        queryVar: Record<string, any>;
        mutation: DocumentNode;
        mutationName: string;
        modelID: Record<string, any>;
    };
}

const UserAddForm: React.FC<IUserAddFormProps> = ({ action, propsObj }) =>
{
    const { query, queryVar, mutation, mutationName, modelID } = propsObj;

    const { data: dataQuery, loading: loadingQuery } = useQuery(query, {
        variables: { ...queryVar },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const [ form ] = Form.useForm();
    const [ _setMutation, { loading: loadingMutation } ] = useMutation(mutation, {
        refetchQueries: [ query ],

        update(cache, { data })
        {
            const {
                [ mutationName ]: { label, message },
            } = data;

            successNotification({
                title: label,
                description: message,
            });
        },
        onError(error)
        {
            errorNotification(error);
        },
    });

    return (
        <Form
            key="add-user-form"
            layout="vertical"
            className="add-user-form"
            form={form}
            onFinish={(values) =>
            {
                _setMutation({
                    variables: {
                        ...modelID,
                        ...values,
                    },
                });

                action();
            }}
        >
            {loadingQuery ? (
                <Skeleton active paragraph={{ rows: 10 }} />
            ) : (
                <FormElements.CheckboxGroup
                    form={form}
                    query={GET_USERS_CURSOR}
                    model="usersCursor"
                    modelCursorLabel={[ 'name', 'surname' ]}
                    name="user_id"
                    initialValue={
                        dataQuery?.users?.data?.length
                            ? dataQuery?.users?.data.map(({ id, name }: { id: string, name: string }) => ({ value: id, label: name }))
                            : false
                    }
                />
            )}

            <div className="form-btn-holder">
                <Button className="light-bg" onClick={action}>
                    <Localize>GLOBAL.Button_Text_Cancel</Localize>
                </Button>

                <Button className="btn-right" type="primary" htmlType="submit" loading={loadingMutation}>
                    <Localize>GLOBAL.Button_Text_Confirm</Localize>
                </Button>
            </div>
        </Form>
    );
};

export default UserAddForm;
