import React from "react";
import { Form, Input, Button, Switch } from "antd";
import { GET_SUPPLIER_CURSOR } from "graphql/query/supplier-gql";
import { GET_ROLES_CURSOR } from "graphql/query/role-gql";
import { GET_TEAMS_CURSOR } from "graphql/query/team-gql";
import { userUseMutation } from "../../hooks";
import UserFormItems from "../fields";
import { EntityRemoveButton } from "components/service";
import Localize, { localizeByKey } from "components/service/localize";
import { FormElements } from "components/layout";
import Icons from "components/icons";
import type { TUser } from "components/user/helpers/user-types-helper";

interface IUserEditFormProps
{
  user: TUser | undefined;
}

const UserEditForm: React.FC<IUserEditFormProps> = ({ user }) =>
{
  const [ form ] = Form.useForm();

  const { _setUserCreateUpdate, loadingMutationUserCreateUpdate: loading } =
    userUseMutation.createUpdate(user?.id);

  const { _setMutationUserDelete, loadingMutationUserDelete } =
    userUseMutation.delete(user?.id);

  const valueFieldStatus = Form.useWatch("status", form);

  const convertToInitialValue = (
    items: { value: string; label: string }[] | false
  ): { value: string; label: string }[] | undefined =>
  {
    return items === false ? undefined : items;
  };

  return (
    <Form
      key="edit-user-form"
      layout="vertical"
      form={form}
      className="model-form edit-user-form"
      onFinish={(values) =>
      {
        _setUserCreateUpdate({
          variables: {
            input: {
              id: user?.id ? +user.id : undefined,
              ...values,
              status:
                typeof values.status === "string"
                  ? user?.status
                  : values?.status === true
                    ? "active"
                    : "block",
            },
          },
        });
      }}
    >
      <div className="switch-holder">
        <Form.Item
          name="status"
          label={<Localize>FORMS.Input_Label_Status</Localize>}
          initialValue={user?.status === "active" ? true : false}
          valuePropName="checked"
          className="form-item-switch"
        >
          <Switch />
        </Form.Item>
        {valueFieldStatus ? (
          <span
            style={{ color: "var(--switch_colorPrimary)" }}
            className="switch-status-text"
          >
            <Localize>GLOBAL.Text_Active</Localize>
          </span>
        ) : (
          <span
            style={{ color: "var(--switch_colorTextQuaternary)" }}
            className="switch-status-text"
          >
            <Localize>GLOBAL.Text_Blocked</Localize>
          </span>
        )}
      </div>

      <div className="row-grid grid-gap-10 row-gap-0 col-md-2">
        <Form.Item
          name="name"
          label={<Localize>FORMS.Input_Label_Name</Localize>}
          initialValue={user?.name}
          rules={[
            {
              required: true,
              message: <Localize>FORM_RULES.Required_Name</Localize>,
            },
          ]}
        >
          <Input name="name" />
        </Form.Item>

        <Form.Item
          name="surname"
          label={<Localize>FORMS.Input_Label_Surname</Localize>}
          initialValue={user?.surname}
          rules={[
            {
              required: true,
              message: <Localize>FORM_RULES.Required_Surname</Localize>,
            },
          ]}
        >
          <Input name="surname" />
        </Form.Item>

        <Form.Item
          name="email"
          label={<Localize>FORMS.Input_Label_Email</Localize>}
          initialValue={user?.email}
          rules={[
            {
              type: "email",
              required: true,
              message: <Localize>FORM_RULES.Required_Email</Localize>,
            },
          ]}
        >
          <Input name="email" />
        </Form.Item>

        <Form.Item
          name="phone"
          label={<Localize>FORMS.Input_Label_Phone</Localize>}
          initialValue={user?.phone}
          rules={[
            {
              required: true,
              message: <Localize>FORM_RULES.Required_Phone</Localize>,
            },
            {
              validator: (_, value) =>
              {
                if (!value) {
                  return Promise.resolve();
                }
                if (/^[+0-9]{6,}$/.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  <Localize>FORM_RULES.Invalid_Phone</Localize>
                );
              },
            },
          ]}
        >
          <Input name="phone" />
        </Form.Item>

        <FormElements.RadioSelect
          form={form}
          query={GET_SUPPLIER_CURSOR}
          model="suppliersCursor"
          name="supplier_id"
          label="FORMS.Input_Label_Supplier"
          modalTitle="FORMS.Title_AddSupplier"
          initialValue={convertToInitialValue(
            user?.supplier?.id
              ? [ { value: user.supplier.id, label: user.supplier.title } ]
              : false
          )}
        />
      </div>

      <FormElements.CheckboxSelect
        form={form}
        query={GET_TEAMS_CURSOR}
        model="teamsCursor"
        name="team_id"
        label="FORMS.Input_Label_AssignedTeams"
        modalTitle="FORMS.Title_AddTeams"
        modalButtonText="MODAL.Button_Text_AddTeam"
        initialValue={convertToInitialValue(
          user?.teams.length
            ? user.teams.map(({ id, title }) => ({
              value: id,
              label: title,
            }))
            : false
        )}
      />

      <FormElements.CheckboxSelect
        form={form}
        query={GET_ROLES_CURSOR}
        model="rolesCursor"
        name="role_id"
        label="FORMS.Input_Label_Role"
        modalTitle="FORMS.Title_AddRoles"
        modalButtonText="MODAL.Button_Text_AddRole"
        initialValue={convertToInitialValue(
          user?.roles.length
            ? user.roles.map(({ id, title }) => ({
              value: id,
              label: title,
            }))
            : false
        )}
      />

      <div className="row-grid grid-gap-10 row-gap-0 col-md-2">
        <UserFormItems.LanguageSelect
          name="lang_app"
          label="FORMS.Input_Label_IntrfaceLanguage"
          initialValue={user?.lang_app}
        />

        <UserFormItems.LanguageSelect
          name="lang_api"
          label="FORMS.Input_Label_ContentLanguage"
          initialValue={user?.lang_api}
        />
      </div>

      <div className="form-btn-holder">
        {user?.id && (
          <EntityRemoveButton
            modalButton={
              <Button icon={<Icons.Delete />}>
                <span className="ellipsis">
                  <Localize>USER.Button_Text_Delete</Localize>
                </span>
              </Button>
            }
            nameEntity={
              localizeByKey("ENTITY.Modal_Title_User")
            }
            dataNameEntity={`${user?.name} ${user?.surname}`}
            loading={loadingMutationUserDelete}
            deleteMutation={_setMutationUserDelete}
            variables={{
              id: user?.id,
            }}
          />
        )}
        <Button
          className="btn-right"
          type="primary"
          htmlType="submit"
          icon={user?.id ? <Icons.Edit /> : <Icons.Plus />}
          loading={loading}
        >
          <span className="ellipsis">
            {user?.id ? (
              <Localize>GLOBAL.Button_Text_SaveChanges</Localize>
            ) : (
              <Localize>GLOBAL.Button_Text_CreateNew</Localize>
            )}
          </span>
        </Button>
      </div>
    </Form>
  );
};

export default UserEditForm;
