import { gql } from "@apollo/client";

export const ROLE_UPDATE = gql`
  mutation RoleUpdate($input: RoleFieldsInput!) {
    roleUpdate(input: $input) {
      label
      message
      role {
        id
        title
        slug
        colorHex
        description
        permissions {
          id
          translation {
            id
            lang
            slug
            translation {
              id
              slug
              value
            }
          }
        }
        created_at
        updated_at
      }
    }
  }
`;

export const ROLE_CREATE = gql`
  mutation RoleCreate($input: RoleFieldsInput!) {
    roleCreate(input: $input) {
      label
      message
      role {
        id
        title
        slug
        colorHex
        description
        permissions {
          id
          translation {
            id
            lang
            slug
            translation {
              id
              slug
              value
            }
          }
        }
        created_at
        updated_at
      }
    }
  }
`;

export const ROLE_SYNC_USERS = gql`
  mutation RoleSyncToUsers($user_id: [ID]!, $role_id: ID!) {
    roleSyncToUsers(user_id: $user_id, role_id: $role_id) {
      label
      message
    }
  }
`;

export const ROLE_DELETE = gql`
  mutation RoleDelete($id: ID!) {
    roleDelete(id: $id) {
      label
      message
    }
  }
`;

export const ROLE_REMOVE_USER = gql`
  mutation RoleRemoveUser($user_id: ID!, $role_id: ID!) {
    roleRemoveUser(user_id: $user_id, role_id: $role_id) {
      label
      message
    }
  }
`;
