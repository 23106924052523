import React, { useState } from "react";
import { Button, Input } from "antd";
import { useMatch, useNavigate } from "react-router-dom";

import { GET_TEAMS } from "graphql/query/team-gql";

import Team from "components/team";
import Icons from "components/icons";
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import Localize, { localizeByKey } from "components/service/localize";

import "./team.scss";

const { Search } = Input;

const basePath = Team.Const.basePath;
const tableHelper = Team.Helpers.TableMain;


const TeamsPage: React.FC = () =>
{
  useActiveMenuItem([ "teams" ], [ "teams" ]);

  const breadcrumbs = [
    {
      label: localizeByKey("BREADCRUMBS.Label_Teams"),
      path: `${basePath}`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const navigate = useNavigate();

  const match = useMatch(`${basePath}/page/:pageNum`);

  const [ searchText, setSearchText ] = useState("");

  return (
    <PageWrapDefault
      className="page-teams"
      dataExist={true}
      title={<Localize>PAGES.Title_Teams</Localize>}
      staticPath=""
    >
      <Tables.Main
        model="teams"
        query={GET_TEAMS}
        varSet={{ perPage: 50 }}
        routeUrl={`${basePath}`}
        currentPage={match ? parseInt(match.params.pageNum || "1") : 1}
        searchText={searchText}
        tableHelper={tableHelper}
      >
        <div className="table-action-bar">
          <div className="col">
            <Search
              className="filter-search"
              prefix={<Icons.Search />}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>

          <div className="col">
            <Button
              type="primary"
              icon={<Icons.Plus />}
              onClick={() => navigate(`${basePath}/create`)}
            >
              <span className="ellipsis">
                <Localize>GLOBAL.Button_Text_CreateNew</Localize>
              </span>
            </Button>
          </div>
        </div>
      </Tables.Main>
    </PageWrapDefault>
  );
};

export default TeamsPage;
