import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Pages } from 'components/pages';


const GraphicsRoute: React.FC = () =>
{
  return (
    <Routes>
      <Route path="/" element={<Pages.Graphics />} />
      <Route path="/:id/*" element={<Pages.Graphic />} />
      <Route path="/create" element={<Pages.Graphic />} />
    </Routes>
  );
};



export default GraphicsRoute;