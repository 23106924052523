import { gql } from "@apollo/client";

export const GET_EQUIPMENTS = gql`
  query GetEquipments(
    $group_id: ID
    $text: String
    $first: Int
    $page: Int
    $where: QueryEquipmentsWhereWhereConditions
    $orderBy: [QueryEquipmentsOrderByOrderByClause!]
  ) {
    equipments(
      group_id: $group_id
      text: $text
      first: $first
      page: $page
      orderBy: $orderBy
      where: $where
    ) {
      paginatorInfo {
        total
        count
        currentPage
        perPage
        lastPage
      }
      data {
        id
        group_id
        title
        lang
        type
        navision_number
        octacad_number
        color
        supplier_article
        supplier_id
        description
        getImages {
          id
          sizes
        }
        supplier {
          id
          title
        }
        relatives {
          id
          group_id
          title
          lang
        }
      }
    }
  }
`;

export const GET_EQUIPMENT_CURSOR = gql`
  query EquipmentsCursor(
    $group_id: ID
    $text: String
    $first: Int!
    $after: String
    $where: QueryEquipmentsCursorWhereWhereConditions
    $whereExhibitionTypeEquipment: QueryEquipmentsCursorWhereExhibitionTypeEquipmentWhereHasConditions
    $orderBy: [QueryEquipmentsCursorOrderByOrderByClause!]
  ) {
    equipmentsCursor(
      group_id: $group_id
      text: $text
      first: $first
      after: $after
      where: $where
      whereExhibitionTypeEquipment: $whereExhibitionTypeEquipment
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        total
        count
        currentPage
        lastPage
      }
      edges {
        cursor
        node {
          id
          group_id
          title
          lang
          type
          navision_number
          octacad_number
          color
          supplier_article
          supplier_id
          description
          getImages {
            id
            sizes
            title
          }
          supplier {
            id
            title
          }
          relatives {
            id
            group_id
            title
            lang
          }
        }
      }
    }
  }
`;

export const GET_EQUIPMENT = gql`
  query GetEquipment($id: ID) {
    equipment(id: $id) {
      id
      group_id
      title
      lang
      type
      navision_number
      octacad_number
      color
      supplier_article
      supplier_id
      description
      supplier {
        id
        title
      }
      getImages {
        id
        sizes
      }
    }
  }
`;

export const GET_EQUIPMENT_GROUP = gql`
  query GetEquipmentsGroup($group_id: ID!) {
    equipmentsGroup(group_id: $group_id) {
      id
      group_id
      title
      lang
      type
      navision_number
      octacad_number
      color
      supplier_article
      supplier_id
      description
      getImages {
        id
        sizes
      }
      supplier {
        id
        title
      }
    }
  }
`;

export const GET_EQUIPMENT_VARIATIONS = gql`
  query GetEquipmentVariations(
    $text: String
    $first: Int
    $page: Int
    $where: QueryEquipmentVariationsWhereWhereConditions
    $orderBy: [QueryEquipmentVariationsOrderByOrderByClause!]
  ) {
    equipmentVariations(
      text: $text
      first: $first
      page: $page
      where: $where
      orderBy: $orderBy
    ) {
      paginatorInfo {
        total
        count
        currentPage
        perPage
        lastPage
      }
      data {
        id
        equipment_group
        title
        organizer_id
        article
        price
        organizer {
          id
          title
        }
        created_at
        updated_at
      }
    }
  }
`;

export const GET_EQUIPMENT_VARIATION = gql`
  query GetEquipmentVariation($id: ID) {
    equipmentVariation(id: $id) {
      id
      equipment_group
      title
      organizer_id
      article
      price
      organizer {
        id
        title
      }
      created_at
      updated_at
    }
  }
`;
