import VariationInfoField from "./variation-info-field";
import VariationActionField from './variation-action-field';
import VariationOrganizerField from './variation-organizer-field';



const VariationFields = {
    Info: VariationInfoField,
    Action: VariationActionField,
    Organizer: VariationOrganizerField,
};

export default VariationFields;