import useMutationUserCreateUpdate from './user-use-mutation-create-update';
import useMutationUserSendPass from "./user-use-mutation-send-password";
import useMutationUserDelete from "./user-use-mutation-delete";
import useMutationUserRemove from "./user-use-mutation-remove";


export const userUseMutation = {
  createUpdate: useMutationUserCreateUpdate,
  sendPass: useMutationUserSendPass,
  delete: useMutationUserDelete,
  remove: useMutationUserRemove,
};