import React from "react";
import Role from "components/role";
import { useBreadCrumbs } from "components/use-hooks";
import { localizeByKey } from "components/service/localize";
import type { TRole } from "components/role/helpers/role-types-helper";

const roleBasePath = Role.Const.basePath;

interface IRoleOverviewSubPageProps
{
    role: TRole | undefined;
}

const RoleOverviewSubPage: React.FC<IRoleOverviewSubPageProps> = ({ role }) =>
{
    const breadcrumbs = [
        {
            label: localizeByKey("BREADCRUMBS.Label_Roles"),
            path: `${roleBasePath}`,
        },
        {
            label:
                (role && localizeByKey(role.title)) ||
                localizeByKey("BREADCRUMBS.Label_Create"),
            path:
                (role && `${roleBasePath}/${role?.id}`) ||
                `${roleBasePath}/create`,
        },
    ];

    useBreadCrumbs(breadcrumbs);

    return (
        <div className="row-grid col-xl-2">
            <div className="col-left">
                <Role.Forms.Edit role={role} />
            </div>
            <div className="col-right"></div>
        </div>
    );
};

export default RoleOverviewSubPage;