import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_EQUIPMENT_GROUP } from "graphql/query/equipment-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import Equipment from "components/equipment";
import EquipmentRoute from "components/routes/equipment/equipment-route";
import Localize, { localizeByKey } from "components/service/localize";
import { TEquipment } from "components/equipment/helpers/equipment-types-helper";


interface IEquipmentsGroup
{
  equipmentsGroup: TEquipment[];
}


const EquipmentPage: React.FC = () =>
{
  useActiveMenuItem([ "equipment" ], [ "equipment" ]);

  const basePath = Equipment.Const.basePath;
  const { group_id, lang } = useParams<{ group_id: string; lang: string }>();
  const equipmentID = group_id ? parseInt(group_id) : null;

  const containsCreate = (url: string) => url.includes("create");
  const conditionCreate = containsCreate(window.location.pathname);

  const { data, loading } = useQuery<IEquipmentsGroup>(GET_EQUIPMENT_GROUP, {
    skip: !equipmentID,
    variables: {
      group_id: equipmentID,
    },
    fetchPolicy: "cache-and-network",
    // nextFetchPolicy: "cache-first",
  });

  const equipment = data?.equipmentsGroup?.filter((item) => item?.lang === lang)[ 0 ];

  const pageNavbar = [
    {
      label: localizeByKey("NAVBAR_PAGES.Label_Overview"),
      path: "",
    },
    {
      label: localizeByKey("NAVBAR_PAGES.Label_Variations"),
      path: "variations",
    },
    {
      label: localizeByKey("NAVBAR_PAGES.Label_Substitude"),
      path: "substitude",
    },
  ];

  return (
    <PageWrapDefault
      className="page-equipment"
      loading={loading}
      title={
        conditionCreate ? (
          <Localize>PAGES.Title_CreateEquipment</Localize>
        ) : !loading && equipment ? (
          equipment.title ? (
            equipment.title
          ) : (
            <Localize>VARIABLE.Label_EMPTY</Localize>
          )
        ) : (
          "Page 404"
        )
      }
      dataExist={conditionCreate ? true : Boolean(!loading && equipment)}
      pageNavbar={!loading && equipment ? pageNavbar : undefined}
      staticPath={`${basePath}/edit/${equipmentID}/${lang}`}
    >
      <EquipmentRoute
        equipment={equipment as TEquipment}
        equipmentsGroup={data?.equipmentsGroup as TEquipment[]}
        conditionCreate={conditionCreate}
        lang={lang || ""}
      />
    </PageWrapDefault>
  );
};

export default EquipmentPage;