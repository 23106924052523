import SupplierOverviewSubPage from "./supplier-owerview-sub-page";
import SupplierUsersSubPage from "./supplier-users-sub-page";


const SubPage = {
    OverView: SupplierOverviewSubPage,
    Users: SupplierUsersSubPage
};

export default SubPage;
