import React from "react";
import { Button, FormInstance } from "antd";
import { GET_SUPPLIER_CURSOR } from "graphql/query/supplier-gql";
import { GET_ORGANIZERS_CURSOR } from "graphql/query/organizer-gql";
import Localize from "components/service/localize";
import { FormElements } from "components/layout";
import { IFilter } from 'components/supplier/helpers/supplier-types-helper';


interface IFilterList
{
  [ key: string ]: IFilter;
}

interface IGraphicFiltersFormProps
{
  setCount: React.Dispatch<any>;
  setFilters: React.Dispatch<React.SetStateAction<IFilter | undefined>>;
  setFilterList: React.Dispatch<React.SetStateAction<IFilterList | undefined>>;
  filterList: IFilterList | undefined;
  graphicFilters: IFilterList | undefined;
  action: () => void;
  gqlBuilderWhere: (filters: any) => any;
  form: FormInstance;
}


const GraphicFiltersForm: React.FC<IGraphicFiltersFormProps> = ({
  form,
  action,
  setCount,
  graphicFilters,
  setFilters,
  setFilterList,
  filterList,
  gqlBuilderWhere,
}) =>
{
  const onChangeSetFilter = (
    e: React.ChangeEvent<HTMLInputElement> | false,
    name: string,
    value: string | string[]
  ) =>
  {

    if (e) {
      name = e.target.name;
      value = e.target.value;
    }

    if (value.toString().length > 0) {
      setFilterList({
        ...filterList,
        [ name ]: {
          column: name,
          operator: "IN",
          value: Array.isArray(value) ? value : [ value ],
        },
      });
    } else {
      const newFilterList = { ...filterList };
      delete newFilterList[ name ];
      setFilterList(newFilterList);
    }
  };


  return (
    <div className="ant-form ant-form-vertical filters-form">
      <div>
        <FormElements.RadioSelect
          skipQuery={!graphicFilters}
          location={true}
          form={form}
          query={GET_SUPPLIER_CURSOR}
          model="suppliersCursor"
          name="SUPPLIER_ID"
          label="FORMS.Input_Label_PrintingCompany"
          modalTitle="FORMS.Title_SelectPrintingCompany"
          objectWhere={{
            column: "TYPE",
            value: "supplier_type_digital_print_supplier",
          }}
          initialValue={
            graphicFilters?.SUPPLIER_ID?.value
              ? [ { value: graphicFilters?.SUPPLIER_ID?.value.toString() } ]
              : undefined
          }
          onChangeSetFilter={() => onChangeSetFilter(false, "SUPPLIER_ID", form.getFieldValue("SUPPLIER_ID"))}
        />
        <FormElements.RadioSelect
          skipQuery={!graphicFilters}
          location={true}
          form={form}
          query={GET_ORGANIZERS_CURSOR}
          model="organizersCursor"
          name="ORGANIZER_ID"
          label="FORMS.Input_Label_Organizer"
          modalTitle="FORMS.Title_SelectOrganizer"
          initialValue={
            graphicFilters?.ORGANIZER_ID?.value
              ? [ { value: graphicFilters?.ORGANIZER_ID?.value.toString() } ]
              : undefined
          }
          onChangeSetFilter={() => onChangeSetFilter(false, "ORGANIZER_ID", form.getFieldValue("ORGANIZER_ID"))}
        />
      </div>

      <div className="form-btn-holder">
        <Button
          className="light-bg"
          htmlType="submit"
          onClick={() =>
          {
            setCount({});
            setFilterList({});
            setFilters(gqlBuilderWhere({}));
            localStorage.removeItem("graphicFilters");
            localStorage.removeItem("graphicsSorting");
          }}
        >
          <Localize>FORMS.Button_Text_ResetFilters</Localize>
        </Button>

        <Button
          className="btn-right"
          type="primary"
          htmlType="submit"
          onClick={() =>
          {
            setFilters(gqlBuilderWhere({ ...graphicFilters, ...filterList }));
            setCount({
              ...graphicFilters,
              ...filterList,
            });
            action();
          }}
        >
          <Localize>FORMS.Button_Text_ApplyFilters</Localize>
        </Button>
      </div>
    </div>
  );
};

export default GraphicFiltersForm;
