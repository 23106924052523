import { TTeam } from 'components/team/helpers/team-types-helper';
import { TSupplier } from 'components/supplier/helpers/supplier-types-helper';
import { TRole } from 'components/role/helpers/role-types-helper';


export const enum EUserStatus
{
    ACTIVE = "active",
    BLOCK = "block",
}

export type TUserSendPass = {
    id: string;
    user_id: string;
    send_user_id: string;
    message: string;
    created_at: Date;
};

export type TPermission = {
    id: string;
    slug: string;
    group_name: string;
    action: string;
    created_at: Date;
    updated_at: Date;
};

export type TUser = {
    id: string;
    name: string;
    surname: string;
    email: string;
    phone: string;
    lang_api: string;
    lang_app: string;
    status: EUserStatus;
    is_admin: boolean;
    job_type: string;
    supplier_id: number;
    is_online: boolean;
    last_visit_at: string;
    sendPassword: TUserSendPass;
    roles: TRole[];
    permissions: TPermission[];
    supplier: TSupplier;
    teams: TTeam[];
    created_at: Date;
    updated_at: Date;
};

export interface IFilter {
    column: string;
    operator: string;
    value: string[];
    AND?: IFilter[];
}
