import { Localize } from "components/service";
import React from "react";
import EquipmentConst from "../equipment-const";
import { TEquipment } from "../helpers/equipment-types-helper";

interface IEquipmentColorFieldProps
{
  equipment: TEquipment | undefined;
  disabledColor: boolean;
}

type TColorKey = keyof typeof EquipmentConst.colorHexList;

const EquipmentColorField: React.FC<IEquipmentColorFieldProps> = ({ equipment, disabledColor }) =>
{
  const { colorHexList } = EquipmentConst;
  const defaultColor: TColorKey = "equipment_color_blue";
  const color: TColorKey = (equipment?.color as TColorKey) ?? defaultColor;

  return (
    <div className="d-flex align-items-center">
      {!disabledColor ? (
        <div
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            marginRight: "7px",
            background: colorHexList[ color ],
          }}
        ></div>
      ) : null}

      <Localize>{equipment?.color}</Localize>
    </div>
  );
};

export default EquipmentColorField;
