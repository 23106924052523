import React from "react";
import { Form, Input, Button } from "antd";
import { useApolloClient } from "@apollo/client";
import { GET_ME } from "graphql/query/user-gql";
import { GET_SUPPLIER_CURSOR } from "graphql/query/supplier-gql";
import { GET_ORGANIZERS_CURSOR } from "graphql/query/organizer-gql";
import { GET_VARIABLE_LISTS_CURSOR } from "graphql/query/variable-gql";

import { graphicUseMutation } from "../../hooks";
import { Localize, EntityRemoveButton } from "components/service";
import { localizeByKey } from "components/service/localize";
import { FormElements } from "components/layout";
import { TGraphicSupplier } from "components/graphic/helpers/graphic-types-helper";
import Icons from "components/icons";

interface IGraphicEditFormProps
{
  graphic?: TGraphicSupplier | undefined;
}

interface IInitialValueItem
{
  value: string;
  label: string;
}

interface IGraphic
{
  id?: string;
  title?: string;
  graphicSupplierVariableList: { group_name: string; slug: string }[];
  organizer?: { id: string; title: string };
  supplier?: { id: string; title: string };
}

interface IVariable
{
  group_name: string;
  slug: string;
}

const GraphicEditForm: React.FC<IGraphicEditFormProps> = ({ graphic }) =>
{
  const [ form ] = Form.useForm();

  const client = useApolloClient();
  const cachedMe = client.readQuery({
    query: GET_ME,
  });
  const activeLang = cachedMe.me.lang_api;

  const {
    _setGraphicCreateUpdate,
    loadingMutationGraphicCreateUpdate: loading,
  } = graphicUseMutation.createUpdate(graphic?.id);

  const { _setMutationGraphicDelete, loadingMutationGraphicDelete } =
    graphicUseMutation.delete(graphic?.id);

  const getInitialValueBySupplierGroup = (
    graphic: IGraphic | undefined,
    groupName: string
  ): IInitialValueItem[] | undefined =>
  {
    if (!graphic) {
      return undefined;
    }

    const graphicsInGroup = graphic.graphicSupplierVariableList.filter(
      (item: { group_name: string; slug: string }) =>
        item.group_name.replace(/-/g, " ") === groupName
    );

    if (graphicsInGroup.length === 0) {
      return undefined;
    }

    return graphicsInGroup.map(({ slug }: { slug: string }) => ({
      value: slug,
      label: slug,
    }));
  };

  const objectWhere = (group: string) => ({
    AND: [
      {
        column: "LANG",
        value: activeLang,
      },
      {
        column: "GROUP_NAME",
        value: group,
      },
    ],
  });

  const generateVariableList = (values: Record<string, any>) =>
  {
    const variableList: IVariable[] = [];

    Object.keys(values).forEach((fieldName) =>
    {
      if (fieldName.startsWith("variableList_")) {
        const parts = fieldName.split("_");

        if (parts.length === 2 && parts[ 0 ] === "variableList") {
          const group_name = parts[ 1 ];

          if (values[ fieldName ]) {
            values[ fieldName ].forEach((slug: string) =>
            {
              variableList.push({
                group_name,
                slug,
              });
            });
          }
        }
      }
    });

    return variableList;
  };

  return (
    <Form
      key="edit-graphic-form"
      layout="vertical"
      form={form}
      className="model-form edit-graphic-form"
      onFinish={(values) =>
      {
        const valuesCopy = { ...values };

        Object.keys(values).forEach((fieldName) =>
        {
          if (fieldName.startsWith("variableList_")) {
            delete values[ fieldName ];
          }
        });

        _setGraphicCreateUpdate({
          variables: {
            input: {
              id: graphic?.id ? +graphic.id : undefined,
              ...values,
              variableList: generateVariableList(valuesCopy),
            },
          },
        });
      }}
    >
      <Form.Item
        name="title"
        label={<Localize>FORMS.Input_Label_Title</Localize>}
        initialValue={graphic?.title}
        rules={[
          {
            required: true,
            message: <Localize>FORM_RULES.Required_Title</Localize>,
          },
        ]}
      >
        <Input name="title" />
      </Form.Item>

      <div className="row-grid grid-gap-10 row-gap-0 col-md-2">
        <FormElements.RadioSelect
          form={form}
          query={GET_ORGANIZERS_CURSOR}
          model="organizersCursor"
          name="organizer_id"
          label="FORMS.Input_Label_Organizer"
          modalTitle="FORMS.Title_AddOrganizer"
          initialValue={
            graphic?.organizer?.id
              ? [
                {
                  value: graphic.organizer.id,
                  label: graphic.organizer.title,
                },
              ]
              : undefined
          }
          rules={[
            {
              required: true,
              message: <Localize>FORM_RULES.Required_Organizer</Localize>,
            },
          ]}
        />

        <FormElements.RadioSelect
          form={form}
          query={GET_SUPPLIER_CURSOR}
          model="suppliersCursor"
          name="supplier_id"
          label="FORMS.Input_Label_PrintingCompany"
          modalTitle="FORMS.Title_AddPrintingCompany"
          initialValue={
            graphic?.supplier?.id
              ? [ { value: graphic.supplier.id, label: graphic.supplier.title } ]
              : undefined
          }
          rules={[
            {
              required: true,
              message: <Localize>
                FORM_RULES.Required_PrintingCompany
              </Localize>,
            },
          ]}
        />
      </div>

      <FormElements.CheckboxGroup
        form={form}
        name="variableList_assembly-type"
        label="FORMS.Input_Label_AssemblyTypes"
        model="variableListsCursor"
        query={GET_VARIABLE_LISTS_CURSOR}
        modelCursorLabel={[ "slug" ]}
        modelCursorValue="slug"
        extraClass="height-checkbox-group-auto search-checkbox-group-none"
        objectWhere={objectWhere("assembly type")}
        initialLang={activeLang}
        initialValue={getInitialValueBySupplierGroup(graphic, "assembly type")}
      />

      <FormElements.CheckboxSelect
        form={form}
        name="variableList_graphic-material"
        model="variableListsCursor"
        query={GET_VARIABLE_LISTS_CURSOR}
        tagInfo={false}
        label="FORMS.Input_Label_Materials"
        modalTitle="FORMS.Title_AddGraphicMaterials"
        modalButtonText="MODAL.Button_Text_AddMore"
        modelCursorLabel={[ "slug" ]}
        modelCursorValue="slug"
        numberOfCol={"d-flex flex-wrap row-gap-10"}
        objectWhere={objectWhere("graphic material")}
        initialLang={activeLang}
        initialValue={getInitialValueBySupplierGroup(
          graphic,
          "graphic material"
        )}
      />

      <FormElements.CheckboxSelect
        form={form}
        name="variableList_oracal-color"
        model="variableListsCursor"
        query={GET_VARIABLE_LISTS_CURSOR}
        tagInfo={false}
        label="FORMS.Input_Label_ColorsOracal"
        modalTitle="FORMS.Title_AddGraphicColorsOracal"
        modalButtonText="MODAL.Button_Text_AddMore"
        modelCursorLabel={[ "slug" ]}
        modelCursorValue="slug"
        numberOfCol={"d-flex flex-wrap row-gap-10"}
        objectWhere={objectWhere("oracal color")}
        initialLang={activeLang}
        initialValue={getInitialValueBySupplierGroup(graphic, "oracal color")}
      />

      <div className="form-btn-holder">
        {graphic?.id && (
          <EntityRemoveButton
            modalButton={
              <Button icon={<Icons.Delete />}>
                <span className="ellipsis">
                  <Localize>GRAPHIC.Button_Text_Delete</Localize>
                </span>
              </Button>
            }
            nameEntity={localizeByKey("ENTITY.Modal_Title_Graphic")}
            dataNameEntity={graphic?.title}
            loading={loadingMutationGraphicDelete}
            deleteMutation={_setMutationGraphicDelete}
            variables={{
              id: graphic?.id,
            }}
          />
        )}
        <Button
          className="btn-right"
          type="primary"
          htmlType="submit"
          icon={graphic?.id ? <Icons.Edit /> : <Icons.Plus />}
          loading={loading}
        >
          <span className="ellipsis">
            {graphic?.id ? (
              <Localize>GLOBAL.Button_Text_SaveChanges</Localize>
            ) : (
              <Localize>GLOBAL.Button_Text_CreateNew</Localize>
            )}
          </span>
        </Button>
      </div>
    </Form>
  );
};

export default GraphicEditForm;
