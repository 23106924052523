import React from "react";
import { Routes, Route } from "react-router-dom";
import SubPage from "components/pages/stand/sub-pages";
import type { TStand } from "components/stand/helpers/stand-types-helper";

interface IStandTypeRouteProps
{
  stand: TStand | undefined;
  standTypesGroup: TStand[] | undefined;
  conditionCreate: boolean;
  lang: string;
}

const StandTypeRoute: React.FC<IStandTypeRouteProps> = ({
  stand,
  standTypesGroup,
  conditionCreate,
  lang,
}) =>
{
  return (

    <Routes>
      <Route path="/" element={
        <SubPage.OverView
          stand={stand}
          standTypesGroup={standTypesGroup}
          conditionCreate={conditionCreate}
          lang={lang} />}
      />
      <Route path="/equipments" element={<SubPage.Equipments stand={stand} lang={lang} />} />
      <Route path="/floorings" element={<SubPage.Floorings stand={stand} lang={lang} />} />
      <Route path="/files" element={<SubPage.Files stand={stand} lang={lang} />} />
      <Route path="/notes" element={<SubPage.Notes stand={stand} lang={lang} />} />
    </Routes>
  );
};

export default StandTypeRoute;
