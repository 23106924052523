import StandOverviewSubPage from "./stand-owerview-sub-page";
import StandEquipmentsSubPage from "./stand-equipments-sub-page";
import StandFlooringsSubPage from "./stand-floorings-sub-page";
import StandFilesSubPage from "./stand-files-sub-page";
import StandNotesSubPage from "./stand-notes-sub-page";


const SubPage = {
  OverView: StandOverviewSubPage,
  Equipments: StandEquipmentsSubPage,
  Floorings: StandFlooringsSubPage,
  Files: StandFilesSubPage,
  Notes: StandNotesSubPage,
};

export default SubPage;
