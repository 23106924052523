import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { ORGANIZER_DELETE } from "graphql/mutation/organizer-gql";
import OrganizerConst from "../organizer-const";
import
  {
    successNotification,
    errorNotification,
  } from "components/request-result";



const useMutationOrganizerDelete = (id: string | undefined) =>
{
  const navigate = useNavigate();

  const [ _setMutationOrganizerDelete, { loading } ] = useMutation(
    ORGANIZER_DELETE,
    {
      update(cache, { data })
      {
        const {
          organizerDelete: { label, message },
        } = data;

        navigate(OrganizerConst.basePath);

        cache.evict({
          id: cache.identify({ id, __typename: "Organizer" }),
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message,
        });
      },
      onError(error)
      {
        errorNotification(error);
      },
    }
  );

  const handleDelete = async (options: { variables: Record<string, any> }) =>
  {
    await _setMutationOrganizerDelete(options);
  };

  return {
    _setMutationOrganizerDelete: handleDelete,
    loadingMutationOrganizerDelete: loading,
  };
};

export default useMutationOrganizerDelete;
