import React from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { graphicUseMutation } from "../hooks";
import GraphicConst from "../graphic-const";
import { EntityRemoveButton } from "components/service";
import Localize, { localizeByKey } from "components/service/localize";
import { TGraphicSupplier } from "../helpers/graphic-types-helper";
import Icons from "components/icons";


interface IGraphicActionFieldProps
{
  graphic: TGraphicSupplier;
}


const GraphicActionField: React.FC<IGraphicActionFieldProps> = ({ graphic }) =>
{
  const navigate = useNavigate();

  const { _setMutationGraphicDelete, loadingMutationGraphicDelete } =
    graphicUseMutation.delete(graphic?.id);

  const items = [
    {
      label: <Localize>MENUS_DROP.Label_Edit</Localize>,
      key: "1",
      icon: <Icons.Edit />,
      onClick: () => navigate(`${GraphicConst.basePath}/${graphic?.id}`),
    },
    {
      key: "2",
      label: (
        <EntityRemoveButton
          modalButton={
            <span style={{ display: "flex", alignItems: "center", width: 100 }}>
              <Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize>
            </span>
          }
          nameEntity={
            localizeByKey("ENTITY.Modal_Title_Graphic")
          }
          dataNameEntity={graphic?.title}
          loading={loadingMutationGraphicDelete}
          deleteMutation={_setMutationGraphicDelete}
          variables={{
            id: graphic?.id,
          }}
        />
      ),
    },
  ];

  return (
    <Dropdown
      // trigger={['click']}
      placement="bottomRight"
      menu={{ items }}
      overlayClassName="drop-more-menu"
      arrow
    >
      <EllipsisOutlined className="btn-more" />
    </Dropdown>
  );
};

export default GraphicActionField;
