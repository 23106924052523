import { Switch, Form } from "antd";
import { exhibitionUseMutation } from "../hooks";
import { Localize } from "components/service";

interface IExhibitionStatusFieldProps
{
    id: string;
    status: string;
    dafaultExhibition: boolean;
}

const ExhibitionStatusField: React.FC<IExhibitionStatusFieldProps> = ({ id, status, dafaultExhibition }) =>
{
    const [ form ] = Form.useForm();

    const { _setExhibitionCreateUpdate } = exhibitionUseMutation.createUpdate(
        id,
        undefined
    );

    const valueFieldStatus = Form.useWatch("status", form);

    return (
        <Form form={form} className="">
            <div className="switch-holder">
                <Form.Item
                    name="status"
                    initialValue={status === "active" ? true : false}
                    style={{ marginBottom: 0 }}
                >
                    <Switch
                        disabled={dafaultExhibition && status === "active"}
                        checked={status === "active" ? true : false}
                        onChange={(checked) =>
                        {
                            _setExhibitionCreateUpdate({
                                variables: {
                                    input: {
                                        id,
                                        status: checked ? "active" : "closed",
                                    },
                                },
                            });
                        }}
                    />
                </Form.Item>

                {valueFieldStatus ? (
                    <span
                        style={{ color: "var(--switch_colorPrimary)" }}
                        className="switch-status-text"
                    >
                        <Localize>GLOBAL.Text_Active</Localize>
                    </span>
                ) : (
                    <span
                        style={{ color: "var(--switch_colorTextQuaternary)" }}
                        className="switch-status-text"
                    >
                        <Localize>GLOBAL.Text_Blocked</Localize>
                    </span>
                )}
            </div>
        </Form>
    );
};

export default ExhibitionStatusField;