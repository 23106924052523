import OrganizerFields from "../fields";
import { Localize } from "components/service";
import type { TOrganizer } from "components/organizer/helpers/organizer-types-helper";


const organizersColumns = [
  {
    title: <Localize>TABLES.Column_Title_ID</Localize>,
    dataIndex: "id",
    columnIndex: "ID",
    sorter: true,
    width: "4%",
  },
  {
    title: <Localize>TABLES.Column_Title_Logo</Localize>,
    dataIndex: "logo_path",
    columnIndex: "LOGO_PATH",
    // width: "10%",
    // sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Name</Localize>,
    dataIndex: "title",
    columnIndex: "TITLE",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_NumberStands</Localize>,
    dataIndex: "total_stands",
    columnIndex: "TOTAL_STANDS",
    // sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_NumberEquipment</Localize>,
    dataIndex: "total_equipment",
    columnIndex: "TOTAL_EQUIPMENT",
    // sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_NumberFlooring</Localize>,
    dataIndex: "total_floorings",
    columnIndex: "TOTAL_FLOORINGS",
    // sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Status</Localize>,
    dataIndex: "status",
    columnIndex: "STATUS",
    // sorter: true
  },
  {
    // title: '',
    dataIndex: "action",
    align: "right",
    width: "5%",
  },
];

const organizersData = (organizers: TOrganizer[]) =>
{
  if (!organizers) {
    return [];
  }

  return organizers.map((organizer) =>
  {
    return {
      key: organizer.id,
      id: organizer.id,
      logo_path: <OrganizerFields.Image organizer={organizer} />,
      title: <OrganizerFields.Info organizer={organizer} />,
      total_stands: organizer.total_stands ?? <strong>&mdash;</strong>,
      total_equipment: organizer.total_equipment ?? <strong>&mdash;</strong>,
      total_floorings: organizer.total_floorings ?? <strong>&mdash;</strong>,
      status: (
        <OrganizerFields.Status id={organizer.id} status={organizer?.status} />
      ),
      action: <OrganizerFields.Action organizer={organizer} />,
    };
  });
};

const OrganizersTableHelper = {
  columns: organizersColumns,
  data: organizersData,
};

export default OrganizersTableHelper;
