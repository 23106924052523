import { ReactiveVar } from "@apollo/client";
import rVar, { IPrivateReactiveVar, IPublicReactiveVar } from "graphql/reactive-variables";

type TReactiveType = "public" | "private";

interface IReactiveVar<T>{
    reactiveVar  : ReactiveVar<T>,
    initialParam : T
}

interface IReactiveHelper {
    set        : ( params  : object ) => void,
    get        : ( param   : string ) => any,
    reset      : () => void
}

const reactiveVarHelper  = <T,>({ reactiveVar, initialParam } : IReactiveVar<T>) : IReactiveHelper => {

    return {
        set : (params) => {

            reactiveVar({
                ...reactiveVar(),
                ...params
            });
        },
        get : (param = "all") => {

            const varParams = { ...reactiveVar() };

            if(param === "all"){
                return varParams;
            }

            return varParams[param as keyof typeof varParams];

        },
        reset : () =>  {
            reactiveVar(initialParam);
        }
    };

};


const getReactiveVariableHelper  = ( type : TReactiveType = "private" ) : IReactiveHelper => {
    //type t = keyof type;
    // return reactiveVarHelper<TRVar<t>>({
    //     reactiveVar  : rVar[type].handler,
    //     initialParam : rVar[type].data,
    // });

    if(type === "public") {
        return reactiveVarHelper<IPublicReactiveVar>({
            reactiveVar : rVar.public.handler,
            initialParam : rVar.public.data,
        });
    }

    return reactiveVarHelper<IPrivateReactiveVar>({
        reactiveVar : rVar.private.handler,
        initialParam : rVar.private.data
    });

};

export default getReactiveVariableHelper;