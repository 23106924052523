import GraphicFields from "./fields";
import GraphicForms from "./forms";
import GraphicConst from "./graphic-const";
import GraphicsHelpers from "./helpers";
import GraphicFilters from "./filters";
import { graphicUseMutation } from "./hooks";

const Graphic = {
  Fields: GraphicFields,
  Forms: GraphicForms,
  Const: GraphicConst,
  Helpers: GraphicsHelpers,
  Hooks: graphicUseMutation,
  Filters: GraphicFilters,
};

export default Graphic;
