import { gql } from "@apollo/client";

export const GET_GRAPHIC_SUPPLIERS_CURSOR = gql`
  query GraphicSuppliersCursor(
    $text: String
    $first: Int!
    $after: String
    $where: QueryGraphicSuppliersCursorWhereWhereConditions
    $orderBy: [QueryGraphicSuppliersCursorOrderByOrderByClause!]
  ) {
    graphicSuppliersCursor(
      text: $text
      first: $first
      after: $after
      where: $where
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        total
        count
        currentPage
        lastPage
      }
      edges {
        cursor
        node {
          id
          title
          organizer_id
          supplier_id
          graphicSupplierVariableList {
            id
            group_name
            graphic_supplier_id
            slug
          }
          organizer {
            id
            title
            logo_path
            et_default_id
            status
            total_stands
            total_equipment
            total_floorings
          }
          supplier {
            id
            title
            number
            type
            usersCount
            description
          }
        }
      }
    }
  }
`;

export const GET_GRAPHIC_SUPPLIER = gql`
  query GetGraphicSupplier($id: ID) {
    graphicSupplier(id: $id) {
      id
      title
      organizer_id
      supplier_id
      graphicSupplierVariableList {
        id
        group_name
        graphic_supplier_id
        slug
      }
      organizer {
        id
        title
        logo_path
        et_default_id
        status
        total_stands
        total_equipment
        total_floorings
      }
      supplier {
        id
        title
        number
        type
        usersCount
        description
      }
    }
  }
`;

export const GET_GRAPHIC_SUPPLIER_RULES_CURSOR = gql`
  query GraphicSupplierRulesCursor(
    $text: String
    $first: Int!
    $after: String
    $where: QueryGraphicSupplierRulesCursorWhereWhereConditions
    $orderBy: [QueryGraphicSupplierRulesCursorOrderByOrderByClause!]
  ) {
    graphicSupplierRulesCursor(
      text: $text
      first: $first
      after: $after
      where: $where
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        total
        count
        currentPage
        lastPage
      }
      edges {
        cursor
        node {
          id
          title
          graphic_supplier_id
          price_manufacture
          price_on_supplier
          price_on_stand
          price_additional
          free_symbols
          lettering
        }
      }
    }
  }
`;

export const GET_GRAPHIC_SUPPLIER_RULE = gql`
  query GetGraphicSupplierRule($id: ID) {
    graphicSupplierRule(id: $id) {
      id
      title
      graphic_supplier_id
      price_manufacture
      price_on_supplier
      price_on_stand
      price_additional
      free_symbols
      lettering
    }
  }
`;
