import SupplierEditForm from "./supplier-edit-form";
import SupplierFiltersForm from "./supplier-filters-form";


const SupplierForms = {
    Edit: SupplierEditForm,
    Filter: SupplierFiltersForm,
};

export default SupplierForms;
