import { gql } from "@apollo/client";

export const ORGANIZER_CREATE = gql`
  mutation OrganizerCreate($input: OrganizerFieldsInput!) {
    organizerCreate(input: $input) {
      label
      message
      organizer {
        id
        title
        logo_path
        et_default_id
        status
        total_stands
        total_equipment
        total_floorings
        suppliers {
          id
          et_id
          supplier_id
          supplier_group
          supplier_title
        }
      }
    }
  }
`;

export const ORGANIZER_UPDATE = gql`
  mutation OrganizerUpdate($input: OrganizerFieldsInput!) {
    organizerUpdate(input: $input) {
      label
      message
      organizer {
        id
        title
        logo_path
        et_default_id
        status
        total_stands
        total_equipment
        total_floorings
        suppliers {
          id
          et_id
          supplier_id
          supplier_group
          supplier_title
        }
      }
    }
  }
`;

export const ORGANIZER_DELETE = gql`
  mutation OrganizerDelete($id: ID!) {
    organizerDelete(id: $id) {
      label
      message
    }
  }
`;
