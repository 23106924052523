import { UsersPage, UserPage } from "./user";
import { RolesPage, RolePage } from "./role";
import { SuppliersPage, SupplierPage } from "./supplier";
import { TeamsPage, TeamPage } from "./team";
import { FlooringsPage, FlooringPage } from "./flooring";
import { EquipmentsPage, EquipmentPage } from "./equipment";
import { VariableGroupPage, VariableListPage } from "./variable";
import { GraphicsPage, GraphicPage } from "./graphic";
import { StandsPage, StandPage } from "./stand";
import { ExhibitionsPage, ExhibitionPage } from "./exhibition";
import { OrganizersPage, OrganizerPage } from "./organizer";

import Dashboard from "./dashboard/dashboard-page";
import Page404 from "./404/404-page";

import "./pages.scss";

export const Pages = {
    Users: UsersPage,
    User: UserPage,
    Roles: RolesPage,
    Role: RolePage,
    Suppliers: SuppliersPage,
    Supplier: SupplierPage,
    Teams: TeamsPage,
    Team: TeamPage,
    Floorings: FlooringsPage,
    Flooring: FlooringPage,
    Equipments: EquipmentsPage,
    Equipment: EquipmentPage,
    VariableGroup: VariableGroupPage,
    VariableList: VariableListPage,
    Graphics: GraphicsPage,
    Graphic: GraphicPage,
    Stands: StandsPage,
    Stand: StandPage,
    Exhibitions: ExhibitionsPage,
    Exhibition: ExhibitionPage,
    Organizers: OrganizersPage,
    Organizer: OrganizerPage,
    Dashboard,
    Page404,
};
