import React from "react";
import { Localize } from "components/service";
import FlooringConst from "../flooring-const";
import { TFlooring } from "../helpers/flooring-types-helper";

interface IFlooringColorFieldProps
{
  flooring: TFlooring | undefined;
  disabledColor: boolean | Record<string, any>;
}


const FlooringColorField: React.FC<IFlooringColorFieldProps> = ({ flooring, disabledColor }) =>
{
  const { colorHexList } = FlooringConst;


  return (
    <div className="d-flex align-items-center">
      {!disabledColor ? (
        <div
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            marginRight: "7px",
            background: colorHexList(flooring?.color || "flooring_color_blue"),
          }}
        ></div>
      ) : null}

      <Localize>{flooring?.color}</Localize>
    </div>
  );
};

export default FlooringColorField;