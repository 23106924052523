import UserInfoField from "./user-info-field";
import UserRoleField from "./user-role-field";
import UserActionField from './user-action-field';
import UserPhoneField from './user-phone-field';
import UserStatus from './user-status-field';
import UserRemoveField from './user-remove-field';
import UserSupplierField from './user-supplier-field';
import UserTeamField from './user-team-field';


const UserFields = {
    Info: UserInfoField,
    Role: UserRoleField,
    Team: UserTeamField,
    Phone: UserPhoneField,
    Status: UserStatus,
    Supplier: UserSupplierField,
    Remove: UserRemoveField,
    Action: UserActionField,
};

export default UserFields;